import React from 'react';
import moment from 'moment';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';

import { t } from '^/i18n';
import {
  changePracticeAndNavToRoute,
  changePracticeAndOpenSetupCycleDateFormModal,
} from '^/actions/actionSequences';
import PracticeProgress from '^/components/app/users/profile/my-practices/PracticeProgress';
import {
  CYCLE_SETUP_STAGES,
  PRACTICE_SETUP_STAGES,
  SETUP_URLS,
  SUBSEQUENT_SETUP_URLS,
} from '^/consts/setup';

export const CurrentCycleDetails = ({ currentCycle, cycleNumber }) => (
  <div>
    <h5>{t('common.cycle', 'Cycle')} {cycleNumber}</h5>
    <p>
      <strong>{t('common.started', 'Started')}:</strong> {moment(currentCycle.get('start_date', '')).format('DD/MM/YY')}&nbsp;
      <strong>{t('common.ends', 'Ends')}:</strong> {moment(currentCycle.get('end_date', '')).format('DD/MM/YY')}
    </p>
    <hr className="thin" />
  </div>
);

export const NextCycleDetails = ({ nextCycle, nextCycleNumber }) => (
  <div>
    <h5>
      {t('common.cycle', 'Cycle')} {nextCycleNumber} - <em>{t('users.profile.upcomingCycle', 'UPCOMING CYCLE')}</em>
    </h5>
    <p>
      <strong>{t('common.starts', 'Starts')}:</strong> {moment(nextCycle.get('start_date', '')).format('DD/MM/YY')}&nbsp;
      <strong>{t('common.ends', 'Ends')}:</strong> {moment(nextCycle.get('end_date', '')).format('DD/MM/YY')}
    </p>
    <hr className="thin" />
  </div>
);

export const SetupNewCycle = props => (
  <div>
    <h5>{t('users.profle.noCycleSetupMsg', 'You have not set up a cycle for this practice yet')}.</h5>
    <button
      className="btn btn-complete centered fullwidth"
      onClick={props.openSetupCycleDateFormModal}
    >
      {t('users.profile.setupCycle', 'setup cycle')}
    </button>
    <hr className="thin" />
  </div>
);

export const SetupNewCycleAsCurrentExpiresSoon = props => (
  <div>
    <h5>
      {t(
        'users.profile.cycleExpiresSoonMsg',
        `Your current cycle expires soon and you have not set up a new cycle for this practice yet.`
      )}
    </h5>
    <button
      className="btn btn-complete centered fullwidth"
      onClick={props.openSetupCycleDateFormModal}
    >
      {t('users.profile.setupCycle', 'setup cycle')}
    </button>
    <hr className="thin" />
  </div>
);

export const SetupPartiallyCompleteCycle = props => (
  <div>
    <h5>
      {t(
        'users.profile.cycleNotFinishedMsg',
        'You have not finished setting up a cycle for this practice yet'
      )}.
    </h5>
    <button
      className="btn btn-complete centered fullwidth"
      onClick={props.onContinueSetup}
    >
      {t('users.profile.continueSetupCycle', 'continue setup cycle')}
    </button>
    <hr className="thin" />
  </div>
);

export const CycleDetails = props => {
  const {
    practice,
    shouldShowSetupNextCycle,
    shouldShowSetupPartiallyCompleteCycle,
    partiallyCompleteSetupUrl,
  } = props;

  const { cycles, current_cycle_number, current_cycle } = practice.toObject();

  const nextUpcomingCycle = cycles
    .filter(
      cycle =>
        cycle.get('setup_stage') === CYCLE_SETUP_STAGES.COMPLETED &&
        cycle.get('start_date') &&
        moment(cycle.get('start_date')).isAfter(moment())
    )
    .sortBy(cycle => cycle.get('start_date'))
    .first();

  const nextUpcomingCycleNumber =
    nextUpcomingCycle &&
    cycles
      .sortBy(cycle => cycle.get('start_date'))
      .findIndex(cycle => cycle.get('id') === nextUpcomingCycle.get('id')) + 1;

  if (current_cycle) {
    return (
      <div>
        <CurrentCycleDetails
          cycleNumber={current_cycle_number}
          currentCycle={current_cycle}
        />
        {shouldShowSetupNextCycle && (
          <SetupNewCycleAsCurrentExpiresSoon
            openSetupCycleDateFormModal={() =>
              props.openSetupCycleDateFormModal(practice)
            }
          />
        )}
        <PracticeProgress practice={practice} />
      </div>
    );
  }

  if (!current_cycle && nextUpcomingCycle) {
    return (
      <NextCycleDetails
        nextCycle={nextUpcomingCycle}
        nextCycleNumber={nextUpcomingCycleNumber}
      />
    );
  }

  if (shouldShowSetupPartiallyCompleteCycle) {
    return (
      <SetupPartiallyCompleteCycle
        onContinueSetup={() =>
          props.changePracticeAndNavToRoute(
            practice.get('id'),
            partiallyCompleteSetupUrl
          )
        }
      />
    );
  }

  if (shouldShowSetupNextCycle) {
    return (
      <SetupNewCycle
        openSetupCycleDateFormModal={() =>
          props.changePracticeAndOpenSetupCycleDateFormModal(practice)
        }
      />
    );
  }

  return <noscript />;
};

CycleDetails.propTypes = {
  practice: ImmutablePropTypes.map.isRequired,
};

export const mapStateToProps = (state, props) => {
  const practice = props.practice;
  const cycles = practice.get('cycles');

  const cycleToSetup = cycles.find(
    cycle => cycle.get('setup_stage') !== CYCLE_SETUP_STAGES.COMPLETED,
    null,
    null
  );

  const practiceSetupComplete =
    practice.get('setup_stage') === PRACTICE_SETUP_STAGES.COMPLETED;
  const cycleSetupComplete = !cycles.isEmpty() && !cycleToSetup;

  const setupComplete = practiceSetupComplete && cycleSetupComplete;

  const dashboardPage = '/pages/dashboard/';
  let partiallyCompleteSetupUrl = dashboardPage;
  if (!setupComplete) {
    if (cycles.size > 1) {
      partiallyCompleteSetupUrl = SUBSEQUENT_SETUP_URLS(
        cycleToSetup.get('id')
      ).get(cycleToSetup.get('setup_stage'), dashboardPage);
    } else if (!practiceSetupComplete) {
      partiallyCompleteSetupUrl = SETUP_URLS.get(
        practice.get('setup_stage'),
        dashboardPage
      );
    } else if (cycles.isEmpty()) {
      partiallyCompleteSetupUrl = SETUP_URLS.get(
        CYCLE_SETUP_STAGES.CYCLE_RESPONSIBILITIES,
        dashboardPage
      );
    } else {
      partiallyCompleteSetupUrl = SETUP_URLS.get(
        cycleToSetup.get('setup_stage'),
        dashboardPage
      );
    }
  }

  const shouldShowSetupNextCycle =
    !practice.get('current_cycle') && practice.get('should_setup_next_cycle');

  return {
    shouldShowSetupPartiallyCompleteCycle: !setupComplete,
    partiallyCompleteSetupUrl,
    shouldShowSetupNextCycle,
  };
};

export default connect(mapStateToProps, {
  changePracticeAndNavToRoute,
  changePracticeAndOpenSetupCycleDateFormModal,
})(CycleDetails);
