import React from 'react';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import { setShowing, setSearchText } from '^/actions/actions';
import PureComponent from '^/components/common/PureComponent';
import Icon from '^/components/app/content/Icon';
import SystemsListing from './SystemsListing';
import TasksListing from './TasksListing';
import ReportsFilter from './ReportsFilter';
import { t } from '^/i18n';

export const FILTER_NAME = 'REPORTS_FILTER';

export class ReportsPage extends PureComponent {

  componentWillMount() {
    this.props.setFilter(null);
  }

  render() {
    const { filter, searchText } = this.props;

    return (
      <div className="col-1 mt-2">
        <h2>{ t('reports.complianceReport', 'Compliance reports') }</h2>

        <p className="help-block">
          <Icon type="lightbulb-o" className="tip-icon" />
          { t('reports.complianceReportHelpBlockText', `Select the practice, compliance system, and how you'd like it filtered to generate a report.`) }
        </p>

        <ReportsFilter
          filter={filter} searchText={searchText}
          applyFilter={_filter => this.props.setFilter((filter || Map()).merge(_filter))}
          applySearch={this.props.setSearchText}
        />

        <SystemsListing filter={filter} searchText={searchText} />

        <TasksListing filter={filter.set('completed_only', true)} searchText={searchText} />

      </div>
    );
  }
}


export function mapStateToProps(state) {
  return {
    filter: state.ui.getIn(['showing', FILTER_NAME], Map()),
    searchText: state.ui.get('searchText'),
  };
}

export default connect(
  mapStateToProps,
  { setFilter: filter => setShowing(FILTER_NAME, filter), setSearchText }
) (ReportsPage);
