import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  Audit,
  AuditCategoryProgress,
} from '^/components/app/digital-tools/audit/types';
import { getAuditById } from '^/actions/audit';
import { selectAuditById } from '^/selectors/audit';
import Loading from '^/components/app/content/Loading';
import { closeModal } from '^/actions/modals';
import { makeStaticPath } from '^/utils';
import { StoreState } from '^/store/types';
import { t } from '^/i18n';

interface DispatchProps {
  onCancel: typeof closeModal;
  getAuditById: typeof getAuditById;
}

type OwnProps = {
  callback: () => void;
  auditId: string;
  audit: Audit;
  isLoading: boolean;
  allQuestionsComplete: boolean;
  statusText: string;
  shouldAllowContinue: boolean;
};

export type Props = DispatchProps & OwnProps;

export class ConfirmAuditStageModal extends Component<Props, {}> {
  public componentDidMount() {
    const { auditId } = this.props;
    this.props.getAuditById(auditId);
  }

  public render() {
    const {
      audit,
      isLoading,
      allQuestionsComplete,
      statusText,
      shouldAllowContinue,
    } = this.props;

    if (isLoading) {
      return <Loading />;
    }

    return (
      <div className="modal-body large-text">
        <div className="p-1 clearfix">
          <div className="text-center">
            <img
              src={makeStaticPath('images/brand/logo-color.svg')}
              className="brand-logo"
            />
          </div>
          {statusText === t('common.audit', 'Audit') &&
          !allQuestionsComplete ? (
            <div>
              <p className="mt-2">
                {t(
                  'digitalTools.audit.completeAllQuestions',
                  'In order to progress to the next stage of the audit you must complete all of the questions.'
                )}
              </p>
              <p className="mt-2">
                {t(
                  'digitalTools.audit.referToGreenTicks',
                  'Please refer to the green ticks in the top right corner of the page to identify incomplete sections.'
                )}
              </p>
            </div>
          ) : (
            <p className="mt-2">
              {t(
                'digitalTools.audit.areYouSureYouWantToComplete',
                'Are you sure you want to complete the {statusText} stage? Once you confirm you will not be able to return to the {statusText} stage.',
                { statusText }
              )}{' '}
            </p>
          )}
          {statusText === 'Actions' && (
            <p className="mt-1">
              {`${
                audit.incorrect_questions_count
                  ? t(
                      'digitalTools.audit.youHaveActioned',
                      'You have actioned {actionedCount}/{totalCount} actions.',
                      {
                        actionedCount: audit.incorrect_questions_actioned_count,
                        totalCount: audit.incorrect_questions_count,
                      }
                    )
                  : t('common.noActionsToComplete', 'No actions to complete')
              }`}
            </p>
          )}
          {statusText === 'Actions' && (
            <p className="mt-1">
              {t(
                'digitalTools.audit.makeSureYouHaveScheduledAllFollowUpTasks',
                'Make sure you have scheduled all follow-up Tasks required.'
              )}
            </p>
          )}
          {shouldAllowContinue && (
            <p className="mt-1">
              {t(
                'digitalTools.audit.clickConfirmToComplete',
                'Click CONFIRM to complete the {statusText} Stage.',
                { statusText }
              )}
            </p>
          )}
          <p className="mt-1">
            {t(
              'digitalTools.audit.clickCancelToGoBack',
              'Click CANCEL to go back to the {statusText} Stage.',
              { statusText }
            )}
          </p>
          <button
            className="btn btn-secondary pull-left mt-2"
            onClick={this.props.onCancel}
          >
            {t('common.button.cancel', 'Cancel')}
          </button>
          {shouldAllowContinue && (
            <button
              className="btn btn-save pull-right mt-2"
              onClick={this.onConfirm}
            >
              {t('common.button.confirm', 'Confirm')}
            </button>
          )}
        </div>
      </div>
    );
  }

  private onConfirm = () => {
    this.props.callback();
    this.props.onCancel();
  };
}

const mapStateToProps = (state: StoreState, props: OwnProps) => {
  const questionCategories = state.audit.questionsCategories || [];
  const audit = selectAuditById(state, props.auditId);
  const allQuestionsComplete = !questionCategories.filter(
    (category: AuditCategoryProgress) => category.is_completed !== true
  ).length;
  const statusText =
    audit && audit.status[0] + audit.status.toLowerCase().substring(1);
  const shouldAllowContinue = !(
    statusText === t('common.audit', 'Audit') && !allQuestionsComplete
  );
  return {
    audit: audit,
    isLoading: state.thunkRequests.getAuditById.isLoading,
    allQuestionsComplete: allQuestionsComplete,
    statusText: statusText,
    shouldAllowContinue: shouldAllowContinue,
  };
};

export default connect<{}, DispatchProps, OwnProps>(mapStateToProps, {
  onCancel: closeModal,
  getAuditById,
})(ConfirmAuditStageModal);
