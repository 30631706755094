import { List } from 'immutable';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';

import { t } from '^/i18n';
import { getCollection } from '^/actions/collections';
import Loading from '^/components/app/content/Loading';
import PureComponent from '^/components/common/PureComponent';
import { GROUP_TEMPLATE_DOCUMENT_DRAFTS } from '^/consts/collectionKeys';
import { isPending } from '^/consts/responseStates';
import { isLoggedOnUserAdminOfCurrentGroup } from '^/stateHelpers';
import { formatDate } from '^/utils';

const PAGE_SIZE = 12;

export class GroupTemplateDocumentDrafts extends PureComponent {
  componentWillMount() {
    this.loadDrafts();
  }

  loadDrafts(currentPage=0, shouldAppend=false) {
    this.props.getCollection(
      GROUP_TEMPLATE_DOCUMENT_DRAFTS,
      {
        shouldAppend,
        filters: { page_size: PAGE_SIZE },
        page: currentPage + 1,
      }
    );
  }

  render() {
    const { drafts, currentPage, hasMore, isLoading, userIsGroupAdmin } = this.props;
    return (
      <div>
        <h2>{t('groups.drafts', 'Drafts')}</h2>
        {userIsGroupAdmin && (
          <Link
            to="/page/groups/group-documents/templates/"
            className="btn btn-primary pull-right mb-1-2 ml-1"
          >
            {t('groups.createNewDocument', 'Create new document')}
          </Link>
        )}
        <table className="responsive padded padded-sm">
          <thead>
            <tr>
              <th>{t('groups.code', 'Code')}</th>
              <th>{t('groups.templateName', 'Template name')}</th>
              <th>{t('groups.folder', 'Folder')}</th>
              <th>{t('groups.lastEdited', 'Last edited')}</th>
            </tr>
          </thead>
          <tbody>
            {drafts.map(draft => {
              return (
                <tr key={draft.get('id')} className="row">
                  <td data-title="Code">
                    {draft.get('code')}
                  </td>
                  <td>
                    <Link
                      to={`/page/groups/group-documents/templates/${draft.get('id')}/`}
                      className="underlined"
                    >
                      {draft.get('name')}
                    </Link>
                  </td>
                  <td data-title="Folder">
                    {draft.getIn(['folder', 'number'])}
                  </td>
                  <td data-title="Last edited">
                    {formatDate(draft.get('modified'))}
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
        {isLoading && <Loading />}
        {(!isLoading && !drafts.count()) && (
          <p className="help-block mt-1">
            {t('groups.noDraftsFound', 'No drafts found.')}
          </p>
        )}
        {hasMore &&
          <a className="inline-link pull-right" onClick={() => this.loadDrafts(currentPage, true)}>
            {t('common.button.loadMore', 'Load more')} {'>>'}
          </a>
        }
      </div>
    );
  }
};

export function mapStateToProps(state) {
  return {
    drafts: state.collections.getIn([GROUP_TEMPLATE_DOCUMENT_DRAFTS, 'items'], List()),
    currentPage: state.collections.getIn([GROUP_TEMPLATE_DOCUMENT_DRAFTS, 'page']),
    isLoading: isPending(state.responses.getIn(['getCollection', GROUP_TEMPLATE_DOCUMENT_DRAFTS])),
    hasMore: state.collections.getIn([GROUP_TEMPLATE_DOCUMENT_DRAFTS, 'hasMore']),
    userIsGroupAdmin: isLoggedOnUserAdminOfCurrentGroup(state),
  };
};

export default connect(mapStateToProps, {
  getCollection
})(GroupTemplateDocumentDrafts);
