import React from 'react';
import { connect } from 'react-redux';
import { Map } from 'immutable';

import { t } from '^/i18n';
import Table from '^/components/app/groups/insights/visualisations/Table';
import TableFooter from '^/components/app/groups/insights/visualisations/TableFooter';
import { changePracticeAndNavToDashboard } from '^/actions/actionSequences';
import withLoadInsightDataOnMount from '^/components/app/groups/insights/hoc/withLoadInsightDataOnMount';
import { INSIGHTS, INSIGHTS_API_URLS, INSIGHTS_CSV_API_URLS } from '^/components/app/groups/insights/constants';
import { getSubGroupFilterFromPath } from '^/components/app/groups/insights/utils';

const INSIGHT = INSIGHTS.ACTIVE_USER_COUNT_BY_PRACTICE;

const COLUMNS = (onClick) => [
  {
    key: 'practice_name',
    header: t('groups.practice', 'Practice'),
    className: 'text-align-left',
    cell: ({ row }) =>
      <a onClick={() => onClick(row.get('practice_id'))}>
        {row.get('practice_name')}
      </a>
  },
  {
    key: 'user_count',
    header: t('groups.insights.userCount', 'User count'),
    className: 'text-align-right',
    cell: ({ row }) => <span>{row.get('user_count')}</span>
  }
];

export const ActiveUsersTable = (props) => (
  <div className="row">
    <div className="col-1">
      <Table
        insight={INSIGHT}
        columns={COLUMNS(props.changePracticeAndNavToDashboard)}
        defaultSortByKey="practice_name"
        defaultSortIsAscending
      />
      <TableFooter
        insight={INSIGHT}
        csvUrl={INSIGHTS_CSV_API_URLS.ACTIVE_USER_COUNT_BY_PRACTICE_REPORT}
      />
    </div>
  </div>
);

const ConnectedActiveUsersTable = connect(
  undefined,
  { changePracticeAndNavToDashboard }
)(ActiveUsersTable);

export default withLoadInsightDataOnMount({
  insight: INSIGHT,
  url: INSIGHTS_API_URLS.ACTIVE_USER_COUNT_BY_PRACTICE,
  getFilters: () => (
    Map({
      practice_sub_group_id: getSubGroupFilterFromPath(),
    })
  )
})(ConnectedActiveUsersTable);
