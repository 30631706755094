import React, { Component } from 'react';
import { connect } from 'react-redux';

import { t } from '^/i18n';
import { deleteInProgressHealthcheckAndNavigateToDashboard } from '^/actions/healthcheck';
import { StoreState } from '^/store/types';
import { closeModal } from '^/actions/modals';
import NonFieldErrorRenderer from '^/components/common/NonFieldErrorRenderer';

interface StateProps {
  errors: unknown;
  isLoading: boolean;
}

interface DispatchProps {
  onDelete: typeof deleteInProgressHealthcheckAndNavigateToDashboard;
  onCancel: typeof closeModal;
}

type Props = StateProps & DispatchProps;

class DeleteHealthcheckModal extends Component<Props, {}> {
  public render() {
    return (
      <div className="mb-1">
        <p>
          {t(
            'healthcheck.deleteHealthcheckConfirmation',
            'Are you sure you want to delete this healthcheck?'
          )}
        </p>
        <NonFieldErrorRenderer errors={this.props.errors} />
        <div className="row align-right mt-1">
          <div className="col-1">
            <button className="btn btn-default" onClick={this.props.onCancel}>
              {t('common.button.cancel', 'Cancel')}
            </button>
            <button
              className="btn btn-warning ml-1"
              onClick={this.onDelete}
              disabled={this.props.isLoading}
            >
              {t('common.button.confirm', 'Confirm')}
            </button>
          </div>
        </div>
      </div>
    );
  }

  private onDelete = () => {
    this.props.onDelete();
  };
}

const mapStateToProps = (state: StoreState): StateProps => ({
  errors: state.thunkRequests.deleteHealthcheck.errors,
  isLoading: state.thunkRequests.deleteHealthcheck.isLoading,
});

export default connect<StateProps, DispatchProps, {}>(mapStateToProps, {
  onDelete: deleteInProgressHealthcheckAndNavigateToDashboard,
  onCancel: closeModal,
})(DeleteHealthcheckModal);
