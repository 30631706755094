import { fromJS } from 'immutable';

import { t } from '^/i18n';

export const GROUP_SECTION = fromJS({
  'GROUP_DETAILS': 'GROUP_DETAILS',
  'PRACTICE_LIST': 'PRACTICE_LIST',
});

export const GROUP_SECTION_TEXT = fromJS({
  [GROUP_SECTION.get('GROUP_DETAILS')]: t('consts.groupDetails', 'Group details'),
  [GROUP_SECTION.get('PRACTICE_LIST')]: t('consts.practiceList', 'Practice List'),
});

export const GROUP_SECTION_URLS = fromJS({
  [GROUP_SECTION.get('GROUP_DETAILS')]: '/page/groups/group-details/',
  [GROUP_SECTION.get('PRACTICE_LIST')]: '/page/groups/group-details/practice-list/',
});
