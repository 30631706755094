import React from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { routeActions } from 'react-router-redux';
import { Map, Set } from 'immutable';

import PureComponent from '^/components/common/PureComponent';
import Loading from '^/components/app/content/Loading';
import Icon from '^/components/app/content/Icon';
import PermissionsComponent from '^/components/app/perms/PermissionsComponent';
import UserAlerts from './UserAlerts';
import CycleMonth from './CycleMonth';
import ActivityTypeCapsule from '^/components/app/content/ActivityTypeCapsule';
import Capsule from '^/components/app/content/Capsule';

import { t } from '^/i18n';
import {
  ACTIVITY_TYPE,
  getActivityTypeText,
  CALENDAR_ACTIVITY_FILTERS,
} from '^/models/activities';
import {
  PRIORITY_CLASSES,
  PRIORITY_NAMES,
  PRIORITIES,
  CALENDAR_PRIORITY_FILTERS,
} from '^/models/constants';
import { resetResponse, setFilter } from '^/actions/actions';
import { pathMatches } from '^/utils';
import { setCurrentMonthAndGetMyActivities } from '^/actions/actionSequences';
import { loadTasksForMonth, loadDocumentReviewsForMonth, clearCollection } from '^/actions/collections';
import { ACTIVITIES, TASKS, DOCUMENT_REVIEWS_BY_MONTH } from '^/consts/collectionKeys';
import { isPending } from '^/consts/responseStates';
import { isNotReadOnlyInCurrentPractice } from '^/models/user';
import { practiceHasAccessToDocumentReviews } from '^/stateHelpers';

const DATE_FORMAT = 'YYYY-MM';
const CALENDAR_ROUTE = '/page/calendar/';
const CALENDAR_GRID_VIEW_ROUTE = '/page/calendar/grid-view/';
const CALENDAR_LIST_VIEW_ROUTE = '/page/calendar/list-view/';
const EXCLUDE_CALENDAR_FILTER = "EXCLUDE_CALENDAR_FILTER";

export const CalendarNav = ({jumpTo, currentMonth}) =>
  <div className="nav-calendar-controls">
    <button
      className="nav-calendar-arrow" tabIndex="0"
      onClick={() => jumpTo(moment(currentMonth).subtract(1, 'months'))}
    >
      &lt;
    </button>
    <button className="btn btn-default" tabIndex="0" onClick={() => jumpTo(moment())}>
      {t('common.today', 'Today')}
    </button>
    <button
      className="nav-calendar-arrow" tabIndex="0"
      onClick={() => jumpTo(moment(currentMonth).add(1, 'months'))}
    >
      &gt;
    </button>
  </div>;

export class CalendarPage extends PureComponent {
  constructor(props) {
    super(props);
    this.loadActivities = this.loadActivities.bind(this);
    this.reloadActivities = this.reloadActivities.bind(this);
    this.buildActivityFilters = this.buildActivityFilters.bind(this);
    this.applyActivityFilters = this.applyActivityFilters.bind(this);
    this.applyTaskFilter = this.applyTaskFilter.bind(this);
    this.applyDocumentReviewFilter = this.applyDocumentReviewFilter.bind(this);
  }

  componentWillMount() {
    const { initialMonth, currentPracticeId, location, activityFilters } = this.props;
    if (location && location.hash) {
      this.loadActivities(
        moment(location.hash, DATE_FORMAT),
        currentPracticeId,
        activityFilters
      );
    } else {
      this.loadActivities(initialMonth || moment(), currentPracticeId, activityFilters);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.currentPracticeId !== this.props.currentPracticeId) {
      this.loadActivities(
        nextProps.currentMonth || moment(),
        nextProps.currentPracticeId,
        this.props.activityFilters
      );
    }
  }

  jumpTo(month) {
    const { pathname, currentPracticeId, activityFilters } = this.props;
    const readableMonth = month.format(DATE_FORMAT);
    this.loadActivities(month, currentPracticeId, activityFilters);
    this.props.routePush(`${pathname}#${readableMonth}`);
  }

  loadActivities(month, practice, filters) {
    const { excludeCalendarFilter, hasDocumentsToReviewAccess } = this.props;

    if (month && practice) {
      this.props.resetResponse('updateItem');
      this.props.setCurrentMonthAndGetMyActivities(month, practice, false, filters);
      if (hasDocumentsToReviewAccess) {
        this.props.loadDocumentReviewsForMonth(month);
      }
      if (!excludeCalendarFilter.get(ACTIVITY_TYPE.TASK)) {
        this.props.loadTasksForMonth(month, practice);
      }
    }
  }

  reloadActivities() {
    const { currentMonth, currentPracticeId, activityFilters } = this.props;
    this.loadActivities(currentMonth, currentPracticeId, activityFilters);
  }

  buildActivityFilters(event) {
    const { activityFilters } = this.props;

    const filterKey = event.target.id;
    const filterValue = event.target.value;
    const filterUnchecked = !event.target.checked;

    const activityFilterSet = activityFilters.get(filterKey) ?
      Set(activityFilters.get(filterKey)) :
      Set();

    this.props.setFilter(EXCLUDE_CALENDAR_FILTER, filterValue, filterUnchecked)

    if (filterUnchecked) {
      return activityFilters.set(filterKey, activityFilterSet.add(filterValue));
    }

    return activityFilterSet.size <= 1 ?
      activityFilters.delete(filterKey) :
      activityFilters.set(filterKey, activityFilterSet.delete(filterValue));
  }

  applyTaskFilter(event) {
    const { currentMonth, currentPracticeId } = this.props;

    const filterValue = event.target.value;
    const filterUnchecked = !event.target.checked;
    
    this.props.setFilter(EXCLUDE_CALENDAR_FILTER, filterValue, filterUnchecked);
    
    if (filterUnchecked) {
      this.props.clearCollection(TASKS);
    } else {
      this.props.loadTasksForMonth(currentMonth, currentPracticeId);
    }
  }

  applyDocumentReviewFilter(event) {
    const { currentMonth } = this.props;

    const filterValue = event.target.value;
    const filterUnchecked = !event.target.checked;
    
    this.props.setFilter(EXCLUDE_CALENDAR_FILTER, filterValue, filterUnchecked);
    
    if (filterUnchecked) {
      this.props.clearCollection(DOCUMENT_REVIEWS_BY_MONTH);
    } else {
      this.props.loadDocumentReviewsForMonth(currentMonth);
    }
  }

  applyActivityFilters(event) {
    const { currentMonth, currentPracticeId } = this.props;

    const filters = this.buildActivityFilters(event);
    const priorityFilters = filters.get('priority');

    if (currentMonth && currentPracticeId) {
      this.props.resetResponse('updateItem');
      this.props.setCurrentMonthAndGetMyActivities(currentMonth, currentPracticeId, false, filters);
      this.props.loadDocumentReviewsForMonth(currentMonth, priorityFilters);
    }
  }

  renderActivityTypeFilterCheckbox(id, label, value, filters, onChange) {
    return (
      <div>
        <label>
          <input
            id={id}
            type="checkbox"
            name={value}
            value={value}
            onChange={onChange}
            checked={!filters.get(value, false)}
          />
          <ActivityTypeCapsule
            className="mr-1-2 calendar-capsule"
            type={value}
          />
          {label}
        </label>
      </div>
    );
  }

  renderDocumentReviewFilterCheckbox(id, label, value, filters, onChange) {
    return (
      <div>
        <label>
          <input
            id={id}
            type="checkbox"
            name={value}
            value={value}
            onChange={onChange}
            checked={!filters.get(value, false)}
          />
          <Capsule className="mr-1-2 calendar-capsule capsule-document-review" />
          {label}
        </label>
      </div>
    );
  }

  renderPriorityFilterCheckbox(value, filters) {
    return (
      <div className="mb-1-2">
        <label>
          <input
            id={"priority"}
            type="checkbox"
            name={value}
            value={value}
            onChange={this.applyActivityFilters}
            checked={!filters.get(value, false)}
          />
          <span className={`mb-1-2 actvities-priority-pill ${PRIORITY_CLASSES[value]}`}>
            {PRIORITY_NAMES[value]}
          </span>
        </label>
      </div>
    );
  }

  render() {
    const {
      currentMonth,
      isLoading,
      isUpdating,
      isDemoCalendar,
      excludeCalendarFilter,
      pathname,
      hasDocumentsToReviewAccess,
    } = this.props;

    if (!currentMonth) {
      return <Loading />;
    }

    return (
      <div>
        <div className="col-1-4 col-md-1 col-last">
          <div className="sidebar-item">
            <UserAlerts />
          </div>
          <div className="sidebar-item">
            <PermissionsComponent
              hasPermission={isNotReadOnlyInCurrentPractice}
            >
              <Link
                className="btn btn-default indented"
                to="/page/tasks/create/"
              >
                <Icon type="check-square-o" className="button-icon" /> 
                {t('calendar.createTask', 'Create Task')}
              </Link>
              <h3>{t('common.show', 'Show')}:</h3>
              <p className="mt-1 filter-header">{t('calendar.activitiesThatAre', 'Activities that are')}:</p>
              {CALENDAR_ACTIVITY_FILTERS.map(activityType =>
                this.renderActivityTypeFilterCheckbox(
                  'type',
                  getActivityTypeText(activityType),
                  activityType,
                  excludeCalendarFilter,
                  this.applyActivityFilters
                )
              )}
              {this.renderActivityTypeFilterCheckbox(
                "excludeTasks",
                t('common.tasksCC', 'Tasks'),
                ACTIVITY_TYPE.TASK,
                excludeCalendarFilter,
                this.applyTaskFilter
              )}
              {hasDocumentsToReviewAccess && this.renderDocumentReviewFilterCheckbox(
                'excludeDocumentReviews',
                t('common.documentReviews', 'Document Reviews'),
                ACTIVITY_TYPE.DOCUMENT_REVIEW,
                excludeCalendarFilter,
                this.applyDocumentReviewFilter,
              )}
              <p className="mt-1 filter-header">{t('calendar.activitiesWithPriority', 'Activities with priority')}:</p>
              {CALENDAR_PRIORITY_FILTERS.filter(
                priorityType =>
                  hasDocumentsToReviewAccess ||
                  priorityType !== PRIORITIES.CRITICAL
              ).map(priorityType =>
                this.renderPriorityFilterCheckbox(
                  priorityType,
                  excludeCalendarFilter
                )
              )}
            </PermissionsComponent>
          </div>
        </div>

        <div className={classNames("col-3-4 col-md-none mt-1", (pathMatches(CALENDAR_LIST_VIEW_ROUTE, pathname)) && "calendar-list-border")}>
          {isDemoCalendar && (
            <p className="alert-warning mt-1">
              <Icon type="lightbulb-o" className="tip-icon pull-left" />
              {t('calendar.limitedFunctionalityMsg', 'You are using iCOMPLY with limited functionality. Contact iComply at')}{' '}
              <a href="mailto:icomply@agiliosoftware.com" className="underlined">
                icomply@agiliosoftware.com
              </a>{' '}
              {t('calendar.limitedFunctionalityContactMsg', 'or on 0330 165 9712 to request a demonstration of full functionality.')}
            </p>
          )}

          <nav className="nav-calendar">
            {isUpdating && (
              <Loading />
            )}
            {isLoading && <Loading className="inline" />}
            <CalendarNav jumpTo={(month) => this.jumpTo(month)} currentMonth={currentMonth} />
            <h1>{currentMonth.format('MMMM YYYY')} <CycleMonth month={currentMonth} showCycleNumber /></h1>
            <div className="calender-view-link-wrapper pull-right">
              <Link
                to={CALENDAR_GRID_VIEW_ROUTE}
                className={
                  classNames(
                    "calendar-view-link",
                    {active: pathMatches(CALENDAR_ROUTE, pathname) && !pathMatches(CALENDAR_LIST_VIEW_ROUTE, pathname)}
                  )
                }
              >
                {t('calendar.monthView', 'Month View')}
              </Link>
              <Link
                to={CALENDAR_LIST_VIEW_ROUTE}
                className={
                  classNames(
                    "calendar-view-link",
                    {active: pathMatches(CALENDAR_LIST_VIEW_ROUTE, pathname)}
                    )
                  }
              >
                {t('calendar.listView', 'List View')}
              </Link>
            </div>
          </nav>
          <div>
            {this.props.children}
          </div>
        </div>
      </div>
    );
  }
}

export function mapStateToProps(state) {
  const activityCollection = state.collections.get(ACTIVITIES);
  const activityFilters = activityCollection ?
    Map(activityCollection.get('filters')) :
    Map();

  const excludeCalendarFilter = state.filterSpec.get(EXCLUDE_CALENDAR_FILTER, Map());

  return {
    activityFilters,
    excludeCalendarFilter,
    pathname: state.routing.location.pathname,
    currentMonth: state.currentMonth,
    initialMonth: state.initialMonth,
    currentPracticeId: state.currentPractice && state.currentPractice.get('id'),
    isLoading: isPending(state.responses.getIn(['getCollection', ACTIVITIES])),
    hasDocumentsToReviewAccess: practiceHasAccessToDocumentReviews(state),
    isDemoCalendar:
      state.currentPractice &&
      state.currentPractice.get('is_demo_calendar_only'),
  };
}

export default connect(mapStateToProps, {
  setCurrentMonthAndGetMyActivities,
  loadTasksForMonth,
  resetResponse,
  clearCollection,
  setFilter,
  loadDocumentReviewsForMonth,
  routePush: routeActions.push,
})(CalendarPage);
