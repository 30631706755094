import { t } from '^/i18n';
import createCategoryPage from './hoc/create-category-page';
import {
  HealthcheckTypes,
  HealthcheckAction,
  HealthcheckStatus,
} from './types';
import ActionRow from './action-row';
import { getHealthcheckActionsCategory } from '^/actions/healthcheck';

export default createCategoryPage<HealthcheckAction>({
  type: HealthcheckTypes.Actions,
  content: t(
    'healthcheck.reviewActionsAndMarkAsComplete',
    'Please review your actions and mark as complete when done. You can navigate the audit categories using the list on the right of the page.'
  ),
  noContent: t(
    'healthcheck.noActionsNeeded',
    'No actions needed! - Complete this stage to review your results.'
  ),
  itemRenderer: ActionRow,
  fetchCategoryAction: getHealthcheckActionsCategory,
  requestKeys: [
    'getHealthcheckActionsProgress',
    'getHealthcheckActionsCategory',
    'updateHealthcheckStatus',
    'getHealthcheckById',
  ],
  getIsLoading: state =>
    state.thunkRequests.getHealthcheckActionsProgress.isLoading ||
    state.thunkRequests.getHealthcheckActionsCategory.isLoading ||
    state.thunkRequests.updateHealthcheckStatus.isLoading,
  getErrors: state =>
    state.thunkRequests.getHealthcheckActionsProgress.errors ||
    state.thunkRequests.getHealthcheckActionsCategory.errors ||
    state.thunkRequests.updateHealthcheckStatus.errors,
  getCategories: state => state.healthcheck.actionsCategories,
  intendedFromStatus: HealthcheckStatus.ACTIONS,
  urlPart: '/actions/',
  areaHeaders: [
    t('healthcheck.action', 'Action'),
    t('healthcheck.markCompleted', 'Mark completed'),
  ],
});
