import React from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';
import { bindActionCreators } from 'redux';

import { t } from '^/i18n';
import PureComponent from '^/components/common/PureComponent';
import Loading from '^/components/app/content/Loading';
import LiveButton from '^/components/app/forms/LiveButton';
import { isPending } from '^/consts/responseStates';
import {
  loadTemplateLatestVersion,
  loadTemplateLatestGroupVersion,
  saveAsWorkingDraft,
  clearItem,
} from '^/actions/items';
import { TEMPLATE_DOCUMENT_VERSION } from '^/consts/collectionKeys';
import {
  setEditorContent,
  resetResponse,
  collapseUiComponent,
} from '^/actions/actions';
import {
  adoptTemplateVersionAndRedirectTo,
  rebaseCopiedTemplateAndRedirectTo,
  discardWorkingDraftLoadLatestAndThen,
  openComparisonEditorExitConfirmationModalAndCheckForLogin,
} from '^/actions/actionSequences';
import DemoModeMayDisallow from '^/components/app/perms/DemoModeMayDisallow';
import SourceDocumentEditor from './SourceDocumentEditor';
import DocumentEditor from './DocumentEditor';
import DocumentHeader from './DocumentHeader';
import HelpBlock from '^/components/app/widgets/HelpBlock';
import { withRouter } from '^/withRouter';
import {
  isGroupsAppSection,
  getAppSectionPathPrefix,
  makeBackLink,
  handleOnBeforeUnload,
  isEditingGroupDocument,
} from '^/utils';
import BackButton from '^/components/app/nav/back-button';
import { openDiscardCurrentAndAdoptNewVersionModal } from '^/actions/modals';

const NEW_TEMPLATE_DOC_VERSION_COLLECTION_KEY = TEMPLATE_DOCUMENT_VERSION + '_2'; 
const SCREEN_WIDTH = window.innerWidth;
const SMALL_SCREEN_SIZE = 1300;
const SOURCE_DOC_HEIGHT = 900;
const EDIT_DOC_HEIGHT = 800;
const SMALL_SCREEN_DOC_HEIGHT = 300;

export class TemplateDocumentUpdatePage extends PureComponent {
  constructor(props) {
    super(props);
    this.confirmBeforeTransition = this.confirmBeforeTransition.bind(this);
  }

  componentWillMount() {
    const { setContent, loadTemplate, loadGroupTemplate, docId, editingGroupDocument } = this.props;
    setContent('');

    if (editingGroupDocument) {
      loadGroupTemplate(docId);
    } else {
      loadTemplate(docId);
    }

    this.clearTransitionListener = this.props.router.listenBefore(
      this.confirmBeforeTransition
    );
    window.onbeforeunload = handleOnBeforeUnload;
  }

  confirmBeforeTransition(_location, callback) {
    callback(false);
    this.confirmIfEditing(() => callback(true));
  }

  confirmIfEditing(onContinue) {
    const {
      userId,
      templateDocumentVersion,
      editorContent,
      currentGroupId,
      saveResponse,
      copyResponse,
      rebaseCopiedResponse,
      editingGroupDocument,
    } = this.props;

    const isCurrentUserEditing =
    templateDocumentVersion &&
    templateDocumentVersion.getIn(['working_version', 'user', 'id']) ===
        userId;

    if (
      this.isEditing() &&
      isCurrentUserEditing &&
      !isPending(saveResponse) &&
      !isPending(copyResponse) &&
      !isPending(rebaseCopiedResponse)
    ) {
      this.props.exitConfirmation(
        templateDocumentVersion,
        editorContent,
        editingGroupDocument && currentGroupId,
        () => {
          this.saveAsWorkingDraft(onContinue);
        },
        () => {
          this.onSave();
        },
        () => {
          this.props.discardWorkingDraftLoadLatestAndThen(
            templateDocumentVersion.get('id'),
            editingGroupDocument && currentGroupId,
            onContinue
          );
        }
      );
    } else {
      onContinue();
    }
  }

  componentWillReceiveProps(newProps) {
    const {
      templateDocumentVersion,
      setContent,
      loadTemplate,
      docId,
      userId,
      currentGroupId,
      editorContent,
      editingGroupDocument,
      loadGroupTemplate
    } = this.props;

    if (newProps.templateDocumentVersion && !templateDocumentVersion) {
      setContent(
        newProps.templateDocumentVersion.getIn([
          'working_version',
          'user',
          'id',
        ]) === userId
          ? newProps.templateDocumentVersion.getIn([
              'working_version',
              'content',
            ])
          : newProps.templateDocumentVersion.get('content')
      );

      if (
        !newProps.templateDocumentVersion.get('working_version')
      ) {
        this.props.startEditing(
          newProps.templateDocumentVersion,
          editingGroupDocument && currentGroupId,
          true
        );
      }
    }

    if (newProps.docId !== docId) {
      if (editingGroupDocument) {
        loadGroupTemplate(newProps.docId);
      } else {
        loadTemplate(newProps.docId);
      }
    } else if (editorContent !== newProps.editorContent &&  templateDocumentVersion) {
    this.props.saveAsWorkingDraftWhileEditing(
        templateDocumentVersion.get('id'),
        newProps.editorContent,
        editingGroupDocument && currentGroupId,
        true
      );
    }
  }

  componentWillUnmount() {
    this.props.resetItem();
    this.clearTransitionListener();
    window.onbeforeunload = null;
  }

  onSave() {
    const {
      templateDocumentVersion,
      adoptTemplate,
      rebaseCopiedTemplate,
      editorContent,
      currentPracticeId,
      currentGroupId,
      backLinkPath,
      activityId
    } = this.props;
    const copiedTemplateDocument = templateDocumentVersion.get(
      'copied_template_document'
    );
    const isGroupDocument = templateDocumentVersion.get(
      'group_template_document'
    );

    if (copiedTemplateDocument) {
      rebaseCopiedTemplate(
        copiedTemplateDocument.get('id'),
        editorContent,
        currentPracticeId,
        backLinkPath,
        activityId
      );
    } else if (isGroupDocument) {
      adoptTemplate(
        this.getBaseDocumentId(),
        editorContent,
        null,
        currentGroupId,
        backLinkPath
      );
    } else {
      adoptTemplate(
        this.getBaseDocumentId(),
        editorContent,
        currentPracticeId,
        null,
        backLinkPath
      );
    }
  }

  saveAsWorkingDraft(onContinue = null) {
    const {
      templateDocumentVersion,
      editorContent,
      currentGroupId,
      editingGroupDocument,
      saveWorkingDraft,
    } = this.props;

    saveWorkingDraft(
      templateDocumentVersion.get('id'),
      editorContent,
      editingGroupDocument && currentGroupId,
      true
    );
    if (onContinue) {
      onContinue();
    }
  }

  getBaseDocumentId() {
    const { templateDocumentVersion } = this.props;
    const templateDocument =
      templateDocumentVersion.getIn([
        'copied_template_document',
        'template_document',
      ]) ||
      templateDocumentVersion.getIn([
        'adopted_template_document',
        'template_document',
      ]) ||
      templateDocumentVersion.getIn([
        'group_template_document',
        'template_document',
      ]) ||
      templateDocumentVersion.getIn([
        'adopted_template_document',
        'group_template_document',
      ]) ||
      templateDocumentVersion.getIn([
        'copied_template_document',
        'group_template_document',
      ]);
    return templateDocument && templateDocument.get('id');
  }

  getBaseDocumentContent() {
    const baseDocumentId = this.getBaseDocumentId();

    if (baseDocumentId) {
      this.props.loadBaseTemplate(baseDocumentId, NEW_TEMPLATE_DOC_VERSION_COLLECTION_KEY);
    }

    return this.props.baseTemplateDocumentVersion.get('content');
  }

  isEditing() {
    const { isEditing, templateDocumentVersion, userId } = this.props;

    return (
      isEditing ||
      Boolean(
        templateDocumentVersion &&
          templateDocumentVersion.getIn(['working_version', 'user', 'id']) ===
            userId
      )
    );
  }

  renderDocument() {
    const {
      templateDocumentVersion,
      response,
      setContent,
      saveResponse,
      userId,
      saveWorkingVersionResponse,
      currentPracticeId,
      currentGroupId,
      editingGroupDocument,
      backLinkPath,
    } = this.props;

    if (isPending(response) || !templateDocumentVersion) {
      return <Loading />;
    }

    const isGroupDocument = templateDocumentVersion.get(
      'group_template_document'
    );

    const working_version = templateDocumentVersion.get('working_version');
    const userCurrentlyEditing =
      working_version &&
      working_version.getIn(['user', 'staffdetail', 'full_name']);
    const locked =
      working_version && working_version.getIn(['user', 'id']) !== userId;

    return (
      <div className="comparison-editor-wrapper">
      <BackButton />
      <div className="comparison-editor">
        <div className="comparison-editor__source-doc">
          <SourceDocumentEditor
            documentId={this.getBaseDocumentId()}
            height={SCREEN_WIDTH > SMALL_SCREEN_SIZE ? SOURCE_DOC_HEIGHT : SMALL_SCREEN_DOC_HEIGHT}
          />
        </div>

        <div className="comparison-editor__edit-doc">
          <div className="text-and-button-wrapper mt-1">
            <div className="text-and-button-wrapper__text">
              <DocumentHeader doc={templateDocumentVersion} editingGroupDocument={isGroupDocument} />
            </div>
            <div className="text-and-button-wrapper__action">
              {userCurrentlyEditing && locked && (
                <HelpBlock className="mb-1-2">
                  <p className="small-text">
                    {userCurrentlyEditing} {t('templateDocument.currentlyUpdatingMsg', 'is currently updating this document')}
                  </p>
                </HelpBlock>
              )}
              <LiveButton
                className="btn btn-primary mb-1-2 fullwidth centered"
                onClick={() => this.saveAsWorkingDraft()}
                response={saveWorkingVersionResponse}
                disabled={locked}
              >
                {t('common.button.saveAndContinueEditing', 'Save And Continue Editing')}
              </LiveButton>
              <LiveButton
                className="btn btn-primary mb-1-2 fullwidth centered"
                onClick={() => this.onSave()}
                response={saveResponse}
                disabled={locked}
              >
                {t('templateDocument.saveAsNewVersion', 'Save As New Version')}
              </LiveButton>
              <LiveButton
                className="btn btn-primary mb-1-2 fullwidth centered"
                onClick={() => this.props.openDiscardCurrentAndAdoptNewVersionModal(
                  this.getBaseDocumentContent(),
                  this.getBaseDocumentId(),
                  `${backLinkPath}/`,
                  templateDocumentVersion,
                  currentPracticeId,
                  currentGroupId,
                )}
                response={saveResponse}
                disabled={locked}
              >
                {t('templateDocument.discardAndAdoptNewVersion', 'Discard Current And Adopt New Version')}
              </LiveButton>
              <LiveButton
                className="btn btn-primary mb-1-2 fullwidth centered"
                onClick={() => this.props.discardWorkingDraftAndLoadLatest(
                  templateDocumentVersion.get('id'),
                  editingGroupDocument && currentGroupId
                )}
                response={saveResponse}
                disabled={locked}
              >
                {t('templateDocument.discardEdits', 'Discard Edits')}
              </LiveButton>
            </div>
          </div>
          <div className="document-editor">
            <div className="mt-1 ptb-1-3">
              <div className="x-small-text">
                <span className="text-uppercase bold-text">
                  {isGroupDocument ? t('common.group', 'Group') : t('common.practiceCC', 'Practice')} {t('templateDocument.documentEdit', 'document (edit)')}
                </span>{' '}
                {t('templateDocument.reviewAndCopyChangesMsg', 'Review and copy changes from new version on the left')}
              </div>
            </div>
            <DocumentEditor
              {...this.props}
              setEditorContent={setContent}
              doc={templateDocumentVersion}
              onSave={() => this.onSave()}
              height={SCREEN_WIDTH > SMALL_SCREEN_SIZE ? EDIT_DOC_HEIGHT : SMALL_SCREEN_DOC_HEIGHT}
            />
          </div>
        </div>
      </div>
      </div>
    );
  }

  render() {
    const { response } = this.props;

    return (
      <DemoModeMayDisallow
        message={t('templateDocument.invisibleClosedActivitiesMsg', 'cannot see documents for closed activities')}
        response={response}
        goBack
      >
        {this.renderDocument()}
      </DemoModeMayDisallow>
    );
  }
}

export const name = props =>
  `TEMPLATE_DOCUMENT_VERSION.content.${props.params && props.params.uuid}`;

export function mapStateToProps(state, props) {
  const templateDocumentVersion = state.items.get(TEMPLATE_DOCUMENT_VERSION);
  const currentPathname = state.routing.location.pathname;
  const editingGroupDocument = isEditingGroupDocument(props.location.pathname);
  return {
    templateDocumentVersion,
    baseTemplateDocumentVersion: state.items.get(NEW_TEMPLATE_DOC_VERSION_COLLECTION_KEY),
    response: state.responses.get('loadTemplateLatestVersion'),
    saveResponse: state.responses.get('adoptTemplateVersion'),
    saveWorkingVersionResponse: state.responses.get('saveAsWorkingDraft'),
    editorContent: state.ui.get('editorContent'),
    currentPracticeId: state.currentPractice && state.currentPractice.get('id'),
    docId: props.params.uuid,
    editingGroupDocument,
    copyResponse: state.responses.get('copyTemplateVersion'),
    rebaseCopiedResponse: state.responses.get('rebaseCopiedTemplate'),
    currentGroupId:
      state.currentPractice && state.currentPractice.getIn(['group', 'id']),
    userId: state.userProfile.get('id'),
    activityId: props.params.activity_id,
    backLinkPath: templateDocumentVersion ? makeBackLink(
      props.params.activity_id,
      templateDocumentVersion,
      isGroupsAppSection(currentPathname),
      getAppSectionPathPrefix(currentPathname)
    ).path : null,
  };
}

export function mapDispatchToProps(dispatch, props) {
  const paramsId = props.params.uuid;
  return {
    loadTemplate: (id) => dispatch(
      loadTemplateLatestVersion(id, TEMPLATE_DOCUMENT_VERSION)
    ),
    loadGroupTemplate: id => dispatch(loadTemplateLatestGroupVersion(id)),
    loadBaseTemplate: (id) => dispatch(
      loadTemplateLatestVersion(id, NEW_TEMPLATE_DOC_VERSION_COLLECTION_KEY)
    ),
    startEditing: (doc, groupId, isComparisonEditor) => {
      dispatch(
        saveAsWorkingDraft(doc.get('id'), doc.get('content'), false, groupId, isComparisonEditor)
      );
      dispatch(resetResponse('renderDocument'));
    },
    saveAsWorkingDraftWhileEditing: _.throttle(
      (docId, content, groupId, isComparisonEditor) =>
        dispatch(saveAsWorkingDraft(docId, content, false, groupId, isComparisonEditor)),
      10000,
      true
    ),
    adoptTemplate: (docId, content, currentPracticeId, currentGroupId, link) =>
      dispatch(
        adoptTemplateVersionAndRedirectTo(
          docId,
          content,
          currentPracticeId,
          currentGroupId,
          () => link
        )
      ),
    rebaseCopiedTemplate: (docId, content, currentPracticeId, link, activityId) =>
      dispatch(
        rebaseCopiedTemplateAndRedirectTo(
          docId,
          content,
          currentPracticeId,
          () => link,
          activityId
        )
      ),
    setContent: content => dispatch(setEditorContent(content)),
    saveWorkingDraft: (docId, content, groupId, isComparisonEditor) =>
      dispatch(saveAsWorkingDraft(docId, content, false, groupId, isComparisonEditor)),
    resetItem: () => dispatch(clearItem(TEMPLATE_DOCUMENT_VERSION)),
    exitConfirmation: (
      document,
      content,
      groupId,
      onSaveAndExit,
      onSaveAndPublish,
      discard
    ) => {
      dispatch(
        openComparisonEditorExitConfirmationModalAndCheckForLogin(
          document,
          content,
          groupId,
          paramsId,
          onSaveAndExit,
          onSaveAndPublish,
          discard
        )
      );
    },
    discardWorkingDraftLoadLatestAndThen: (id, groupId, andThen) =>
      dispatch(
        discardWorkingDraftLoadLatestAndThen(id, groupId, paramsId, () => {
          dispatch(collapseUiComponent(name(props)));
          andThen();
        })
      ),
    discardWorkingDraftAndLoadLatest: (id, groupId) =>
      dispatch(
        discardWorkingDraftLoadLatestAndThen(id, groupId, paramsId, () =>
          dispatch(collapseUiComponent(name(props)))
        )
      ),
    openDiscardCurrentAndAdoptNewVersionModal: bindActionCreators(openDiscardCurrentAndAdoptNewVersionModal, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(TemplateDocumentUpdatePage));
