import React from 'react';
import { connect } from 'react-redux';
import { List } from 'immutable';
import { Link } from 'react-router';

import { t } from '^/i18n';
import { getCollection } from '^/actions/collections';
import { NEWS } from '^/consts/collectionKeys';
import { isPending } from '^/consts/responseStates';
import PureComponent from '^/components/common/PureComponent';
import Loading from '^/components/app/content/Loading';
import Collapse from '^/components/app/content/Collapse';
import CollapseButton from '^/components/app/content/CollapseButton';
import Icon from '^/components/app/content/Icon';
import NewsStoryPreviews from '^/components/app/news/NewsStoryPreviews';


const NEWS_LIST_LENGTH = 3;


export class LatestNews extends PureComponent {

  componentWillMount() {
    const { practiceId } = this.props;
    this.loadNewsStories(practiceId);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.practiceId !== this.props.practiceId) {
      this.loadNewsStories(nextProps.practiceId);
    }
  }

  loadNewsStories(practiceId) {
    if (practiceId) {
      this.props.getCollection(NEWS, { filters: { practice: practiceId } });
    }
  }

  render() {
    const { newsStories, response } = this.props;

    if (isPending(response)) {
      return <Loading />;
    }

    return (
      <div>
        <h4 className="m-toggle">
          <CollapseButton collapseId="mobile-newsStories" initiallyCollapsed collapseOnMount>
            <Icon type="newspaper-o" className="title" /> {t('dashboard.latestNews', 'Latest News')}
          </CollapseButton>
        </h4>
        <Collapse collapseId="mobile-newsStories" className="m-content">
          {newsStories.size ? (
            <div>
              <NewsStoryPreviews newsStories={newsStories.take(NEWS_LIST_LENGTH)} />

              {newsStories.size > NEWS_LIST_LENGTH && (
                <Link className="btn btn-default centered" to="/page/news/">
                  {t('dashboard.button.viewAllNews', 'View all news')}
                </Link>
              )}
            </div>
          ) : (
            <h5 className="text-empty-state">{t('dashboard.noNewsStories', 'No news stories.')}</h5>
          )}
        </Collapse>
      </div>
    );
  }
}

export function mapStateToProps(state) {
  return {
    newsStories: state.collections.getIn([NEWS, 'items'], List()),
    response: state.responses.getIn(['getCollection', NEWS]),
  };
}

export default connect(mapStateToProps, { getCollection })(LatestNews);
