import React from 'react';
import { connect } from 'react-redux';

import { getSetupTeamMembers } from '^/actions/actions';
import { setupCycleGroupTasksAndReloadUser } from '^/actions/actionSequences';
import { getAllCollection } from '^/actions/collections';
import { loadItem } from '^/actions/items';
import { CYCLE_GROUP_TASKS, CYCLES } from '^/consts/collectionKeys';
import { getCurrentPracticeId } from '^/models/user';
import { getCycleToSetupId } from '^/consts/setup';

import PureComponent from '^/components/common/PureComponent';
import Loading from '^/components/app/content/Loading';
import AccountSetupHeader from '^/components/app/account-set-up/AccountSetupHeader';
import GroupTasksSetupForm from '^/components/app/account-set-up/GroupTasksSetupForm';
import HelpBlock from '^/components/app/widgets/HelpBlock';
import { isPending, hasFailed } from '^/consts/responseStates';
import { transformGroupTaskData } from './utils';
import { getActiveCycleSetUpPracticeMembers } from '^/stateHelpers';
import { t } from '^/i18n';

export class GroupTasksSetupPage extends PureComponent {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.onSubmitNoGroupTasks = this.onSubmitNoGroupTasks.bind(this);
  }

  componentDidMount() {
    const { cycleId, practiceId } = this.props;
    this.props.loadItem(CYCLES, cycleId);
    this.props.getSetupTeamMembers(practiceId);
    this.props.getAllCollection(CYCLE_GROUP_TASKS);
  }

  onSubmit(data) {
    const { cycleId, setup } = this.props;
    setup(cycleId, transformGroupTaskData(data));
  }

  onSubmitNoGroupTasks() {
    const { cycleId, setup } = this.props;
    setup(cycleId, transformGroupTaskData([]));
  }

  render () {
    const { isLoading, hasFailedToLoad, cycle, user, teamMembers, groupTasks, practice, response } = this.props;

    if (isLoading) {
      return (<Loading />);
    }

    if (hasFailedToLoad) {
      return (<p className="text-error">{ t('common.failedToLoad', 'Failed to load') }</p>);
    }

    if (!teamMembers || !cycle || !groupTasks) {
      return null;
    }

    return (
      <div>
        <AccountSetupHeader user={user} practice={practice}>{ t('accountSetup.setupGroupTasks', 'Setup - Group Tasks') }</AccountSetupHeader>

        {groupTasks.count() === 0 ? (
          <div>
            <HelpBlock className='mb-2'>{ t('cycleSetup.noGroupTasks', 'Your group has no tasks')}.</HelpBlock>
            <button className='btn btn-default' pendingMessage={`${t('common.saving', 'Saving')}...`} onClick={this.onSubmitNoGroupTasks}>{t('common.next','Next')}...</button>
          </div>
        ) : (
          <div>
            <HelpBlock className="mb-2">
              { t('accountSetup.groupTasksHelp', 'Select the Group Tasks you require. Dates should be set now but these can be changed later in calendar') }.
            </HelpBlock>

            <GroupTasksSetupForm
              response={response}
              teamMembers={teamMembers}
              user={user}
              groupTasks={groupTasks}
              onSubmit={this.onSubmit}
              cycle={cycle}
            />
          </div>
        )}

      </div>
    );
  }

}

export const mapStateToProps = (state) => {
  const practiceId = getCurrentPracticeId(state.userProfile);
  const loadTeamMemberResponse = state.responses.get('getSetupTeamMembers');
  const loadCycleResponse = state.responses.getIn(['getCollection', CYCLES]);
  const loadGroupTasksResponse = state.responses.getIn(['loadItem', CYCLE_GROUP_TASKS]);
  return {
    user: state.userProfile,
    practiceId,
    cycleId: getCycleToSetupId(state.userProfile, practiceId),
    cycle: state.items.get(CYCLES),
    teamMembers: getActiveCycleSetUpPracticeMembers(state),
    practice: state.currentPractice,
    response: state.responses.get('setupCycleReminders'),
    groupTasks: state.collections.getIn([CYCLE_GROUP_TASKS, 'items']),
    isLoading: Boolean(
      isPending(loadTeamMemberResponse) ||
      isPending(loadCycleResponse) ||
      isPending(loadGroupTasksResponse)
    ),
    hasFailedToLoad: Boolean(
      hasFailed(loadTeamMemberResponse) ||
      hasFailed(loadCycleResponse) ||
      hasFailed(loadGroupTasksResponse)
    ),
  };
};

export default connect(mapStateToProps, {
  getSetupTeamMembers,
  setup: setupCycleGroupTasksAndReloadUser,
  getAllCollection,
  loadItem,
}) (GroupTasksSetupPage);
