import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { List } from 'immutable';

import { t } from '^/i18n';
import {
  RelatedTemplate, UploadedActivityTaskFile, AttachedDocument
} from '^/components/app/activities/documents/ActivityDocuments';


export const TaskRelatedTemplatesAndFiles = ({
  relatedTemplates, relatedFiles, uploadedFiles, removeFile, taskId, togglePrivateDocument, userId
}) =>
  <div className="mt-1">
    <h2>{t('tasks.relatedTemplatesAndGuidance', 'Related Templates and Guidance')}</h2>
    {(relatedTemplates.isEmpty() && relatedFiles.isEmpty() && uploadedFiles.isEmpty()) ? (
      <div>
        <p>{t('tasks.noRelatedTemplates', 'There are no related templates for this Task')}</p>
        <hr className="thin" />
      </div>
    ) : (
      <div>
        {relatedTemplates.map(template =>
          <RelatedTemplate key={template.get('id')} relatedTemplate={template} taskId={taskId} userId={userId} />
        )}
        {relatedFiles.map(file =>
          <UploadedActivityTaskFile key={file.get('id')} uploadedActivityTaskFile={file} />
        )}
        {uploadedFiles.map(file =>
          <UploadedActivityTaskFile
            key={file.get('id')}
            uploadedActivityTaskFile={file}
            removeDocument={() => removeFile(file)}
            togglePrivateDocument={() => togglePrivateDocument(file)}
            userId={userId}
          />
        )}
      </div>
    )}
  </div>;

export const TaskAttachedDocumentsAndFiles = ({attachedDocuments, uploadedFiles, relatedFiles, showNotes}) =>
  <div className="mt-1">
    <h2>Attached documents and files</h2>
    {(attachedDocuments.isEmpty() && relatedFiles.isEmpty() && uploadedFiles.isEmpty()) ? (
      <div>
        <p>{t('tasks.noAttachedDocuments', 'There are no attached documents and files for this Task')}</p>
        <hr className="thin" />
      </div>
    ) : (
      <div>
        {attachedDocuments.map(attachedDocument =>
          <AttachedDocument key={attachedDocument.get('id')} attachedDocument={attachedDocument} />)}
        {relatedFiles.map(file =>
          <UploadedActivityTaskFile key={file.get('id')} uploadedActivityTaskFile={file} />)}
        {uploadedFiles.map(file =>
          <UploadedActivityTaskFile key={file.get('id')} uploadedActivityTaskFile={file} showNotes={showNotes} />)}
      </div>
    )}
  </div>;

export const TaskDocuments = ({
  task,
  uploadedFiles,
  attachedDocuments,
  removeFile,
  isCompleted,
  togglePrivateDocument,
  userId,
  showNotes
}) => {
  const relatedTemplates = (task && task.get('related_templates')) || List();
  const relatedFiles = (task && task.get('related_files')) || List();
  return isCompleted ? (
    <TaskAttachedDocumentsAndFiles
      uploadedFiles={uploadedFiles || List()}
      attachedDocuments={attachedDocuments || List()}
      relatedFiles={relatedFiles}
      showNotes={showNotes}
    />
  ) : (
    <TaskRelatedTemplatesAndFiles
      taskId={task && task.get('id')}
      relatedTemplates={relatedTemplates}
      relatedFiles={relatedFiles}
      uploadedFiles={uploadedFiles || List()}
      removeFile={removeFile}
      userId={userId}
      togglePrivateDocument={togglePrivateDocument}
    />
  );
};

TaskDocuments.propTypes = {
  task: ImmutablePropTypes.map.isRequired,
  removeFile: React.PropTypes.func,
  uploadedFiles: ImmutablePropTypes.list,
  togglePrivateDocument: React.PropTypes.func,
  userId: React.PropTypes.string.isRequired,
  showNotes: React.PropTypes.bool,
};

export default TaskDocuments;
