import React from 'react';
import { reduxForm } from 'redux-form';
import { List, fromJS, Map } from 'immutable';
import moment from 'moment';

import { isOptional } from '^/models/compliancereviews';
import { calculateNextDueDate } from '^/models/urgency';
import { formatDateOrNull } from '^/utils';

import FormError from '^/components/app/forms/FormError';
import LiveButton from '^/components/app/forms/LiveButton';
import DayOfTheWeekInput from '^/components/app/forms/DayOfTheWeekInput';
import { INCLUDED, DUE_DATE, DATE_LAST_DONE, NEVER_DONE, SECURITY_AUDIT_START_DAY } from '^/consts/account-set-up/fieldNames';
import { 
  OptionalTemplateSecurityAuditOrAssessmentFields,
  CompulsoryTemplateSecurityAuditOrAssessmentFields
} from '^/components/app/account-set-up/SecurityAuditsRiskAssessmentsCommonFormComponents';
import { t } from '^/i18n';

export const SECURITY_AUDITS_SETUP_FORM = 'SECURITY_AUDITS_SETUP_FORM';

export const SecurityAuditsSetupForm = ({
   handleSubmit, response, fields, templateSecurityAudits, cycleStartDate
}) =>
  <form onSubmit={handleSubmit}>

    <div className="form-group">
      <div className="col-1">
        <div>
          <label htmlFor={SECURITY_AUDIT_START_DAY} className="padded">
            {t('accountSetup.whichDay', 'Which day of the week would you like do your audits?')}
          </label>
        </div>
        <div>
          <DayOfTheWeekInput
            field={fields[SECURITY_AUDIT_START_DAY]}
            className="form-select mr-1"
            id={SECURITY_AUDIT_START_DAY}
          />
          <FormError response={response} formKey={SECURITY_AUDIT_START_DAY} />
        </div>
      </div>
    </div>

    <hr className="thin" />

    <CompulsoryTemplateSecurityAuditOrAssessmentFields
      fields={fields}
      response={response}
      auditsOrAssessments={templateSecurityAudits.filterNot(isOptional)}
      cycleStartDate={cycleStartDate}
      isAudit
    />

    <OptionalTemplateSecurityAuditOrAssessmentFields
      fields={fields}
      response={response}
      auditsOrAssessments={templateSecurityAudits.filter(isOptional)}
      isAudit
    />

    <hr className="thin" />

    <LiveButton className="btn-default" pendingMessage={`${t('common.saving', 'Saving')}...`} response={response}>
      {t('common.next', 'Next')}...
    </LiveButton>
  </form>;


export const getAuditFields = (templateAudits) =>
  templateAudits
    .map(
      audit =>
        List.of(INCLUDED, DATE_LAST_DONE, NEVER_DONE, DUE_DATE).map(each => audit.get('id') + '.' + each)
    )
    .flatten()
    .toJS();

export const getFields = (templateAudits) =>
  [SECURITY_AUDIT_START_DAY].concat(getAuditFields(templateAudits));


function _transformAuditData(audits, templateSecurityAudits, cycleStartDate, preferredWeekday) {
  const findAudit = auditId => templateSecurityAudits.find(each => each.get('id') === auditId);

  return audits
    .filterNot((audit, auditId) =>
      isOptional(findAudit(auditId)) && !audit.get(INCLUDED)
    )
    .map((audit, auditId) => Map({
      id: auditId,
      [INCLUDED]: audit.get(INCLUDED),
      [DUE_DATE]: formatDateOrNull(
        audit.get(DUE_DATE) || calculateNextDueDate(
          audit.get(DATE_LAST_DONE),
          findAudit(auditId),
          moment(cycleStartDate),
          preferredWeekday,
          audit.get(NEVER_DONE)
        ),
        'YYYY-MM-DD'
      ),
    }));
}

export function transformAuditData(audits, templateSecurityAudits, cycle, preferredWeekday) {
  return _transformAuditData(audits, templateSecurityAudits, cycle.get('start_date'), preferredWeekday)
    .valueSeq().toJS();
}

export function validateData(data, templateSecurityAudits, cycleStartDate) {
  const preferredWeekday = data[SECURITY_AUDIT_START_DAY] || 1;
  const audits = fromJS(data).remove(SECURITY_AUDIT_START_DAY);
  const findAudit = auditId => templateSecurityAudits.find(each => each.get('id') === auditId);

  return _transformAuditData(audits, templateSecurityAudits, cycleStartDate, preferredWeekday)
    .filter(audit => !audit.get(DUE_DATE))
    .map((audit, auditId) => ({
      [DATE_LAST_DONE]: isOptional(findAudit(auditId)) ? t('accountSetup.scheduledDateForOptional', 'You must provide a scheduled date for an optional audit.') : t('accountSetup.lastDoneDateForCompulsory', 'You must provide a last done date for a compulsory audit.')
    }))
    .toJS();
}

export const mapStateToProps = (state, props) => ({
  fields: getFields(props.templateSecurityAudits),
  validate: (data) => validateData(data, props.templateSecurityAudits, props.cycleStartDate),
});

export default reduxForm({
  form: SECURITY_AUDITS_SETUP_FORM,
  initialValues: {[SECURITY_AUDIT_START_DAY]: 1},
}, mapStateToProps)(SecurityAuditsSetupForm);
