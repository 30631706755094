import React from 'react';
import { Link } from 'react-router';

import { canUserEditDocument, getBaseTemplateDocument } from '^/models/documents';
import { t } from '^/i18n';

const getHelpText = templateVersion => getBaseTemplateDocument(templateVersion).get('help_text');

export function isDocumentLocked(templateVersion) {
  const workingVersion = templateVersion.get('working_version');
  const isCopied = Boolean(templateVersion.get('copied_template_document'));
  return !isCopied && workingVersion && workingVersion.getIn(['user', 'id']);
}

export function MaybeDisplayDocumentTakeOver(
  to, userCurrentlyEditing, userIsAdmin, children, className,
  templateVersion, openModal
  ) {

    if (userIsAdmin) {
    return (
      <span>
        <span className='lighten-text'>{ t('perms.userIsCurrentlyEditing', '{children} - {userCurrentlyEditing} is currently working on this document.', {children, userCurrentlyEditing}) }</span>
        <a
          className={className}
          onClick={() => openModal(templateVersion, userCurrentlyEditing, to)}
        >
          { t('perms.takeOver', ' Click here to take over.') }
        </a>
    </span>
    )
  }

  return (
    <span>
      <span className="lighten-text">{children}</span>
      <span>{ t('perms.beingWorkedOnBy', ' - being worked on by {userCurrentlyEditing}', {userCurrentlyEditing}) }</span>
    </span>
  )
}

export const MaybeDocumentEditLink = ({templateVersion, children, className, to, targetBlank, userId, userIsAdmin, openModal}) => {
  const locked = isDocumentLocked(templateVersion);
  const userCurrentlyEditing = locked && templateVersion.getIn(['working_version', 'user', 'staffdetail', 'full_name']);
  const userCurrentlyEditingId = locked && templateVersion.getIn(['working_version', 'user', 'id']);
  const loggedInUserIsEditing = locked && Boolean(userId === userCurrentlyEditingId);


  if (canUserEditDocument(templateVersion) && to) {
    if (!loggedInUserIsEditing && userCurrentlyEditingId) {
      return (
        <div className="inline-text" title={getHelpText(templateVersion)}>
          {MaybeDisplayDocumentTakeOver(to, userCurrentlyEditing, userIsAdmin, children, className, templateVersion, openModal)}
        </div>
      );
    } else if (loggedInUserIsEditing) {
      return (
        <span>
          <Link
            to={to}
            className={className}
            target={targetBlank && '_blank'}
            title={getHelpText(templateVersion)}
          >
            {children}
          </Link>
          <span>{ t('perms.youAreCurrentlyWorkingOnThisDocument', ' - You are currently working on this document and it is locked for other users') }</span>
        </span>
      );
    }
      return (
        <Link to={to} className={className} target={targetBlank && '_blank'} title={getHelpText(templateVersion)}>
          {children}
        </Link>
      );
  }
    return (<span title={getHelpText(templateVersion)}>{children}</span>);

};

export const DocumentEditingMayDisallow = ({templateVersion, children, className, hideMessage}) => {
  if (canUserEditDocument(templateVersion)) {
    return <div className={className}>{children}</div>;
  }

  if (hideMessage) {
    return <noscript />;
  }

  return (
    <div className={className}>
      <div className="p-1 alert alert-warning">{ t('perms.sorryYouAreNotAllowedToEditThisDocument', 'Sorry, you are not allowed to edit this document.') }</div>
    </div>
  );
};
