import React from 'react';

import { t } from '^/i18n';
import PureComponent from '^/components/common/PureComponent';
import Loading from '^/components/app/content/Loading';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import { closeModal } from '^/actions/modals';
import { exportDocumentFolder, resetResponse } from '^/actions/actions';
import { pollComplete, pollFailed, EXPORT_STATUS } from '^/models/export';
import { isPending, hasSucceeded, hasFailed } from '^/consts/responseStates';
import { DownloadButton } from '^/components/app/activities/documents/DownloadDocumentButton';
import ProgressBar, { COMPLETE } from '^/components/app/widgets/ProgressBar';

const STEPS = {
  ONE: 1,
  TWO: 2,
  THREE: 3
};

const FINAL_STEP = STEPS.THREE;


export class DocumentFolderExportModal extends PureComponent {
  componentDidMount() {
    this.props.exportDocumentFolder(this.props.folder.get('id'));
  }

  componentWillUnmount() {
    this.props.resetResponse('exportDocumentFolder');
    this.props.resetResponse('pollExportStatusBulk');
  }

  getStatus() {
    const {
      exportFoldersResponse,
      pollExportStatusBulkResponse,
      pollMergeDocumentsResponse,
      folder
    } = this.props;

    if (hasSucceeded(pollMergeDocumentsResponse) && pollComplete(pollMergeDocumentsResponse)) {
      const payload = pollMergeDocumentsResponse.get('payload');
      return [STEPS.THREE, (
        // eslint-disable-next-line react/jsx-key
        <div>
          <p>'{folder.get('name')}' {t('templateDocument.readyToDownload', 'is ready to download')}</p>
          <DownloadButton filename={payload.get('filename')} downloadLink={payload.get('download_url')} />
        </div>
      )];
    }
    if (pollComplete(pollExportStatusBulkResponse)) {
      return [STEPS.TWO, <p>{t('templateDocument.mergingDocuments', 'Merging Documents')}...</p>];  // eslint-disable-line react/jsx-key
    }

    const completedExports = pollExportStatusBulkResponse.getIn(['payload', 'statuses', EXPORT_STATUS.COMPLETE]);
    const totalExports = exportFoldersResponse.getIn(['payload', 'count']);
    const hasNotStarted = pollExportStatusBulkResponse.getIn(['payload', 'status']) === EXPORT_STATUS.NOTSTARTED;

    if (hasNotStarted) {
      return [STEPS.ONE, <span />]; // eslint-disable-line react/jsx-key
    }
    /* eslint-disable react/jsx-key */
    return [
      STEPS.ONE,
      <p>
        {t('templateDocument.generatingDocuments', 'Generating Documents')}... {completedExports && totalExports && `(${completedExports}/${totalExports})`}
      </p>
    ];
     /* eslint-enable react/jsx-key */
  }

  render() {
    const {
      exportFoldersResponse,
      pollExportStatusBulkResponse,
      mergeDocumentsResponse,
      pollMergeDocumentsResponse
    } = this.props;

    if (isPending(exportFoldersResponse)) {
      return <div><Loading /></div>;
    }

    if (hasFailed(exportFoldersResponse)
      || hasFailed(pollExportStatusBulkResponse)
      || hasFailed(mergeDocumentsResponse)
      || hasFailed(pollMergeDocumentsResponse)
      || hasFailed(pollExportStatusBulkResponse)
      || (hasSucceeded(pollMergeDocumentsResponse) && pollFailed(pollMergeDocumentsResponse))
      || (hasSucceeded(pollExportStatusBulkResponse) && pollFailed(pollExportStatusBulkResponse))) {
      return (
        <div className="text-center">
          <p>{t('templateDocument.fetchDocumentErrorMsg', 'Something went wrong getting your documents. Please try again later.')}</p>
          <a className="btn btn-default" onClick={this.props.closeModal}>Close</a>
        </div>
      );
    }

    if (exportFoldersResponse.getIn(['payload', 'count']) === 0) {
      return (
        <div className="text-center">
          <p>{t('templateDocument.noDocsExportMsg', 'No documents in this folder need exporting')}</p>
          <a className="btn btn-default" onClick={this.props.closeModal}>{t('common.button.close', 'Close')}</a>
        </div>
      );
    }

    const [ currentStep, statusComponent ] = this.getStatus();
    const percentage = Math.floor((currentStep / FINAL_STEP) * 100);
    return (
      <div className="text-center">
        <ProgressBar forceColour={COMPLETE} percentage={percentage} />
        { statusComponent }
        { currentStep !== STEPS.THREE && (<Loading className="mt-1" />)}
      </div>
    );
  }
}

export function mapStateToProps(state) {
  const mergeDocumentsResponse = state.responses.get('mergeDocuments', Map());
  const mergeExportId = mergeDocumentsResponse.getIn(['payload', 'id']);
  return {
    exportFoldersResponse: state.responses.get('exportDocumentFolder', Map()),
    pollExportStatusBulkResponse: state.responses.get('pollExportStatusBulk', Map()),
    mergeDocumentsResponse,
    mergeExportId,
    pollMergeDocumentsResponse: state.responses.getIn(['pollExport', mergeExportId], Map()),
  };
}

export default connect(mapStateToProps, {
  exportDocumentFolder,
  closeModal,
  resetResponse,
})(DocumentFolderExportModal);
