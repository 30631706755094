import classNames from 'classnames';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import { t } from '^/i18n';
import InlineSVG from '^/components/common/inlinesvg';
import { StoreState } from '^/store/types';
import { selectCurrentPracticeName } from '^/selectors/practice';
import BatteryBar from '^/components/app/groups/insights/visualisations/BatteryBar';
import Loading from '^/components/app/content/Loading';
import { getHealthcheckReview } from '^/actions/healthcheck';
import { extractErrorMessage } from '^/utils/common';
import FormError from '^/components/app/forms/FormError';
import { asPercent, getRateColourClassNames, makeStaticPath } from '^/utils';
import ReviewNextSteps from './review-next-steps';
import { HealthcheckReview } from './types';
import { THRESHOLD_BAD, THRESHOLD_OK } from '^/consts/healthcheck';

export const OverallScoreDisplay = ({
  overallScore,
}: {
  overallScore: number;
}) => (
  <div className="review-row pb-2 pt-2">
    <div>
      <h2 className="mt-4">{t('healthcheck.overallScore', 'Overall score')}</h2>
      <p
        className={classNames(
          'review-score',
          'bold',
          getRateColourClassNames(overallScore, THRESHOLD_BAD, THRESHOLD_OK)
        )}
      >
        {asPercent(overallScore)}%
      </p>
    </div>
    <div>
      <InlineSVG src={makeStaticPath(`images/report-woman.svg`)} />
    </div>
  </div>
);

interface StateProps {
  practiceName: string;
  healthcheckReview: HealthcheckReview | null;
  isLoading: boolean;
  error: string | null;
}

interface DispatchProps {
  getHealthcheckReview: typeof getHealthcheckReview;
}

type OwnProps = RouteComponentProps<{ healthcheckId: string }, {}>;

export type Props = StateProps & DispatchProps & OwnProps;

export class Review extends Component<Props, {}> {
  public componentDidMount() {
    this.props.getHealthcheckReview(this.props.params.healthcheckId);
  }

  public render() {
    const { practiceName, healthcheckReview, isLoading, error } = this.props;

    if (isLoading) {
      return <Loading className="" />;
    }

    if (error) {
      return <FormError error={error} />;
    }

    if (!healthcheckReview) {
      return (
        <FormError
          error={t(
            'healthcheck.cannotGetHealthcheckReview',
            'Could not get healthcheck review'
          )}
        />
      );
    }

    const {
      overall_score,
      question_yes_count,
      question_total_count,
      action_completed_count,
      action_total_count,
    } = healthcheckReview;

    return (
      <div className="col-1 mt-2 mb-2">
        <h1>
          {t(
            'healthcheck.complianceHealthcheckResults',
            'Compliance Healthcheck - Results'
          )}
          <span className="light-text ml-1">{practiceName}</span>
        </h1>
        <hr className="thin" />
        <OverallScoreDisplay overallScore={overall_score} />
        <hr className="thin" />
        <div className="review-row pb-2 pt-2">
          <div>
            <h3>{t('common.audit', 'Audit')}</h3>
            <BatteryBar
              className="mt-1 mb-1 healthcheck-review"
              rate={question_yes_count / question_total_count}
              height={16}
              borderRadius={8}
              thresholdBad={THRESHOLD_BAD}
              thresholdOk={THRESHOLD_OK}
            />
            <p className="large-text">
              {question_yes_count}/{question_total_count}{' '}
              {t(
                'healthcheck.questionsAnsweredYesInAuditPhase',
                'Questions answered yes in the audit phase.'
              )}
            </p>
          </div>
          <div>
            <h3>{t('common.actions', 'Actions')}</h3>
            <BatteryBar
              className="mt-1 mb-1 healthcheck-review"
              rate={
                action_total_count
                  ? action_completed_count / action_total_count
                  : 1
              }
              height={16}
              borderRadius={8}
              thresholdBad={THRESHOLD_BAD}
              thresholdOk={THRESHOLD_OK}
            />
            <p className="large-text">
              {`${
                action_total_count
                  ? `${action_completed_count}/${action_total_count} ${t(
                      'healthcheck.actionsCompleted',
                      'Actions completed in the actions phase.'
                    )}`
                  : t(
                      'healthcheck.noActionsToComplete',
                      'No actions to complete'
                    )
              }`}
            </p>
          </div>
        </div>
        <hr className="thin" />
        <ReviewNextSteps />
      </div>
    );
  }
}

const mapStateToProps = (state: StoreState): StateProps => ({
  practiceName: selectCurrentPracticeName(state),
  healthcheckReview: state.healthcheck.review,
  isLoading: state.thunkRequests.getHealthcheckReview.isLoading,
  error: extractErrorMessage(state.thunkRequests.getHealthcheckReview.errors),
});

export default connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, {
  getHealthcheckReview: getHealthcheckReview,
})(Review);
