import React from 'react';

import { t } from '^/i18n';

export const DEFAULT_SELECTION_VALUE = '0';

export const PracticesFilterDropdown = ({ onChange, choices, value, defaultDisplay, className }) =>
    <span className={className}>
      <select
        value={value || 0} onChange={evt => evt.target.value && onChange(evt.target.value)}
        id="practice-filter-select" className="form-control-margin"
      >
        <option value={0}>{t('users.profile.filterBy', 'Filter by')} {defaultDisplay}&hellip;</option>
        {choices.map(choice =>
          <option key={choice.get('value')} value={choice.get('value')}>
            {choice.get('display')}
          </option>
          )}
      </select>
    </span>;

export default PracticesFilterDropdown;
