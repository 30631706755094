import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import classNames from 'classnames';

import { t } from '^/i18n';
import { ACCOUNT_STAGES, ACCOUNT_URLS, ACCOUNT_STAGE_TEXT } from '^/consts/myAccount';


const MyAccountNavItem = ({accountStage, currentStage}) => {
  const isCurrent = accountStage === currentStage;
  return (
    <li className={isCurrent ? 'sidenav-active' : ''}>
      <Link className="sidenav-text" to={ACCOUNT_URLS.get(accountStage, '')}>
        <span>{ACCOUNT_STAGE_TEXT.get(accountStage)}</span>
      </Link>
    </li>
  );
};

const MyAccountNav = ({className, currentStage}) =>
  <div className={classNames(className, 'secondary-left')}>
    <h2>{t('common.myAccount', 'My Account')}:</h2>
    <ul className="list-no-indent icon-right">
      {ACCOUNT_STAGES.map(stage =>
        <MyAccountNavItem key={stage} accountStage={stage} currentStage={currentStage} />)}
    </ul>
  </div>;

const MyAccountPage = ({children, currentStage}) => (
  <div className="mt-2">
    <MyAccountNav className="col-1-3" currentStage={currentStage} />
    <div className="col-2-3 bordered-left">{children}</div>
  </div>
);


const mapStateToProps = (state) => {
  const path = state.routing.location.pathname || '';
  return {
    currentStage: ACCOUNT_URLS.keyOf(path)
  };
};

export default connect(mapStateToProps) (MyAccountPage);
