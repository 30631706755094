import React from 'react';
import { List } from 'immutable';

import { t } from '^/i18n';
import ChooseExistingTeamMemberForm from '^/components/app/users/forms/ChooseExistingTeamMemberForm';


export function filterMembers(members, userId, currentPractice) {
  const currentPracticeMembersIds =
    currentPractice
      .get('members', List())
      .map(member => member.getIn(['user', 'id']));

  return members
    .filter(member => member.get('staffdetail'))
    .filterNot(member => member.get('id') === userId)
    .filterNot(member => currentPracticeMembersIds.includes(member.get('id')));
}

export function mapPractices(practices, userId, currentPractice) {
  const currentPracticeId = currentPractice.get('id');

  return practices
    .filterNot(practice => practice.get('id') === currentPracticeId)
    .map(practice => practice.update('members', members => filterMembers(members, userId, currentPractice)))
    .filterNot(practice => practice.get('members', List()).isEmpty());
}


export const ChooseExistingTeamMember = props => {
  const {
    adminPractices,
    selectedPracticeId,
    onChangePractice,
    onSubmitExistingMembers,
    response,
    userId,
    currentPractice
  } = props;

  const otherPractices = mapPractices(adminPractices, userId, currentPractice);

  if (otherPractices.isEmpty()) {
    return <h2>
        {t('users.modals.allMembersAdded', 'All members from other practices have been added.')}
      </h2>;
  }

  const selectedPractice =
    otherPractices
      .find(practice => practice.get('id') === selectedPracticeId)
      || otherPractices.first();

  const selectedId = selectedPractice.get('id');
  const members = selectedPractice.get('members', List());

  const fields =
    members
      .map(member => member.get('id'))
      .toJS();

  return (
    <div>
      <select value={selectedId} onChange={e => onChangePractice(e.target.value)}>
        {otherPractices.map(practice =>
          <option key={practice.get('id')} value={practice.get('id')}>
            {practice.get('name')}
          </option>
        )}
      </select>

      <ChooseExistingTeamMemberForm
        response={response}
        members={members}
        onSubmit={onSubmitExistingMembers}
        fields={fields}
      />
    </div>
  );
};


export default ChooseExistingTeamMember;
