import React from 'react';
import { connect } from 'react-redux';

import { t } from '^/i18n';
import { expandUiComponent, collapseUiComponent, updateCollaborators, } from '^/actions/actions';
import Icon from '^/components/app/content/Icon';
import ManageCollaboratorsControl from './ManageCollaboratorsControl';
import PureComponent from '^/components/common/PureComponent';
import FormError from '^/components/app/forms/FormError';
import { isPending } from '^/consts/responseStates';

const COLLABORATORS_POPUP = 'COLLABORATORS_POPUP';

const name = (props, popup) => `${props.copiedDocumentId}.${popup}`;

export class ManageCollaborators extends PureComponent {

  constructor(props) {
    super(props);
    this.onAddCollaborator = this.onAddCollaborator.bind(this);
    this.onRemoveCollaborator = this.onRemoveCollaborator.bind(this);
    this.changeCollaborators = this.changeCollaborators.bind(this);
    this.onClosePopup = this.onClosePopup.bind(this);
    this.getPopupName = this.getPopupName.bind(this);
    this.onTogglePopup = this.onTogglePopup.bind(this);
    this.onOpenPopup = this.onOpenPopup.bind(this);
  }

  changeCollaborators (newCollaboratorIds) {
    const { copiedDocumentId, } = this.props;
    this.props.updateCollaborators(copiedDocumentId, newCollaboratorIds.toArray());
  }

  onAddCollaborator (collaboratorId) {
    const { currentCollaborators } = this.props;
    this.changeCollaborators(currentCollaborators.push(collaboratorId));
  }

  onRemoveCollaborator (collaboratorId) {
    const { currentCollaborators } = this.props;
    this.changeCollaborators(currentCollaborators.filterNot(id => id === collaboratorId));
  }

  getPopupName () {
    return name(this.props, COLLABORATORS_POPUP);
  }

  onClosePopup () {
    this.props.collapseUiComponent(this.getPopupName());
  }

  onOpenPopup () {
    this.props.expandUiComponent(this.getPopupName());
  }

  onTogglePopup() {
    const { isPopupOpen, } = this.props;

    if (isPopupOpen) {
      this.onClosePopup();
    } else {
      this.onOpenPopup();
    }
  }

  render () {
    const { currentCollaborators, isPopupOpen, response, } = this.props;

    return (
      <div className="popup-container ml-1-2">
        {isPopupOpen && (
          <div className="popup collaborators">
            <a className="close" onClick={this.onClosePopup}>
              <Icon type="close" className="inline-icon" />
            </a>

            <h5>{t('templateDocuments.changeCollaborators', 'Change collaborators')}</h5>

            <ManageCollaboratorsControl
              currentCollaborators={currentCollaborators}
              onAdd={this.onAddCollaborator}
              onRemove={this.onRemoveCollaborator}
              isLoading={isPending(response)}
            />

            <FormError response={response} formKey="collaborators" />
            <FormError response={response} formKey="non_field_errors" />
          </div>
        )}
        <a onClick={this.onTogglePopup} className="underlined">{t('common.button.manage', 'Manage')}</a>
      </div>
    );
  }
}

export function mapStateToProps (state, props) {
  return {
    isPopupOpen: state.ui.get('expandedUiComponents').contains(name(props, COLLABORATORS_POPUP)),
    response: state.responses.get('changeCollaborators'),
  };
}

export default connect(
  mapStateToProps,
  { collapseUiComponent, expandUiComponent, updateCollaborators, }
)(ManageCollaborators);
