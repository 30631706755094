import React from 'react';
import { closeModal } from '^/actions/modals';
import { connect } from 'react-redux';
import { List } from 'immutable';

import { t } from '^/i18n';
import ImmutablePropTypes from 'react-immutable-proptypes';
import CreateBulkDocReadForm from '^/components/app/template-documents/CreateBulkDocReadForm';
import PureComponent from '^/components/common/PureComponent';
import Loading from '^/components/app/content/Loading';

import { resetResponse } from '^/actions/actions';
import { bulkDocSendReadRequestAndCloseModal } from '^/actions/actionSequences';
import { getActiveMembers } from '^/models/practice';
import { isPending, hasSucceeded } from '^/consts/responseStates';
import { pollInProgress } from '^/models/export';
import { getAllCollection, getAllPracticeMembers } from '^/actions/collections';
import { ALL_PRACTICE_MEMBERS, GET_BULK_DOCUMENT_TO_SEND, DOC_READ_REQUEST_BULK_SEND } from '^/consts/collectionKeys';


export class CreateBulkDocReadModal extends PureComponent {
  componentDidMount() {
    this.props.getAllDocPracticeMembers()
    this.props.getAllDocCollection();
    this.props.resetResponse('pollBulkDocSendReadRequest');
  }

  render() {
    const {
      onClose,
      allActivePracticeMembers,
      allPracticeMembersResponse,
      bulkDocuments,
      bulkDocumentsResponse,
      pollCreateResponse,
      bulkDocSendReadResquestResponse,
    } = this.props;



    if (isPending(allPracticeMembersResponse) || isPending(bulkDocumentsResponse)) {
      return <Loading />;
    }

    if (isPending(bulkDocSendReadResquestResponse) || isPending(pollCreateResponse) || pollInProgress(pollCreateResponse)) {
      return (
        <div>
          <p>{t('templateDocument.bulkSendDocProgressMsg', 'Bulk Send Doc Read Request in progress... please wait')}!</p>
          <Loading />
        </div>
      );
    }

    return (
      <div>
        {
          hasSucceeded(bulkDocSendReadResquestResponse) &&
          <p className="alert mt-1 mb-1 alert-success">{t('templateDocument.bulkSendDoneMsg', 'Bulk Send Doc Read Request is done')}!</p>
        }
        <CreateBulkDocReadForm
          onClose={onClose}
          allActivePracticeMembers={allActivePracticeMembers}
          bulkDocuments={bulkDocuments}
          onSubmit={data => {this.props.createBulkDocReadRequest(data)}}
          hasSucceeded={hasSucceeded(bulkDocSendReadResquestResponse)}
        />
      </div>
    );
  }
}

CreateBulkDocReadModal.propTypes = {
  allActivePracticeMembers: ImmutablePropTypes.list,
  practiceId: React.PropTypes.string,
  alreadyAddedMembers: React.PropTypes.bool,
  onClose: React.PropTypes.func,
};

function dispatchDefaultCreateBulkDocRead(
  data,
  dispatch
) {
  const transformedDocData = {
    ...data
  }
  return dispatch(bulkDocSendReadRequestAndCloseModal(DOC_READ_REQUEST_BULK_SEND, transformedDocData));
}

export function mapDispatchToProps(dispatch, props) {
  return{
    createBulkDocReadRequest: (data) => dispatchDefaultCreateBulkDocRead(data,dispatch),
    onClose: () => dispatch(closeModal()),
    getAllDocPracticeMembers: () => dispatch(getAllPracticeMembers(props.practiceId)),
    getAllDocCollection: () => dispatch(getAllCollection(GET_BULK_DOCUMENT_TO_SEND)),
    resetResponse: (type) => dispatch(resetResponse(type)),
  }
}

export function mapStateToProps(state) {
  const bulkDocSendReadRequestJobId = state.items.getIn([DOC_READ_REQUEST_BULK_SEND, 'job_tracker_id']);
  const practiceMembers = state.collections.getIn(
    [ALL_PRACTICE_MEMBERS, 'items'],
    List()
  );
  const bulkDocuments = state.collections.getIn(
    [GET_BULK_DOCUMENT_TO_SEND, 'items'],
    List()
  );
  return {
    allActivePracticeMembers: getActiveMembers(practiceMembers),
    bulkDocuments,
    allPracticeMembersResponse: state.responses.getIn([
      'getCollection',
      ALL_PRACTICE_MEMBERS,
    ]),
    bulkDocumentsResponse: state.responses.getIn([
      'getCollection',
      GET_BULK_DOCUMENT_TO_SEND,
    ]),
    bulkDocSendReadResquestResponse: state.responses.getIn(['createItem', DOC_READ_REQUEST_BULK_SEND]),
    pollCreateResponse: state.responses.getIn(['pollBulkDocSendReadRequest', bulkDocSendReadRequestJobId]),
    pollTimeout: state.pollUpdateGroupTaskTimeouts.get(bulkDocSendReadRequestJobId),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateBulkDocReadModal);
