import React, { SFC } from 'react';
import { connect } from 'react-redux';

import { t } from '^/i18n';
import { StoreState } from '^/store/types';
import {
  selectBannerButtonText,
  selecthasCreateHealthcheckPermissions,
} from '^/selectors/healthcheck';
import Alert from '^/components/common/content/alert';
import Icon from '^/components/app/content/Icon';
import { startContinueOrViewHealthcheck } from '^/actions/healthcheck';
import NonFieldErrorRenderer from '^/components/common/NonFieldErrorRenderer';

interface DispatchProps {
  onClickButton: typeof startContinueOrViewHealthcheck;
}

interface StateProps {
  hasCreateHealthcheckPermissions: boolean;
  buttonText: string;
  isLoading: boolean;
  errors: unknown;
}

export type Props = StateProps & DispatchProps;

const HealthcheckBanner: SFC<Props> = ({
  hasCreateHealthcheckPermissions,
  onClickButton,
  isLoading,
  buttonText,
  errors,
}) => {
  if (!hasCreateHealthcheckPermissions) {
    return <noscript />;
  }

  return (
    <Alert className="healthcheck-banner small-text mb-1">
      <div className="content">
        <div className="left">
          <Icon type="info-circle" className="tip-icon pull-left" />
          <h5 className="m0 mb-1-2 mt-1-4 extra-bold-text">
            {t('healthcheck.startAHealthcheck', 'Start a Healthcheck')}
          </h5>
          <p>
            {t(
              'healthcheck.threeStepProcessMessage',
              'A healthcheck is a simple 3 step process that helps you audit your practice to see where you stand, and advise you of things you can do to quickly improve.'
            )}
          </p>
        </div>
        <div className="right">
          <button
            className="btn btn-default"
            onClick={onClickButton}
            disabled={isLoading}
          >
            {buttonText}
          </button>
        </div>
      </div>
      <NonFieldErrorRenderer errors={errors} className="mt-1" />
    </Alert>
  );
};

const mapStateToProps = (state: StoreState): StateProps => ({
  hasCreateHealthcheckPermissions: selecthasCreateHealthcheckPermissions(state),
  buttonText: selectBannerButtonText(state),
  isLoading:
    state.thunkRequests.createHealthcheck.isLoading ||
    state.thunkRequests.getHealthcheckQuestionsProgress.isLoading ||
    state.thunkRequests.getHealthcheckActionsProgress.isLoading,
  errors:
    state.healthcheck.genericHealthcheckError ??
    state.thunkRequests.createHealthcheck.errors ??
    state.thunkRequests.getHealthcheckQuestionsProgress.errors ??
    state.thunkRequests.getHealthcheckActionsProgress.errors,
});

export { HealthcheckBanner };

export default connect<StateProps, DispatchProps, {}>(mapStateToProps, {
  onClickButton: startContinueOrViewHealthcheck,
})(HealthcheckBanner);
