import React, { Component } from 'react';
import { connect } from 'react-redux';

import { t } from '^/i18n';
import { IntroOptions } from '^/components/app/healthcheck/types';
import { updateHealthcheckStatusIfMatchAndContinue } from '^/actions/healthcheck';
import { CurrentUserProfile } from '^/types/accounts';
import { StoreState } from '^/store/types';
import { selectNonImmutableUserProfile } from '^/selectors/healthcheck';

interface StateProps {
  userProfile: CurrentUserProfile | null;
}

interface DispatchProps {
  updateHealthcheckStatusIfMatchAndContinue: typeof updateHealthcheckStatusIfMatchAndContinue;
}

type Props = StateProps & DispatchProps;

const createIntroPage = <P extends {} = {}>(options: IntroOptions) => (
  Content: React.ComponentType<P>
) => {
  class HealthcheckIntroPage extends Component<Props & P, {}> {
    public render() {
      const { userProfile, ...remainingProps } = this.props;

      return (
        <div className="healthcheck">
          <div className="main">
            <h1>
              <span className="bold">
                {t(
                  'healthcheck.complianceHealthcheck',
                  'Compliance Healthcheck'
                )}{' '}
                - {options.type}
              </span>
              <span className="ml-1">
                {userProfile?.staffdetail.current_practice.name ??
                  t('healthcheck.unknownPractice', 'Unknown Practice')}
              </span>
            </h1>
            <hr className="thin" />

            <h2>{options.header}</h2>
            <Content {...(remainingProps as P)} />
            <button className="btn btn-save" onClick={this.onClickNext}>
              {options.buttonText}
            </button>
          </div>
        </div>
      );
    }

    private onClickNext = () => {
      this.props.updateHealthcheckStatusIfMatchAndContinue(
        options.intendedFromStatus
      );
    };
  }

  const mapStateToProps = (
    state: Pick<StoreState, 'userProfile'>
  ): StateProps => ({
    userProfile: selectNonImmutableUserProfile(state),
  });

  return connect<StateProps, DispatchProps, {}>(mapStateToProps, {
    updateHealthcheckStatusIfMatchAndContinue,
  })(HealthcheckIntroPage as any); // eslint-disable-line @typescript-eslint/no-explicit-any
};

export default createIntroPage;
