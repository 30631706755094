import React from 'react';

import { t } from '^/i18n';
import LandingCard from '^/components/app/groups/insights/LandingCard';

export const GroupDocumentsDeniedAccessLandingText = () => (
  <p className="text-center">
    To gain access to group documents, please contact{' '}
    <a href="mailto:icomply@agiliosoftware.com" className="underlined">iComply</a>.
  </p>
);

const GroupDocumentsDeniedAccessLanding = ({query}) => {
  return (
    <LandingCard
      title={t('groups.insights.groupDocumentsAdopted', 'Group documents adopted')}
      className="insights-card disabled"
      icon="file-solid"
      query={query}
    >
      <div className="group-documents-landing">
        <GroupDocumentsDeniedAccessLandingText />
      </div>
    </LandingCard>
  );
};

export default (GroupDocumentsDeniedAccessLanding);
