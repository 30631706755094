import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { t } from '^/i18n';
import Icon from '^/components/app/content/Icon';
import {
  getSubsequentCyclesSetupStagesToComplete,
  SUBSEQUENT_SETUP_URLS,
  SETUP_STAGE_TEXT,
  getCycleStages,
} from '^/consts/setup';
import { selectCurrentPractice } from '^/selectors/practice';


const CycleSetupNavItem = ({allSetupStages, setupStage, currentStage}) => {
  const isComplete = allSetupStages.toJS().indexOf(setupStage) < allSetupStages.toJS().indexOf(currentStage);
  const isCurrent = setupStage === currentStage;
  return (
    <li className={isCurrent ? 'sidenav-active' : ''}>
      <span
        className={
        classNames(
          'sidenav-text',
          isComplete ? 'step-complete' : ''
        )}
      >
        {SETUP_STAGE_TEXT.get(setupStage)}
        {isCurrent &&
          <Icon type="long-arrow-left" className="setup-icon" />
        }
        {!isCurrent && isComplete &&
          <Icon type="check" className="setup-icon complete" />
        }
      </span>
    </li>
  );
};

const CycleSetupNavHeader = () =>
  <li><span className="sidenav-text"><strong>{t('cycleSetup.cycleSetup', 'Cycle Setup')}:</strong></span></li>;

const CycleSetupNav = ({className, currentStage, setupStagesToComplete, allSetupStages}) =>
  <div className={classNames(className, 'secondary-left')}>
    <ul className="list-no-indent icon-right">
      <CycleSetupNavHeader />
      {setupStagesToComplete.map(stage =>
        <CycleSetupNavItem key={stage} allSetupStages={allSetupStages} setupStage={stage} currentStage={currentStage} />)}
    </ul>
  </div>;

export const CycleSetupPage = ({children, currentStage, setupStagesToComplete, allSetupStages}) =>
  <div className="col-1 mb-2 mt-2">
    <div className="row">
      <CycleSetupNav className="col-1-3" currentStage={currentStage} setupStagesToComplete={setupStagesToComplete} allSetupStages={allSetupStages} />
      <div className="col-2-3 bordered-left">{children}</div>
    </div>
  </div>;


export const mapStateToProps = (state, props) => {
  const path = state.routing.location.pathname || '';
  const cycleId = props.params.uuid;
  const practice = selectCurrentPractice(state);
  return {
    allSetupStages: getCycleStages(practice),
    setupStagesToComplete: getSubsequentCyclesSetupStagesToComplete(practice),
    currentStage: SUBSEQUENT_SETUP_URLS(cycleId).keyOf(path)
  };
};

export default connect(mapStateToProps) (CycleSetupPage);
