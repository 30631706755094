import React from 'react';
import { connect } from 'react-redux';

import { t } from '^/i18n';
import { closeModal } from '^/actions/modals';
import { makeStaticPath } from '^/utils';
import LiveButton from '^/components/app/forms/LiveButton';

function actionAndCloseModal(action, onClose) {
  action();
  onClose();
}

export const ComparisonEditorExitConfirmationModal = ({
  document,
  onSaveAndExit,
  onSaveAndPublish,
  response,
  discard,
  closeModal: onClose,
}) => {
  const documentName = (
    document.getIn(['template_document', 'name']) ||
    document.getIn(['adopted_template_document', 'document_name']) ||
    document.getIn(['copied_template_document', 'name'])
  );

  return (
    <div className="leaving-confirmation-modal-wrapper">
      <div className="brand-logo-modal">
        <img src={makeStaticPath('images/brand/logo-color.svg')} className="brand-logo-modal" />
      </div>
      <div className="leaving-modal-list">
        <ul>
          <li>{t('templateDocument.saveChangesMsg', 'Do you want to save your changes to')} "{documentName}"?</li>
          <li>
            {t('templateDocument.notSavedWarningMsg', `If you click 'Don't Save' you will lose any edits to the document that you have made since you last saved it.`)}
          </li>
          <li>{t('templateDocument.cancelDocumentWarningMsg', `Click 'Cancel' to go back to edit the document.`)}</li>
        </ul>
      </div>
      <div className="leaving-modal-buttons">
        <LiveButton
          className="btn btn-leaving-modal margin-mid"
          onClick={() => actionAndCloseModal(onSaveAndExit, onClose)}
          response={response}
        >
          {t('common.button.saveAndExit', 'Save and Exit')}
        </LiveButton>
        <LiveButton
          className="btn btn-leaving-modal margin-mid"
          onClick={() => actionAndCloseModal(onSaveAndPublish, onClose)}
          response={response}
        >
          {t('common.button.saveAndPublish', 'Save and Publish')}
        </LiveButton>
        <LiveButton
          className="btn btn-leaving-modal margin-mid"
          onClick={() => actionAndCloseModal(discard, onClose)}
          response={response}
        >
          {t('common.button.dontSave', `Don't Save`)}
        </LiveButton>
        <LiveButton
          className="btn btn-leaving-modal margin-mid"
          onClick={onClose}
        >
          {t('common.button.cancel', 'Cancel')}
        </LiveButton>
      </div>
    </div>
  );
};

export function mapStateToProps(state) {
  return {
    response: state.responses.get('createTemplateVersion'),
  };
}

export default connect(mapStateToProps, {closeModal}) (ComparisonEditorExitConfirmationModal);
