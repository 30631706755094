import React from 'react';
import { connect } from 'react-redux';

import { t } from '^/i18n';
import { closeModal } from '^/actions/modals';
import { restoreAdoptedVersionAndCloseModal } from '^/actions/actionSequences';
import LiveButton from '^/components/app/forms/LiveButton';

export const RestoreAdoptedVersionModal = ({docId, activityId, redirectToFunc, onRestore, onClose, response}) => {
  return (
    <div>
      <div className="row">
        <div className="col-1">
          <p>
            {t('templateDocument.restoreOriginalTemplateConfirmationMsg', `Restoring this document to the original template will remove all current document data and restore it to the iComply template. If you wish to continue click 'Restore'.`)}
          </p>
        </div>
      </div>
      <div className="row align-right mt-1">
        <div className="col-1">
          <button className="btn btn-default" onClick={onClose}>
            {t('common.button.cancel', 'Cancel')}
          </button>
          <LiveButton
            className="btn btn-warning ml-1"
            onClick={() => onRestore(docId, activityId, redirectToFunc)}
            response={response}
          >
            {t('common.button.restore', 'Restore')}
          </LiveButton>
        </div>
      </div>
    </div>
  );
};

export function mapStateToProps(state) {
  return {
    response: state.responses.get('createTemplateVersion'),
  };
}

export default connect(mapStateToProps, {
  onRestore: restoreAdoptedVersionAndCloseModal,
  onClose: closeModal
}) (RestoreAdoptedVersionModal);
