import React from 'react';
import { reduxForm } from 'redux-form';
import { List, Map } from 'immutable';


import FormError from '^/components/app/forms/FormError';
import LiveButton from '^/components/app/forms/LiveButton';
import DayOfTheWeekInput from '^/components/app/forms/DayOfTheWeekInput';
import TimeInput from '^/components/app/forms/TimeInput';
import { MEETING_DAY, MEETING_TIME } from '^/consts/account-set-up/fieldNames';
import { t } from '^/i18n';

export const MEETINGS_SETUP_FORM = 'MEETINGS_SETUP_FORM';

export const PRACTICE_MEETING = 'PRACTICE_MEETING';

export const MEETING_TYPES = Map({
  [PRACTICE_MEETING]: 'Practice',
});


export const MeetingsFields = ({fields, templateMeetings, response, meetingType, cycleStartingMonth}) =>
  <div className="mt-2 mb-2 small-text">
    <h4 className="pl-1 pr-1 m0">{ t('accountSetup.meetings', '{meetingType} meetings', { meetingType: MEETING_TYPES.get(meetingType) }) }</h4>
    <div>
      <label className="mr-1 ml-1" htmlFor={meetingType + MEETING_DAY}>{ t('accountSetup.dayOfTheWeek', 'Day of week') }</label>
      <DayOfTheWeekInput id={meetingType + MEETING_DAY} field={fields[meetingType][MEETING_DAY]} />
      <label className="mr-1 ml-1" htmlFor={meetingType + MEETING_TIME}>at</label>
      <TimeInput id={meetingType + MEETING_TIME} {...fields[meetingType][MEETING_TIME]} className="valign-middle" />
      <FormError response={response} formKeyPath={[meetingType, MEETING_DAY]} />
      <FormError response={response} formKeyPath={[meetingType, MEETING_TIME]} />
    </div>
    <hr className="thin" />

    {
      templateMeetings && templateMeetings.count() > 0 ?
        templateMeetings
          .sortBy(
            each =>
              each.getIn(['template_activity', 'default_month'])
          )
          .map(
            each =>
              <div
                key={each.get('id')} className="pl-1 pr-1 template-meeting"
                title={each.getIn(['template_activity', 'help_text'])}
              >
                {/* This is an index so add 1 for the non l33t h4x0rz */}
                { t('common.month', 'Month') } {each.getIn(['template_activity', 'default_month']) + 1}:
                &nbsp;
                {each.getIn(['template_activity', 'name'])}
              </div>
          ) :
        <p className="pl-1 pr-1">
          { t('accountSetup.noMeetingsForCycle', 'No meetings for the cycle starting in month {cycleStartingMonth} need to be held.', { cycleStartingMonth }) }
        </p>
    }

    <hr className="thin" />
  </div>;


export const MeetingsSetupForm = ({
   handleSubmit, response, fields, templateMeetings, cycleStartingMonth
}) =>
  <form onSubmit={handleSubmit}>

    <MeetingsFields
      response={response}
      meetingType={PRACTICE_MEETING}
      fields={fields}
      templateMeetings={templateMeetings.get(PRACTICE_MEETING)}
      cycleStartingMonth={cycleStartingMonth}
    />

    <LiveButton className="btn-default" pendingMessage="Saving..." response={response}>
      { t('common.next', 'Next')}...
    </LiveButton>
  </form>
;

export const fields = MEETING_TYPES
  .map((description, name) => List.of(name + '.' + MEETING_DAY, name + '.' + MEETING_TIME))
  .valueSeq()
  .flatten()
  .toJS();

export const initialValues = MEETING_TYPES
  .map(
    () =>
      Map({
        [MEETING_DAY]: 1,
        [MEETING_TIME]: '09:00',
      })
  )
  .toJS();

export default reduxForm(
  { form: MEETINGS_SETUP_FORM, fields, initialValues }
)(MeetingsSetupForm);
