import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { List } from 'immutable';
import moment from 'moment';
import classNames from 'classnames';

import { t } from '^/i18n';
import {
  getActivityTypeText,
  STATUS_CLASSES,
  ACTIVITY_STATUSES,
  ACTIVITY_TYPE,
} from '^/models/activities';
import { PRIORITY_CLASSES } from '^/models/constants';
import { isPending } from '^/consts/responseStates';
import { setCurrentMonthAndGetMyActivities } from '^/actions/actionSequences';
import { ACTIVITIES } from '^/consts/collectionKeys';
import PureComponent from '^/components/common/PureComponent';
import Loading from '^/components/app/content/Loading';
import GenericComplianceReviewList from '^/components/app/activities/generic-activity/GenericComplianceReviewList';
import CycleMonth from '^/components/app/calendar/CycleMonth';
import ActivityStatusIcon from '^/components/app/activities/ActivityStatusIcon';
import Collapse from '^/components/app/content/Collapse';
import CollapseButton from '^/components/app/content/CollapseButton';

const COLLAPSE_ID_PREFIX = 'COMPLIANCE_REVIEWS_SIDEBAR_';

const ActivityNavListItem = ({activity, isSelected, selectedActivity}) => {
  const { id, type, title, status, help_text, compliance_reviews, priority } = activity.toObject();
  const className = STATUS_CLASSES[status || ACTIVITY_STATUSES.NOT_STARTED];
  const to = `/page/activities/${id}/`;

  return (
    <li className={classNames({'sidenav-active': isSelected})}>
      <Link className={classNames('step-link', `step-${className}`)} to={to} title={help_text}>
        <div className="display-flex">
          {priority && <span className={classNames('vertical-lozenge', `${PRIORITY_CLASSES[priority]}`)} />}
          <span><b>{getActivityTypeText(type)}</b>: {title}</span>
          <ActivityStatusIcon activity={activity} />
        </div>
      </Link>

      {compliance_reviews && (
        <div className="mb-1-2">
          <CollapseButton collapseId={COLLAPSE_ID_PREFIX + activity.get('id')}>
            <h5 className="ml-1-2 mb-1-2">{t('activities.complianceReviews', 'Compliance Reviews')}</h5>
          </CollapseButton>
          <Collapse
            initiallyCollapsed={selectedActivity.get('type') !== ACTIVITY_TYPE.ADMIN_STEP}
            collapseId={COLLAPSE_ID_PREFIX + activity.get('id')}
            className="content"
          >
            <GenericComplianceReviewList activities={compliance_reviews} selectedActivity={selectedActivity} />
          </Collapse>
        </div>
      )}
    </li>
  );
};

const ActivityNavSection = ({selectedActivity, activities}) =>
  <ul className="list-no-indent icon-right">
    {activities
      .sortBy(each => each.get('start_date'))
      .map(each =>
        <ActivityNavListItem
          key={each.get('id')}
          activity={each}
          selectedActivity={selectedActivity}
          isSelected={each.get('id') === selectedActivity.get('id')}
        />
      )
    }
  </ul>
;

const ActivityNav = ({activity, activities, month}) =>
  <div className="col-1-3 mt-2 secondary-left hidden-m">
    <div className="back-link-container relative" />

    <h3><CycleMonth month={month} /> ({month.format('MMMM')})</h3>
    <ActivityNavSection
      selectedActivity={activity}
      activities={activities}
    />

  </div>
;

export class ActivityNavLoader extends PureComponent {
  getMonth() {
    const evalStartDate = this.props.adminStep ? this.props.adminStep.get('start_date') :
      this.props.activity.get('start_date');
    return moment(evalStartDate);
  }

  componentWillMount() {
    this.loadActivities(this.getMonth(), this.props.currentPracticeId);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.currentPracticeId !== this.props.currentPracticeId) {
      this.loadActivities(this.getMonth(), nextProps.currentPracticeId);
    }
  }

  loadActivities(month, practice) {
    if (month && practice) {
      this.props.setCurrentMonthAndGetMyActivities(month, practice, true);
    }
  }

  render() {
    return this.props.loading ? (
      <div className="col-1-3 mt-2 secondary-left hidden-m">
        <Loading />
      </div>
    ) : (
      <ActivityNav month={this.getMonth()} {...this.props} />
    );
  }
}

ActivityNavLoader.propTypes = {
  activity: ImmutablePropTypes.map,
};

export function mapStateToProps(state) {
  return {
    activities: state.collections.getIn(['activities', 'items'], List()),
    currentPracticeId: state.currentPractice && state.currentPractice.get('id'),
    loading: isPending(state.responses.getIn(['getCollection', ACTIVITIES])),
  };
}

export default connect(mapStateToProps, { setCurrentMonthAndGetMyActivities }) (ActivityNavLoader);
