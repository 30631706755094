import React from 'react';
import { connect } from 'react-redux';
import { Map } from 'immutable';

import { getSetupTeamMembers } from '^/actions/actions';
import { setupCycleResponsibilitiesAndReloadUser } from '^/actions/actionSequences';
import { getAllCollection } from '^/actions/collections';
import { RESPONSIBILITIES } from '^/consts/collectionKeys';

import PureComponent from '^/components/common/PureComponent';
import LiveButton from '^/components/app/forms/LiveButton';
import Loading from '^/components/app/content/Loading';
import AccountSetupHeader from '^/components/app/account-set-up/AccountSetupHeader';
import CycleResponsibilitiesForm, { OVERRIDE_TEXT } from '^/components/app/account-set-up/CycleResponsibilitiesForm';
import { t } from '^/i18n';
import _ from 'underscore';


export class CycleResponsibilitiesSetupPage extends PureComponent {

  componentWillMount() {
    this.loadPracticeAndMembers(this.props.practiceId);
    this.props.getAllCollection(RESPONSIBILITIES);
  }

  componentWillReceiveProps(newProps) {
    if (this.props.practiceId !== newProps.practiceId) {
      this.loadPracticeAndMembers(newProps.practiceId);
    }
  }

  loadPracticeAndMembers(practiceId) {
    if (practiceId) {
      this.props.getSetupTeamMembers(practiceId);
    }
  }

  transformAndSubmitData(data) {
    const { practiceId, responsibilities } = this.props;
    const dataToSubmit = Map(responsibilities.map((each) => {
      if (data[each.get('id')]){
        return [
          each.get('id'),
          {staff_id: data[each.get('id')], override_text: data[each.get('id') + OVERRIDE_TEXT]}
        ];
    }
    })).toJS();
    this.props.setupCycleResponsibilitiesAndReloadUser(practiceId, dataToSubmit);
  }

  render () {
    const { user, practiceId, response, teamMembers, responsibilities, practice } = this.props;

    if (!user || !practiceId || !teamMembers || !responsibilities) {
      return <Loading />;
    }

    const fields = responsibilities.map(each => each.get('id')).toJS();
    const overrideTextFields = responsibilities.map(each => each.get('id') + OVERRIDE_TEXT).toJS();

    const initialValues = _.object(fields, Array(fields.length).fill(user.getIn(['staffdetail', 'id'])));

    return (
      <div>
        <AccountSetupHeader user={user} practice={practice}>
          { t('accountSetup.setupCycleResponsibilitiesHeaderMsg', 'Setup - cycle responsibilities') }
        </AccountSetupHeader>

        <p>{ t('practices.chooseReponsibleTeamMembersMsg', 'Please choose team members who will be responsible for compliance reminders and administrative activities')}</p>
        <hr className="thin" />

        <CycleResponsibilitiesForm
          response={response}
          teamMembers={teamMembers}
          responsibilities={responsibilities}
          fields={fields.concat(overrideTextFields)}
          initialValues={initialValues}
          onSubmit={data => this.transformAndSubmitData(data)}
        >
          <LiveButton className="btn-default" pendingMessage="Saving..." response={response}>
            { t('common.next', 'Next')}...
          </LiveButton>
        </CycleResponsibilitiesForm>
      </div>
    );
  }

}

export const mapStateToProps = (state) => ({
  user: state.userProfile,
  practiceId: state.userProfile && state.userProfile.getIn(['staffdetail', 'current_practice', 'id']),
  teamMembers: state.teamMembers,
  practice: state.currentPractice,
  response: state.responses.get('setupCycleResponsibilities'),
  responsibilities: state.collections.getIn([RESPONSIBILITIES, 'items']),
});

export default connect(mapStateToProps, {
  getSetupTeamMembers, setupCycleResponsibilitiesAndReloadUser, getAllCollection
}) (CycleResponsibilitiesSetupPage);
