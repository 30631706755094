import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ConnectHOC } from '@dabapps/connect-hoc';

import { t } from '^/i18n';
import ButtonRow from '^/components/app/forms/ButtonRow';
import {
  ItemRendererProps,
  HealthcheckQuestion,
  HealthcheckQuestionResponse,
} from './types';
import { StoreState } from '^/store/types';
import { answerHealthcheckQuestion } from '^/actions/healthcheck';
import NonFieldErrorRenderer from '^/components/common/NonFieldErrorRenderer';
import {
  selectRequestErrorsById,
  selectRequestIsLoadingById,
} from '^/selectors/thunk-requests';

interface StateProps {
  errors: unknown;
  isLoading: boolean;
}

interface DispatchProps {
  onAnswerSelect: typeof answerHealthcheckQuestion;
}

type OwnProps = ItemRendererProps<HealthcheckQuestion>;

export type Props = OwnProps & StateProps & DispatchProps;

class QuestionAndAnswer extends Component<Props, {}> {
  public render() {
    const { item, errors, isLoading } = this.props;

    return (
      <div className="question-and-answer-wrapper">
        <div className="question-and-answer">
          <span className="question pr-large">{item.question_text}</span>
          <span>
            <ButtonRow
              onSelect={this.onSelect}
              buttons={[
                {
                  text: t('common.yes', 'Yes'),
                  value: HealthcheckQuestionResponse.YES,
                  selected: item.response === HealthcheckQuestionResponse.YES,
                  disabled: isLoading,
                },
                {
                  text: t('common.no', 'No'),
                  value: HealthcheckQuestionResponse.NO,
                  selected: item.response === HealthcheckQuestionResponse.NO,
                  disabled: isLoading,
                },
                {
                  text: t('common.notAvailable', 'N/A'),
                  value: HealthcheckQuestionResponse.NA,
                  selected: item.response === HealthcheckQuestionResponse.NA,
                  disabled: isLoading,
                },
              ]}
            />
          </span>
        </div>
        {errors && (
          <div>
            <NonFieldErrorRenderer errors={errors} />
            <button onClick={this.onTryAgain}>Try again?</button>
          </div>
        )}
      </div>
    );
  }

  public onSelect = (value: HealthcheckQuestionResponse) => {
    const {
      healthcheckId,
      categoryId,
      areaId,
      item,
      onAnswerSelect,
    } = this.props;

    onAnswerSelect(healthcheckId, categoryId, areaId, item.id, value);
  };

  public onTryAgain = () => {
    const { response } = this.props.item;
    if (response) {
      this.onSelect(response);
    }
  };
}

const mapStateToProps = (state: StoreState, props: OwnProps): StateProps => ({
  errors: selectRequestErrorsById(state, {
    requestName: 'answerHealthcheckQuestion',
    requestId: props.item.id,
  }),
  isLoading: selectRequestIsLoadingById(state, {
    requestName: 'answerHealthcheckQuestion',
    requestId: props.item.id,
  }),
});

export { QuestionAndAnswer as QuestionAndAnswerUnconnected };

export default (connect as ConnectHOC)<
  StateProps,
  DispatchProps,
  OwnProps,
  StoreState
>(mapStateToProps, {
  onAnswerSelect: answerHealthcheckQuestion,
})(QuestionAndAnswer);
