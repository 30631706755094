import React from 'react';
import { reduxForm } from 'redux-form';

import { t } from '^/i18n';
import { formatDate } from '^/utils';
import LiveButton from '^/components/app/forms/LiveButton';

export const REASSIGN_TASKS_FORM = 'REASSIGN_TASKS_FORM';


export const TaskField = ({fields, task, memberChoices}) => {
  const field = fields[task.get('id')];

  return (
    <tr>
      <td>
        <label htmlFor={task.get('id')}>
          {task.get('title')}
        </label>
      </td>
      <td>
        {formatDate(task.get('deadline'))}
      </td>
      <td>
        <select id={field.name} {...field} >
          {memberChoices.map(each =>
              <option key={each.get('value')} value={each.get('value')}>
                {each.get('display')}
              </option>
          )}
        </select>
      </td>
    </tr>
  );
};

export const ReassignTasksForm = ({fields, tasks, memberChoices, handleSubmit}) =>
  <form onSubmit={handleSubmit}>
    <div className="mt-1 small-text">
      {
        tasks.size > 0 ?
        <table>
          <thead>
            <tr>
              <th>
                <strong>{t('common.tasksCC', 'Tasks')} ({tasks.size})</strong>
              </th>
              <th>{t('common.forms.dueDate', 'Due Date')}</th>
              <th>{t('common.newAssignee', 'New Assignee')}</th>
            </tr>
          </thead>
          <tbody>
            {
              tasks.map(each =>
                <TaskField
                  key={each.get('id')}
                  memberChoices={memberChoices}
                  task={each}
                  fields={fields}
                />
              )
            }
          </tbody>
        </table>
        : <p>{t('practices.noTasksReassignMsg', 'No tasks to reassign')}.</p>
      }
      <LiveButton className="btn-default mt-1">
        {t('common.next', 'Next')}...
      </LiveButton>
    </div>
  </form>;

export default reduxForm({ form: REASSIGN_TASKS_FORM })(ReassignTasksForm);

