import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import { Audit, AuditStatus } from '^/components/app/digital-tools/audit/types';
import { continueAuditFrom } from '^/actions/audit';
import { t } from '^/i18n';

interface OwnProps {
  currentUserId: string | undefined;
  inProgressAudit: Audit;
}

interface DispatchProps {
  continueAuditFrom: typeof continueAuditFrom;
}

export type Props = OwnProps & DispatchProps;

export class ActivityAudit extends Component<Props, {}> {
  public render() {
    const { inProgressAudit, currentUserId } = this.props;
    const auditCompleted =
      inProgressAudit.status === AuditStatus.REVIEW ||
      inProgressAudit.status === AuditStatus.COMPLETED;

    return (
      <div className="x-small-text">
        <span className="bold-text">
          {`${
            auditCompleted
              ? `Completed: ${moment(
                  inProgressAudit.modified,
                  'YYYY-MM-DD'
                ).format('DD/MM/YYYY')}`
              : t('digitalTools.audit.inProgress', 'In progress')
          }`}
        </span>
        <span className="ml-1">
          {`${auditCompleted ? 'Completed by:' : 'Assigned to:'}`}
        </span>
        <span className="bold-text ml-1-4">
          {inProgressAudit.user.full_name}
        </span>
        {auditCompleted && (
          <span>
            <span className="ml-1">Audit score:</span>
            <span className="bold-text ml-1-4">
              {Math.round(inProgressAudit.audit_score * 100)}%
            </span>
          </span>
        )}
        {auditCompleted && (
          <span
            onClick={this.onClickContinueAudit}
            className="underlined pointer ml-1"
          >
            {t('common.button.view', 'View')}
          </span>
        )}
        {!auditCompleted && inProgressAudit.user.id === currentUserId && (
          <span
            onClick={this.onClickContinueAudit}
            className="underlined pointer ml-1"
          >
            {t('digitalTools.audit.continue', 'Continue')}
          </span>
        )}
      </div>
    );
  }

  private onClickContinueAudit = () => {
    const { inProgressAudit } = this.props;
    this.props.continueAuditFrom(inProgressAudit.status, inProgressAudit.id);
  };
}

export default connect<{}, DispatchProps, OwnProps>(null, {
  continueAuditFrom,
})(ActivityAudit);
