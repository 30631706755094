import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';


import { t } from '^/i18n';
import { formatValue, pluralize } from '^/utils';
import {
  getAllCollection,
} from '^/actions/collections';
import {
  PRIVATE_TEMPLATES_COPIES,
} from '^/consts/collectionKeys';
import { openReassignPrivateDocumentsModal } from '^/actions/modals';
import Icon from '^/components/app/content/Icon';
import { SSO_SECURITY_QUESTION_PATH, SSO_CORRESPONDENCE_PATH } from '^/consts/single-sign-on';

import PureComponent from '^/components/common/PureComponent';

export class UserProfilePage extends PureComponent {

  componentWillMount() {
    this.props.getAllCollection(PRIVATE_TEMPLATES_COPIES);
  }

  render() {
    const { user, privateDocuments } = this.props;
    const staffdetail = user.get('staffdetail');
    const privateDocumentItems = privateDocuments && privateDocuments.get('items');
    const privateDocumentCount = privateDocuments && parseInt(privateDocuments.get('count'), 10);

    return (
      <div className="employee-details">
        <h1>{t('users.profile.personalDetails', 'Personal details')}: <em>{formatValue(user, ['staffdetail', 'full_name'])}</em></h1>
        <hr className="dark" />

        <div className="form-group">
          <div className="col-1-3 label-left">
            {t('common.forms.firstName', 'First Name')}
          </div>
          <div className="col-2-3">
            {formatValue(user, ['staffdetail', 'first_name'])}
          </div>
        </div>

        <div className="form-group">
          <div className="col-1-3 label-left">
            {t('common.forms.lastName', 'Last name')}
          </div>
          <div className="col-2-3">
            {formatValue(user, ['staffdetail', 'last_name'])}
          </div>
        </div>

        <div className="form-group">
          <div className="col-1-3 label-left">
            {t('common.forms.email', 'Email')}
          </div>
          <div className="col-2-3">
            <a href={`mailto:${formatValue(user, ['email'])}`} className="underlined">{formatValue(user, ['email'])}</a>
          </div>
        </div>

        <div className="form-group">
          <div className="col-1-3 label-left">
            {t('common.forms.phone', 'Phone')}
          </div>
          <div className="col-2-3">
            {formatValue(user, ['staffdetail', 'phone'])}
          </div>
        </div>

        <div className="form-group">
          <div className="col-1-3 label-left">
            {t('common.forms.mobile', 'Mobile')}
          </div>
          <div className="col-2-3">
            {formatValue(user, ['staffdetail', 'mobile'])}
          </div>
        </div>

        <div className="form-group">
          <div className="col-1-3 label-left">
            {t('common.forms.DOB', 'Date of Birth')}
          </div>
          <div className="col-2-3">
            {formatValue(user, ['staffdetail', 'birth_date'], 'date')}
          </div>
        </div>

        <div className="form-group">
          <div className="col-1-3 label-left">
            {t('users.profile.preferedEmailAlertTime', 'Preferred email alert time')}
          </div>
          <div className="col-2-3">
            {formatValue(user, ['alert_time'], 'timeNoDate')}
          </div>
        </div>

        <div className="form-group">
          <div className="col-1-3 label-left">
            {t('users.profile.indemnityProvider', 'Indemnity Provider')}
          </div>
          <div className="col-2-3">
            {formatValue(user, ['staffdetail', 'indemnity_provider'], 'boolean')}
          </div>
        </div>

        {
          staffdetail.get('indemnity_provider') ? (
            <div className="indemnity-provider">
              <div className="form-group">
                <div className="col-1-3 label-left">
                  {t('users.profile.provider', 'Provider')}
                </div>
                <div className="col-2-3">
                  {formatValue(user, ['staffdetail', 'indemnity_provider'])}
                </div>
              </div>
              <div className="form-group">
                <div className="col-1-3 label-left">
                  {t('users.profile.expiryDate', 'Expiry Date')}
                </div>
                <div className="col-2-3">
                  {formatValue(user, ['staffdetail', 'indemnity_expiration_date'], 'date')}
                </div>
              </div>
            </div>
          ) : null
        }

        <Link className="btn btn-default indented" to="/page/profile/edit/">
          <p><Icon type="pencil" className="button-icon" />{t('users.profile.editPersonalDetails', 'Edit personal details')}</p>
        </Link>

        <hr className="thin" />

        <h3>{t('users.profile.yourPassword', 'Your Password')}</h3>
        <Link className="btn btn-default indented" to="/page/profile/change-password/">
          <p><Icon type="pencil" className="button-icon" />{t('users.profile.changePassword', 'Change password')}</p>
        </Link>

        <hr className="thin" />

        {!isNaN(privateDocumentCount) && (
          <div>
            <h3>{t('common.privateDocuments', 'Private Documents')}</h3>
            <p className="help-block">
              {t('common.youHave', 'You have')} {pluralize(privateDocumentCount, 'private document').toLowerCase()}.
            </p>
            <a
              className="btn btn-default indented mb-1"
              onClick={privateDocumentCount
                ? () => this.props.openReassignPrivateDocumentsModal(privateDocumentItems)
                : undefined
              }
              disabled={!privateDocumentCount}
            >
              <p><Icon type="pencil" className="button-icon" />{t('users.profile.reassign', 'Reassign')}...</p>
            </a>
          </div>
        )}

      <h3>{t('users.profile.securityQuestion', 'Security Question')}</h3>
      <a className="btn btn-default indented mb-1" href={SSO_SECURITY_QUESTION_PATH}>
        <p><Icon type="pencil" className="button-icon" />{t('users.profile.changeSecurityQuestionOrAnswer', 'Change Security Question/Answer')}</p>
      </a>

      <h3>{t('users.profile.correspondencePreferences', 'Correspondence Preferences')}</h3>
      <a className="btn btn-default indented mb-1" href={SSO_CORRESPONDENCE_PATH}>
        <p><Icon type="pencil" className="button-icon" />{t('users.profile.updatePreferences', 'Update Preferences')}</p>
      </a>

      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.userProfile,
    privateDocuments: state.collections.get(PRIVATE_TEMPLATES_COPIES),
  };
}

export default connect(
  mapStateToProps,
  {
    getAllCollection,
    openReassignPrivateDocumentsModal
  }
) (UserProfilePage);
