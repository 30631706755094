import React from 'react';
import { Link } from 'react-router';

import { t } from '^/i18n';
import PermissionsComponent from '^/components/app/perms/PermissionsComponent';
import { isNewerVersion } from '^/utils';
import { getDocumentLink, KINDS, TYPES } from '^/models/documents';
import { isNotReadOnlyInCurrentPractice } from '^/models/user';


export function UpdateToIfLaterVersion({adoptedDocument, copiedDocument, activityId, showAllCopies, documentKind, isGroupsApp}) {
  const doc = adoptedDocument || copiedDocument;
  const {
    id,
    template_document,
    group_template_document,
    document_version_number
  } = doc.toObject();
  const rootDocument = template_document || group_template_document.get('template_document');

  if ((!isGroupsApp && documentKind === KINDS.AUDIT) ||rootDocument.get('type') !== TYPES.HTML) {
    return <noscript />;
  }

  const parentDocument = template_document || group_template_document;
  const latestVersionNumber = parentDocument.get('latest_version_number');
  const groupLatestVersionNumber = rootDocument.get('group_template_document_latest_version_number');
  const latestRelevantVersionNumber = groupLatestVersionNumber || latestVersionNumber;

  if (!isNewerVersion(latestRelevantVersionNumber, document_version_number)) {
    return <noscript />;
  }

  // if its a copied doc and its not an activity, link thru to the copies listing page
  const updateLink = (copiedDocument && !activityId && showAllCopies)
    ? getDocumentLink(parentDocument.get('id')) + 'copies/'
    : getDocumentLink(id) + `update/${activityId ? activityId + '/' : ''}`;

  const isGroup = group_template_document || (!copiedDocument && groupLatestVersionNumber);

  return (
    <PermissionsComponent hasPermission={isNotReadOnlyInCurrentPractice}>
      <Link className="inline-link x-small-text ml-1-4" to={updateLink}>
        {t('templateDocuments.updateToLatest', 'Please update now to the latest')} {isGroup ? t('common.group', 'Group') : 'iComply'} {t('templateDocument.version', 'version')} {latestRelevantVersionNumber}
      </Link>
    </PermissionsComponent>
  );
}

export default UpdateToIfLaterVersion;
