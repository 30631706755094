import React from 'react';
import classNames from 'classnames';

import { t } from '^/i18n';
import { KINDS, TYPES } from '^/models/documents';
import Icon from '^/components/app/content/Icon';


function getIconTypeAndAltText(templateDocument) {
  const { kind, type, is_secure_document } = templateDocument.toObject();

  if (type === TYPES.FILE) {
    return [
      'download',
      t('templateDocuments.documentTypeCannotEditMsg', 'Download only - FILE Type: This document type cannot be edited online and needs to be downloaded in order to be completed. You can upload the completed document to an activity as evidence which will then be stored in iComply.') // eslint-disable-line max-len
    ];
  }
  switch (kind) {
    case KINDS.AUDIT:
      return [
        'clipboard',
        t('templateDocuments.documentTypeAudit', 'Audit: This document type is used for the majority of risk assessments and audits. To begin working with the document use ‘create copy’ and assign it a name. You can also clone your edited customised documents using ‘save copy’ or update a document by editing the relevant copy.') // eslint-disable-line max-len
      ];
    case KINDS.CONTRACT:
      if (is_secure_document) {
        return [
          'lock',
          t('templateDocuments.documentTypeSecure', 'Secure: These are confidential documents, e.g. contracts or complaint forms, with access restricted to the original editor and team members invited to contribute. To begin working with the document use ‘create copy’ and assign it a name. You can also clone these documents to make minor changes using ‘save copy’ (e.g. put a different name on a contract you have already created).') // eslint-disable-line max-len
        ];
      }
        return [
          'list-ol',
          t('templateDocuments.documentTypeChecklist', 'Checklist: This document type is used for checklists and logs. To begin working with the document use ‘create copy’ and assign it a name. You can also clone your existing edited checklist documents using ‘save copy’ in order to, for example, continuously use your customised digital logs.') // eslint-disable-line max-len
        ];
    case KINDS.OVERVIEW:
      return [
        'info-square-light',
        t('templateDocuments.documentTypeOverview', 'Overview: These guidance documents are not templates and do not need to be edited by the practice. These are written by Agilio to compile and simplify information on a topic and/or provide an overview of a document set, in order that team members can educate themselves.') // eslint-disable-line max-len
      ];
    case KINDS.POLICY:
    default:
      return [
        'file-text-o',
        t('templateDocuments.documentTypePolicy', 'Policy: These are policies, procedures and occasionally risk assessments where the practice overwrites the previous version when editing and does not need to make or retain copies. iComply tells you when Agilio has released an updated version to adopt.') // eslint-disable-line max-len
      ];
  }
}

function printIcon(show, large, displayRight) {
  if (show) {
    return (
      <Icon
        className={classNames('inline-icon', displayRight && 'icon-position-right', !large && 'small-icon gray-icon')}
        type="print"
        title={t('templateDocuments.printDocumentAndKeepSafeMsg', "iComply recommend you print this document and keep it safe")}
      />
    );
  }
}

const TemplateDocumentTypeIcon = ({templateDocument, large, displayRight}) => {
  const [iconType, title] = getIconTypeAndAltText(templateDocument);
  return (
    <div className="inline">
      <Icon
        className={classNames('inline-icon', displayRight && 'icon-position-right', !large && 'small-icon gray-icon')}
        type={iconType}
        title={title}
      />
      {printIcon(templateDocument.get('is_print_recommended'), large, displayRight)}
    </div>
  );
};

export default TemplateDocumentTypeIcon;
