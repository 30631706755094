import React from 'react';
import { connect } from 'react-redux';

import { t } from '^/i18n';
import { loadTemplateActivities } from '^/actions/actions';
import { setupCycleComplianceReviewsAndRedirectToDashboard } from '^/actions/actionSequences';
import { getCurrentPracticeId } from '^/models/user';
import { getCycleToSetupId } from '^/consts/setup';

import CycleSetupHeader from '^/components/app/cycle-setup/CycleSetupHeader';
import
  ComplianceReviewsSetupForm, { transformAdminStepData }
from '^/components/app/account-set-up/ComplianceReviewsSetupForm';
import Loading from '^/components/app/content/Loading';
import HelpBlock from '^/components/app/widgets/HelpBlock';
import PureComponent from '^/components/common/PureComponent';

export const ADMIN_STEP_TEMPLATE_TYPE = 'activities/setup/admin-steps';

export class CycleSetupAdditionalComplianceReviewsPage extends PureComponent {

  componentWillMount() {
    const { practiceId, cycleId } = this.props;
    this.loadPracticeAndAdminSteps(practiceId, cycleId);
  }

  componentWillReceiveProps(newProps) {
    if (this.props.practiceId !== newProps.practiceId || this.props.cycleId !== newProps.cycleId) {
      this.loadPracticeAndAdminSteps(newProps.practiceId, newProps.cycleId);
    }
  }

  loadPracticeAndAdminSteps(practiceId, cycleId) {
    if (practiceId && cycleId) {
      this.props.loadTemplateActivities(ADMIN_STEP_TEMPLATE_TYPE, practiceId, cycleId);
    }
  }

  transformAndSubmitData(data) {
    const { cycleId } = this.props;
    this.props.setupCycleComplianceReviewsAndRedirectToDashboard(
      cycleId,
      { admin_steps: transformAdminStepData(data), }
    );
  }

  render() {
    const { user, practice, templateAdminSteps, response } = this.props;

    if (!user || !practice || templateAdminSteps === undefined || templateAdminSteps === null) {
      return <Loading />;
    }

    return (
      <div>
        <CycleSetupHeader practice={practice}>
          {t('cycleSetup.setupAddComplianceReviews', 'Setup - additional compliance reviews')}
        </CycleSetupHeader>

        <HelpBlock>
          <p className="small-text">
            {t(
              'cycleSetup.selectAddSetupMsg',
              `Please select any additional compliance reviews you'd like to do this year and remove any you no longer want to do.`
            )}
          </p>
        </HelpBlock>

        <hr className="thin" />
        <ComplianceReviewsSetupForm
          response={response}
          isSubsequentSetup
          templateAdminSteps={templateAdminSteps}
          buttonText="Finish cycle setup"
          onSubmit={data => this.transformAndSubmitData(data)}
        />
      </div>
    );
  }
}


export const mapStateToProps = (state) => {
  const practiceId = getCurrentPracticeId(state.userProfile);
  return {
    user: state.userProfile,
    practiceId,
    cycleId: getCycleToSetupId(state.userProfile, practiceId),
    templateAdminSteps: state.templateActivities.get(ADMIN_STEP_TEMPLATE_TYPE),
    practice: state.currentPractice,
    response: state.responses.get('setupCycleComplianceReviews'),
  };
};

export default connect(mapStateToProps,
  { loadTemplateActivities, setupCycleComplianceReviewsAndRedirectToDashboard }
) (CycleSetupAdditionalComplianceReviewsPage);

