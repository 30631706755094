import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';

import { t } from '^/i18n';
import { isComplete } from '^/models/activities';
import { openCreateTaskModal } from '^/actions/modals';
import Icon from '^/components/app/content/Icon';
import PermissionsComponent from '^/components/app/perms/PermissionsComponent';
import { isNotReadOnlyInCurrentPractice } from '^/models/user';
import TasksList from './TasksList';


export const RelatedTasksNoCollapse = props => {
  const { activity, wide, helpText, showTitle } = props;
  const tasks = activity.get('tasks');
  const completeTasks = tasks.filter(isComplete);
  const incompleteTasks = tasks.filterNot(isComplete);

  const button =
    <PermissionsComponent hasPermission={isNotReadOnlyInCurrentPractice} >
      <button
        className="btn btn-default indented"
        onClick={() => props.openCreateTaskModal({activity})}
      >
        <Icon type="check-square-o" className="button-icon" /> {t('activities.createRelatedTask', 'create related task')}
      </button>
    </PermissionsComponent>
  ;

  const list =
    <div>
      <h4>
        {showTitle ? "Related Tasks:" : ""}
        {helpText && (
          <p className="help-block">
            <Icon type="lightbulb-o" className="tip-icon" />
            {helpText}
          </p>
        )}
      </h4>
      {!tasks.isEmpty() ? (
        <div>
        <TasksList tasks={incompleteTasks} />
        {!completeTasks.isEmpty() && (
          <div>
            <h4>{t('activities.completedTasks', 'Completed Tasks')}:</h4>
            <TasksList tasks={completeTasks} />
          </div>
        )}
        </div>
      ) : (
        <h5 className="text-empty-state">
          {t('activities.noRelatedTasks', 'No related tasks')}
        </h5>
      )}
    </div>
  ;

  return (
    <div>
      {wide ? (
        <div>{list}{button}</div>
      ) : (
        <div>{button}{list}</div>
      )}
    </div>
  );
};

RelatedTasksNoCollapse.propTypes = {
  tasks: ImmutablePropTypes.list.isRequired,
  activity: ImmutablePropTypes.map.isRequired,
};

export default connect(null, { openCreateTaskModal }) (RelatedTasksNoCollapse);
