import React from 'react';
import FormError from '^/components/app/forms/FormError';
import { t } from '^/i18n';

const TextField = ({ field, display, response, required }) => (
  <div className="form-group">
    <div className="col-1-3">
      <label htmlFor={field.name}>{display}</label>
      {required && (<span className="help-block">{t('common.required.upperCase', 'Required')}</span>)}
    </div>
    <div className="col-2-3">
      <input id={field.name} type="text" placeholder={display} {...field} />
      <FormError response={response} formKey={field.name} />
    </div>
  </div>
);

export default TextField;
