import React from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';

import { closeModal } from '^/actions/modals';
import { t } from '^/i18n';

export const DemoModeDisallowsModal = ({goBack, message, closeModal: close}) => (
  <div>
    <p>
      { 
        !message ? 
        t('perms.demoMode.cannotDoThisAction', 'Sorry, in demo mode you cannot do this action.') : 
        t('perms.demoMode.cannotDoThisActionWithMessage', 'Sorry, in demo mode you {message}.', {message: message})
      }
    </p>

    <p className="secondary-info lighten-text">
      { t('perms.demoMode.upgradeAccount', 'To upgrade your account to full access, please contact iComply.') }
    </p>

    <div className="row">
      <div className="col-1">
        <button
          className="btn btn-default pull-right"
          onClick={() => {
            if (goBack) {
              browserHistory.goBack();
            }
            close();
          }}
        >
          { t('common.ok', 'OK') }
        </button>
      </div>
    </div>
  </div>
);

export default connect(null, { closeModal }) (DemoModeDisallowsModal);
