import React from 'react';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import { List } from 'immutable';

import { t } from '^/i18n';
import { getCollection } from '^/actions/collections';
import { PRACTICES_DETAILS } from '^/consts/collectionKeys';
import { isPending } from '^/consts/responseStates';

import Loading from '^/components/app/content/Loading';
import Icon from '^/components/app/content/Icon';
import PracticesDetailGrid from '^/components/app/users/profile/my-practices/PracticesDetailGrid';
import PracticesFilter from '^/components/app/users/profile/my-practices/PracticesFilter';
import PureComponent from '^/components/common/PureComponent';
import ShowIfAdmin from '^/components/app/perms/ShowIfAdmin';
import ShowIfApplication from '^/components/app/perms/ShowIfApplication';


export class MyPractices extends PureComponent {

  componentDidMount() {
    this.props.getCollection(PRACTICES_DETAILS);
  }

  render() {
    const { practices, user, isLoading, showPagination } = this.props;

    if (!practices) { return <Loading />; }

    if (practices.isEmpty()) { return <p>You are not a member of any practices.</p> }

    return (
      <div>
        <h1 className="inline">{t('users.profile.myPractices', 'My Practices')}</h1>
        <ShowIfAdmin>
          <ShowIfApplication>
            <Link
              className="btn btn-complete inline pull-right indented"
              to="/page/practices/add/"
            >
              <Icon type="plus" className="button-icon" />
              {t('users.profile.addAPractice', 'Add a Practice')}
            </Link>
          </ShowIfApplication>
        </ShowIfAdmin>

        <hr />

        <PracticesFilter/>
        <PracticesDetailGrid practices={practices} user={user} />
        {isLoading && <Loading />}
        {showPagination && (
          <a className="inline-link pull-right" onClick={() => this.loadMorePractices()}>
            {t('common.button.loadMore', 'Load more')} {'>>'}
          </a>
        )}
      </div>
    );
  }

  loadMorePractices() {
    this.props.getCollection(PRACTICES_DETAILS, {
      page: this.props.currentPage + 1,
      shouldAppend: true
    });
  }
}

export function mapStateToProps(state) {
  const practiceCollection = state.collections.get(PRACTICES_DETAILS);

  return {
    user: state.userProfile,
    practices: state.collections.getIn([PRACTICES_DETAILS, 'items'], List()),
    showPagination: practiceCollection && practiceCollection.get('hasMore'),
    currentPage: practiceCollection && practiceCollection.get('page'),
    isLoading: (isPending(state.responses.getIn(['getCollection', PRACTICES_DETAILS])))
  };
}

export default connect(mapStateToProps, { getCollection })(MyPractices);
