import React, { PropTypes } from 'react';
import classNames from 'classnames';

import { STAFF_PRACTICE_TYPE_DISPLAY } from '^/models/staffPractice';
import Icon from '^/components/app/content/Icon';
import { t } from '^/i18n';

const TeamMember = ({member}) =>
  <tr key={member}>
    <td>
      <Icon type="user" className="metadata-icon" /> {member.get('full_name', '--')}
    </td>
    <td>
      {member.get('email', '--')}
    </td>
    <td>
      ({STAFF_PRACTICE_TYPE_DISPLAY[member.get('type')]})
    </td>
  </tr>;

const TeamMemberList = ({className, teamMembers, noMembersMessage}) =>
  <div className={classNames(className, 'team-member-list')}>
    <h5>{ t('practices.teamMember', 'Team Members') }</h5>
    {
      teamMembers && teamMembers.size ?
        <table>
          <tbody>
            { teamMembers.map(member => <TeamMember key={member} member={member} />) }
          </tbody>
        </table>
   :
    <p>{noMembersMessage}</p>
  }

  </div>;

TeamMemberList.propTypes = {
  className: PropTypes.string,
  teamMembers: PropTypes.object,
  noMembersMessage: PropTypes.string,
};

TeamMemberList.defaultProps = {
  noMembersMessage: t('accountSetup.noTeamMembersAdded', 'No team members added yet!'),
};

export default TeamMemberList;
