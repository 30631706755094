import React from 'react';
import { t } from '^/i18n';

const ChoosePracticeMembers = ({
  onAdd,
  onRemove,
  practiceMembers,
  chosenMembers,
  optionLabel,
  isArchived
}) => {

  const practiceMembersNotChosen = practiceMembers
    .filterNot(member => chosenMembers.find(chosenMember => chosenMember.get('id') === member.get('id')));

  return (
    <div>
      <div>
        {chosenMembers.map(chosenMember =>
          <div className="nowrap" key={chosenMember.get('id')}>
            {chosenMember.get('name')}
            {!isArchived &&
              <a
                className="pull-right inline-edit"
                onClick={() => onRemove(chosenMember.get('id'))}
              >
                { t('common.button.remove', 'Remove') }
              </a>
            }
          </div>
        )}
      </div>

    	{!isArchived &&
        <select
          id="assignee"
          defaultValue={0}
          onChange={evt => evt.target.value && onAdd(evt.target.value)}
          className="form-control-margin"
        >
          <option value={0}>
            {chosenMembers.isEmpty() ? t('digitalTools.forms.chooseA', 'Choose a') : t('tasks.addAnother')} {optionLabel}
          </option>
          {practiceMembersNotChosen.map(user =>
          <option key={user.get("id")} value={user.get("id")}>
            {user.get("name")}
          </option>
          )}
        </select>
      }
    </div>
  );
};

export default ChoosePracticeMembers;
