import React from 'react';
import { connect } from 'react-redux';

import { t } from '^/i18n';
import PureComponent from '^/components/common/PureComponent';
import { collapseUiComponentAndFollowLink } from '^/actions/actionSequences';
import Loading from '^/components/app/content/Loading';
import { destructureTemplateVersion } from '^/models/documents';
import { PRACTICES_SECTION_PREFIX } from '^/utils';
import { setActiveTab, setShowing, setSearchText, setDefaultDocumentSearchValue } from '^/actions/actions';
import {
  SEARCH_NAME,
  TEMPLATE_DOCUMENTS_TABSET
} from '^/consts/templateViewBy';

import Icon from '^/components/app/content/Icon';

export const SEARCH_RESULTS_LISTING = 'SEARCH_RESULTS_LISTING';
export const SEARCH_MODAL = 'SEARCH_MODAL';

const isManage = (task, userProfile) =>
  task.get('created_by') === userProfile.getIn(['staffdetail', 'id']);

function DocLink({doc, collapseUiAndFollowLink}) {
  const {
    docPagePath,
    isCopied,
    templateDocument,
    adoptedDocument,
    groupDocument,
  } = destructureTemplateVersion(doc, null, PRACTICES_SECTION_PREFIX);

  const url =
    `${docPagePath}${(adoptedDocument || groupDocument || templateDocument).get('id')}/${isCopied ? 'copies/' : ''}`;

  return (
    <a onClick={() => collapseUiAndFollowLink(url)}>
      {templateDocument.get('name')}
    </a>
  );
}

export class SearchResults extends PureComponent {
  render() {
    const {
      className,
      docsResults,
      activitiesResults,
      uploadedFilesResults,
      tasksResults,
      userProfile,
      collapseUiAndFollowLink,
      loading,
      searchQuery,
    } = this.props;

    return (
      <div onMouseDown={event => event.preventDefault()} className={'search-results-wrapper ' + (className || '')} >
        <div className="search-results-header">{t('common.searchResults', 'Search results')}</div>
        <hr className="thin" />
        <div className="search-result-content">
          {loading && <Loading />}
    
          {!docsResults.isEmpty() && !loading &&
            <div>
              <h4 className="search-result-title"><Icon type="file-o" className="inline-icon" />
                {t('common.templateDocuments', 'Template Documents')}
              </h4>
    
              <ul className="list-no-indent">
                {docsResults.map(doc =>
                  <li key={doc.get('id')}>
                    <DocLink doc={doc} collapseUiAndFollowLink={collapseUiAndFollowLink} />
                  </li>
                )}
              </ul>
              {(docsResults.length >= 12)
                &&
                <a onClick={() => {
                  this.props.setShowing(SEARCH_NAME, true);
                  this.props.setActiveTab(TEMPLATE_DOCUMENTS_TABSET, 0);
                  this.props.setDefaultDocumentSearchValue(searchQuery);
                  collapseUiAndFollowLink('/page/documents/');
                }}
                >
                  {t('common.button.viewMore', 'View More')}
                </a>
              }
            </div>
          }
    
          {!uploadedFilesResults.isEmpty() && !loading &&
             <div>
              <h4 className="search-result-title"><Icon type="file-o" className="inline-icon" />
                {t('common.uploadedDocuments', 'Uploaded Documents')}
              </h4>
    
              <ul className="list-no-indent">
                {uploadedFilesResults.map(doc =>
                  {
                    const task = doc.get("task")
                    const activity = doc.get("activity")
                    const task_assignee = doc.get("task_assignee")
    
                    const taskLink = task && `/page/tasks/manage/${task.get('id')}/`;
                    const activityLink = activity && `/page/activities/${activity.get('id')}/`;
                    const taskAssigneeLink = task_assignee && `/page/tasks/${task_assignee.getIn(['task', 'id'])}/`;
    
                    return (
                      <li key={doc.get('id')}>
                        <a onClick={() => collapseUiAndFollowLink(
                          activityLink || taskLink || taskAssigneeLink
                        )}>
                          {doc.get('name', '')}
                        </a>
                      </li>
                    );
                  } 
                )}
              </ul>
            </div>
          }
    
          {!activitiesResults.isEmpty() && !loading &&
            <div>
              {!docsResults.isEmpty() && <hr className="thin" />}
              <h4 className="search-result-title text-capitalize"><Icon type="calendar" className="inline-icon" />
                {t('common.activities', 'activities')}
              </h4>
    
              <ul className="list-no-indent">
                {activitiesResults.map(activity =>
                  <li key={activity.get('id')}>
                    <a onClick={() => collapseUiAndFollowLink(`/page/activities/${activity.get('id')}/`)}>
                      {activity.get('title', '')}
                    </a>
                  </li>
                )}
              </ul>
            </div>
          }
    
          {!tasksResults.isEmpty() && !loading &&
            <div>
              {(!docsResults.isEmpty() || !activitiesResults.isEmpty()) && <hr className="thin" />}
              <h4 className="search-result-title text-capitalize"><Icon type="check-square-o" className="inline-icon" />
                {t('common.tasks', 'tasks')}
              </h4>
    
              <ul className="list-no-indent">
                {tasksResults.map(task =>
                  <li key={task.get('id')}>
                    <a
                      onClick={() => collapseUiAndFollowLink(
                        `/page/tasks/${isManage(task, userProfile) ? '/manage' : ''}/${task.get('id')}/`
                      )}
                    >
                      {task.get('title', '')}
                    </a>
                  </li>
                )}
              </ul>
            </div>
          }
    
          {tasksResults.isEmpty() && activitiesResults.isEmpty() && docsResults.isEmpty() && uploadedFilesResults.isEmpty() && !loading &&
            <span>{t('common.noMatchesFound', 'No matches found')}.</span>
          }
        </div>
      </div>
    )
  }
}


export function mapStateToProps(state) {
  return {
    userProfile: state.userProfile,
    searchQuery: state.ui.getIn(['searchItemQuery', SEARCH_MODAL], ''),
  };
}

export default connect(
  mapStateToProps,
  {
    collapseUiAndFollowLink: url => collapseUiComponentAndFollowLink(SEARCH_MODAL, url),
    setShowing,
    setSearchText,
    setActiveTab,
    setDefaultDocumentSearchValue,
  }
) (SearchResults);
