import { List } from 'immutable';
import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import { t } from '^/i18n';
import { closeModal } from '^/actions/modals';
import LiveButton, { DefaultErrorMessage } from '^/components/app/forms/LiveButton';
import { FormGroupInput, FormGroupSelect } from '^/components/app/groups/group-documents/GroupTemplateDocumentForm';
import {
  GROUP_CREATED_TEMPLATE_DOCUMENTS,
  TEMPLATE_CATEGORIES,
  TEMPLATE_FOLDERS,
} from '^/consts/collectionKeys';
import { hasFailed, hasSucceeded, isPending } from '^/consts/responseStates';
import { DOCUMENT_PRIORITY_OPTIONS } from '^/models/documents';

function GroupTemplateDocumentEditForm({
  fields: {
    name,
    code,
    folder,
    template_category,
    priority,
  },
  handleSubmit,
  folders,
  categories,
  response,
  isUpdating,
  hasUpdated,
  updateFailed,
}) {
  return (
    <form onSubmit={handleSubmit}>
      <FormGroupInput
        field={name}
        fieldName="name"
        label="Name"
        type="text"
        response={response}
      />
      <FormGroupInput
        field={code}
        fieldName="code"
        label="Code"
        type="text"
        response={response}
      />
      <FormGroupSelect
        field={folder}
        fieldName="folder"
        label="Folder"
        options={folders}
        response={response}
      />
      <FormGroupSelect
        field={template_category}
        fieldName="template_category"
        label="Document category"
        options={categories}
        response={response}
      />
      <FormGroupSelect
        field={priority}
        fieldName="priority"
        label="Priority"
        options={DOCUMENT_PRIORITY_OPTIONS}
      />
      <div className="col-1">
        <LiveButton
          className="btn btn-primary pull-left"
          response={response}
          disabled={isUpdating || hasUpdated}
          hideDefaultErrorMessage
        >
          {t('common.button.save', 'Save')}
        </LiveButton>
        <button className="btn btn-default ml-1" onClick={closeModal}>
          {t('common.button.cancel', 'Cancel')}
        </button>
        {updateFailed && <DefaultErrorMessage /> }
      </div>
    </form>
  );
}

export { GroupTemplateDocumentEditForm as UnconnectedGroupTemplateDocumentEditForm };

const FormifiedGroupTemplateDocumentEditForm = reduxForm({
  form: 'GroupTemplateDocumentEditForm',
  fields: [
    'name',
    'code',
    'folder',
    'template_category',
    'priority',
  ],
})(GroupTemplateDocumentEditForm);

const mapStateToProps = (state) => {
  const response = state.responses.getIn(['updateItem', GROUP_CREATED_TEMPLATE_DOCUMENTS]);
  return {
    categories: state.collections.getIn([TEMPLATE_CATEGORIES, 'items'], List()),
    folders: state.collections.getIn([TEMPLATE_FOLDERS, 'items'], List()),
    isUpdating: isPending(response),
    hasUpdated: hasSucceeded(response),
    updateFailed: hasFailed(response),
    response,
  }
};

export default connect(mapStateToProps, {
  closeModal
})(FormifiedGroupTemplateDocumentEditForm);
