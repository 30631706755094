import React from 'react';
import { t } from '^/i18n';

export default function MaintenanceModal() {
  return (
    <div>
      { t(`modals.maintenanceMessage`, 'The server responded with an error. iComply may have been placed in maintenance mode, or your request may have taken too long.')}
      <a href="/">{ t('common.pleaseRefreshThePage', 'Please refresh the page.') }</a>.
    </div>
  );
}
