import React from 'react';
import { connect } from 'react-redux';

import { t } from '^/i18n';
import { showAllInsightTableResults } from '^/actions/insights';
import Icon from '^/components/app/content/Icon';
import withInsightTableData from '^/components/app/groups/insights/hoc/withInsightTableData';

export const Pagination = (props) => {
  const {
    insight,
    paginatedNumberOfResults,
    totalNumberOfResults,
    areAllShown,
    isLoading,
    hasFailedToLoad
  } = props;
  if (isLoading || hasFailedToLoad || totalNumberOfResults === 0) {
    return (<div />);
  }
  return (
    <div className="insights-pagination">
      <span>{t('groups.insights.showingPracticesResults', 'Showing {paginatedNumberOfResults} of {totalNumberOfResults} practices ', {paginatedNumberOfResults, totalNumberOfResults})}</span>
      {!areAllShown && paginatedNumberOfResults < totalNumberOfResults &&
        <span onClick={() => props.showAllInsightTableResults(insight)}>
          - show all <Icon type="caret-down" className="icon-position-right-small small-icon" />
        </span>
      }
    </div>
  );
};

Pagination.propTypes = {
  insight: React.PropTypes.string.isRequired
};

const ConnectedPagination = connect(
  undefined,
  { showAllInsightTableResults }
)(Pagination);

export default withInsightTableData(ConnectedPagination);
