import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { List } from 'immutable';

import { t } from '^/i18n';
import PureComponent from '^/components/common/PureComponent';


const DEFAULT_PAGE_SIZES = [20, 40, 60, 80, 100]

class ListPageSize extends PureComponent {

  getPaginationLinkClassName(isActive, isDisabled) {
    if (isActive) {
      return 'active';
    }
    return isDisabled ? 'disabled' : '';
  }

  _setPageSize(pageSize, activeLink) {
    if (activeLink) {
      this.props.setPageSize(pageSize);
    }
  }

  renderPagniationLink(pageSize, name, isDisabled = false, isActive = false, key = name) {
    const className = this.getPaginationLinkClassName(isActive, isDisabled);
    return (
      <a key={key} className={className} onClick={() => this._setPageSize(pageSize, !isActive && !isDisabled)}>
        {name}
      </a>
    );
  }

  renderPageList(currentPageSize) {
    return List(DEFAULT_PAGE_SIZES).map(idx => {
      const pageSize = idx;
      const isActive = currentPageSize === idx
      return this.renderPagniationLink(pageSize, pageSize, false, isActive);
    });
  }

  render () {
    const { pageSize } = this.props;

    return (
      <div className="list-pagination mt-1 clearfix">
        <div className="row">
          <div className="col-2-4">
            <div className="list-pagesize-controls">
              {t('tables.view', 'View')}:
              {this.renderPageList(pageSize)}
               {t('tables.perPage', 'per page')}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ListPageSize.propTypes = {
  collection: ImmutablePropTypes.map.isRequired,
  setPageSize: React.PropTypes.func.isRequired,
  pageSize: React.PropTypes.number.isRequired,
};

export default ListPageSize;
