import React from 'react';
import { connect } from 'react-redux';
import { List } from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';

import { t } from '^/i18n';
import { getAllCollection } from '^/actions/collections';
import { GROUP_TASK_RESPONSIBILITIES } from '^/consts/collectionKeys';
import { openCreateEditGroupTaskModal } from '^/actions/modals';
import { isPending } from '^/consts/responseStates';

import PureComponent from '^/components/common/PureComponent';
import GroupTasksTable from '^/components/app/groups/group-tasks/GroupTasksTable';

export class GroupTasksPage extends PureComponent {
  componentDidMount() {
    this.loadResponsibilities();
  }

  loadResponsibilities() {
    this.props.getAllCollection(GROUP_TASK_RESPONSIBILITIES);
  }

  render() {
    const { responsibilities, responsibilitiesLoading } = this.props;
    return (
      <div>
        <button
          onClick={() => this.props.openCreateEditGroupTaskModal()}
          className="btn btn-primary pull-right mb-1-2 ml-1 mt-2"
        >
          + {t('groups.createGroupTask', 'Create Group Task')}
        </button>
        <div className="col-1 text-center mb-3">
          <GroupTasksTable
            responsibilities={responsibilities}
            responsibilitiesLoading={responsibilitiesLoading}
          />
        </div>
      </div>
    );
  }
}

GroupTasksPage.propTypes = {
  responsibilities: ImmutablePropTypes.list,
  responsibilitiesLoading: React.PropTypes.bool,
  getAllCollection: React.PropTypes.func.isRequired,
  openCreateEditGroupTaskModal: React.PropTypes.func.isRequired,
}

export function mapStateToProps(state) {
  return {
    responsibilities: state.collections.getIn(
      [GROUP_TASK_RESPONSIBILITIES, 'items'],
      List()
    ),
    responsibilitiesLoading: isPending(
      state.responses.getIn(['getCollection', GROUP_TASK_RESPONSIBILITIES])
    ),
  };
}

export default connect(mapStateToProps, {
  getAllCollection,
  openCreateEditGroupTaskModal,
})(GroupTasksPage);
