import React from 'react';

import { t } from '^/i18n';
import Icon from '^/components/app/content/Icon';
import { formatBackendDate } from '^/utils';
import DatePopupForm from './DatePopupForm';
import { getSelectedRepeatOption, toOptionValue, transformRepeatOption } from '^/models/task';


function onSubmit(data, onSave) {
  const dataToSubmit = Object.assign(
    transformRepeatOption(data)
  );

  onSave(dataToSubmit);
}

const DatePopup = ({task, onSave, onCancel, response}) =>
  <div className="popup">
    <a className="close" onClick={() => onCancel()}>
      <Icon type="close" className="inline-icon" />
    </a>

    <h5>{t('tasks.enterDueDate', 'Enter due date')}</h5>

    <DatePopupForm
      initialValues={{
        deadline: formatBackendDate(task.get('deadline')),
        repeatOption: toOptionValue(getSelectedRepeatOption(task)),
      }}
      task={task}
      onSubmit={data => onSubmit(data, onSave)}
      response={response}
    />
  </div>
;

export default DatePopup;
