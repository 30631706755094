import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import classNames from 'classnames';
import moment from 'moment';

import { getAuditById } from '^/actions/audit';
import { Audit } from '^/components/app/digital-tools/audit/types';
import Icon from '^/components/app/content/Icon';
import Loading from '^/components/app/content/Loading';
import NonFieldErrorRenderer from '^/components/common/NonFieldErrorRenderer';
import { selectAuditById } from '^/selectors/audit';
import { StoreState } from '^/store/types';
import { t } from '^/i18n';

interface OwnProps {
  auditId: string;
  isResultsScreen?: boolean;
  loadingContent: boolean;
  loadingContentFailed: boolean;
  renderContent(): JSX.Element | null;
  renderExpandButton?: () => JSX.Element | null;
}

interface StateProps {
  audit: Audit | null;
  isLoading: boolean;
}

interface DispatchProps {
  getAuditById: typeof getAuditById;
}

type Props = OwnProps & StateProps & DispatchProps;

class ReviewPageWrapper extends Component<Props, {}> {
  public componentDidMount() {
    if (!this.props.audit) {
      this.props.getAuditById(this.props.auditId);
    }
  }

  public render() {
    const {
      auditId,
      audit,
      isResultsScreen,
      isLoading,
      renderExpandButton,
    } = this.props;

    if (isLoading) {
      return (
        <div className="healthcheck">
          <Loading />
        </div>
      );
    }

    if (!audit) {
      return (
        <div className="healthcheck">
          <NonFieldErrorRenderer errors="Failed to load the review" />
        </div>
      );
    }

    return (
      <div className="healthcheck">
        <div className="main">
          <h1>
            <span className="bold">{audit.audit_type.name} -</span>
            <span className="light-text"> {audit.practice.name}</span>
          </h1>
          <div className="audit-actions audit-actions-review mt-2">
            <div className="tab-bar">
              <div
                className={classNames('audit-actions-review-tab', {
                  selected: isResultsScreen,
                  ['print-hide']: !isResultsScreen,
                })}
              >
                <Link to={`/page/tools/audit/${auditId}/review/`}>
                  {t('digitalTools.audit.results', 'Results')}
                </Link>
              </div>
              <div
                className={classNames('audit-actions-review-tab', {
                  selected: !isResultsScreen,
                  ['print-hide']: isResultsScreen,
                })}
              >
                <Link to={`/page/tools/audit/${auditId}/review-q-and-a/`}>
                  {t(
                    'digitalTools.audit.questionsAndAnswers',
                    'Questions & Answers'
                  )}
                </Link>
              </div>
              <div className="audit-actions-review-buttons">
                {renderExpandButton && renderExpandButton()}
                <a
                  className="btn btn-save pull-right"
                  onClick={this.onClickPrint}
                >
                  {t('common.button.print', 'Print')}{' '}
                  <Icon className="inline-icon small-icon" type="print" />
                </a>
              </div>
            </div>
          </div>
          {this.renderContent()}
        </div>
      </div>
    );
  }

  private onClickPrint = () => {
    const printDateTimeLocation = window.document.getElementsByClassName(
      'healthcheck'
    );
    if (
      this.props.audit &&
      printDateTimeLocation &&
      printDateTimeLocation.length > 0
    ) {
      const priorHtml = printDateTimeLocation[0].innerHTML;
      const dateTime = moment(this.props.audit.modified).format('DD/MM/YYYY');
      printDateTimeLocation[0].append(
        document.createTextNode(
          `${t(
            'digitalTools.audit.completionDate',
            'Audit Completion Date:'
          )} ${dateTime}`
        )
      );
      window.print();
      printDateTimeLocation[0].innerHTML = priorHtml;
    } else {
      window.print();
    }
  };
  private renderContent = () => {
    if (this.props.loadingContent) {
      return <Loading />;
    }

    if (this.props.loadingContentFailed) {
      return (
        <NonFieldErrorRenderer
          errors={t(
            'digitalTools.audit.failedToLoadReviewData',
            'Failed to load review data'
          )}
        />
      );
    }

    return this.props.renderContent();
  };
}

export { ReviewPageWrapper as ReviewPageWrapperUnconnected };

const mapStateToProps = (state: StoreState, props: OwnProps) => ({
  audit: selectAuditById(state, props.auditId),
  isLoading: state.thunkRequests.getAuditById.isLoading,
});

export default connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, {
  getAuditById,
})(ReviewPageWrapper);
