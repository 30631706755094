import React from 'react';
import { connect } from 'react-redux';

import { t } from '^/i18n';
import { closeModal } from '^/actions/modals';
import { deleteTemplateCopyAndCloseModal } from '^/actions/actionSequences';

export const DeleteCopyModal = ({id, originalId, activityId, name, onDelete, onClose}) => {
  return (
    <div>
      <div className="row">
        <div className="col-1">
          <p>
            {t('templateDocument.deleteCopiedDocMsg', 'Are you sure you wish to delete the copied document')}: {name}
          </p>
        </div>
      </div>
      <div className="row align-right mt-1">
        <div className="col-1">
          <button className="btn btn-default" onClick={onClose}>
            {t('common.button.cancel', 'Cancel')}
          </button>
          <button className="btn btn-warning ml-1" onClick={() => onDelete(id, originalId, activityId)}>
            {t('common.button.delete', 'Delete')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default connect(null, {onDelete: deleteTemplateCopyAndCloseModal, onClose: closeModal}) (DeleteCopyModal);
