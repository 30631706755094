import React from 'react';
import { reduxForm } from 'redux-form';

import { t } from '^/i18n';
import LiveButton from '^/components/app/forms/LiveButton';

export const REASSIGN_PRIVATE_DOCUMENTS_FORM = 'REASSIGN_PRIVATE_DOCUMENTS_FORM';


export const PrivateDocumentField = ({fields, privateDocument, memberChoices}) => {
  const field = fields[privateDocument.get('id')];

  return (
    <tr>
      <td>
        <label htmlFor={privateDocument.get('id')}>
          {privateDocument.get('name')}
        </label>
      </td>
      <td>
        <select id={field.name} {...field} >
          {memberChoices.map(each =>
              <option key={each.get('id')} value={each.get('value')}>
                {each.get('display')}
              </option>
          )}
        </select>
      </td>
    </tr>
  );
};

export const ReassignPrivateDocumentsForm = ({fields, privateDocuments, memberChoices, handleSubmit}) =>
  <form onSubmit={handleSubmit}>
    <div className="mt-1 small-text">
      {
        privateDocuments.size > 0 ? (
        <table>
          <thead>
            <tr>
              <th>
                <strong>{t('common.privateDocuments', 'Private Documents')} ({privateDocuments.size})</strong>
              </th>
              <th>{t('common.newAssignee', 'New Assignee')}</th>
            </tr>
          </thead>
          <tbody>
            {
              privateDocuments.map(each =>
                <PrivateDocumentField
                  key={each.get('id')}
                  memberChoices={memberChoices}
                  privateDocument={each}
                  fields={fields}
                />
              )
            }
          </tbody>
        </table>
        ) : (<p>{t('practices.noActivitiesReassignMsg', 'No activities to reassign')}.</p>)
      }
      <LiveButton className="btn-default mt-1">
        {t('common.next', 'Next')}...
      </LiveButton>
    </div>
  </form>;

export default reduxForm({ form: REASSIGN_PRIVATE_DOCUMENTS_FORM })(ReassignPrivateDocumentsForm);
