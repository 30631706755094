import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { fromJS, Map } from 'immutable';

import { t } from '^/i18n';
import { loadItem } from '^/actions/items';
import { CYCLES } from '^/consts/collectionKeys';
import { formatBackendDate } from '^/utils';
import { loadTemplateActivities, loadTemplateActivityTypeLastDone } from '^/actions/actions';
import {
  setupCycleReviewRiskAssessmentsAndRedirectToReviewMeetings
} from '^/actions/actionSequences';
import { calculateNextDueDate } from '^/models/urgency';
import { getCurrentPracticeId } from '^/models/user';
import { getCycleToSetupId } from '^/consts/setup';

import PureComponent from '^/components/common/PureComponent';
import AccountSetupHeader from '^/components/app/account-set-up/AccountSetupHeader';
import HelpBlock from '^/components/app/widgets/HelpBlock';
import Loading from '^/components/app/content/Loading';
import { initialValues } from '^/components/app/cycle-setup/CycleSetupOptionalAuditsPage';

import RiskAssessmentsSetupForm from '^/components/app/account-set-up/RiskAssessmentsSetupForm';
import { DUE_DATE, DATE_LAST_DONE, NEVER_DONE } from '^/consts/account-set-up/fieldNames';

export const RISK_ASSESSMENT_TEMPLATE_TYPE = 'activities/setup/assessments';


export class CycleSetupReviewRiskAssessmentsPage extends PureComponent {

  componentWillMount() {
    const { practiceId, cycleId } = this.props;
    this.loadPracticeCycleAndTemplateActivities(practiceId, cycleId);
  }

  componentWillReceiveProps(newProps) {
    if (this.props.practiceId !== newProps.practiceId || this.props.cycleId !== newProps.cycleId) {
      this.loadPracticeCycleAndTemplateActivities(newProps.practiceId, newProps.cycleId);
    }
  }

  loadPracticeCycleAndTemplateActivities(practiceId, cycleId) {
    if (practiceId && cycleId) {
      this.props.loadItem(CYCLES, cycleId);
      this.props.loadTemplateActivities(RISK_ASSESSMENT_TEMPLATE_TYPE, practiceId, cycleId);
      this.props.loadTemplateActivityTypeLastDone(RISK_ASSESSMENT_TEMPLATE_TYPE, cycleId);
    }
  }

  transformAndSubmitData(data) {
    const { cycleId, templateRiskAssessments, cycle } = this.props;

    const dataToSubmit = fromJS(data)
      .map((assessment, assessmentId) => Map({
        id: assessmentId,
        [DUE_DATE]: formatBackendDate(
          assessment.get(DUE_DATE) || calculateNextDueDate(
            assessment.get(DATE_LAST_DONE),
            templateRiskAssessments.find(each => each.get('id') === assessmentId),
            moment(cycle.get('start_date')),
            1,
            assessment.get(NEVER_DONE)
          )
        ),
      }))
      .valueSeq()
      .toJS();

    this.props.setup(cycleId, dataToSubmit);
  }

  render() {

    const {
      user, practice, response, cycle, templateRiskAssessments, templateActivityTypeLastDone
    } = this.props;

    if (!user || !cycle || !practice || !templateRiskAssessments || !templateActivityTypeLastDone) {
      return <Loading />;
    }

    return (
      <div>

        <AccountSetupHeader user={user} practice={practice}>
          {t('cycleSetup.reivewAssessments', 'Review Assessments')}
        </AccountSetupHeader>

        <HelpBlock>
          <p className="small-text">
            {t('cycleSetup.reviewAssessmentsMsg', `Review the assessments for the upcoming cycle below, and update if necessary. Most assessments are done annually.`)}
          </p>
        </HelpBlock>


        <RiskAssessmentsSetupForm
          response={response}
          cycleStartDate={cycle.get('start_date')}
          templateRiskAssessments={templateRiskAssessments}
          initialValues={
            initialValues(templateRiskAssessments, templateActivityTypeLastDone, cycle.get('start_date'), true)
          }
          onSubmit={data => this.transformAndSubmitData(data)}
          isReview
        />

      </div>
    );
  }

}


export const mapStateToProps = (state) => {
  const practiceId = getCurrentPracticeId(state.userProfile);
  return {
    user: state.userProfile,
    practiceId,
    cycleId: getCycleToSetupId(state.userProfile, practiceId),
    practice: state.currentPractice,
    templateActivityTypeLastDone: state.templateActivityTypeLastDone.get(RISK_ASSESSMENT_TEMPLATE_TYPE),
    templateRiskAssessments: state.templateActivities.get(RISK_ASSESSMENT_TEMPLATE_TYPE),
    cycle: state.items.get(CYCLES),
    response: state.responses.get('setupRiskAssessments'),
  };
};

export default connect(
  mapStateToProps,
  {
    loadItem,
    setup: setupCycleReviewRiskAssessmentsAndRedirectToReviewMeetings,
    loadTemplateActivities,
    loadTemplateActivityTypeLastDone
  }
) (CycleSetupReviewRiskAssessmentsPage);
