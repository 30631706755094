import React from 'react';
import { connect } from 'react-redux';

import { t } from '^/i18n';
import { closeModal } from '^/actions/modals';
import {
  removeUploadedFileReloadActivityAndCloseModal,
  removeUploadedFileReloadTaskAssigneeFilesAndCloseModal
} from '^/actions/actionSequences';

import LiveButton from '^/components/app/forms/LiveButton';

export const RemoveUploadedActivityFileModal = ({
  response, activityId, taskAssigneeId, taskId, removeFromActivity, removedFromTaskAssignee, uploadedFile, cancel
}) =>
  <div>
    <p className="mb-2">
      {t('activities.removeConfirmationMsg', 'Are you sure you want to remove')} <b>{uploadedFile.get('name')}</b> {t('activities.fromThis', 'from this')} {activityId ? t('activities.activityCC', 'activity') : t('common.task', 'task')}?
    </p>

    <button onClick={cancel} className="btn btn-default mr-1">{t('common.button.cancel', 'Cancel')}</button>

    <LiveButton
      response={response}
      onClick={() =>
        activityId ?
          removeFromActivity(activityId, uploadedFile.get('id')) :
          removedFromTaskAssignee(taskAssigneeId, taskId, uploadedFile.get('id'))}
      className="btn btn-warning"
    >
      {t('common.button.remove', 'Remove')}
    </LiveButton>

  </div>;

export const mapStateToProps = (state) => ({
  response: state.responses.get('removeUploadedFile'),
});

RemoveUploadedActivityFileModal.propTypes = {
  activityId: React.PropTypes.string,
  taskAssigneeId: React.PropTypes.string,
  taskId: React.PropTypes.string,
  uploadedFile: React.PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  {
    removeFromActivity: removeUploadedFileReloadActivityAndCloseModal,
    removedFromTaskAssignee: removeUploadedFileReloadTaskAssigneeFilesAndCloseModal,
    cancel: closeModal,
  }
) (RemoveUploadedActivityFileModal);
