import React from 'react';

import { t } from '^/i18n';
import { activitiesInsightsConfig, FILTER_KEYS } from '^/components/app/groups/insights/constants';
import Header from '^/components/app/groups/insights/Header';
import ActivitiesTable from '^/components/app/groups/insights/activities/ActivitiesTable';
import ActivitiesDetailCard from '^/components/app/groups/insights/activities/ActivitiesDetailCard';
import ActivityTypeFilters from '^/components/app/groups/insights/activities/ActivityTypeFilters';
import TimePeriodFilter, { getTimePeriodFilters } from '^/components/app/groups/insights/filters/TimePeriodFilter';
import SubGroupFilter from '^/components/app/groups/insights/filters/SubGroupFilter';

const ActivitiesPage = ({ location: { pathname, query } }) => (
  <div className="col-1">
    <Header
      shouldLinkToDashboard
      subtitle={t('activitiesCC', 'Activities')}
      query={query}
    />
    <div className="display-flex">
      <div>
        <TimePeriodFilter
          insightsConfig={activitiesInsightsConfig}
          getFilters={getTimePeriodFilters}
        />
      </div>
      <div className="ml-2">
        <SubGroupFilter
          insightsConfig={activitiesInsightsConfig}
          filterKey={FILTER_KEYS.SUB_GROUP}
          pathname={pathname}
          selectedSubGroup={query.sub_group}
          country={query.country}
        />
      </div>
    </div>
    <ActivityTypeFilters />
    <ActivitiesDetailCard />
    <ActivitiesTable />
  </div>
);

export default ActivitiesPage;
