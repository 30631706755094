import React from 'react';

import { t } from '^/i18n';
import { formatDate } from '^/utils';
import Icon from '^/components/app/content/Icon';


const CompletedBy = ({activity}) => {
  const {is_completed, completed_by, completed_date, completed_by_static_full_name} = activity.toObject();
  const completedByFullName = completed_by_static_full_name ? completed_by_static_full_name : completed_by && completed_by.get('full_name');
  return is_completed ? (
    <p className="alert-success bold-text mb-1">
      <Icon type="check" className="inline-icon complete" /> {t('activities.completed', 'Completed')}
      {completed_by && completed_date && (
        <span className="help-block">
          {t('activities.completedBy', 'Completed by')} <b>{completedByFullName}</b> on <b>{formatDate(completed_date)}</b>
        </span>
      )}
    </p>
  ) : <noscript />;
};

export default CompletedBy;
