import React from 'react';
import { connect } from 'react-redux';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Link } from 'react-router';

import { t } from '^/i18n';
import { formatDate } from '^/utils';
import { isComplete, getActivityLink } from '^/models/activities';
import UpdateToIfLaterVersion from './UpdateToIfLaterVersion';
import { getDocumentLink, getVersionNumber } from '^/models/documents';
import { openDeleteCopyModal } from '^/actions/modals';
import Icon from '^/components/app/content/Icon';


const MaybeActivityLink = ({activity}) =>
  activity ? (
    <span>
      <Link className="underlined" to={getActivityLink(activity.get('id'))}>
        {activity.get('title')}
      </Link>
      {isComplete(activity) && ` ${t('templateDocument.completed', '(completed)')}`}
    </span>
  ) : (
    <noscript />
  );


export const DocumentCopiesTable = props => (
  <table className="padded padded-md bordered mt-1-2 mb-1-2">
    <thead>
      <tr>
        <th>
          {t('common.name', 'Name')}
        </th>
        <th>
          {t('templateDocument.lastEditedBy', 'Last edited by')}
        </th>
        <th>
          {t('common.date', 'Date')}
        </th>
        <th>
          {t('common.activityCC', 'Activity')}
        </th>
        <th>
          {t('templateDocument.copiedFrom', 'Copied from')}
        </th>
        <th />
        {props.isAdmin && <th />}
      </tr>
    </thead>

    <tbody>
      {props.copies.map(copiedDocument =>
        <tr key={copiedDocument.get('id')}>
          <td>
            {copiedDocument.get('name') || '--'}
          </td>
          <td>
            {copiedDocument.getIn(['latest_version', 'user', 'staffdetail', 'full_name'])}
          </td>
          <td>
            {formatDate(copiedDocument.getIn(['latest_version', 'created']))}
          </td>
          <td>
            <MaybeActivityLink activity={copiedDocument.get('activity')} />
          </td>
          <td>
            {copiedDocument.get('group_template_document') ? t('common.group', 'Group') : 'iComply'} {t('common.document', 'document')} v
            {getVersionNumber(props.documentKind, copiedDocument)}
            {' '}
            <UpdateToIfLaterVersion copiedDocument={copiedDocument} documentKind={props.documentKind} />
          </td>
          <td>
            <Link
              className="inline-link"
              to={getDocumentLink(copiedDocument.get('id'))}
            >
              {t('common.button.view', 'View')}
            </Link>
          </td>
          {props.isAdmin &&
            <td>
              <a
                className="text-danger text-underlined"
                onClick={() => props.openDeleteCopyModal(
                  copiedDocument.get('id'),
                  copiedDocument.getIn(['template_document', 'id']),
                  copiedDocument.get('activity') && props.activityId,
                  copiedDocument.get('name') || '--'
                )}
              >
                {t('common.button.delete', 'Delete')}
                <Icon type="close" className="alert-dismiss" />
              </a>
            </td>
          }
        </tr>
      )}
    </tbody>
  </table>
);

DocumentCopiesTable.propTypes = {
  copies: ImmutablePropTypes.list.isRequired,
  documentKind: React.PropTypes.string.isRequired,
};

export default connect(null, { openDeleteCopyModal }) (DocumentCopiesTable);
