import React from 'react';
import { connect } from 'react-redux';
import { List } from 'immutable';

import { t } from '^/i18n';
import { isPending } from '^/consts/responseStates';
import { setActiveTab, setShowing, setSearchText, setDefaultDocumentSearchValue } from '^/actions/actions';
import { getAllCollection } from '^/actions/collections';
import NewAndUpdatedDocuments from '^/components/app/groups/group-documents/NewAndUpdatedDocuments';
import GroupTemplateDocumentDrafts from '^/components/app/groups/group-documents/GroupTemplateDocumentDrafts';
import { openCreateGroupFolderModal } from '^/actions/modals';
import PureComponent from '^/components/common/PureComponent';
import { isGroupsAppSection } from '^/utils';
import TemplatesNav from './TemplatesNav';
import TemplatesSearchForm from './TemplatesSearchForm';
import TemplatesListing from './TemplatesListing';
import TemplatesDropdown from './TemplatesDropdown';
import {
  SEARCH_NAME,
  TEMPLATE_VIEW_BY_COLLECTION,
  VIEW_BY_NAME
} from '^/consts/templateViewBy';

export class TemplatesDocuments extends PureComponent {

  constructor(props) {
    super(props);
    this.setViewBy = this.setViewBy.bind(this);
  }

  componentWillMount() {
    const { defaultViewBy, defaultSearchValue, viewBy, viewByOptions } = this.props;

    if (!this.props.isViewBySet) {
      this.setViewBy(defaultViewBy);
    } else if (!(viewBy in viewByOptions)) {
      this.setViewBy(defaultViewBy);
    }
    if(defaultSearchValue) {
      this.props.setSearchText(defaultSearchValue);
      this.props.setDefaultDocumentSearchValue(''); //reset to empty, it is used as an intermediary
    }
  }

  componentWillReceiveProps(newProps) {
    if(newProps.defaultSearchValue && newProps.defaultSearchValue !== this.props.defaultSearchValue) {
      this.props.setSearchText(newProps.defaultSearchValue);
      this.props.setDefaultDocumentSearchValue(''); //reset to empty, it is used as an intermediary
    }
  }

  setViewBy(viewBy) {
    const viewByCollection = TEMPLATE_VIEW_BY_COLLECTION[viewBy];
    this.props.setShowing(VIEW_BY_NAME, viewBy);
    if (viewByCollection) {
      this.props.getAllCollection(viewByCollection);
    }
  }

  render() {
    const {
      activeTab,
      response,
      viewByOptions,
      viewByValues,
      viewBy,
      showingSearch,
      searchText,
      groupDocuments,
      isGroupsApp,
      showingViewBy,
    } = this.props;
    const selectedViewByValue = activeTab || (viewByValues.size && viewByValues.first());
    return (
      <div className="wrapper mb-2">
        <div className="row">
          <div className="col-1-3 secondary-left pt-1">
            {showingSearch ? (
              <div>
                <TemplatesSearchForm
                  onSubmit={values => this.props.setSearchText(values.searchText)}
                  overrideDefaultValue={searchText}
                >
                  <a
                    className={"toggle-text toggle-text-link pull-right" + (!showingViewBy ? " larger-text" : "")}
                    onClick={() => this.props.setShowing(SEARCH_NAME, !showingSearch)}
                  >
                    {!showingViewBy ? t('templateDocuments.viewFolders', "View folders") : t('templateDocuments.viewBy', "View by...")}
                  </a>
                </TemplatesSearchForm>
              </div>
            ) : (
              <div>
                {showingViewBy ? (
                  <TemplatesDropdown
                    viewByOptions={viewByOptions}
                    setViewBy={this.setViewBy}
                    value={viewBy}
                  />
                ) : (
                  <h4 className="inline">{t('templateDocuments.folders', 'Folders')}</h4>
                )}

                { ![viewByOptions.NEW_AND_UPDATED_DOCUMENTS, viewByOptions.DRAFTS].includes(viewBy) &&
                  <a
                    className={"toggle-text toggle-text-link pull-right" + (!showingViewBy ? " larger-text": "")}
                    onClick={() => this.props.setShowing(SEARCH_NAME, !showingSearch)}
                  >
                    {t('templateDocuments.search', 'Search')}
                  </a>
                }
                <TemplatesNav
                  isLoading={isPending(response)}
                  viewBy={viewBy}
                  viewByValues={viewByValues}
                  selectedViewByValue={selectedViewByValue}
                  setViewByValue={viewByValue => this.props.setActiveTab(viewBy, viewByValue)}
                />
                {isGroupsApp && viewBy === viewByOptions.FOLDER && (
                  <button
                    className="btn btn btn-default mt-1 mb-1"
                    onClick={this.props.openCreateGroupFolderModal}
                  >
                    {t('templateDocuments.button.addNewFolder', 'Add new folder')}
                  </button>
                )}
              </div>
            )}
          </div>

          <div className="col-2-3 bordered-left pt-1">
            { ![viewByOptions.NEW_AND_UPDATED_DOCUMENTS, viewByOptions.DRAFTS].includes(viewBy) &&
              <TemplatesListing
                showingSearch={showingSearch}
                viewBy={viewBy}
                selectedViewByValue={selectedViewByValue}
                search={searchText}
                groupDocuments={groupDocuments}
              />
            }
            { viewBy === viewByOptions.NEW_AND_UPDATED_DOCUMENTS && <NewAndUpdatedDocuments /> }
            { viewBy === viewByOptions.DRAFTS && <GroupTemplateDocumentDrafts /> }
          </div>
        </div>
      </div>
    );
  }
}

export function mapStateToProps(state, props) {
  const { defaultViewBy } = props;
  const isViewBySet = !!state.ui.getIn(['showing', VIEW_BY_NAME]);
  const viewBy = state.ui.getIn(['showing', VIEW_BY_NAME], defaultViewBy);

  return {
    isViewBySet,
    viewBy,
    viewByValues: state.collections.getIn([TEMPLATE_VIEW_BY_COLLECTION[viewBy], 'items'], List()),
    response: state.responses.getIn(['getCollection', TEMPLATE_VIEW_BY_COLLECTION[viewBy]]),
    activeTab: state.ui.getIn(['activeTabs', viewBy]),
    showingSearch: state.ui.getIn(['showing', SEARCH_NAME]),
    searchText: state.ui.get('searchText'),
    defaultSearchValue: state.ui.get('defaultDocumentSearchValue'),
    isGroupsApp: isGroupsAppSection(state.routing.location.pathname),
  };
}

export default connect(mapStateToProps, {
  getAllCollection,
  setActiveTab,
  setShowing,
  setSearchText,
  setDefaultDocumentSearchValue,
  openCreateGroupFolderModal
})(TemplatesDocuments);
