import React from 'react';

import * as keyCodes from '^/consts/keyCodes';

import { t } from '^/i18n'
import PureComponent from '^/components/common/PureComponent';


export class SearchInput extends PureComponent {
  onKeyDown(event) {
    switch (event.keyCode) {
      case keyCodes.ESCAPE:
        this._searchInput.blur();
        break;
      case keyCodes.ENTER:
        this.props.search(event.target.value);
        break;
    }
  }

  render() {
    const { collapse, searchQuery, updateQuery, loading } = this.props;

    return (
      <input
        type="text"
        name="search-box"
        ref={input => { this._searchInput = input; }}
        onKeyDown={evt => this.onKeyDown(evt)}
        placeholder={t('nav.searchIcomply', 'Search iComply')}
        className="nav-search-box"
        onChange={event => updateQuery(event.target.value)}
        onBlur={collapse}
        value={searchQuery}
        disabled={loading}
      />
    );
  }
}

export default SearchInput;
