import React from 'react';

import FormError from '^/components/app/forms/FormError';
import { t } from '^/i18n';

const CheckBoxBulkDocMembers = ({ field, response, options }) => {
  const SELECT_ALL_TEAM_MEMBERS_ID = 'all_team_members';

  const handleCheckedBoxes = event => {
    const checkboxValue = event.target.value;
    const selectedValues = field.value || [];

    if (event.target.id === SELECT_ALL_TEAM_MEMBERS_ID) {
      return event.target.checked
        ? field.onChange(checkboxValue.split(','))
        : field.onChange([]);
    }

    return event.target.checked
      ? field.onChange([...selectedValues, checkboxValue])
      : field.onChange(
          selectedValues.filter(
            selectedValue => selectedValue !== checkboxValue
          )
        );
  };

  return (
    <div className="form-group bulk-doc-send-header-wrapper">
      <div className="col-1 doc-read-team-members">
        <div className="doc-read-team-member">
          <label>
            <input
              id={SELECT_ALL_TEAM_MEMBERS_ID}
              type="checkbox"
              name={field.name}
              value={options.map(option => option.value)}
              onChange={handleCheckedBoxes}
            />
            { t('common.label.selectAll', 'Select All') }
          </label>
        </div>
        <table>
          <thead>
            <tr className="bulk-doc-send-header bold-text">
              <th>{ t('common.fullName', 'Full Name') }</th>
              <th>{ t('common.forms.userLevel', 'User Level') }</th>
            </tr>
          </thead>
          <tbody>
            {options.map(({ label, value, role }, index) => (
              <tr key={`label${index}`}>
                <td>
                  <input
                    type="checkbox"
                    name={field.name}
                    checked={
                      (field.value || []).filter(
                        selectedValue => selectedValue === value
                      ).length > 0
                    }
                    value={value}
                    onChange={handleCheckedBoxes}
                  />
                  {label}
                </td>
                <td> {role}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <FormError response={response} formKey={field.name} />
        <FormError error={field && field.touched && field.error} />
      </div>
    </div>
  );
};

export default CheckBoxBulkDocMembers;
