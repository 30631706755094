import React from 'react';
import { reduxForm } from 'redux-form';

import PureComponent from '^/components/common/PureComponent';
import FormError from '^/components/app/forms/FormError';
import LiveButton from '^/components/app/forms/LiveButton';
import { createSlugFromString } from '^/utils';
import { t } from '^/i18n';

const CREATE_CUSTOM_FIELD_FORM = 'CREATE_CUSTOM_FIELD_FORM';

export class CreateCustomFieldForm extends PureComponent {
  render() {
    const {
      fields: { name, heading, field_type },
      headings,
      handleSubmit,
      response,
    } = this.props;
    return (
      <form onSubmit={handleSubmit} className="">
        <div className="form-group">
          <div className="col-1-3">
            <label htmlFor="name">{t('common.name', 'Name')}:</label>
          </div>
          <div className="col-2-3">
            <input type="text" className="form-control" id={name} {...name} />
            <FormError response={response} formKey="name" />
            <FormError error={name && name.touched && name.error} />
          </div>
        </div>
        <div className="form-group">
          <div className="col-1-3">
            <label htmlFor="heading">{t('digitalTools.records.checklist.heading', 'Heading')}:</label>
          </div>
          <div className="col-2-3">
            <select name="heading" id="heading" {...heading}>
              <option value="">--{t('digitalTools.records.checklist.chooseAHeading', 'Please choose a heading')}--</option>
              {headings.map((selectedHeading, idx) => (
                <option
                  key={idx}
                  value={`${selectedHeading.lastIndex}|${selectedHeading.heading}`}
                >
                  {selectedHeading.heading}
                </option>
              ))}
            </select>
            <FormError response={response} formKey="heading" />
            <FormError error={heading && heading.touched && heading.error} />
          </div>
        </div>
        <div className="form-group">
          <div className="col-1-3">
            <label htmlFor="field_type">{t('digitalTools.records.checklist.fieldType', 'Field Type')}:</label>
          </div>
          <div className="col-2-3">
            <select name="field_type" id="field_type" {...field_type}>
              <option value="">--{t('digitalTools.records.checklist.pleaseChooseAFieldType', 'Please choose a field type')}--</option>
              <option value="YES_NO_NA">Yes, No, N/A</option>
              <option value="YES_NO">Yes, No</option>
              <option value="FREE_TEXT">Free Text</option>
            </select>
            <FormError response={response} formKey="field_type" />
            <FormError
              error={field_type && field_type.touched && field_type.error}
            />
          </div>
        </div>
        <div className="form-group">
          <div className="col-1">
            <LiveButton
              pendingMessage="Creating..."
              response={response}
              className="btn-default pull-right"
            >
              Create
            </LiveButton>
          </div>
        </div>
      </form>
    );
  }
}

export function validate(values, props) {
  const { name, heading, field_type } = values;
  const { customChecklistFields } = props;
  const errors = {};

  if (!name) {
    errors.name = t('common.nameIsRequired', 'Name is required');
  }

  if (!heading) {
    errors.heading = t('digitalTools.records.checklist.pleaseChooseAFieldType', 'Please choose a heading');
  }

  if (!field_type) {
    errors.heading = t('digitalTools.records.checklist.pleaseChooseAFieldType', 'Please choose a field type')
  }

  if (name && customChecklistFields !== null) {
    const existingCustomFieldSlugs = customChecklistFields.map(
      customField => customField.slug
    );
    if (existingCustomFieldSlugs.contains(createSlugFromString(name))) {
      errors.name = t('digitalTools.records.checklist.nameAlreadyExists', 'Name already exists, please specify a unique name');
    }
  }

  return errors;
}

export default reduxForm({
  form: CREATE_CUSTOM_FIELD_FORM,
  fields: ['name', 'heading', 'field_type'],
  validate,
})(CreateCustomFieldForm);
