import { fromJS } from 'immutable';

import { t } from '^/i18n';

export const ACCOUNT_STAGES = fromJS({
  'PERSONAL_DETAILS': 'PERSONAL_DETAILS',
  'MY_PRACTICES': 'MY_PRACTICES'
});

export const ACCOUNT_STAGE_TEXT = fromJS({
  [ACCOUNT_STAGES.get('PERSONAL_DETAILS')]: t('consts.personalDetails', 'Personal Details'),
  [ACCOUNT_STAGES.get('MY_PRACTICES')]: t('consts.myPractices', 'My Practices'),
});

export const ACCOUNT_URLS = fromJS({
  [ACCOUNT_STAGES.get('PERSONAL_DETAILS')]: '/page/profile/',
  [ACCOUNT_STAGES.get('MY_PRACTICES')]: '/page/profile/my-practices/'
});
