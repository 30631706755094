import React from 'react';
import { Set } from 'immutable';
import { reduxForm } from 'redux-form';

import { t } from '^/i18n';
import PureComponent from '^/components/common/PureComponent';
import FormError from '^/components/app/forms/FormError';
import ManyRelatedSelect from '^/components/app/forms/ManyRelatedSelect';
import DateInput from '^/components/app/forms/DateInput';
import { isPending, hasSucceeded } from '^/consts/responseStates';
import LiveButton from '^/components/app/forms/LiveButton';
import { getStaffPracticeTypes } from '^/models/staffPractice';


export class EditMemberModalForm extends PureComponent {
  render() {
    const {
      fields: {
        type,
        is_owner,
        practice_emails_suppressed,
        user: {
          email,
          staffdetail: {
            first_name,
            last_name,
            birth_date,
            phone,
            mobile,
            roles,
          }
        }
      },
      values,
      handleSubmit,
      response,
      allRoles,
      isPracticeAdmin,
    } = this.props;

    const roleSet = values && values.user.staffdetail.roles ? Set(values.user.staffdetail.roles) : Set();

    return (
      <form className="form-horizontal" onSubmit={handleSubmit}>
        <div className="form-group">
          <div className="col-1-3">
            <label htmlFor="first_name" className="label-left padded">{t('common.forms.firstName', 'First Name')}</label>
          </div>
          <div className="col-2-3">
            <input
              type="text"
              id="first_name"
              placeholder={t('common.forms.username', 'Username')}
              className="form-control"
              disabled={isPending(response)}
              {...first_name}
            />
          </div>
          <FormError response={response} formKeyPath={['user', 'staffdetail', 'first_name']} />
        </div>

        <div className="form-group">
          <div className="col-1-3">
            <label htmlFor="last_name" className="label-left padded">{t('common.forms.lastName', 'Last Name')}</label>
          </div>
          <div className="col-2-3">
            <input
              type="text"
              id="last_name"
              placeholder={t('common.forms.username', 'Username')}
              className="form-control"
              disabled={isPending(response)}
              {...last_name}
            />
          </div>
          <FormError response={response} formKeyPath={['user', 'staffdetail', 'last_name']} />
        </div>

        <div className="form-group">
          <div className="col-1-3">
            <label htmlFor="email" className="label-left padded">{t('common.forms.email','Email address')}</label>
          </div>
          <div className="col-2-3">
            <input
              type="text"
              id="email"
              placeholder={t('common.forms.email', 'Email address')}
              className="form-control"
              disabled
              {...email}
            />
            <p className="grey">
            {t('practices.contactAdminInviteMsg', 'Please contact your iComply administrator to update the email on an invite or team member')}
            </p>
          </div>
          <FormError response={response} formKeyPath={['user', 'email']} />
        </div>

        <div className="form-group">
          <div className="col-1-3">
            <label htmlFor="email" className="label-left padded">{t('common.forms.userLevel', 'User Level')}</label>
          </div>
          <div className="col-2-3">
            <select
              className="form-control"
              id="type"
              disabled={isPending(response)}
              {...type}
            >
              {getStaffPracticeTypes(isPracticeAdmin).map((val, key) =>
                <option value={key} key={key}>{val}</option>
              )}
            </select>
          </div>
          <FormError response={response} formKey="type" />
        </div>

        <div className="form-group">
          <div className="col-1-3">
            <label htmlFor="roles" className="label-left padded">{t('common.roles', 'Roles')}</label>
          </div>
          <div className="col-2-3">
            <ManyRelatedSelect
              selectedItems={allRoles.filter(each => roleSet.contains(each.get('id')))}
              items={allRoles}
              onAdd={id => roles.onChange(roleSet.add(id).toArray())}
              onRemove={id => roles.onChange(roleSet.delete(id).toArray())}
              controlName="edit-roles"
              displayKey={['name']}
            />
          </div>
          <FormError response={response} formKeyPath={['user', 'staffdetail', 'roles']} />
        </div>

        {isPracticeAdmin && (
          <div>
            <div className="form-group">
              <div className="col-1-3">
                <label htmlFor="email" className="label-left padded">{t('practices.isOwner', 'Is owner')}</label>
              </div>
              <div className="col-2-3">
                <input
                  className="form-control ml-0"
                  type="checkbox"
                  disabled={isPending(response)}
                  {...is_owner}
                />
              </div>
              <FormError response={response} formKey="is_owner" />
            </div>

            <div className="form-group">
              <div className="col-1-3">
                <label htmlFor="email" className="label-left padded">
                  {t('practices.suppressedEmails', 'Practice emails suppressed')}
                </label>
              </div>
              <div className="col-2-3">
                <input
                  className="form-control ml-0"
                  type="checkbox"
                  disabled={isPending(response)}
                  {...practice_emails_suppressed}
                />
                <p className="grey">
                  {/* TODO: edge case – inline HTML */}
                  If selected, your weekly update will only inform you of your <em>own</em> activities and tasks.
                </p>
              </div>
              <FormError response={response} formKey="practice_emails_suppressed" />
            </div>
          </div>
        )}

        <div className="form-group">
          <div className="col-1-3">
            <label htmlFor="phone" className="label-left padded">{t('common.forms.phone', 'Phone')}</label>
          </div>
          <div className="col-2-3">
            <input
              type="text"
              id="phone"
              placeholder={t('common.formsPhoneNumber', 'Phone Number')}
              className="form-control"
              disabled={isPending(response)}
              {...phone}
            />
          </div>
          <FormError response={response} formKeyPath={['user', 'staffdetail', 'phone']} />
        </div>

        <div className="form-group">
          <div className="col-1-3">
            <label htmlFor="mobile" className="label-left padded">{t('common.forms.mobile', 'Mobile')}</label>
          </div>
          <div className="col-2-3">
            <input
              type="text"
              id="mobile"
              placeholder={t('common.forms.mobileNumber', 'Mobile Number')}
              className="form-control"
              disabled={isPending(response)}
              {...mobile}
            />
          </div>
          <FormError response={response} formKeyPath={['user', 'staffdetail', 'mobile']} />
        </div>

        <div className="form-group">
          <div className="col-1-3">
            <label htmlFor="birth_date" className="label-left padded">{t('common.forms.DOB', 'Date of Birth')}</label>
          </div>
          <div className="col-2-3">
            <DateInput id="birth_date" readonly={isPending(response)} {...birth_date} />
          </div>
          <FormError response={response} formKeyPath={['user', 'staffdetail', 'birth_date']} />
        </div>

        <hr className="thin" />

        <div className="col-1 mb-1">
          <LiveButton
            className="btn btn-primary pull-right"
            response={response}
            pendingMessage={`${t('common.updating', 'Updating')}...`}
          >
            {t('common.button.save', 'Save')}
          </LiveButton>
        </div>
        { hasSucceeded(response) && (
          <p className="alert mt-1 alert-success">{t('common.forms.successfullyUpdated', 'Successfully updated')}!</p>
        )}
      </form>
    );
  }
}

export default reduxForm({
  form: 'editPracticeMemberModal',
  fields: [
    'type',
    'is_owner',
    'practice_emails_suppressed',
    'user.staffdetail.first_name',
    'user.staffdetail.last_name',
    'user.email',
    'user.staffdetail.phone',
    'user.staffdetail.mobile',
    'user.staffdetail.birth_date',
    'user.staffdetail.roles',
  ]
})(EditMemberModalForm);
