import React from 'react';

import { t } from '^/i18n';
import PureComponent from '^/components/common/PureComponent';
import NoAuth from '^/components/app/users/login/NoAuth';
import LoggedOutLogo from '^/components/app/users/LoggedOutLogo';

export default class UserSuspended extends PureComponent {
  render() {
    return (
      <div>
        <LoggedOutLogo />
        <NoAuth>
          <div className="p-1">
            <h1>
              {t(
                'users.login.accountSuspended',
                'Your account has been suspended from using iComply.'
              )}
            </h1>
            <h2>
              {t(
                'users.login.contactAgilioTeam',
                'Please contact a member of the Agilio team.'
              )}
            </h2>
          </div>
        </NoAuth>
      </div>
    );
  }
}
