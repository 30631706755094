import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { t } from '^/i18n';
import { patchItemSetUpdatingForm } from '^/actions/actionSequences';
import { ACTIVITIES } from '^/consts/collectionKeys';
import { isPending } from '^/consts/responseStates';
import { DONE_CHOICE_TEXTS, ACTIONS } from '^/models/compliancereviews';
import Collapse from '^/components/app/content/Collapse';
import CollapseButton from '^/components/app/content/CollapseButton';

export const ActivityActionsTaken = ({activity, updateActivity, response, pendingFields}) => {
  const readOnly = activity.get('is_completed');
  const isLoading = key => isPending(response) && pendingFields && pendingFields.has(key);
  const ACTIONS_TAKEN_COLLPASE_ID = 'ACTIONS_TAKEN_COLLPASE_ID';

  return (
    <div className="collapsible-section-activities">
      <CollapseButton collapseId={ACTIONS_TAKEN_COLLPASE_ID}>
        <h2>{t('activities.actionsTaken', 'Actions taken')}:</h2>
      </CollapseButton>
      <Collapse initiallyCollapsed collapseId={ACTIONS_TAKEN_COLLPASE_ID}>
        {ACTIONS.map(({key, text}) =>
          <div key={key} className={classNames('form-group', isLoading(key) && 'lighten-text')}>
            <div className="col-1-3">
              {DONE_CHOICE_TEXTS.map((choiceText, choice) =>
                <label key={choice} className={classNames('label-inline', 'nowrap')}>
                  <input
                    type="radio"
                    checked={activity.get(key) === choice}
                    onChange={() => !readOnly && updateActivity(key, choice)}
                    name={key}
                    disabled={readOnly || isLoading(key)}
                  /> <span className={classNames(activity.get(key) === choice && 'bold-text')}>{choiceText}</span>
                </label>
              )}
            </div>

            <div className="col-2-3">
              <div className="small-text">{text}</div>
            </div>
          </div>
        )}
        </Collapse>
    </div>
  );
};

export function mapStateToProps(state) {
  return {
    response: state.responses.getIn(['updateItem', ACTIVITIES]),
    pendingFields: state.pendingFormFields.get(ACTIVITIES),
  };
}

export function mapDispatchToProps(dispatch, props) {
  const { activity } = props;
  return {
    updateActivity: (key, value) => dispatch(patchItemSetUpdatingForm(ACTIVITIES, activity.get('id'), {[key]: value})),
  };
}

export default connect(mapStateToProps, mapDispatchToProps) (ActivityActionsTaken);
