import React from 'react';

import { t } from '^/i18n';
import {
  PRACTICE_DETAILS_DISPLAY_SECTION_1,
  PRACTICE_DETAILS_DISPLAY_SECTION_2,
  PRACTICE_DETAILS_DISPLAY_SECTION_3,
  PRACTICE_DETAILS_DISPLAY_SECTION_4_REQUIRED_DENTAL_SECTOR_ONLY,
  BUSINESS_DAY_CHOICES,
} from '^/models/practice';
import { getPlaceholderImage } from '^/utils';
import { SectorTypes } from '^/consts/sector';


export const DetailsDisplayRow = ({ fieldName, display }) =>
   <div className="form-group">
      <div className="col-1-3 label-left">
        {fieldName}
      </div>
      <div className="col-2-3">
        {display}
      </div>
    </div>;


export const PracticeDetailsDisplay = ({practice}) =>
  <div>

    <DetailsDisplayRow fieldName={t('practices.practiceName', 'Practice Name')} display={practice.get('name', '--')} />

    <hr className="thin" />

    {PRACTICE_DETAILS_DISPLAY_SECTION_1.map((fieldName, display) =>
      <DetailsDisplayRow key={fieldName} fieldName={fieldName} display={practice.get(display, '--')} />
      )}

    <hr className="thin" />

    {PRACTICE_DETAILS_DISPLAY_SECTION_2.map((fieldName, display) =>
      <DetailsDisplayRow key={fieldName} fieldName={fieldName} display={practice.get(display, '--')} />
      )}

    <hr className="thin" />

    <div className="form-group">
      <div className="col-1-3 label-left">
        <label>{t('practices.businessDays', 'Business days')}</label>
      </div>
      <div className="col-2-3">
        {BUSINESS_DAY_CHOICES.map(({ label, value }, index) => (
            <label key={`label${index}`} className="label-inline">
              {label}
              <input
                key={`label${index}`}
                type="checkbox"
                id={`label${index}`}
                name="business_days"
                checked={
                  (practice.get("business_days")).filter(
                    businessDay => businessDay === value
                    ).count() > 0
                  }
                value={value}
              />
            </label>
        ))}
      </div>
    </div>

    <hr className="thin" />

    <div className="form-group">
      <div className="col-1-3 label-left">
        {t('common.logo', 'Logo')}
      </div>
      <div className="col-2-3">
        <img
          src={practice.get('logo') || getPlaceholderImage()}
          alt="logo" className="responsive"
        />
      </div>
    </div>

    <hr className="thin" />

    {PRACTICE_DETAILS_DISPLAY_SECTION_3.map((fieldName, display) =>
      <DetailsDisplayRow key={fieldName} fieldName={fieldName} display={practice.get(display, '--')} />
      )}

    <hr className="thin" />

    {practice.get('sector') === SectorTypes.DENTAL &&
      PRACTICE_DETAILS_DISPLAY_SECTION_4_REQUIRED_DENTAL_SECTOR_ONLY.map((fieldName, display) => (
        <DetailsDisplayRow
          key={fieldName}
          fieldName={fieldName}
          display={practice.get(display, '--')}
        />
      ))}

    <hr className="thin" />

  </div>;

export default PracticeDetailsDisplay;
