import React from 'react';

import { t } from '^/i18n';
import PracticeList from './PracticeList';

const PracticeListPage = () => (
  <div>
    <h1>{t('groups.practiceList', 'Practice List')}</h1>
    <hr className="dark mb-2" />
    <PracticeList />
  </div>
);

export default PracticeListPage;
