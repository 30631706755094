import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import classNames from 'classnames';

import { t } from '^/i18n';
import { openMarkTaskCompleteModal } from '^/actions/modals';
import PermissionsComponent from '^/components/app/perms/PermissionsComponent';
import { readOnlyOrAssigneeIsUserPermission } from '^/models/task';
import { STATUS_CLASSES, STATUS_ICONS, ACTIVITY_STATUSES } from '^/models/activities';
import Icon from '^/components/app/content/Icon';

export const AssigneeStatus = ({assignee, task, status, confirmMarkComplete}) => {
  const { full_name, completed_date } = assignee.toObject();

  return (
    <li>
      <span className={classNames('step-link nohover', `step-${STATUS_CLASSES[status]}`)}>
        {full_name}
        {status === ACTIVITY_STATUSES.COMPLETED ? (
          <div className="x-small-text complete">
            {moment(completed_date).format('Do MMM')}
          </div>
        ) : (
          <PermissionsComponent hasPermission={readOnlyOrAssigneeIsUserPermission(assignee)} >
            <div>
              <a className="inline-link x-small-text" onClick={() => confirmMarkComplete(assignee, task)}>
                {t('tasks.markComplete', 'Mark complete')}
              </a>
            </div>
            </PermissionsComponent>
        )}
        <Icon
          key={STATUS_ICONS[status]}
          type={STATUS_ICONS[status]}
          className={classNames('step-icon', 'step-icon-right', STATUS_CLASSES[status])}
        />
      </span>
    </li>
  );
};

export default connect(null, {confirmMarkComplete: openMarkTaskCompleteModal}) (AssigneeStatus);
