import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';

import { t } from '^/i18n';
import { isComplete } from '^/models/activities';
import { openCreateTaskModal } from '^/actions/modals';
import Icon from '^/components/app/content/Icon';
import Collapse from '^/components/app/content/Collapse';
import CollapseButton from '^/components/app/content/CollapseButton';
import PermissionsComponent from '^/components/app/perms/PermissionsComponent';
import { isNotReadOnlyInCurrentPractice } from '^/models/user';
import TasksList from './TasksList';


const MOBILE_TASKS_COLLAPSE = 'mobile-tasks';

export const RelatedTasks = props => {
  const { activity, helpText, showTitle } = props;
  const tasks = activity.get('tasks');
  const completeTasks = tasks.filter(isComplete);
  const incompleteTasks = tasks.filterNot(isComplete);

  const button =
    <PermissionsComponent hasPermission={isNotReadOnlyInCurrentPractice} >
        <button
          className="btn btn-default indented"
          onClick={() => props.openCreateTaskModal({activity})}
        >
          <Icon type="check-square-o" className="button-icon" /> {t('activities.createRelatedTask', 'create related task')}
        </button>
    </PermissionsComponent>
  ;

  return (
    <div>
      <h4 className="m-toggle">
        <CollapseButton collapseId={MOBILE_TASKS_COLLAPSE} initiallyCollapsed collapseOnMount>
          {showTitle ? "Related Tasks:" : ""}
        </CollapseButton>
        {helpText && (
          <p className="help-block">
            <Icon type="lightbulb-o" className="tip-icon" />
            {helpText}
          </p>
        )}
      </h4>

      {!tasks.isEmpty() ? (
        <Collapse collapseId={MOBILE_TASKS_COLLAPSE} className="m-content">
          <TasksList tasks={incompleteTasks} />
          {!completeTasks.isEmpty() && (
            <div>
              <h4 className="m-toggle">{t('activities.completedTasks', 'Completed Tasks')}:</h4>
              <TasksList tasks={completeTasks} />
            </div>
          )}
          {button}
        </Collapse>
      ) : (
        <Collapse collapseId={MOBILE_TASKS_COLLAPSE} className="m-content">
          <h5 className="text-empty-state">
            {t('activities.noRelatedTasks', 'No related tasks')}
          </h5>
          {button}
        </Collapse>
      )}
    </div>
  );
};

RelatedTasks.propTypes = {
  tasks: ImmutablePropTypes.list.isRequired,
  activity: ImmutablePropTypes.map.isRequired,
};

export default connect(null, { openCreateTaskModal }) (RelatedTasks);
