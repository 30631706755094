import React from 'react';
import { reduxForm } from 'redux-form';

import { t } from '^/i18n';
import LiveButton from '^/components/app/forms/LiveButton';
import {
  SuggestedTasksSetupTable, validateTasks, getTaskFields
} from '^/components/app/account-set-up/SuggestedTasksSetupForm';
import { getSuggestedTasksSetupInitialValues } from '^/components/app/account-set-up/utils';

export const FORM_NAME = 'setupCycleReviewTasks';

export const CycleSetupReviewTasksForm = ({
  fields, handleSubmit, response, templateTasks, cycleStartDate, cycleEndDate, teamMembers, openCreateTaskModal,
  newCycleTasks
}) =>
  <form onSubmit={handleSubmit}>
    <SuggestedTasksSetupTable
      fields={fields}
      response={response}
      templateOrGroupTasks={templateTasks}
      teamMembers={teamMembers}
      newCycleTasks={newCycleTasks}
      cycleEndDate={cycleEndDate}
      cycleStartDate={cycleStartDate}
    />

    <hr className="thin" />

    <a className="btn btn-primary" onClick={openCreateTaskModal}>Add another Task</a>

    <hr className="thin" />

    <LiveButton
      pendingMessage={(`${t('common.saving', 'Saving')}...`)}
      response={response}>
        {t('common.next', 'Next')}...
    </LiveButton>
  </form>;

export const mapStateToProps = (state, { templateTasks, cycle, user, }) => {
  const { start_date, end_date } = cycle.toObject();
  return {
    fields: getTaskFields(templateTasks),
    initialValues: getSuggestedTasksSetupInitialValues(templateTasks, user),
    validate: (data, props) => validateTasks(data, start_date, props).toJS(),
    cycleStartDate: start_date,
    cycleEndDate: end_date,
  };
};

export default reduxForm({ form: FORM_NAME, }, mapStateToProps)(CycleSetupReviewTasksForm);
