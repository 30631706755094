import React from 'react';
import { Link } from 'react-router';

import { t } from '^/i18n';
import FormError from '^/components/app/forms/FormError';
import { formatDate } from '^/utils';

export default function TaskError ({ response, deleteTask}) {
  const taskError = response && response.getIn(['errors', 'task_error_details']);
  const message = deleteTask ? t('tasks.youCanDelete', 'You can delete') : t('tasks.updateRepeatInterval', 'You can update the repeat interval of')
  return (
    <div className="small-text">
      <FormError response={response} formKey="non_field_errors" />
      {
        taskError && !taskError.isEmpty() && taskError.get('updateable_task_id') && (
          <div className="form-error text-error mt-1">
            {message}
            <Link className="ml-1-2" to={`/page/tasks/manage/${taskError.get('updateable_task_id')}/`}>
              {taskError.get('updateable_task_title')} ({formatDate(taskError.get('updateable_task_deadline'))})
            </Link>.
          </div>
        )
      }
    </div>
  );
}
