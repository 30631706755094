import React from 'react';

import { t } from '^/i18n';
import { makeStaticPath } from '^/utils';
import LiveButton from '^/components/app/forms/LiveButton';
import PureComponent from '^/components/common/PureComponent';
import Loading from '^/components/app/content/Loading';


export class ReassignConfirmationModal extends PureComponent {
  render() {
    const { onReassign, onClose, loading } = this.props;
    return (
      <div className="text-center">
        <div className="brand-logo-modal">
          <img src={makeStaticPath('images/brand/logo-color.svg')} className="brand-logo-modal" />
        </div>
        <div className="leaving-modal-list">
          <p className="mt-1">
            {t('practices.reassignDocumentConfirmationMsg', 'Are you sure you want to reassign this document?')}
          </p>
          <p className="mt-1">
            {t('practices.ownershipChangeMsg', 'Changing the owner will remove your ownership of the document.')}
          </p>
        </div>
        {loading ? (<Loading />) : (
          <div className="leaving-modal-buttons">
            <LiveButton
              className="btn btn-leaving-modal inline-spaced"
              onClick={onReassign}
            >
              {t('practices.changeOwner', 'Change Owner')}
            </LiveButton>
            <LiveButton
              className="btn btn-leaving-modal inline-spaced"
              onClick={onClose}
            >
              {t('common.button.cancel', 'Cancel')}
            </LiveButton>
          </div>
        )}
      </div>
    );
  }
}

export default (ReassignConfirmationModal);
