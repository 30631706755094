import { fromJS, OrderedMap, Map, List } from 'immutable';
import { STATUS_TYPES } from '^/models/user';
import { STATUS_CHOICES, STAFF_PRACTICE_TYPE } from '^/models/staffPractice';
import { t } from '^/i18n';

export const MY_PRACTICES_FILTER = 'MY_PRACTICES';
export const COUNTY_FILTER = 'COUNTY_FILTER';
export const PRACTICE_FILTER = 'PRACTICE_FILTER';

export const ENGLAND = 'EN';
export const COUNTRIES = OrderedMap({
  [ENGLAND]: 'England (CQC Care Quality Commission)',
  CI: 'Channel Isles',
  NI: 'Northern Ireland (RQIA Regulation and Quality Improvement Agent)',
  SC: 'Scotland (NHS Scotland)',
  SC_PRIV: 'Scotland (HIS Healthcare Improvement Scotland)',
  SI: 'Southern Ireland (HIQA health Improvement and Quality Agency)',
  WA: 'Wales (HIW Healthcare Inspectorate Wales)',
  NL: 'Netherlands (Netherlands system placeholder)',
});

export const BASE_MEMBERSHIP_TYPE = {
  ADP: 'ADP',
  AMA: 'AMA',
  ICOMPLY_MANUAL: 'ICOMPLY_MANUAL',
  ICOMPLY_APPLICATION: 'ICOMPLY_APPLICATION',
  QPS: 'QPS',
  TOTAL_HR: 'TOTAL_HR',
  ICOMPLY_LITE: 'ICOMPLY_LITE',
  NHS_ESSENTIALS: 'NHS_ESSENTIALS',
};

export const BASE_MEMBERSHIP_TYPE_DISPLAY = {
  [BASE_MEMBERSHIP_TYPE.ADP]: 'ADP',
  [BASE_MEMBERSHIP_TYPE.AMA]: 'AMA',
  [BASE_MEMBERSHIP_TYPE.ICOMPLY_MANUAL]: 'iComply Manual',
  [BASE_MEMBERSHIP_TYPE.ICOMPLY_APPLICATION]: 'iComply Application',
  [BASE_MEMBERSHIP_TYPE.QPS]: 'QPS',
  [BASE_MEMBERSHIP_TYPE.TOTAL_HR]: 'Total HR',
  [BASE_MEMBERSHIP_TYPE.ICOMPLY_LITE]: 'iComply Essentials',
  [BASE_MEMBERSHIP_TYPE.NHS_ESSENTIALS]: 'NHS Essentials',
};

export const MEMBERSHIP_TYPE = {
  ADP: 'ADP',
  ADP_M: 'ADP_M',
  ADP_M2: 'ADP_M2',
  BUP: 'BUP',
  AMA: 'AMA',
  AFA: 'AFA',
  AFA_M: 'AFA_M',
  AFAN: 'AFAN',
  AFAN_M: 'AFAN_M',
  AFSA: 'AFSA',
  AFSA_M: 'AFSA_M',
  AMA_M: 'AMA_M',
  AMAN_M: 'AMAN_M',
  AMSA_M: 'AMSA_M',
  CGMS: 'CGMS',
  CGMS_A: 'CGMS_A',
  ICMS: 'ICMS',
  ICAPP: 'ICAPP',
  QPS_N: 'QPS_N',
  QPS_A: 'QPS_A',
  TOTAL_HR: BASE_MEMBERSHIP_TYPE.TOTAL_HR,
};

export const MEMBERSHIP_TYPE_DISPLAY = {
  [MEMBERSHIP_TYPE.ADP]: 'ADP',
  [MEMBERSHIP_TYPE.ADP_M]: 'ADP-M',
  [MEMBERSHIP_TYPE.BUP]: 'BUP',
  [MEMBERSHIP_TYPE.AMA]: 'AMA',
  [MEMBERSHIP_TYPE.AFA]: 'AFA',
  [MEMBERSHIP_TYPE.AFA_M]: 'AFA-M',
  [MEMBERSHIP_TYPE.AFAN]: 'AFAN',
  [MEMBERSHIP_TYPE.AFAN_M]: 'AFAN-M',
  [MEMBERSHIP_TYPE.AFSA]: 'AFSA',
  [MEMBERSHIP_TYPE.AFSA_M]: 'AFSA-M',
  [MEMBERSHIP_TYPE.AMA_M]: 'AMA-M',
  [MEMBERSHIP_TYPE.AMAN_M]: 'AMAN-M',
  [MEMBERSHIP_TYPE.AMSA_M]: 'AMSA-M',
  [MEMBERSHIP_TYPE.CGMS]: 'CGMS',
  [MEMBERSHIP_TYPE.CGMS_A]: 'CGMS-A',
  [MEMBERSHIP_TYPE.ICMS]: 'ICMS',
  [MEMBERSHIP_TYPE.ICAPP]: 'ICAPP',
  [MEMBERSHIP_TYPE.QPS_N]: 'QPS (working towards)',
  [MEMBERSHIP_TYPE.QPS_A]: 'QPS (membership achieved)',
  [MEMBERSHIP_TYPE.TOTAL_HR]: BASE_MEMBERSHIP_TYPE_DISPLAY.TOTAL_HR,
};

export const hasMembership = (practice, membership) =>
  practice.get('memberships').some(m => m.get('membership') === membership);

export const isQPSMember = practice =>
  hasMembership(practice, MEMBERSHIP_TYPE.QPS_A);
export const isTotalHRMember = practice =>
  hasMembership(practice, MEMBERSHIP_TYPE.TOTAL_HR);

export const SEDATION_TYPES = OrderedMap({
  RA: 'RA',
  IV: 'IV',
  BOTH: 'Both',
});

export const PRACTICE_SECTION = fromJS({
  PRACTICE_DETAILS: 'PRACTICE_DETAILS',
  TEAM_MEMBERS: 'TEAM_MEMBERS',
  RESPONSIBILITIES: 'RESPONSIBILITIES',
  COMPLIANCE_HEALTHCHECK: 'COMPLIANCE_HEALTHCHECK',
});

export const PRACTICE_SECTION_TEXT = fromJS({
  [PRACTICE_SECTION.get('PRACTICE_DETAILS')]: 'Practice details',
  [PRACTICE_SECTION.get('TEAM_MEMBERS')]: 'Practice members',
  [PRACTICE_SECTION.get('RESPONSIBILITIES')]: 'Responsibilities',
  [PRACTICE_SECTION.get('COMPLIANCE_HEALTHCHECK')]: 'Compliance healthcheck',
});

export function getPracticeSectionUrls(uuid) {
  return fromJS({
    [PRACTICE_SECTION.get('PRACTICE_DETAILS')]: `/page/practices/${uuid}/`,
    [PRACTICE_SECTION.get('TEAM_MEMBERS')]: `/page/practices/${uuid}/team/`,
    [PRACTICE_SECTION.get(
      'RESPONSIBILITIES'
    )]: `/page/practices/${uuid}/responsibilities/`,
    [PRACTICE_SECTION.get(
      'COMPLIANCE_HEALTHCHECK'
    )]: `/page/practices/${uuid}/compliance_healthcheck/`,
  });
}

export const PRACTICE_DETAILS_DISPLAY_SECTION_1 = OrderedMap({
  address_line_1: 'Address Line 1',
  address_line_2: 'Address Line 2',
  address_line_3: 'Address Line 3',
  city: 'City',
  county: 'County',
  postcode: 'Postcode',
});

export const PRACTICE_DETAILS_DISPLAY_SECTION_2 = OrderedMap({
  phone: 'Phone',
  email: 'Email',
  url: 'Website',
});

export const PRACTICE_DETAILS_DISPLAY_SECTION_3 = OrderedMap({
  medical_physics_expert: 'Medical physics expert',
  radiation_protection_advisor: 'Radiation protection adviser',
});

export const PRACTICE_DETAILS_DISPLAY_SECTION_4_REQUIRED_DENTAL_SECTOR_ONLY = OrderedMap(
  {
    practices_in_group: 'How many practices are there in your group?',
    treatment_rooms: 'How many treatment rooms are there in this practice?',
  }
);

export function mergeFieldChange(valuesChanged, field, fieldName, event) {
  const val = event.target ? event.target.value : event;
  field.onChange(event);
  valuesChanged({ [fieldName]: val });
}

// transform made necessary by redux form's radio button behaviour
export function transformInitialValues(practice) {
  const stringifyBooleans = val => {
    if (typeof val === 'boolean') {
      return val.toString();
    }
    if (val && val.size) {
      return val.map(stringifyBooleans);
    }

    return val;
  };

  return practice && practice.map(stringifyBooleans).toJS();
}

export function getAvailableMembers(practice, currentMember) {
  const isSameMember = practiceMember =>
    practiceMember.get('id') === currentMember.get('id');
  const isActive = practiceMember =>
    practiceMember.get('status') === STATUS_CHOICES.ACTIVE;
  const isPendingInvite = practiceMember =>
    practiceMember.getIn(['user', 'status']) === STATUS_TYPES.INVITED;

  return practice
    .get('members', List())
    .filterNot(isSameMember)
    .filterNot(isPendingInvite)
    .filter(isActive);
}

export function getActiveMembers(practiceMembers) {
  const isActive = practiceMember =>
    practiceMember.get('status') === STATUS_CHOICES.ACTIVE;
  const isNotInsightsOnly = practiceMember =>
    practiceMember.get('type') !== STAFF_PRACTICE_TYPE.INSIGHTS_ONLY;
  return practiceMembers.filter(isActive).filter(isNotInsightsOnly);
}

export function getMemberChoices(practice, currentMember) {
  return getAvailableMembers(practice, currentMember).map(practiceMember =>
    Map({
      display: practiceMember.getIn(['user', 'staffdetail', 'full_name']),
      value: practiceMember.getIn(['user', 'staffdetail', 'id']),
    })
  );
}

export const PRACTICE_GROUP_DETAILS = OrderedMap({
  address_line_1: 'Address Line 1',
  address_line_2: 'Address Line 2',
  address_line_3: 'Address Line 3',
  city: 'City',
  country: 'Country',
  postcode: 'Postcode',
  phone: 'Phone',
  email: 'Email',
  url: 'Website',
});

// TODO: do we want to translation the label or the value? is the value used for the backend? surely it's easier to translate the label
export const BUSINESS_DAY_CHOICES = [
  { label: 'M', value: t('common.daysOfWeek.monday.upperCase', 'MONDAY') },
  { label: 'T', value: t('common.daysOfWeek.tuesday.upperCase', 'TUESDAY') },
  { label: 'W', value: t('common.daysOfWeek.wednesday.upperCase', 'WEDNESDAY') },
  { label: 'T', value: t('common.daysOfWeek.thursday.upperCase', 'THURSDAY') },
  { label: 'F', value: t('common.daysOfWeek.friday.upperCase', 'FRIDAY') },
  { label: 'S', value: t('common.daysOfWeek.saturday.upperCase', 'SATURDAY') },
  { label: 'S', value: t('common.daysOfWeek.sunday.upperCase', 'SUNDAY') },
];

export function hasPracticeGroup(practice) {
  return Boolean(practice.get('group'));
}
