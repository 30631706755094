import React from 'react';

import { t } from '^/i18n';
import FormError from '^/components/app/forms/FormError';
import LiveButton, { DefaultErrorMessage } from '^/components/app/forms/LiveButton';
import { hasFailed, isPending } from '^/consts/responseStates';

const GroupTemplateDocumentNewFileVersionButtons = ({
  updateVersion,
  newVersionResponse,
  currentVersionResponse,
  editorContent,
}) => {
  const isPendingNewOrCurrentVersion = isPending(newVersionResponse) || isPending(currentVersionResponse);
  const error = newVersionResponse && newVersionResponse.get('errors');
  return (
    <div>
      <div className="mb-1 clearfix">
        <LiveButton
          className="btn btn-primary mr-1 pull-left"
          onClick={() => updateVersion({
            new_version: false,
            content_file: editorContent
          })}
          response={currentVersionResponse}
          disabled={isPendingNewOrCurrentVersion}
          hideDefaultErrorMessage
        >
          {t('groups.button.publishAsCurrentVersion', 'Publish as current version')}
        </LiveButton>
        <LiveButton
          className="btn btn-primary mr-1 pull-left"
          onClick={() => updateVersion({
            new_version: true,
            content_file: editorContent
          })}
          response={newVersionResponse}
          disabled={isPendingNewOrCurrentVersion}
          hideDefaultErrorMessage
        >
          {t('groups.button.publishAsNewVersion', 'Publish as new version')}
        </LiveButton>
        {(hasFailed(currentVersionResponse) || hasFailed(newVersionResponse)) && (
          <DefaultErrorMessage />
        )}
      </div>
      {error && (
        <div className="mb-1">
          <FormError error={error} />
        </div>
      )}
    </div>
  );
};

export default GroupTemplateDocumentNewFileVersionButtons;
