import React from 'react';
import moment from 'moment';
import { OrderedMap } from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';

import { REMINDER_CHOICES, REMINDER_CHOICES_TEXT, getRepeatOptions, toOptionValue } from '^/models/task';

import FormError from '^/components/app/forms/FormError';
import DateInput from '^/components/app/forms/DateInput';
import { INCLUDED, DEADLINE, REMINDER, REPEAT, STAFF, TITLE } from '^/consts/account-set-up/fieldNames';
import { t } from '^/i18n';

function SuggestedTaskField ({ suggestedTask, field, teamMembers, cycleStartDate, cycleEndDate }) {
  const namePrefix = suggestedTask.get('id');
  const reminderChoices = OrderedMap(REMINDER_CHOICES);
  const included = field[INCLUDED];
  const deadline = field[DEADLINE];
  const reminderField = field[REMINDER];
  const repeat = field[REPEAT];
  const staff = field[STAFF];
  const hasDeadline = !!deadline.value;
  const isIncluded = !!included.value;

  return (
    <tr>
      <td className="top-aligned">
        <input type="checkbox" id={namePrefix + INCLUDED} {...included} />
      </td>
      <td className="top-aligned">
        <label className="padded reminder-label" htmlFor={namePrefix + INCLUDED}>{suggestedTask.get(TITLE)}</label>
        <FormError error={included && included.touched && included.error} />
        <FormError error={deadline && deadline.touched && deadline.error} />
        <FormError error={reminderField && reminderField.touched && reminderField.error} />
        <FormError error={repeat && repeat.touched && repeat.error} />
        <FormError error={staff && staff.touched && staff.error} />
      </td>
      <td>
        <DateInput
          {...deadline}
          minDate={moment(cycleStartDate)}
          maxDate={cycleEndDate ? moment(cycleEndDate) : moment(cycleStartDate).add(1, 'y')}
          disabled={!isIncluded}
        />
      </td>
      <td>
        <select className="small" {...repeat} disabled={!isIncluded || !hasDeadline}>
          {getRepeatOptions(suggestedTask).map(option =>
            <option key={option} value={toOptionValue(option)}>{option.get('text')}</option>)}
        </select>
      </td>
      <td>
        <select className="small" {...reminderField} disabled={!isIncluded || !hasDeadline}>
          {reminderChoices.map(reminderValue =>
            <option key={reminderValue} value={reminderValue}>{REMINDER_CHOICES_TEXT[reminderValue]}</option>)}
        </select>
      </td>
      <td>
        <select className="small" {...staff} disabled={!isIncluded || !hasDeadline}>
          <option value="" key="0">{ t('groups.pleaseSelect', 'Please select') }</option>
          {teamMembers.map(member =>
            <option value={member.get('staffdetail')} key={member}>{member.get('full_name')}</option>)}
        </select>
      </td>
    </tr>
  );
}

SuggestedTaskField.propTypes = {
  suggestedTask: ImmutablePropTypes.map.isRequired,
  field: React.PropTypes.object.isRequired,
  teamMembers: ImmutablePropTypes.list.isRequired,
};

export default SuggestedTaskField;
