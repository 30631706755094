import React from 'react';
import { connect } from 'react-redux';
import { Map } from 'immutable';

import { t } from '^/i18n';
import Table from '^/components/app/groups/insights/visualisations/Table';
import TableFooter from '^/components/app/groups/insights/visualisations/TableFooter';
import { changePracticeAndNavToDashboard } from '^/actions/actionSequences';
import withLoadInsightDataOnMount from '^/components/app/groups/insights/hoc/withLoadInsightDataOnMount';
import { INSIGHTS, INSIGHTS_API_URLS, INSIGHTS_CSV_API_URLS } from '^/components/app/groups/insights/constants';
import { getSubGroupFilterFromPath } from '^/components/app/groups/insights/utils';
import { selectInsightFilters } from '^/selectors/insights';
import {
  convertRateToPercentage,
  MOMENT_UTC_TODAY,
  MOMENT_UTC_A_YEAR_AGO,
  displayDateIfAvailable
} from '^/utils';

const INSIGHT = INSIGHTS.ACTIVITY_COMPLETION_RATE_BY_PRACTICE;

export const COLUMNS = (onClick, isFilteredToActivity) => [
  {
    key: 'practice_name',
    header: t('groups.practice', 'Practice'),
    className: 'text-align-left',
    cell: ({ row }) =>
      <div>
        <a onClick={() => onClick(row.get('practice_id'))}>
          {row.get('practice_name')}
        </a>
        {isFilteredToActivity && (
          <div className="small-text">
            Last done: {displayDateIfAvailable(row.get('last_completed_date'))}
          </div>
        )}
      </div>
  },
  ...(isFilteredToActivity ? [
    {
      key: 'due_date',
      header: 'Due',
      className: 'text-align-left',
      cell: ({ row }) => <span>{displayDateIfAvailable(row.get('due_date'))}</span>
    }
  ] : []),
  {
    key: 'completed_rate',
    header: t('common.complete', 'Complete'),
    className: 'text-align-right',
    cell: ({ row }) => <span>{`${convertRateToPercentage(row.get('completed_rate'))} %`}</span>
  },
  {
    key: 'in_progress_rate',
    header: t('groups.insights.inProgress', 'In-progress'),
    className: 'text-align-right',
    cell: ({ row }) => <span>{`${convertRateToPercentage(row.get('in_progress_rate'))} %`}</span>
  },
  {
    key: 'overdue_rate',
    header: t('groups.insights.outstanding', 'Outstanding'),
    className: 'text-align-right',
    cell: ({ row }) => <span>{`${convertRateToPercentage(row.get('overdue_rate'))} %`}</span>
  }
];

export const ActivitiesTable = (props) => (
  <div className="row">
    <div className="col-1">
      <Table
        insight={INSIGHT}
        columns={COLUMNS(props.changePracticeAndNavToDashboard, props.isFilteredToActivity)}
        defaultSortByKey="overdue_rate"
        defaultSortIsAscending={false}
      />
      <TableFooter
        insight={INSIGHT}
        csvUrl={INSIGHTS_CSV_API_URLS.ACTIVITY_COMPLETION_RATE_BY_PRACTICE_REPORT}
      />
    </div>
  </div>
);

export function mapStateToProps(state) {
  return {
    isFilteredToActivity: !!selectInsightFilters(INSIGHT)(state).get('template_id')
  }
}

const ConnectedActivitiesTable = connect(
  mapStateToProps,
  { changePracticeAndNavToDashboard }
)(ActivitiesTable);

export default withLoadInsightDataOnMount({
  insight: INSIGHT,
  url: INSIGHTS_API_URLS.ACTIVITY_COMPLETION_RATE_BY_PRACTICE,
  getFilters: () => (
    Map({
      date_from: MOMENT_UTC_A_YEAR_AGO,
      date_to: MOMENT_UTC_TODAY,
      practice_sub_group_id: getSubGroupFilterFromPath(),
    })
  )
})(ConnectedActivitiesTable);
