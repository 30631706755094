import React from 'react';

import { t } from '^/i18n';
import ProgressSpeechBubble from '^/components/app/widgets/ProgressSpeechBubble';
import RelatedTasks from '^/components/app/activities/RelatedTasks';


const AdminStepProgress = ({percent}) =>
  <ProgressSpeechBubble
    percentage={percent}
    title={<span>{t('activities.monthlyFocus', 'Monthly Focus')}<br />{percent}% {t('common.complete', 'Complete')}</span>}
    text={['', `${t('activities.almostThere', 'Almost there')}…`, t('activities.greatWork', 'Great work!')][Math.floor(percent / 50)]}
  />
;

const AdminStepSidebar = ({ percentDone, activity }) =>
  <div className="col-1-4">
    <div className="sidebar-step">
      <AdminStepProgress percent={percentDone} />
      <RelatedTasks activity={activity} showTitle />
    </div>
  </div>
;

export default AdminStepSidebar;
