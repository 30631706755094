import React from 'react';

import { t } from '^/i18n';
import Icon from '^/components/app/content/Icon';

interface OwnProps {
  icon: string;
  title: string;
  description: string;
  stepIndex: number;
}

const InfoBox: React.SFC<OwnProps> = ({
  icon,
  title,
  description,
  stepIndex,
}) => {
  return (
    <span className="info-box text-center">
      <div className="info-step-wrapper">
        <div className="info-step text-uppercase">
          {t('healthcheck.step', 'Step')} {stepIndex}
        </div>
      </div>
      <Icon type={icon} className="info-icon" />
      <div className="info-text">
        <h1 className="text-uppercase semi-bold-text mt-1 mb-1-2">{title}</h1>
        <p>{description}</p>
      </div>
    </span>
  );
};

export default InfoBox;
