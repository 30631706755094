import React from 'react';

import { t } from '^/i18n';

const Placeholder = () => (
  <div className="activity-chart-placeholder-wrapper">
    <svg width="131" height="52" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <text fontFamily="OpenSans-Italic, Open Sans" fontSize="16" fontStyle="italic" fill="#636363">
          <tspan x="0" y="30">{t('common.loading', 'loading')}… </tspan>
          </text>
        <path
          d="M105 0c14.36 0 26 11.64 26 26s-11.64 26-26 26-26-11.64-26-26S90.64 0 105 0zm0 13c-7.18 0-13 5.82-13 13s5.82 13 13 13 13-5.82 13-13-5.82-13-13-13z" // eslint-disable-line max-len
          fill="#E9E7E7"
          fillRule="nonzero"
        />
      </g>
    </svg>
  </div>
);

export default Placeholder;
