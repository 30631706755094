import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { t } from '^/i18n';
import { formatDate } from '^/utils';
import { isLoggedOnUserAdminOfCurrentPractice } from '^/stateHelpers';
import { isComplete, isOverdue } from '^/models/activities';
import { isAssignedTo } from '^/models/task';
import Icon from '^/components/app/content/Icon';
import MaybeLink from '^/components/app/content/MaybeLink';


const summariseAssignee = assignee =>
  `${
    assignee.get('completed_by_static_full_name') ?
    assignee.get('completed_by_static_full_name') : assignee.getIn(['completed_by', 'full_name'])} on ${formatDate(assignee.get('completed_date'))}`;

const completionTip = task =>
  t('activities.completedBy', 'Completed by ') + task.get('assignees').map(summariseAssignee).join(', ');

function maybeLink(isAdmin, userStaffId, task) {
  const isOwn = isAssignedTo(userStaffId)(task);

  if (!isOwn && !isAdmin) {
    return false;
  }
  return `/page/tasks${isOwn ? '' : '/manage'}/${task.get('id')}/`;
}

export const TasksList = ({tasks, userStaffId, isAdmin, className}) =>
  <ul className={classNames('list-no-indent', className)}>
    {tasks.map(task =>
      <li
        key={task.get('id')}
        className={isOverdue(task) && 'outstanding'}
        title={isComplete(task) ? completionTip(task) : undefined}
      >
        <MaybeLink className="list-link indented-link" to={maybeLink(isAdmin, userStaffId, task)}>
          <Icon
            type={isComplete(task) ? 'check-square-o' : 'square-o'}
            className={classNames('task-icon', {complete: isComplete(task), incomplete: isOverdue(task)})}
          /> {task.get('title')}
        </MaybeLink>
      </li>
    )}
  </ul>
;

export function mapStateToProps(state) {
  return {
    isAdmin: isLoggedOnUserAdminOfCurrentPractice(state),
    userStaffId: state.userProfile.getIn(['staffdetail', 'id']),
  };
}

export default connect(mapStateToProps) (TasksList);
