import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import { t } from '^/i18n';
import InfoBox from './info-box';
import Icon from '^/components/app/content/Icon';
import { StoreState } from '^/store/types';
import { selectCurrentPracticeName } from '^/selectors/practice';
import {
  selectCurrentPracticeHealthCheck,
  selectCurrentUserId,
} from '^/selectors/healthcheck';
import { updateHealthcheckStatusIfMatchAndContinue } from '^/actions/healthcheck';
import { Healthcheck, HealthcheckStatus } from './types';

interface StateProps {
  practiceName: string;
  inProgressHealthcheck: Healthcheck | undefined;
  currentUserId: string | undefined;
}

interface DispatchProps {
  updateHealthcheckStatusIfMatchAndContinue: typeof updateHealthcheckStatusIfMatchAndContinue;
}

type OwnProps = RouteComponentProps<{ healthcheckId: string }, {}>;

export type Props = OwnProps & DispatchProps & StateProps;

export class HealthcheckInfo extends Component<Props, {}> {
  public render() {
    const { practiceName, inProgressHealthcheck, currentUserId } = this.props;

    return (
      <div className="col-1 mt-2">
        <h1>
          {t('healthcheck.complianceHealthcheck', 'Compliance Healthcheck')}
          <span className="light-text ml-1">{practiceName}</span>
        </h1>
        <hr className="thin" />
        <div className="row">
          <div className="text-center mt-2 col-2-3 col-1-6-offset">
            <h1 className="mb-1">
              {t('healthcheck.whatIsAHealthcheck', 'What is a healthcheck?')}
            </h1>
            <p>
              {t('healthcheck.healthcheckIsA', 'A healthcheck is a')}{' '}
              <strong>
                {t(
                  'healthcheck.simpleThreeStepProcess',
                  'simple 3 step process'
                )}
              </strong>{' '}
              {t(
                'healthcheck.identifyGapsInComplianceMessage',
                'to audit your practice, clearly identify any gaps in compliance and provide you with instructions to catch up before you begin your first cycle of reviews in iComply. It’s based on Agilio’s experience of helping practices for 40 years and is made up of three parts:'
              )}
            </p>
          </div>
        </div>
        <div className="mt-3 mb-3 info-box-wrapper display-flex flex-justify-space-between flex-align-items-center">
          <InfoBox
            icon="search-light"
            title={t('healthcheck.performAnAudit', 'Perform an audit')}
            description={t(
              'healthcheck.whatIDidMessage',
              "Easily find out what you have and haven't done."
            )}
            stepIndex={1}
          />
          <Icon type="arrow-right" className="info-arrow" />
          <InfoBox
            icon="list-light"
            title={t(
              'healthcheck.takeSuggestedActions',
              'Take suggested actions'
            )}
            description={t(
              'healthcheck.workThroughComplianceGaps',
              'Work through your compliance gaps, adopting documents and writing notes'
            )}
            stepIndex={2}
          />
          <Icon type="arrow-right" className="info-arrow" />
          <InfoBox
            icon="award-light"
            title={t(
              'healthcheck.reviewResultsAndReflect',
              'Review results & reflect'
            )}
            description={t(
              'healthcheck.scoreboardMessage',
              "See a score showing how well you've done."
            )}
            stepIndex={3}
          />
        </div>
        <div className="healthcheck-box text-center">
          {inProgressHealthcheck &&
          inProgressHealthcheck?.user.id !== currentUserId ? (
            <div>
              <button className="btn btn-save large mt-1 mb-1" disabled>
                {t(
                  'healthcheck.button.healthCheckInProgress',
                  'Healthcheck in progress'
                )}
              </button>
              <p>
                <strong>
                  {t(
                    'healthcheck.progressByMessage',
                    'This Healthcheck is already in progress by'
                  )}{' '}
                  {inProgressHealthcheck.user.email}.
                </strong>
              </p>
              <p>
                {t(
                  'healthcheck.emailRestartMessage',
                  'If you need to start again, please email'
                )}{' '}
                <a href="mailto:icomply@agiliosoftware.com">
                  icomply@agiliosoftware.com
                </a>{' '}
                {/* TODO: edge-case detected */}
                0330 165 9712.
              </p>
            </div>
          ) : (
            <button
              className="btn btn-save large mt-1 mb-1"
              onClick={this.onStartClick}
            >
              {inProgressHealthcheck?.status === HealthcheckStatus.CREATED
                ? t('healthcheck.button.start', 'Start')
                : t('common.button.continue', 'Continue')}{' '}
              {t('healthcheck.healthcheck', 'healthcheck')}
            </button>
          )}
        </div>
      </div>
    );
  }

  private onStartClick = () => {
    const { inProgressHealthcheck } = this.props;
    if (inProgressHealthcheck) {
      this.props.updateHealthcheckStatusIfMatchAndContinue(
        HealthcheckStatus.CREATED
      );
    }
  };
}

const mapStateToProps = (state: StoreState): StateProps => ({
  practiceName: selectCurrentPracticeName(state),
  inProgressHealthcheck: selectCurrentPracticeHealthCheck(state),
  currentUserId: selectCurrentUserId(state),
});

export default connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, {
  updateHealthcheckStatusIfMatchAndContinue,
})(HealthcheckInfo);
