import React from 'react';
import { List } from 'immutable';
import PureComponent from '^/components/common/PureComponent';
import Loading from '^/components/app/content/Loading';
import * as responseStates from '^/consts/responseStates';
import classNames from 'classnames';

export const DefaultErrorMessage = () =>
  <p className="alert-error">There was an error submitting data.</p>;

export default class LiveButton extends PureComponent {

  renderDefault(isPending) {
    const { className, onClick, children, pendingMessage, disabled} = this.props;

    const props = Object.assign(
      { className: classNames('btn', className || 'btn-default')},
      onClick ? { onClick } : {type: 'submit'},
      (isPending || disabled) && {disabled: true}
    );

    if (isPending) {
      delete props.onClick;
      props.type = 'button';  // Explicitly set the type to not 'submit'
    }

    return (
      <button {...props}>
        {isPending && <Loading className="inline small" />}
        {(isPending && pendingMessage) || children}
      </button>
    );
  }

  getErrors(response) {
    const errors = response.getIn(['errors', 'non_field_errors'], List());

    if (this.props.getAdditionalErrors) {
      return errors.concat(this.props.getAdditionalErrors(response));
    }
    return errors;
  }

  render() {
    const { response, hideErrors } = this.props;

    switch (response && response.get('state')) {
      case responseStates.PENDING:
        return this.renderDefault(true);

      case responseStates.FAILED:
        const errors = this.getErrors(response);
        return (!hideErrors && errors.count()) ? (
            <div>
              {this.renderDefault()}
              {errors.map(error => <p key={error} className="alert-error">{error}</p>)}
            </div>
        ) : (
          <div>
            {this.renderDefault()}
            {!this.props.hideDefaultErrorMessage && <DefaultErrorMessage />}
          </div>
        );

      case responseStates.SUCCESSFUL:
        if (this.props.successContent) {
          return this.props.successContent;
        }
        return this.renderDefault();

      default:
        return this.renderDefault();
    }
  }
}
