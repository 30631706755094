import React from 'react';
import { connect } from 'react-redux';

import { t } from '^/i18n';
import { TEMPLATE_DOCUMENT_VERSION } from '^/consts/collectionKeys';
import { loadTemplateLatestVersion, clearItem } from '^/actions/items';
import { isPending } from '^/consts/responseStates';
import PureComponent from '^/components/common/PureComponent';
import Loading from '^/components/app/content/Loading';
import DemoModeMayDisallow from '^/components/app/perms/DemoModeMayDisallow';
import DocumentEditor from './DocumentEditor';
import DocumentHeader from './DocumentHeader';


export class SourceDocumentEditor extends PureComponent {
  componentWillMount() {
    this.props.loadTemplate();
  }

  componentWillReceiveProps(newProps) {
    if (this.props.documentId && newProps.documentId !== this.props.documentId) {
      this.props.loadTemplate();
    }
  }

  componentWillUnmount() {
    this.props.resetItem();
  }

  renderDocument() {
    const { templateDocumentVersion, response } = this.props;

    if (isPending(response) || !templateDocumentVersion) {
      return (
        <Loading />
      );
    }

    const isGroupDocument = templateDocumentVersion.get('group_template_document');
    return (
      <div className="source-doc-editor-wrapper">
        <div className="source-doc-editor">
          <div className="source-doc-editor__text">
            <DocumentHeader doc={templateDocumentVersion} editingGroupDocument={isGroupDocument} showDocInfo />
          </div>
          <div className="source-doc-editor__document">
            <div className="p-1-2">
            <div className="x-small-text review-copy-info">
              <span className="text-uppercase bold-text">{t('templateDocuments.newerVersionRef', 'Newer version (reference)')} </span>
              {t('templateDocuments.reviewAndCopyDocMsg', 'Review and copy changes from this document to the adopted document on the right')}
            </div>
              <DocumentEditor
                {...this.props}
                doc={templateDocumentVersion}
                readOnly
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { response } = this.props;

    return (
      <DemoModeMayDisallow message="cannot update documents" response={response} goBack>
        {this.renderDocument()}
      </DemoModeMayDisallow>
    );
  }
}

const OTHER_TEMPLATE_DOC_VERSION = TEMPLATE_DOCUMENT_VERSION + '_2';

export function mapStateToProps(state) {
  return {
    templateDocumentVersion: state.items.get(OTHER_TEMPLATE_DOC_VERSION),
    response: state.responses.getIn(['loadTemplateLatestVersion', OTHER_TEMPLATE_DOC_VERSION]),
  };
}

export function mapDispatchToProps(dispatch, props) {
  return {
    loadTemplate: () => dispatch(loadTemplateLatestVersion(props.documentId, OTHER_TEMPLATE_DOC_VERSION)),
    resetItem: () => dispatch(clearItem(OTHER_TEMPLATE_DOC_VERSION)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps) (SourceDocumentEditor);
