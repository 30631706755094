import React from 'react';
import { connect } from 'react-redux';
import { fromJS } from 'immutable';

import { t } from '^/i18n';
import * as modals from '^/actions/modals';
import {
  uploadActivityFileReloadActivityAndCloseModal, uploadTaskAssigneeFileReloadTaskFilesAndCloseModal
} from '^/actions/actionSequences';

import Icon from '^/components/app/content/Icon';
import CollapseButton from '^/components/app/content/CollapseButton';
import Collapse from '^/components/app/content/Collapse';

export const transformData = (data) => fromJS(data).set('file', data.file && data.file[data.file.length - 1].id);

export const ActivityTaskAssigneeDocumentUpload = ({
  openUploadActivityTaskAssigneeFileModal,
  activityId,
  taskAssigneeId,
  uploadToActivity,
  uploadToTaskAssignee,
  taskId,
}) =>
  <div className="collapsible-section-activities">
  <CollapseButton collapseId={'LINK_UPLOAD_DOCUMENTS_COLLAPSE_ID'}>
    <h2>{t('activities.linkToOrUploadDocuments', 'Link to or upload documents')}</h2>
  </CollapseButton>
  <Collapse initiallyCollapsed collapseId={'LINK_UPLOAD_DOCUMENTS_COLLAPSE_ID'}>
    <div className="mt-1">
        <div className="help-block mb-1">
          <Icon type="lightbulb-o" className="tip-icon" />
          {t('activities.additionalDocumentsCompletedMsg', 'You can upload or describe where to find any additional documents completed outside of the iComply system.')}
        </div>
        <button
          className="btn btn-default indented" onClick={
          () => openUploadActivityTaskAssigneeFileModal(
            activityId || taskAssigneeId,
            activityId ?
              (id, data) => uploadToActivity(id, transformData(data)) :
              (id, data) => uploadToTaskAssignee(id, taskId, transformData(data))
          )
        }
        >
          <Icon type="upload" className="button-icon" /> {t('activities.addDocuments', 'add documents')}
        </button>
    </div>
  </Collapse>
  </div>;

ActivityTaskAssigneeDocumentUpload.propTypes = {
  activityId: React.PropTypes.string,
  taskAssigneeId: React.PropTypes.string,
  taskId: React.PropTypes.string,
};

export default connect(
  null,
  {
    openUploadActivityTaskAssigneeFileModal: modals.openUploadActivityTaskAssigneeFileModal,
    uploadToActivity: uploadActivityFileReloadActivityAndCloseModal,
    uploadToTaskAssignee: uploadTaskAssigneeFileReloadTaskFilesAndCloseModal,
  }
)(ActivityTaskAssigneeDocumentUpload);
