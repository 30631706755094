import React, { PropTypes } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';

import Icon from '^/components/app/content/Icon';
import { t } from '^/i18n';

export const AccountSetupHeader = ({user, practice, children}) =>
  <div className="account-setup-header">
    <div>
      {practice && !practice.isEmpty() &&
        <div className="text-capitalize mt-1">{practice.get('name')}:</div>}
      <h1>{children}</h1>
      <div className="metadata text-capitalize">
        <Icon type="user" className="metadata-icon mr-1-2" />
        {user.getIn(['staffdetail', 'full_name'])} | { t('accountSetup.practiceAdmin', 'Practice Admin') }
      </div>
    </div>
    <hr className="strong" />
  </div>;


AccountSetupHeader.propTypes = {
  user: ImmutablePropTypes.map.isRequired,
  practice: ImmutablePropTypes.map,
  children: PropTypes.any,
};


export default AccountSetupHeader;
