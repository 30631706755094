import React from 'react';
import { reduxForm } from 'redux-form';
import moment from 'moment';

import { t } from '^/i18n';
import { getRepeatOptions, toOptionValue } from '^/models/task';
import LiveButton from '^/components/app/forms/LiveButton';
import FormError from '^/components/app/forms/FormError';
import DateInput from '^/components/app/forms/DateInput';
import TaskError from './TaskError';

export const DatePopupForm = ({ fields: { deadline, repeatOption }, values, task, handleSubmit, response }) =>
  <form onSubmit={handleSubmit}>
    <DateInput id="deadline" minDate={moment()} showYearDropdown={false} {...deadline} />
    <FormError response={response} formKey="deadline" />
    <FormError error={deadline && deadline.touched && deadline.error} />
    <div className="nowrap">
      {t('tasks.repeatsCapitalCase', 'Repeats')}: <select {...repeatOption}>
        {getRepeatOptions(task.merge(values)).map(option =>
          <option key={option} value={toOptionValue(option)}>
            {option.get('text')}
          </option>
        )}
      </select>
    </div>

    <LiveButton
      className="btn-default"
      response={response}
      hideErrors
    >
      {t('tasks.applyChanges', 'apply changes')}
    </LiveButton>

    <TaskError response={response} />
  </form>
;

export function validateData(data) {
  if (!data.deadline || data.deadline === '') {
    return { deadline: t('tasks.dueDateProvided', 'due date must be provided') };
  }
  return {};
}

export default reduxForm({
  form: 'taskDate',
  fields: ['deadline', 'repeatOption'],
  validate: validateData
})(DatePopupForm);
