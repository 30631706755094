import React, { PropTypes } from 'react';
import momentPropTypes from 'react-moment-proptypes';
import { connect } from 'react-redux';

import { t } from '^/i18n';
import { navigateToCalendarMonth } from '^/actions/actionSequences';


export const BackToCalendarLink = ({month, backToCalendar}) =>
  <a className="back-link" onClick={() => backToCalendar(month)}>{t('activities.backToCalendar', 'Back to Calendar')}</a>;


BackToCalendarLink.propTypes = {
  month: momentPropTypes.momentObj.isRequired,
  backToCalendar: PropTypes.func.isRequired,
};

export default connect(null, { backToCalendar: navigateToCalendarMonth }) (BackToCalendarLink);
