import React from 'react';
import { Link } from 'react-router';

import Loading from '^/components/app/content/Loading';

import { isPending } from '^/consts/responseStates';
import { t } from '^/i18n';

export const ChecklistRecordGroupHistory = ({
  checklistHistory,
  checklistHistoryResponse,
  recordGroupId
}) => {
  if (isPending(checklistHistoryResponse)) {
    return <Loading />
  }

  return (
    <div>
      <h3 className="mt-1 mb-1">{ t('common.history', 'History') }</h3>
      <table className="responsive padded padded-sm mb-2">
        <thead>
          <th>{ t('common.date', 'Date') }</th>
          <th>{ t('common.label.yes', 'Yes') }</th>
          <th>{ t('common.label.no', 'No') }</th>
          <th>{ t('common.label.na', 'N/A') }</th>
          <th />
        </thead>
        <tbody>
          {checklistHistory.count() === 0 && <tr><td colSpan="5">No history to display</td></tr>}
          {checklistHistory.map(history => {
            if (history.get('status') === 'NOT_SUBMITTED') {
              return (
                <tr key={history.get('date_id')}>
                  <td>{history.get('date_id')}</td>
                  <td colSpan="3">{ t('common.label.nothingSubmitted', 'Nothing submitted') }</td>
                  <td>
                    <Link
                      className="base-font-color"
                      to={`/page/tools/records/checklist/${recordGroupId}/${history.get('date_id')}/`}
                    >
                      { t('common.button.view', 'View') }
                    </Link>
                  </td>
                </tr>
              );
            }

            return (
              <tr key={history.get('date_id')}>
                <td>{history.get('date_id')}</td>
                <td>{history.get('answered_yes_count')}</td>
                <td>{history.get('answered_no_count')}</td>
                <td>{history.get('answered_na_count')}</td>
                <td>
                  <Link
                    className="base-font-color"
                    to={`/page/tools/records/checklist/${recordGroupId}/${history.get('date_id')}/`}
                  >
                    { t('common.button.view', 'View') }
                  </Link>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default ChecklistRecordGroupHistory;
