import React from 'react';

import { t } from '^/i18n';
import PureComponent from '^/components/common/PureComponent';
import NoAuth from '^/components/app/users/login/NoAuth';
import LoggedOutLogo from '^/components/app/users/LoggedOutLogo';

export default class LoginFailed extends PureComponent {
  render() {
    return (
      <div>
        <LoggedOutLogo />
        <NoAuth>
          <h1>
            {t(
              'users.login.unableToLogin',
              'We are currently unable to log you in at this time.'
            )}
          </h1>
          <h2>
            {t(
              'users.login.contactAgilioTeam',
              'Please contact a member of the Agilio team.'
            )}
          </h2>
        </NoAuth>
      </div>
    );
  }
}
