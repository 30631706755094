import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Map } from 'immutable';
import { connect } from 'react-redux';

import { t } from '^/i18n';
import { expandUiComponent, collapseUiComponent, resetResponse } from '^/actions/actions';
import { TASKS } from '^/consts/collectionKeys';
import { formatDate } from '^/utils';
import { isPending } from '^/consts/responseStates';
import { patchItemAndClosePopup } from '^/actions/actionSequences';
import { REMINDER_CHOICES_TEXT, getRepeatText } from '^/models/task';
import { isNotReadOnlyInCurrentPractice } from '^/models/user';

import PureComponent from '^/components/common/PureComponent';
import Icon from '^/components/app/content/Icon';
import Loading from '^/components/app/content/Loading';
import EditableFieldText from '^/components/app/widgets/EditableFieldText';
import EditableFieldChoices from '^/components/app/widgets/EditableFieldChoices';
import PermissionsComponent from '^/components/app/perms/PermissionsComponent';
import DatePopup from './DatePopup';
import AssigneePopup from './AssigneePopup';
import ShowIfAdmin from '^/components/app/perms/ShowIfAdmin';


const POPUPS = {
  DATE_POPUP: 'DATE_POPUP',
  ASSIGNEE_POPUP: 'ASSIGNEE_POPUP',
};


export class TitleAndDetailsEditBar extends PureComponent {
  componentWillMount() {
    this.closeOpenPopups();
  }

  componentWillReceiveProps(newProps) {
    const { task } = this.props;
    if (task !== newProps.task) {
      this.closeOpenPopups();
    }
  }

  closeOpenPopups() {
    const { openPopups, close } = this.props;
    openPopups.forEach(each => close(each));
  }

  updateAssignees(updater) {
    const { update, task } = this.props;
    const assigneeIdSet =
      task.get('assignees')
        .map(each => each.get('id'))
        .toSet();

    update({ assignees: updater(assigneeIdSet).toJS() }, POPUPS.ASSIGNEE_POPUP);
  }

  addAssignee(assigneeId) {
    this.updateAssignees(assigneeIdSet => assigneeIdSet.add(assigneeId));
  }

  removeAssignee(assigneeId) {
    this.updateAssignees(assigneeIdSet => assigneeIdSet.delete(assigneeId));
  }

  updateDate(data) {
    const { update } = this.props;
    update(data, POPUPS.DATE_POPUP);
  }

  toggle(popup) {
    const { openPopups, open, close, resetPatchResponse } = this.props;
    if (openPopups.contains(popup)) {
      close(popup);
    } else {
      this.closeOpenPopups();
      resetPatchResponse();
      open(popup);
    }
  }

  render() {
    const { task, openPopups, close, response, practiceMembers } = this.props;
    const { deadline, assignees } = task.toObject();

    return (
      <div>
        <h1>
          <EditableFieldText key={task.get('id')} object={task} model={TASKS} fieldName="title" />
        </h1>

        {isPending(response) && <Loading />}

        <div className="metadata">
          <div className="inline mr-1">

            <Icon type="user" className="inline-icon" />
            {
              assignees.size ? (
                assignees.map(assignee => assignee.get('full_name')).join(', ')
              ) : (
                <i>{t('tasks.noAssignees', 'No assignees')}</i>
              )
            }
            <ShowIfAdmin>
              <div className="popup-container">
                {openPopups.contains(POPUPS.ASSIGNEE_POPUP) && (
                  <AssigneePopup
                    practiceMembers={practiceMembers}
                    assignees={assignees}
                    response={response}
                    onAdd={assigneeId => this.addAssignee(assigneeId)}
                    onRemove={assigneeId => this.removeAssignee(assigneeId)}
                    onClose={() => close(POPUPS.ASSIGNEE_POPUP)}
                  />
                )}
              </div>
              <a onClick={() => this.toggle(POPUPS.ASSIGNEE_POPUP)} className="underlined ml-1-2">{t('healthcheck.change', 'change')}</a>
            </ShowIfAdmin>
          </div>

          <div className="inline mr-1">
            <Icon type="calendar" className="inline-icon" />
            {formatDate(deadline)}
            <span>{getRepeatText(task)}</span>
            <div className="popup-container">
              {openPopups.contains(POPUPS.DATE_POPUP) && (
                <DatePopup
                  task={task}
                  onSave={data => this.updateDate(data)}
                  onCancel={() => close(POPUPS.DATE_POPUP)}
                  response={response}
                />
              )}
            </div>
            <PermissionsComponent hasPermission={isNotReadOnlyInCurrentPractice} >
              <a className="underlined ml-1-2" onClick={() => this.toggle(POPUPS.DATE_POPUP)}>{t('healthcheck.change', 'change')}</a>
            </PermissionsComponent>
          </div>

          <div className="inline">
            <Icon type="bell-o" className="inline-icon" />
            <EditableFieldChoices
              choices={Map(REMINDER_CHOICES_TEXT)}
              object={task} model={TASKS} fieldName="reminder"
            />
          </div>
        </div>
      </div>
    );
  }
}

TitleAndDetailsEditBar.propTypes = {
  task: ImmutablePropTypes.map.isRequired,
};

const name = (props, popup) => `TASK_POPUPS.${props.task.get('id')}.${popup}`;

export function mapStateToProps(state, props) {
  return {
    openPopups: Map(POPUPS)
      .map(popup => state.ui.get('expandedUiComponents').contains(name(props, popup)))
      .filter(x => x)
      .keySeq(),
    response: state.responses.getIn(['updateItem', TASKS]),
  };
}

export function mapDispatchToProps(dispatch, props) {
  return {
    open: popup => dispatch(expandUiComponent(name(props, popup))),
    resetPatchResponse: () => dispatch(resetResponse('updateItem')),
    close: popup => dispatch(collapseUiComponent(name(props, popup))),
    update: (data, popup) => dispatch(patchItemAndClosePopup(TASKS, props.task.get('id'), data, name(props, popup))),
  };
}

export default connect(mapStateToProps, mapDispatchToProps) (TitleAndDetailsEditBar);
