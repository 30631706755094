import React from 'react';
import { reduxForm } from 'redux-form';
import FormError from '^/components/app/forms/FormError';
import HelpTooltip from '^/components/app/widgets/HelpTooltip';
import { t } from '^/i18n';

export const COLLAPSE_ID = 'PERSONAL_DETAILS_FORM_COLLLAPSE';
export const OVERRIDE_TEXT = '_override_text';

export const SelectTeamMembers = ({
  field, teamMembers, className
}) =>
  <select id={field.name} {...field} className={className}>
    {teamMembers.map(member =>
      <option value={member.get('staffdetail')} key={member}>{member.get('full_name')}</option>)}
  </select>;


export const CycleResponsibilityField = ({
  responsibility, field, overrideTextField, response, teamMembers
}) =>
  <div className="form-group">
    <div className="col-1-3">
      <label htmlFor={field.name} className="padded">
        {responsibility.get('name')}
        {responsibility.get('help_text') && (
          <HelpTooltip><p className="small-text">{responsibility.get('help_text')}</p></HelpTooltip>
        )}
      </label>
      <span className="help-block">{responsibility.get('is_optional') ? '' : t('common.required', 'required')}</span>
      <FormError response={response} formKey={field.name} />
    </div>
    <div className="col-1-3">
      <SelectTeamMembers field={field} teamMembers={teamMembers} />
    </div>
    <div className="col-1-3">
    {responsibility.get('allow_override') === true &&
      <input
        type="text"
        {...overrideTextField}
        id={overrideTextField.name}
        name={overrideTextField.name}
        placeholder={t('accountSetup.documentsOverride', 'Documents override')}
      />
    }
    </div>
  </div>;


export const CycleResponsibilitiesForm = ({
  fields, handleSubmit, response, responsibilities, teamMembers, children
}) =>
  <form onSubmit={handleSubmit}>
    {responsibilities.map(each =>
      <CycleResponsibilityField
        key={each.get('id')}
        field={fields[each.get('id')]}
        overrideTextField={fields[each.get('id') + OVERRIDE_TEXT]}
        responsibility={each}
        teamMembers={teamMembers}
        response={response}
      />
    )}
    {children}
  </form>;

export default reduxForm({
  form: 'setupCycleResponsibilitiesForm',
})(CycleResponsibilitiesForm);
