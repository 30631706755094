import React from 'react';
import moment from 'moment';

import { t } from '^/i18n';
import { Column } from '^/components/app/healthcheck/hoc/create-summary-table';
import { asPercent } from '^/utils';
import {
  AuditListSummary,
  AuditStatus,
} from '^/components/app/digital-tools/audit/types';
import {
  HealthcheckStatus,
  HealthcheckListSummary,
} from '^/components/app/healthcheck/types';

const renderStatus = (status: HealthcheckStatus | AuditStatus) => {
  if (
    status === HealthcheckStatus.COMPLETED ||
    status === AuditStatus.COMPLETED
  ) {
    return 'completed';
  }
  if (status === HealthcheckStatus.REVIEW || status === AuditStatus.REVIEW) {
    return 'in review';
  }
  return 'in progress';
};

export const HEALTHCHECK_SUMMARY_TABLE_COMMON_COLUMNS: Column<
  HealthcheckListSummary
>[] = [
  {
    name: t('consts.date', 'Date'),
    renderer: (props: { data: HealthcheckListSummary }) => (
      <span>{moment(props.data.modified).format('DD/MM/YYYY')}</span>
    ),
  },
  {
    name: t('consts.completedBy', 'Completed by'),
    renderer: (props: { data: HealthcheckListSummary }) => (
      <span>{props.data.user.email}</span>
    ),
  },
  {
    name: t('consts.score', 'Score'),
    renderer: (props: { data: HealthcheckListSummary }) => (
      <span>{asPercent(props.data.overall_score)}</span>
    ),
  },
  {
    name: t('consts.completedActions', 'Completed Actions'),
    renderer: (props: { data: HealthcheckListSummary }) => (
      <span>
        {props.data.action_completed_count}/{props.data.action_total_count}
      </span>
    ),
  },
  {
    name: t('consts.status', 'Status'),
    renderer: (props: { data: HealthcheckListSummary }) => (
      <span>{renderStatus(props.data.status)}</span>
    ),
  },
];

export const AUDIT_SUMMARY_TABLE_COMMON_COLUMNS: Column<AuditListSummary>[] = [
  {
    name: t('consts.date', 'Date'),
    renderer: (props: { data: AuditListSummary }) => (
      <span>{moment(props.data.modified).format('DD/MM/YYYY')}</span>
    ),
  },
  {
    name: t('consts.completedBy', 'Completed by'),
    renderer: (props: { data: AuditListSummary }) => (
      <span>{props.data.user.email}</span>
    ),
  },
  {
    name: t('consts.score', 'Score'),
    renderer: (props: { data: AuditListSummary }) => (
      <span>{asPercent(props.data.score)}</span>
    ),
  },
  {
    name: t('consts.status', 'Status'),
    renderer: (props: { data: AuditListSummary }) => (
      <span>{renderStatus(props.data.status)}</span>
    ),
  },
];
