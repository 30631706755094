import React from 'react';
import { fromJS } from 'immutable';

import ProgressSpeechBubble from '^/components/app/widgets/ProgressSpeechBubble';
import { t } from '^/i18n';

const PROGRESS_TEXT = fromJS([
  { text: '', begin: 0, end: 14 },
  { text: '', begin: 15, end: 99 },
  { text: t('common.wellDone', 'Well done!'), begin: 100, end: 100 },
]);

const getProgressText = (percentage) => {
  const extraText = PROGRESS_TEXT
    .find(item => percentage >= item.get('begin') && percentage <= item.get('end'))
    .get('title');
  return t('widgets.inCurrentCycle', 'in current cycle.') + (extraText ? ' ' + extraText : '');
};


const CycleProgress = ({progress, forceColour}) => {
  const cycleProgress = progress && Math.round(progress * 100).toFixed(0);

  return (
    <ProgressSpeechBubble
      percentage={cycleProgress}
      forceColour={forceColour}
      title={`${cycleProgress}% ${t('commmon.complete', 'Complete')}`}
      text={getProgressText(cycleProgress)}
    />
  );
};

export default CycleProgress;
