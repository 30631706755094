import React from 'react';
import moment from 'moment';
import { OrderedMap, Range, } from 'immutable';

import { START_MONTHS, START_MONTHS_TEXT } from '^/models/cycle';
import { formatBackendDate } from '^/utils';

import FormError from '^/components/app/forms/FormError';
import Collapse from '^/components/app/content/Collapse';
import CollapseButton from '^/components/app/content/CollapseButton';
import { t } from '^/i18n';

const COLLAPSE_ID = 'CYCLE_SUGGESTED_TASKS_FORM_COLLLAPSE';
const startMonths = OrderedMap(START_MONTHS);


const SuggestedTasksSetupFormDateSection = ({start_date, response, end_date}) =>
  <div>
    <p className="small-text mb-1">{ t('accountSetup.selectCycleStartMonth', 'First select the cycle start month') }:</p>

    <div className="form-group">
      <div className="col-1-3">
        <label htmlFor="start_date" className="padded">{ t('accountSetup.cycleStartMonth', 'Cycle start month') }</label>
      </div>
      <div className="col-2-3">
        <select {...start_date} id="start_date">
          <option />
          {Range(1, 13).map(monthNum => {
            const startDate = moment().add(monthNum, 'months').startOf('month');
            return (
              <option value={formatBackendDate(startDate)} key={startDate}>
                {startDate.format('MMMM YYYY')}
              </option>
            );
          })}
        </select>
        <FormError response={response} formKey="start_date" />
        <FormError error={start_date && start_date.touched && start_date.error} />
      </div>
    </div>

    <div className="mb-2 optional-field">
      <CollapseButton collapseId={COLLAPSE_ID} className="optional-field-toggle mb-1">
        { t('accountSetup.areYouUpgradingFromAManualSystemMidCycle', 'Are you upgrading from a manual system mid cycle?') }
      </CollapseButton>

      <Collapse initiallyCollapsed collapseId={COLLAPSE_ID}>
        <div className="form-group">
          <div className="col-1-3">
            <label htmlFor="end_date" className="padded">{ t('accountSetup.startOnCycleMonth', 'Start on cycle month') }:</label>
            </div>
          <div className="col-2-3">
            <select {...end_date}>
              {startMonths.map(value =>
                <option value={value} key={value}>{START_MONTHS_TEXT[value]}</option>)}
            </select>
            <FormError response={response} formKey="end_date" />
            <FormError error={end_date && end_date.touched && end_date.error} />
          </div>
        </div>
      </Collapse>
    </div>
  </div>;

export default SuggestedTasksSetupFormDateSection;
