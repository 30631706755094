import React from 'react';
import { connect } from 'react-redux';
import { fromJS, List } from 'immutable';

import { t } from '^/i18n';
import { getSetupTeamMembers } from '^/actions/actions';
import { loadItem } from '^/actions/items';
import { CYCLES, TEMPLATE_TASKS } from '^/consts/collectionKeys';
import {
  setupCycleReviewTasksAndRedirectToNextStage,
  createCycleSetupTaskAndCloseModal
} from '^/actions/actionSequences';
import { getCollectionFromUrl, getAllCollection } from '^/actions/collections';
import { transformRepeatOption } from '^/models/task';
import { openCreateTaskModal } from '^/actions/modals';

import PureComponent from '^/components/common/PureComponent';
import Loading from '^/components/app/content/Loading';
import HelpBlock from '^/components/app/widgets/HelpBlock';
import CycleSetupHeader from '^/components/app/cycle-setup/CycleSetupHeader';
import CycleSetupReviewTasksForm from '^/components/app/cycle-setup/CycleSetupReviewTasksForm';
import { transformSuggestedTaskData } from '^/components/app/account-set-up/utils';
import { getActiveCycleSetUpPracticeMembers } from '^/stateHelpers';

function transformCycleTaskData(newCycleTasks) {
    return newCycleTasks
    .toJS()
    .map(transformRepeatOption);
}

const isReminderTask = (templateTasks, taskId) =>
  templateTasks.find(each => each.get('id') === taskId);

function transformFormData(data, templateTasks, newCycleTasks) {

  const reminderTasks = fromJS(data).filter((task, taskId) => isReminderTask(templateTasks, taskId));

  return {
    'template_tasks': transformSuggestedTaskData(reminderTasks),
    'other_tasks': transformCycleTaskData(newCycleTasks),
  };
}

const CycleSetupReviewTasks = ({
  practice, response, setup, cycleId, cycle, templateTasks, teamMembers, user, openCreateTask, createTask,
  newCycleTasks
}) =>
  <div>
    <CycleSetupHeader practice={practice}>
      {t('cycleSetup.reviewTasks', 'Review Tasks')}
    </CycleSetupHeader>

    <HelpBlock className="mb-2">
      {t('cycleSetup.reviewTasksMsg', `Review the Tasks you have set up and add any extra ones you'd like to have this cycle. You can remove an existing Task by unticking it.`)}
    </HelpBlock>

    <CycleSetupReviewTasksForm
      response={response}
      cycle={cycle}
      user={user}
      teamMembers={teamMembers}
      templateTasks={templateTasks}
      newCycleTasks={newCycleTasks}
      openCreateTaskModal={
        () => openCreateTask({
          isCycleReviewTaskPage: true,
          createTask: (data) => createTask(cycleId, data),
          singleAssignee: true,
        })
      }
      onSubmit={data => {setup(cycleId, practice, transformFormData(data, templateTasks, newCycleTasks));}}
    />
  </div>;


export class CycleSetupReviewTasksPage extends PureComponent {

  componentWillMount() {
    this.loadData(this.props);
  }

  componentWillReceiveProps(newProps) {
    if (this.props.practice.get('id') !== newProps.practice.get('id')) {
      this.loadData(newProps);
    }
  }

  loadData(props) {
    const { practice, cycleId } = props;
    this.props.loadItem(CYCLES, cycleId);
    this.props.getSetupTeamMembers(practice.get('id'));
    this.props.getAllCollection(TEMPLATE_TASKS);
  }

  render () {
    const { practice, teamMembers, cycle, templateTasks } = this.props;
    return (practice && teamMembers && cycle && templateTasks) ?
      <CycleSetupReviewTasks {...this.props} /> :
      <Loading />;
  }

}

export const mapStateToProps = (state, props) => {
  return {
    user: state.userProfile,
    practice: state.currentPractice,
    cycleId: props.params.uuid,
    cycle: state.items.get(CYCLES),
    teamMembers: getActiveCycleSetUpPracticeMembers(state),
    newCycleTasks: state.cycleSetupTasks.get(props.params.uuid) || List(),
    templateTasks: state.collections.getIn([TEMPLATE_TASKS, 'items']),
    response: state.responses.get('setupCycleReviewTasks'),
  };
}


export default connect(
  mapStateToProps,
  {
    loadItem,
    setup: setupCycleReviewTasksAndRedirectToNextStage,
    getAllCollection,
    getCollectionFromUrl,
    getSetupTeamMembers,
    openCreateTask: openCreateTaskModal,
    createTask: createCycleSetupTaskAndCloseModal,
  }
) (CycleSetupReviewTasksPage);
