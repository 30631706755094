import React from 'react';
import { connect } from 'react-redux';
import { Map, Set } from 'immutable';

import {
  CALENDAR_PRIORITY_FILTERS,
  PRIORITY_CLASSES,
  PRIORITY_NAMES,
  PRIORITIES,
} from '^/models/constants';
import { switchPractice, setFilter, clearFilter } from '^/actions/actions';
import { loadStats, loadYearToDateStats } from '^/actions/stats';
import { ACTIVITIES } from '^/consts/collectionKeys';
import { isPending, hasFailed } from '^/consts/responseStates';
import PureComponent from '^/components/common/PureComponent';
import ContentTabset from '^/components/app/content/ContentTabset';
import ContentTab from '^/components/app/content/ContentTab';
import Loading from '^/components/app/content/Loading';
import ActivityStatsAllPractices from '^/components/app/stats/activities/ActivityStatsAllPractices';
import ActivityStatsThisYear from '^/components/app/stats/activities/ActivityStatsThisYear';
import ActivityStatsProgress from '^/components/app/stats/activities/ActivityStatsProgress';
import { t } from '^/i18n';

export const ACTIVITY_STATS = 'ACTIVITY_STATS';
const EXCLUDE_STATS_FILTER = "EXCLUDE_STATS_FILTER";

export class ActivityStats extends PureComponent {
  constructor(props) {
    super(props);
    this.buildPriorityFilter = this.buildPriorityFilter.bind(this);
    this.applyPriorityFilter = this.applyPriorityFilter.bind(this);
  }

  componentWillMount() {
    const { practiceId, currentTeamMember } = this.props;
    this.loadActivityStats(practiceId, currentTeamMember);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.practiceId !== this.props.practiceId
      || nextProps.currentTeamMember !== this.props.currentTeamMember) {
      this.loadActivityStats(nextProps.practiceId, nextProps.currentTeamMember);
    }
  }

  loadActivityStats(practiceId, teamMember) {
    const { model, statsPriorityFilters } = this.props;
    if (practiceId) {
      this.props.loadStats(model, practiceId, practiceId, teamMember && teamMember.get('id'));
      this.props.loadYearToDateStats(
        model,
        practiceId,
        statsPriorityFilters,
        practiceId,
        teamMember && teamMember.get('id')
      );
    }
  }

  buildPriorityFilter(event) {
    const { statsPriorityFilters } = this.props;

    const filterKey = event.target.id;
    const filterValue = event.target.value;
    const filterUnchecked = !event.target.checked;

    const statsPriorityFilterSet = statsPriorityFilters.get(filterKey) ?
      Set(statsPriorityFilters.get(filterKey)) :
      Set();

    this.props.setFilter(EXCLUDE_STATS_FILTER, filterValue, filterUnchecked);

    if (filterUnchecked) {
      return statsPriorityFilters.set(filterKey, statsPriorityFilterSet.add(filterValue));
    }

    return statsPriorityFilterSet.size <= 1 ?
      statsPriorityFilters.delete(filterKey) :
      statsPriorityFilters.set(filterKey, statsPriorityFilterSet.delete(filterValue));
  }

  applyPriorityFilter(event) {
    const { model, practiceId, currentTeamMember } = this.props;
    
    const filters = this.buildPriorityFilter(event);
    
    this.props.loadYearToDateStats(
      model,
      practiceId,
      filters,
      practiceId,
      currentTeamMember && currentTeamMember.get('id')
    );
  }

  renderPriorityFilterCheckbox(value, filters) {
    return (
      <div className="mb-1-2">
        <label>
          <input
            id={"priority"}
            type="checkbox"
            name={value}
            value={value}
            onChange={this.applyPriorityFilter}
            checked={!filters.get(value, false)}
          />
          <span className={`mb-1-2 actvities-priority-pill ${PRIORITY_CLASSES[value]}`}>
            {PRIORITY_NAMES[value]}
          </span>
        </label>
      </div>
    );
  }

  render() {
    const { stats, yearToDateStats, tabsetName, response, practiceId, excludePriorityStatsFilter } = this.props;
    const loading = isPending(response) || !stats;
    const hasFailedToLoad = hasFailed(response)

    return (
      <ContentTabset name={tabsetName} defaultActiveTab={0} className="mb-1">
        <ContentTab tabsetName={tabsetName} tabId={0} tabName={t('common.label.progress', 'Progress')}>
          <div className="stats-container">
            {loading ? <Loading /> : <ActivityStatsProgress stats={yearToDateStats} hasFailedToLoad={hasFailedToLoad}/>}
            <div>
              <p className="mb-1">{ t('activities.filterByPriority', 'Filter by priority') }:</p>
              {CALENDAR_PRIORITY_FILTERS.filter(
                priorityType => priorityType !== PRIORITIES.CRITICAL
              ).map(priorityType =>
                this.renderPriorityFilterCheckbox(
                  priorityType,
                  excludePriorityStatsFilter
                )
              )}
            </div>
          </div>
        </ContentTab>
        <ContentTab tabsetName={tabsetName} tabId={1} tabName={t('activities.activitiesThisYear', 'Activities this year')}>
          {loading ? <Loading /> : <ActivityStatsThisYear stats={stats} />}
        </ContentTab>
        <ContentTab tabsetName={tabsetName} tabId={2} tabName={t('activities.allPracticesActivities', 'All practices\' activities')}>
          {loading ? <Loading /> : (
            <ActivityStatsAllPractices
              stats={stats}
              currentPracticeId={practiceId}
              switchPractice={this.props.switchPractice}
            />
          )}
        </ContentTab>
      </ContentTabset>
    );
  }
}

export function mapStateToProps(state, props) {
  const { practiceId } = props;
  const model = ACTIVITIES;
  const yearToDateStats = state.yearToDateStats;
  const excludePriorityStatsFilter = state.filterSpec.get(EXCLUDE_STATS_FILTER, Map());
  const statsPriorityFilters = yearToDateStats ? Map(yearToDateStats.get('filters')) : Map();

  return {
    stats: state.stats.get(practiceId),
    yearToDateStats: state.yearToDateStats.get(practiceId),
    response: state.responses.getIn(['loadStats', practiceId]),
    model,
    statsPriorityFilters,
    excludePriorityStatsFilter,
    tabsetName: ACTIVITY_STATS,
    currentTeamMember: state.currentTeamMember,
  };
}

export default connect(
  mapStateToProps,
  {
    setFilter,
    loadStats,
    clearFilter,
    loadYearToDateStats,
    switchPractice,
  }
)(ActivityStats);
