import React from 'react';
import { connect } from 'react-redux';
import { List } from 'immutable';

import { t } from '^/i18n';
import { getAllCollection } from '^/actions/collections';
import { uploadFile } from '^/actions/actionSequences';
import UploadActivityFileForm from '^/components/app/activities/documents/modals/UploadActivityFileForm';
import DemoModeMayDisallow from '^/components/app/perms/DemoModeMayDisallow';
import PureComponent from '^/components/common/PureComponent';
import { TEMPLATE_FOLDERS } from '^/consts/collectionKeys';
import { isPending } from '^/consts/responseStates';

const UPLOAD_DOCUMENTS_MODAL = 'UPLOAD_DOCUMENTS_MODAL';

export class UploadActivityTaskAssigneeFileModal extends PureComponent {
  componentWillMount() {
    this.props.getAllCollection(TEMPLATE_FOLDERS, {}, UPLOAD_DOCUMENTS_MODAL);
  }

  render () {
    const { isPendingUpload, response, activityOrTaskAssigneeId, save, setUpload, folders } = this.props;
    const initialValues = {
      folder: folders.getIn([0, 'id'])
    };
    return (
      <DemoModeMayDisallow message={t('activities.cannotUploadFiles', 'cannot upload files')} response={response}>
        <UploadActivityFileForm
          isPendingUpload={isPendingUpload}
          response={response}
          folders={folders}
          onSubmit={(data) => save(activityOrTaskAssigneeId, data)}
          uploadFile={setUpload}
          initialValues={initialValues}
        />
      </DemoModeMayDisallow>
    );
  }
}

export const mapStateToProps = (state) => ({
  response: state.responses.get('uploadActivityTaskAssigneeIdFile'),
  isPendingUpload: isPending(state.responses.get('uploadFiletoSignedUrl')),
  folders: state.collections.getIn([UPLOAD_DOCUMENTS_MODAL, 'items'], List()),
});

UploadActivityTaskAssigneeFileModal.propTypes = {
  activityOrTaskAssigneeId: React.PropTypes.string.isRequired,
  save: React.PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  {
    getAllCollection,
    setUpload: uploadFile,
  }
) (UploadActivityTaskAssigneeFileModal);
