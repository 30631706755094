import React from 'react';
import { connect } from 'react-redux';
import { Map } from 'immutable';

import { t } from '^/i18n';
import { clearTabset } from '^/actions/actions';
import { USER_REASSIGN_STEPS, STEP_TEXT, REASSIGN_STEPS } from '^/consts/retireSuspend';
import { getMemberChoices } from '^/models/practice';
import { onReassignSuccess } from '^/actions/actionSequences';

import PureComponent from '^/components/common/PureComponent';
import Icon from '^/components/app/content/Icon';
import ReassignResponsibilities from '^/components/app/practices/reassign-member/ReassignResponsibilities';
import ReassignActivities from '^/components/app/practices/reassign-member/ReassignActivities';
import ReassignTasks from '^/components/app/practices/reassign-member/ReassignTasks';
import ReassignPrivateDocuments from '^/components/app/practices/reassign-member/ReassignPrivateDocuments';
import ConfirmReassignment from '^/components/app/practices/reassign-member/ConfirmReassignment';

function onSuccess (props) {
  props.onReassignSuccess(props.practiceId);
}

export class SuspendOrRetireTeamMemberPage extends PureComponent {

  componentWillMount() {
    this.props.clearTabset(REASSIGN_STEPS);
  }

  getStep(memberChoices) {
    const {
      currentStep, member, currentPractice,
      onSubmit, reassignData,
      reassignResponse, action
    } = this.props;

    switch (currentStep) {
      case USER_REASSIGN_STEPS.get(0):
        return (
          <ReassignResponsibilities
            member={member}
            practice={currentPractice}
            memberChoices={memberChoices}
            currentStep={currentStep}
          />
        );
      case USER_REASSIGN_STEPS.get(1):
        return (
          <ReassignActivities
            member={member}
            practice={currentPractice}
            memberChoices={memberChoices}
            currentStep={currentStep}
          />
        );
      case USER_REASSIGN_STEPS.get(2):
        return (
          <ReassignTasks
            member={member}
            practice={currentPractice}
            memberChoices={memberChoices}
            currentStep={currentStep}
          />
        );
      case USER_REASSIGN_STEPS.get(3):
        return (
          <ReassignPrivateDocuments
            member={member}
            practice={currentPractice}
            memberChoices={memberChoices}
            currentStep={currentStep}
          />
        );
      case USER_REASSIGN_STEPS.get(4):
        return (
          <ConfirmReassignment
            onSubmit={onSubmit.bind(this, member.get('id'), reassignData.toJS())}
            reassignData={reassignData}
            onSuccess={() => onSuccess(this.props)}
            action={action}
            response={reassignResponse}
          />
        );
    }
  }

  render () {
    const { member, currentStep, currentPractice } = this.props;

    if (!currentPractice) {
      return null;
    }

    const memberChoices = getMemberChoices(currentPractice, member);

    if (memberChoices.isEmpty()) {
      return <div className="mb-1">
        <p>
          {t('practices.noReassignMembersLeftMsg', 'No members left to reassign tasks from')}
          {!member.isEmpty() && ' ' + member.getIn(['user', 'staffdetail', 'full_name'])}.
        </p>
        <p>{t('practices.addMorePracticeMembersMsg', 'Please add more members to the practice')}.</p>
      </div>;
    }

    return (
      <div>
        <div>
          <h1 className="inline">{STEP_TEXT.get(currentStep)}</h1>
          <span className="pull-right">
            <em>{t('practices.step', 'Step')} {USER_REASSIGN_STEPS.indexOf(currentStep) + 1} {t('practices.of', 'of')} {USER_REASSIGN_STEPS.size}</em>
          </span>
        </div>
        <div>
          for {!member.isEmpty() && member.getIn(['user', 'staffdetail', 'full_name'])}&nbsp;
          <Icon type="user" className="inline-icon" />
        </div>
        <hr />
        {this.getStep(memberChoices)}
      </div>
    );
  }
}

export const mapStateToProps = state => {
  return {
    reassignResponse: state.responses.get('reassignMember'),
    reassignData: state.reassignData || Map(),
    currentStep: state.ui.getIn(['activeTabs', REASSIGN_STEPS], USER_REASSIGN_STEPS.get(0)),
    currentPractice: state.currentPractice,
  };
};

export default connect(mapStateToProps, { onReassignSuccess, clearTabset }) (SuspendOrRetireTeamMemberPage);
