import React from 'react';
import { connect } from 'react-redux';

import { isDemoUser } from '^/models/user';
import { t } from '^/i18n';

export const DemoModeDisable = ({isDisabled, message, children}) =>
  isDisabled ? (
    <p className="alert-info">
      {
        !message ? 
        t('perms.demoMode.cannotDoThisAction', 'Sorry, in demo mode you cannot do this action.') :
        t('perms.demoMode.cannotDoThisActionWithMessage', 'Sorry, in demo mode you {message}.', {message: message})
      }      
    </p>
  ) : (
    children
  );

export const mapStateToProps = state => ({
  isDisabled: isDemoUser(state.userProfile)
});

export default connect(mapStateToProps) (DemoModeDisable);
