import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import classNames from 'classnames';
import { Set } from 'immutable';

import { t } from '^/i18n';
import PureComponent from '^/components/common/PureComponent';
import { isPending } from '^/consts/responseStates';
import { isComplete } from '^/models/activities';
import { collapseAllSections, toggleExpandSection } from '^/actions/actions';
import { REPEAT_CHOICES, getRepeatGroupId } from '^/models/task';
import Icon from '^/components/app/content/Icon';
import UserCapsule from '^/components/app/calendar/UserCapsule';
import MaybeCollapsible from '^/components/app/widgets/MaybeCollapsible';
import Loading from '^/components/app/content/Loading';
import LinkAndCloseMenu from '^/components/app/content/LinkAndCloseMenu';
import { LIST_NAV_ID } from '^/components/app/widgets/CollapsibleLeftNav';

export const TaskItem = ({task, activeId, numberOfRepeats, toggleExpandRepeats, isExpanded, isRepeat}) =>
  <li className={classNames({'sidenav-active': task.get('id') === activeId, 'ml-1': isRepeat})}>
    <LinkAndCloseMenu className="step-link" to={`/page/tasks/manage/${task.get('id')}/`} collapseId={LIST_NAV_ID}>
      <Icon
        type="check-circle-o"
        className={classNames('task-icon task-icon-indicator', {complete: isComplete(task)})}
      />
      {task.get('title')}
      <span className="task-date">{moment(task.get('deadline')).format('Do MMM')}</span>
      {task.get('repeat') !== REPEAT_CHOICES.NONE && (
        <Icon type="repeat" className="repeat-icon small-icon gray-icon" title={t('common.repeatingTask', 'repeating task')} />
      )}
      {!isRepeat && (numberOfRepeats > 0) && (
        <span> <span onClick={toggleExpandRepeats} className="inline-link x-small-text">
          {isExpanded ? t('tasks.hide', 'hide') : t('tasks.show', 'show')} {numberOfRepeats} {t('tasks.repeats', 'repeats')}
        </span></span>
      )}
      {task.get('assignees').size > 1 ? (
        <span className="capsule-user">{t('tasks.multiple', 'Multiple')} ({task.get('assignees').size})</span>
      ) : (
        task.get('assignees').map(assignee =>
          <UserCapsule key={assignee.get('id')} staffDetail={assignee} />
        )
      )}
    </LinkAndCloseMenu>
  </li>;

export const TasksSection = ({tasks, activeId}) =>
  tasks && tasks.size ? (
    <div className="clearfix">
      <ul className="list-no-indent icon-right icon-left mt-1">
        {tasks.map(task =>
          <TaskItem
            key={task.get('id')}
            task={task}
            activeId={activeId}
          />
        )}
      </ul>
    </div>
  ) : <noscript />;

export const CollapsibleTasksSection = ({
  tasks,
  activeId,
  onExpand,
  onLoadMore,
  isLoading,
  expandedRepeats,
  toggleExpandRepeats
}) => {
  // Group by repeat; in each group, put the parent first if there is one
  const groupedTasks = tasks.groupBy(getRepeatGroupId)
    .map(taskGroup => taskGroup.sortBy(task => task.get('repeat_clone_of') ? 1 : 0));

  const isExpanded = id => expandedRepeats && expandedRepeats.contains(id);

  return (
    <div className="clearfix">
      <MaybeCollapsible
        collapsible
        id="tasks-complete"
        initiallyCollapsed
        onExpand={onExpand}
        collapseButton={
          <h5 className="mt-1 complete">
            {t('tasks.completed', 'Completed')}:
          </h5>
        }
      >
        <ul className="list-no-indent icon-right icon-left mt-1">
          {groupedTasks
            .map(taskGroup =>
              taskGroup.map((task, idx) => {
                const isRepeat = idx > 0;
                const numberOfRepeats = taskGroup.count() - 1;
                const repeatGroupId = getRepeatGroupId(task);
                const isGroupExpanded = isExpanded(repeatGroupId);

                if (isRepeat && !isGroupExpanded) {
                  return <noscript />;
                }

                return (
                  <TaskItem
                    key={task.get('id')}
                    task={task}
                    activeId={activeId}
                    numberOfRepeats={numberOfRepeats}
                    isExpanded={isGroupExpanded}
                    isRepeat={isRepeat}
                    toggleExpandRepeats={() => toggleExpandRepeats(repeatGroupId)}
                  />
                );
              })
            )
            .valueSeq().flatten(1)
          }
          {isLoading ? (
            <Loading />
          ) : (
            onLoadMore && (
              <a className="underlined small-text bold-text" onClick={onLoadMore}>
                {t('common.button.loadMore', 'Load more')}…
              </a>
            )
          )}
        </ul>
      </MaybeCollapsible>
    </div>
  );
};

export class ManageTasksList extends PureComponent {
  componentWillMount() {
    this.props.collapseAllRepeats();
  }

  render() {
    const {
      tasks,
      activeId,
      onLoadCompleted,
      onLoadMoreCompleted,
      loadCompletedResponse,
      expandedRepeats,
      toggleExpandRepeats
    } = this.props;

    const sortedTasks = tasks.sortBy(each => each.get('deadline'));

    const notCompletedTasks = sortedTasks.filterNot(isComplete);
    const completedTasks = sortedTasks.filter(isComplete).reverse();

    return (
      <div>
        <TasksSection tasks={notCompletedTasks} activeId={activeId} />
        <CollapsibleTasksSection
          tasks={completedTasks} activeId={activeId}
          onExpand={onLoadCompleted}
          onLoadMore={onLoadMoreCompleted}
          isLoading={isPending(loadCompletedResponse)}
          expandedRepeats={expandedRepeats}
          toggleExpandRepeats={toggleExpandRepeats}
        />
      </div>
    );
  }
}

const TASKS = 'TASKS';

export function mapStateToProps(state) {
  return {
    expandedRepeats: state.ui.getIn(['expandedSections', TASKS], Set())
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    toggleExpandRepeats: id => dispatch(toggleExpandSection(TASKS, id)),
    collapseAllRepeats: () => dispatch(collapseAllSections(TASKS)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageTasksList);
