import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { List } from 'immutable';

import { t } from '^/i18n';
import PureComponent from '^/components/common/PureComponent';

class ListPagination extends PureComponent {

  getPaginationLinkClassName(isActive, isDisabled) {
    if (isActive) {
      return 'active';
    }
    return isDisabled ? 'disabled' : '';
  }

  _loadPage(page, activeLink) {
    if (activeLink) {
      this.props.loadPage(page);
    }
  }

  renderPagniationLink(page, name, isDisabled = false, isActive = false, key = name) {
    const className = this.getPaginationLinkClassName(isActive, isDisabled);
    return (
      <a key={key} className={className} onClick={() => this._loadPage(page, !isActive && !isDisabled)}>
        {name}
      </a>
    );
  }

  getPageListStartPageNumber(activePage, numberOfPages, numberOfPagesToRender) {
    const idealStartPage = Math.max(activePage - Math.floor(numberOfPagesToRender / 2), 1);
    return idealStartPage + numberOfPagesToRender - 1 <= numberOfPages ?
      idealStartPage :
      Math.max(1, numberOfPages - numberOfPagesToRender + 1);
  }

  renderPageList(activePage, numberOfPages) {
    const pageListStartPageNumber = this.getPageListStartPageNumber(activePage, numberOfPages, 5);
    return List([0, 1, 2, 3, 4]).map(idx => {
      const pageNumber = pageListStartPageNumber + idx;
      return pageNumber <= numberOfPages &&
        this.renderPagniationLink(pageNumber, pageNumber, false, pageNumber === activePage);
    });
  }

  render () {
    const { collection, pageSize } = this.props;
    const { page, count, items } = collection.toObject();

    const loadedItemsCount = items ? items.count() : 0;
    const firstItemNumber = loadedItemsCount === 0 ? 0 : ((page - 1) * pageSize) + 1;
    const lastItemNumber = Math.max(firstItemNumber + Math.min(pageSize, loadedItemsCount) - 1, 0);

    const numberOfPages = Math.ceil(count / pageSize);
    const isFirstPage = page === 1;
    const isLastPage = numberOfPages === 0 || page === numberOfPages;

    return (
      <div className="list-pagination mt-1 clearfix">
        <div className="row">
          <div className="col-1-4 pagination-count">
            {t(
              'tables.showEntryCount',
              'Showing {firstItemNumber} to {lastItemNumber} of {count} entries.',
              { firstItemNumber, lastItemNumber, count}
            )}
          </div>
          <div className="col-3-4">
            <div className="list-pagination-controls">
              {this.renderPagniationLink(1, t('tables.first', '<< First'), isFirstPage)}
              {this.renderPagniationLink(page - 1, t('tables.previous', '< Previous'), isFirstPage)}
              {this.renderPageList(page, numberOfPages)}
              {this.renderPagniationLink(page + 1, t('tables.next', 'Next >'), isLastPage)}
              {this.renderPagniationLink(numberOfPages, t('tables.last', 'Last >>'), isLastPage)}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ListPagination.propTypes = {
  collection: ImmutablePropTypes.map.isRequired,
  loadPage: React.PropTypes.func.isRequired,
  pageSize: React.PropTypes.number.isRequired,
};

export default ListPagination;
