import React from 'react';

import { t } from '^/i18n';


const EmptyListPage = ({ reassignType, handleSubmit }) =>
 <div className="mb-1">
    {t('common.no', 'No')} {reassignType} {t('practices.toReassign', 'to reassign')}.

    <div className="mt-1 small-text">
      <button
        className="btn btn-default mt-1"
        onClick={() => handleSubmit()}
      >
        {t('common.next', 'Next')}...
      </button>
    </div>
  </div>;

EmptyListPage.propTypes = {
  handleSubmit: React.PropTypes.func.isRequired,
  reassignType: React.PropTypes.string.isRequired,
};

export default EmptyListPage;
