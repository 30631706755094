import React from 'react';
import PureComponent from '^/components/common/PureComponent';
import { isPending } from '^/consts/responseStates';
import { t } from '^/i18n';

export default class BlankOption extends PureComponent {
  render() {
    return (
      <option value="" disabled={this.props.disabled}>
        {
          isPending(this.props.response) ?
          `${t('common.loading', 'Loading')}...` :
          `${t('appContent.pleaseSelect', 'Please select')}...`
        }
      </option>
    );
  }
}
