import React from 'react';
import { connect } from 'react-redux';

import { t } from '^/i18n';
import { closeModal } from '^/actions/modals';
import { removeUploadedDocumentAndCloseModal } from '^/actions/actionSequences';

export const RemoveUploadedDocumentModal = ({fileId, activityTaskId, name, onDelete, onClose}) => {
  return (
    <div>
      <div className="row">
        <div className="col-1">
          <p>
            {t('templateDocument.deleteUploadedDocumentConfirmationMsg', 'Are you sure you wish to delete the uploaded document')}: {name}
          </p>
          <p>
            {t('templateDocument.removeFileFromListMsg', `Removing the file will only remove it from this list. The file will still remain connected to the activity that you uploaded the file to as an audit trail.`)}
          </p>
          <p>
            {t('templateDocument.removeFileFromListConfirmationMsg', 'Are you sure you wish to remove the file from this list?')}
          </p>
        </div>
      </div>
      <div className="row align-right mt-1">
        <div className="col-1">
          <button className="btn btn-default" onClick={onClose}>
            {t('common.button.cancel', 'Cancel')}
          </button>
          <button className="btn btn-warning ml-1" onClick={() => onDelete(activityTaskId, fileId)}>
            {t('common.confirm', 'Confirm')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default connect(null, {onDelete: removeUploadedDocumentAndCloseModal, onClose: closeModal}) (RemoveUploadedDocumentModal);
