import React from 'react';

import { t } from '^/i18n';

const TemplateDocumentTerms = ({ year }) => {
  return (
    <span className="alert alert-info x-small-text">
      <p>
        {t(
          'templateDocuments.termsOfUse',
          `Terms of use: Agilio Software is the registered trademark of Agilio Software BidCo Limited, registered at Unit 18, Jessops Riverside, 800 Brightside Lane, Sheffield, England, S9 2RX , Company number 12242288 and VAT number 344765282. CODEplan Limited is part of the Agilio Software Group and iComply is a product of the Group. The information in templates, modules and iComply is written in general terms and is believed to be based on the relevant legislation, regulations and good practice guidance. This information is indicative only and is intended as a guide for you to review and take particular professional advice to suit your circumstances. Agilio Software and CODEplan do not accept any liability for any loss or claim that may arise from reliance on information provided. The use of this document indicates acceptance of these terms.`
        )}{' '}
        &copy; CODEplan {year}
      </p>
    </span>
  );
};

export default TemplateDocumentTerms;
