import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';

import { t } from '^/i18n';
import { getDigitalRecordNavigationList } from '^/actions/actions';
import { isPending } from '^/consts/responseStates';
import CollapseButton from '^/components/app/content/CollapseButton';
import Loading from '^/components/app/content/Loading';
import Icon from '^/components/app/content/Icon';
import Collapse from '^/components/app/content/Collapse';
import PureComponent from '^/components/common/PureComponent';

export const RECORDS_PATHNAME = '/page/tools/records';
export const CHECKLIST_PATHNAME = `${RECORDS_PATHNAME}/checklist`;

export class CheckListSwitcher extends PureComponent {
  constructor() {
    super();
    this.state = {
      selectedUrl: '',
    };
    this.handleSelectChange = this.handleSelectChange.bind(this);
  }

  handleSelectChange(event) {
    this.setState({ selectedUrl: event.target.value });
  }
  componentDidMount() {
    this.props.getDigitalRecordNavigationList();
  }

  render() {
    const {
      digitalRecordsNavigationListResponse,
      digitalRecordsNavigationList,
    } = this.props;

    if (
      isPending(digitalRecordsNavigationListResponse) ||
      !digitalRecordsNavigationList
    ) {
      return <Loading />;
    }

    const renderChecklistRecordGroup = recordGroup => {
      const name = recordGroup.get('name');
      const id = recordGroup.get('id');
      const currentDateId = recordGroup.get('current_date_id');
      const url = `${CHECKLIST_PATHNAME}/${id}/${currentDateId}`;

      return (
        <option id={`id${id}`} key={`key${id}`} value={url}>
          {name}
        </option>
      );
    };

    const renderDigitalRecordType = digitalRecordType => {
      const name = digitalRecordType.get('name');
      const slug = digitalRecordType.get('slug');
      const url = `${RECORDS_PATHNAME}/${slug}`;

      return (
        <option id={`id${slug}`} value={url}>
          {name} {t('dashboard.records', 'records')}
        </option>
      );
    };

    const renderQuickCheckItem = quickCheckItem => {
      const name = quickCheckItem.get('name');
      const slug = quickCheckItem.get('slug');
      const type = quickCheckItem.get('item_type');
      const relatedType = quickCheckItem.get('related_digital_record_type');
      const relatedTypeSlug = relatedType ? relatedType.get('slug') : null;
      const relatedTypeUrl = relatedType
        ? `${RECORDS_PATHNAME}/${relatedTypeSlug}`
        : null;

      if (type === 'CHECKLIST_RECORD_TYPE') {
        return (
          <optgroup key={`key${slug}`} label={name}>
            {quickCheckItem
              .get('record_groups')
              .map(recordGroup => renderChecklistRecordGroup(recordGroup))}
            {relatedType && (
              <option
                id={`id${relatedTypeSlug}`}
                key={`key${relatedTypeSlug}`}
                value={relatedTypeUrl}
              >
                {relatedType.get('name')} {t('dashboard.records', 'records')}
              </option>
            )}
          </optgroup>
        );
      } else if (type === 'DIGITAL_RECORD_TYPE') {
        return renderDigitalRecordType(quickCheckItem);
      }
    };

    return (
      <span className="inline-link">
        <h4 className="m-toggle">
          <CollapseButton
            collapseId="mobile-tasks"
            initiallyCollapsed
            collapseOnMount
          >
            <Icon type="check-square-o" className="title" /> {t('dashboard.quickCheck', 'Quick Check')}:
          </CollapseButton>
        </h4>
        <Collapse collapseId="mobile-tasks" className="m-content">
          <select defaultValue="none" onChange={this.handleSelectChange}>
            <option>{t('dashboard.selectAnItem', 'Select an item')}</option>
            {digitalRecordsNavigationList.map(navItem => {
              return renderQuickCheckItem(navItem);
            })}
          </select>
          {this.state.selectedUrl && (
            <Link
              to={this.state.selectedUrl}
              className="btn btn-default centered"
            >
              {t('dashboard.button.start', 'START')}
            </Link>
          )}
        </Collapse>
      </span>
    );
  }
}

export function mapStateToProps(state) {
  const digitalRecordsNavigationListResponse = state.responses.get(
    'getDigitalRecordNavigationList'
  );

  return {
    digitalRecordsNavigationList: state.digitalRecordsNavigationList,
    digitalRecordsNavigationListResponse: digitalRecordsNavigationListResponse,
  };
}

export default connect(mapStateToProps, {
  getDigitalRecordNavigationList,
})(CheckListSwitcher);
