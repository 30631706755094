import React from 'react';
import { Map } from 'immutable';

import { t } from '^/i18n';
import { MOMENT_UTC_TODAY, MOMENT_UTC_A_YEAR_AGO } from '^/utils';
import { INSIGHTS, INSIGHTS_API_URLS, PATHS, DATABASE_MODEL_NAME } from '^/components/app/groups/insights/constants';
import { getSubGroupFilterFromPath } from '^/components/app/groups/insights/utils';
import withInsight from '^/components/app/groups/insights/hoc/withInsight';
import withLastUpdatedTimestampData from '^/components/app/groups/insights/hoc/withLastUpdatedTimestampData';
import LandingCard from '^/components/app/groups/insights/LandingCard';
import ActivitiesChart from '^/components/app/groups/insights/activities/chart/ActivitiesChart';

const ActivitiesLanding = ({data, hasFailedToLoad, lastUpdatedTimestamp, query}) => {
  return (
    <LandingCard
      title={t('groups.insights.activitiesTitle', 'Activities')}
      icon="list-ul"
      link={PATHS.ACTIVITIES}
      linkText={t('groups.insights.viewMore', 'VIEW MORE')}
      lastUpdatedTimestamp={lastUpdatedTimestamp}
      query={query}
    >
      <ActivitiesChart
        data={data}
        hasFailedToLoad={hasFailedToLoad}
      />
    </LandingCard>
  );
};

export { ActivitiesLanding };

const ActivitiesLandingWithLastUpdatedTimestamp = withLastUpdatedTimestampData(
  DATABASE_MODEL_NAME.ACTIVITY
)(ActivitiesLanding);

export default withInsight({
  insight: INSIGHTS.ACTIVITY_COMPLETION_RATE_LANDING,
  url: INSIGHTS_API_URLS.ACTIVITY_COMPLETION_RATE,
  getFilters: () => (
    Map({
      date_from: MOMENT_UTC_A_YEAR_AGO,
      date_to: MOMENT_UTC_TODAY,
      practice_sub_group_id: getSubGroupFilterFromPath(),
    })
  )
})(ActivitiesLandingWithLastUpdatedTimestamp);
