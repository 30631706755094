import React from 'react';
import { connect } from 'react-redux';

import { t } from '^/i18n';
import { closeModal } from '^/actions/modals';
import { updateWorkingDraftAndRedirect, updateWorkingDraftAndReload } from '^/actions/actionSequences';
import FormError from '^/components/app/forms/FormError';
import { hasFailed } from '^/consts/responseStates';


export const DocumentTakeOverModal = ({
  templateVersion, onClose, onSubmitUpdateWorkingDraftAndRedirect,
  onSubmitUpdateWorkingDraftAndReload, userCurrentlyEditing, to, response
}) => {

  const documentTakeOverFailed = hasFailed(response)

  const callBack = to ? onSubmitUpdateWorkingDraftAndRedirect : onSubmitUpdateWorkingDraftAndReload

  return (
    <div>
      <div className="row">
        <div className="col-1">
          <p>
            {userCurrentlyEditing} {t('templateDocument.documentTakeOverConfirmationMsg', 'is currently editing this document. Do you want to take over?')}
          </p>
        </div>
      </div>
      <div className="row align-right mt-1">
        <div className="col-1">
          <button className="btn btn-default" onClick={onClose}>
            {t('common.button.cancel', 'Cancel')}
          </button>
          <button className="btn btn-default ml-1" onClick={() => callBack(
            templateVersion.getIn(['working_version', 'id']),
            to
          )}>
            {t('templateDocument.takeOver', 'Take Over')}
          </button>
        </div>
      </div>
      { documentTakeOverFailed &&
      <div>
        <FormError response={response} key="non_field_errors" />
      </div>
      }
    </div>
  );
};


export const mapStateToProps = (state) => {
  return {
    response: state.responses.get('updateTemplateVersionFromWorkingDraft')
  };
};


export default connect(mapStateToProps, {
  onSubmitUpdateWorkingDraftAndRedirect: updateWorkingDraftAndRedirect,
  onSubmitUpdateWorkingDraftAndReload: updateWorkingDraftAndReload,
  onClose: closeModal,
}) (DocumentTakeOverModal);
