import React from 'react';
import { Link } from 'react-router';
import { connect } from 'react-redux';

import { t } from '^/i18n';
import Icon from '^/components/app/content/Icon';
import { toggleLoggedInAsDropdown } from '^/actions/actions';
import { selectTotalHRDashboardUrl } from '^/selectors/hr';
import { AGILO_ILEARN_URL } from '^/consts/hr';


export const LoggedInAs = ({
  userName,
  userType,
  logout,
  toggleDropdown,
  isDropdownOpen,
  hrUrl,
}) => (
  <div>
    <div onClick={toggleDropdown} className="nav-logged-in-as">
      <div className="username">
        {userName}
        <Icon
          type="caret-down"
          className="icon-position-right-small small-icon gray-icon"
        />
      </div>

      <div className="usertype">{userType}</div>

      <div
        className="dropdown"
        style={{ visibility: isDropdownOpen ? 'visible' : 'hidden' }}
      >
        <div>
          <Link to="/page/profile/">{t('common.account', 'My Account')}</Link>
        </div>

        <div>
          <a href={hrUrl} target="_blank">{t('common.agilioITeam', 'Agilio iTeam')}</a>
        </div>

        <div>
          <a href={AGILO_ILEARN_URL} target="_blank">{t('common.agilioILearn', 'Agilio iLearn')}</a>
        </div>

        <div>
          <a onClick={logout}>{t('common.button.logOut', 'Log out')}</a>
        </div>
      </div>
    </div>

    {isDropdownOpen && (
      <div onClick={toggleDropdown} className="nav-logged-in-as-outer" />
    )}
  </div>
);

export const mapStateToProps = state => ({
  isDropdownOpen: state.ui.get('loggedInAsDropdownOpen', false),
  hrUrl: selectTotalHRDashboardUrl(state),
});

export default connect(mapStateToProps, {
  toggleDropdown: toggleLoggedInAsDropdown
})(LoggedInAs);
