import React from 'react';
import { Map } from 'immutable';

import { t } from '^/i18n';
import { formatDateTime, MOMENT_UTC_TODAY, MOMENT_UTC_A_YEAR_AGO } from '^/utils';
import { getTimePeriodLabel, getSubGroupFilterFromPath } from '^/components/app/groups/insights/utils';
import DetailsCard from '^/components/app/groups/insights/DetailsCard';
import {
  activitiesInsightsConfig,
  FILTER_KEYS,
  INSIGHTS,
  INSIGHTS_API_URLS,
  DATABASE_MODEL_NAME
} from '^/components/app/groups/insights/constants';
import withInsight from '^/components/app/groups/insights/hoc/withInsight';
import withSelectedFilters from '^/components/app/groups/insights/hoc/withSelectedFilters';
import withLastUpdatedTimestamp from '^/components/app/groups/insights/hoc/withLastUpdatedTimestamp';
import ActivitiesChart from '^/components/app/groups/insights/activities/chart/ActivitiesChart';
import DateRangeFilter from '^/components/app/groups/insights/filters/DateRangeFilter';
import ActivityTemplateFilter from '^/components/app/groups/insights/filters/ActivityTemplateFilter';

const INSIGHT = INSIGHTS.ACTIVITY_COMPLETION_RATE;

const TYPES_TO_TITLES = Map({
  'AUDIT': t('common.audits', 'Audits'),
  'ASSESSMENT': t('common.assessments', 'Risk Assessments'),
  'COMPLIANCE_REVIEW': t('common.complianceReviews', 'Compliance Reviews'),
  'PRACTICE_MEETING': t('common.practiceMeetings', 'Practice Meetings'),
});

const ActivitiesDetailCard = ({type, dateFrom, dateTo, cycleIndex, data, hasFailedToLoad, lastUpdatedTimestamp}) => {
  return (
    <DetailsCard>
      <div className="row">
        <div className="col-1-2 col-md-1 print-full-width">
          <h3>
            {TYPES_TO_TITLES.get(type, t('groups.insights.allActivities', 'All Activities'))}
            {dateFrom && dateTo && (
              <DateRangeFilter
                insightsConfig={activitiesInsightsConfig}
                dateFrom={dateFrom}
                dateTo={dateTo}
              />
            )}
          </h3>
          { lastUpdatedTimestamp &&
            <p className="insights-timestamp">{t('groups.insights.updated', 'Updated')}: {formatDateTime(lastUpdatedTimestamp)}</p>
          }
          <p className="caption mt-1">
            {t('groups.insights.activitiesStatusMsg', 'Activities completed/in-progress/overdue')} {getTimePeriodLabel(dateFrom, dateTo, cycleIndex)}.
          </p>
          <p className="caption mt-1">
            <ActivityTemplateFilter
              insightsConfig={activitiesInsightsConfig}
              filterKey={FILTER_KEYS.TEMPLATE_ACTIVITY}
            />
          </p>
        </div>
        <div className="col-1-2 col-md-1 print-full-width">
          <ActivitiesChart
            data={data}
            hasFailedToLoad={hasFailedToLoad}
          />
        </div>
      </div>
    </DetailsCard>
  );
};

export { ActivitiesDetailCard };

const ActivitiesDetailCardWithLastUpdatedTimestamp = withLastUpdatedTimestamp(
  DATABASE_MODEL_NAME.ACTIVITY
)(ActivitiesDetailCard);

const ActivitiesDetailCardWithLastUpdatedTimestampAndSelectedFilters = withSelectedFilters(
  INSIGHT
)(ActivitiesDetailCardWithLastUpdatedTimestamp);

export default withInsight({
  insight: INSIGHT,
  url: INSIGHTS_API_URLS.ACTIVITY_COMPLETION_RATE,
  getFilters: () => (
    Map({
      date_from: MOMENT_UTC_A_YEAR_AGO,
      date_to: MOMENT_UTC_TODAY,
      practice_sub_group_id: getSubGroupFilterFromPath(),
    })
  )
})(ActivitiesDetailCardWithLastUpdatedTimestampAndSelectedFilters);
