import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';

import { t } from '^/i18n';
import { loadPracticesAndGetUserInfo } from '^/actions/actionSequences';

import LiveButton from '^/components/app/forms/LiveButton';
import HelpBlock from '^/components/app/widgets/HelpBlock';


export const AddPracticePaymentPage = ({refreshPracticeListAndUser, response}) =>
  <div className="col-1 mt-1">
    <h1>{t('practices.addNewPractice', 'Add a new practice')}</h1>
    <hr />

    <HelpBlock>
      {/* TODO: edge-case detected */}
      {t('practices.contactAgilioTeam', 'Please contact a member of the Agilio team')} <a href="mailto:icomply@agiliosoftware.com" className="underlined">
        icomply@agiliosoftware.com</a> or on 0330 165 9712 to set up membership details and payment.
    </HelpBlock>

    <p className="mt-2 mb-2">
      {t('practices.continueAccountSetupMsg', 'Once you have done so, click below to continue with your account setup.')}
    </p>

    <Link
      className="btn btn-default inline mr-1"
      to="/page/profile/my-practices"
    >
      {t('common.button.cancel', 'Cancel')}
    </Link>
    <LiveButton
      className="btn-primary inline"
      onClick={refreshPracticeListAndUser}
      response={response}
      pendingMessage="Checking for new practices..."
    >
      {t('practices.paymentConfirmationMsg', 'Confirm Payment and Setup Practice')}
     </LiveButton>
  </div>
;

export const mapStateToProps = (state) => ({
  response: state.responses.get('getUserInfo'),
});

export default connect(
  mapStateToProps,
  {refreshPracticeListAndUser: loadPracticesAndGetUserInfo}
) (AddPracticePaymentPage);
