import React from 'react';
import { reduxForm } from 'redux-form';
import moment from 'moment';

import { t } from '^/i18n';
import LiveButton from '^/components/app/forms/LiveButton';
import FormError from '^/components/app/forms/FormError';
import DateInput from '^/components/app/forms/DateInput';

export const ActivityDatePopupForm = ({ fields: { end_date }, handleSubmit, response }) =>
  <form onSubmit={handleSubmit}>
    <DateInput id="end_date" minDate={moment()} showYearDropdown={false} {...end_date} />
    <FormError response={response} formKey="end_date" />
    <FormError error={end_date && end_date.touched && end_date.error} />

    <LiveButton
      className="btn-default mt-1"
      response={response}
    >
      {t('activities.updateDueDate', 'Update due date')}
    </LiveButton>
  </form>
;

export function validateData(data) {
  if (!data.end_date || data.end_date === '') {
    return { end_date: t('activities.provideDueDateMsg', 'due date must be provided') };
  }
  return {};
}

export default reduxForm({
  fields: ['end_date'],
  validate: validateData
})(ActivityDatePopupForm);
