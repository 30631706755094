import React from 'react';
import { Link } from 'react-router';
import classNames from 'classnames';

import { t } from '^/i18n';

export const ViewSwitcher = ({isOnGroupAdminPage}) =>
  <div className="view-switcher">
    <Link
      to="/page/dashboard/"
      className={classNames('pill text-capitalize', isOnGroupAdminPage ? '' : 'active')}
    >
      {t('common.practices', 'practices')}
    </Link>
    <Link
      to="/page/groups/"
      className={classNames('pill text-capitalize', isOnGroupAdminPage ? 'active' : '')}
    >
      {t('common.groups', 'groups')}
    </Link>
  </div>
;

ViewSwitcher.propTypes = {
  isOnGroupAdminPage: React.PropTypes.bool.isRequired,
};

export default ViewSwitcher;
