import React from 'react';
import { reduxForm } from 'redux-form';
import { Map } from 'immutable';

import { t } from '^/i18n';
import LiveButton from '^/components/app/forms/LiveButton';

import { ActivityPriority } from '^/components/app/activities/ActivityPriority';

export const SECURITY_AUDITS_GROUP_CONFIG_FORM = 'SECURITY_AUDITS_GROUP_CONFIG_FORM';

export const TemplateAuditFields = ({
  fields,
  optionalAudits,
  mandatoryAudits
}) => {
  return (
    <div className="mt-1 small-text pl-1 pr-1">
      <div className="row">
        <div className="col-1-2">
          <h5>{t('groups.compulsoryAudits', 'Compulsory Audits')} ({mandatoryAudits.size})</h5>
            {mandatoryAudits.size > 0 ? (
                <table className="setup-compliance-review-table selectable">
                  <tbody>
                    {mandatoryAudits.map(each => (
                      <tr key={each.get('id')}>
                        <td>
                          <input type="checkbox" {...fields[each.get('id')]} id={each.get('id')} />
                        </td>
                        <td className="table-col-lg">
                          <label title={each.get('help_text')} htmlFor={each.get('id')}>
                            {each.get('name')}
                          </label>
                        </td>
                        <td>
                          <ActivityPriority activity={each}/>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
            ) : <p>{t('groups.noCompulsoryAuditsMsg', 'There are no compulsory audits for this practice group')}</p>
          }
        </div>
        <div className="col-1-2">
            <h5>{t('groups.optionalAudits', 'Optional Audits')} ({optionalAudits.size})</h5>
            {optionalAudits.size > 0 ? (
                <table className="setup-compliance-review-table selectable">
                  <tbody>
                    {optionalAudits.map(each => (
                      <tr key={each.get('id')}>
                        <td>
                          <input type="checkbox" {...fields[each.get('id')]} id={each.get('id')} />
                        </td>
                        <td className="table-col-lg">
                          <label title={each.get('help_text')} htmlFor={each.get('id')}>
                            {each.get('name')}
                          </label>
                        </td>
                        <td>
                          <ActivityPriority activity={each}/>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : <p>{t('groups.noOptionalAuditsMsg', 'There are no optional audits for this practice group')}</p>
            }
        </div>
      </div>
    </div>
  );
};

export const AuditsGroupConfigForm = ({
  handleSubmit,
  fields,
  templateAudits,
  response,
}) => {
  const optionalAudits = templateAudits
    .map((templateAudit) => templateAudit.get('template_activity'))
    .filter((templateActivity) => templateActivity.get('is_optional'));

  const mandatoryAudits = templateAudits
    .map((templateAudit) => templateAudit.get('template_activity'))
    .filter((templateActivity) => !templateActivity.get('is_optional'));

  return (
    <form onSubmit={handleSubmit}>
      <hr className="thin" />
      <div className="mb-2">
        <TemplateAuditFields
          fields={fields}
          optionalAudits={optionalAudits}
          mandatoryAudits={mandatoryAudits}
        />
      </div>
  
      <LiveButton className="btn-default" pendingMessage={`${t('common.saving', 'Saving')}...`} response={response}>
        {t('common.button.save', 'Save')}
      </LiveButton>
    </form>
  );
};


export const getFields = (templateAudits) => templateAudits
  .map(templateAudit => templateAudit.getIn(['template_activity', 'id']))
  .flatten()
  .toJS();

export const getInitialValues = (templateAudits) =>
  Map(
    templateAudits
      .map(
      templateAudit =>
        [
          templateAudit.getIn(['template_activity', 'id']),
          !templateAudit.getIn(['template_activity', 'is_excluded'])
        ]
    )
  .flatten())
  .toJS();

export const mapStateToProps = (state, props) => {
  return {
    fields: getFields(props.templateAudits),
    initialValues: getInitialValues(props.templateAudits),
  };
};

export default reduxForm({
  form: SECURITY_AUDITS_GROUP_CONFIG_FORM,
}, mapStateToProps)(AuditsGroupConfigForm);
