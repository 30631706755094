import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { t } from '^/i18n';
import { setSelectedEvent } from '^/actions/actions';
import { DOCUMENT_REVIEW_STATUSES, documentReviewIsOverdue, isOverdue, isComplete } from '^/models/activities';
import { REPEAT_CHOICES } from '^/models/task';
import ActivityTypeCapsule from '^/components/app/content/ActivityTypeCapsule';
import Capsule from '^/components/app/content/Capsule';
import Icon from '^/components/app/content/Icon';

export const CALENDAR_EVENT_TYPE = {
  ACTIVITY: 'ACTIVITY',
  TASK: 'TASK',
  DOCUMENT_REVIEW: 'DOCUMENT_REVIEW',
};

function getTaskClassNames(event) {
  return classNames('task', {'task-outstanding': isOverdue(event), 'task-complete': isComplete(event)});
}

const ActivityCalendarEvent = ({activity, onClick}) =>
  <div className="calendar-event">
    <a onClick={onClick}>
      <ActivityTypeCapsule type={activity.get('type')} />
      <span
        className={getTaskClassNames(activity)}
        title={activity.get('help_text')}
      >
        {activity.get('title')}
      </span>
    </a>
  </div>
;

const TaskCalendarEvent = ({ task, onClick }) => {
  const isGroupTask = task.get('group_task_id') !== null

  return (
    <div className="calendar-event">
      <a onClick={onClick}>
        <Capsule
          className={
            isGroupTask
              ? 'capsule-group-task'
              : 'capsule-task'
          }
        />
        <span className={getTaskClassNames(task)}>
          {task.get('title')}&nbsp;
          {task.get('repeat') && task.get('repeat') !== REPEAT_CHOICES.NONE && (
            <Icon
              type="repeat"
              className="repeat-icon inline small-icon gray-icon"
              title={t('common.repeatingTask', 'repeating task')}
            />
          )}
        </span>
      </a>
    </div>
  );
};

const DocumentReviewCalendarEvent = ({documentReview, onClick}) =>
  <div className="calendar-event">
    <a onClick={onClick}>
      <Capsule className="capsule-document-review" />
      <span className={classNames(
        'task',
        {
          'task-outstanding': documentReviewIsOverdue(documentReview),
          'task-complete': documentReview.get('status') === DOCUMENT_REVIEW_STATUSES.COMPLETED
        }
      )}>
        {documentReview.get('adopted_template_document_name')}&nbsp;
      </span>
    </a>
  </div>
;

export class CalendarEvent extends React.Component {

  onClick(type, object, evt) {
    this.props.setSelectedEvent(type, object, evt.pageX, evt.pageY);
  }

  render() {
    const { event: { type, activity, task, documentReview } } = this.props;
    switch (type) {
      case CALENDAR_EVENT_TYPE.ACTIVITY:
        return <ActivityCalendarEvent
          activity={activity}
          onClick={this.onClick.bind(this, CALENDAR_EVENT_TYPE.ACTIVITY, activity)}
        />
      case CALENDAR_EVENT_TYPE.TASK:
        return <TaskCalendarEvent
          task={task}
          onClick={this.onClick.bind(this, CALENDAR_EVENT_TYPE.TASK, task)}
        />;
      case CALENDAR_EVENT_TYPE.DOCUMENT_REVIEW:
        return <DocumentReviewCalendarEvent
          documentReview={documentReview}
          onClick={this.onClick.bind(this, CALENDAR_EVENT_TYPE.DOCUMENT_REVIEW, documentReview)}
        />;
      default:
        return  <p>Error: invalid activity type</p>;
    }
  }
}

CalendarEvent.propTypes = {
  event: React.PropTypes.object.isRequired,
};

export default connect(null, { setSelectedEvent }) (CalendarEvent);
