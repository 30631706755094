import { fromJS } from 'immutable';
import classNames from 'classnames';
import { t } from '^/i18n';

const STATUS_COLOR_MAP = {
  'Expired': 'drug-expired',
  'Active': 'drug-active',
  'Expiring soon': 'drug-expiring-soon',
}

const expiryDateStyleHandler = (record) => {
  const status = STATUS_COLOR_MAP[record.get('status')];
  return classNames(`${status}`);
}

const statusStyleHandler = (record) => {
  const status = STATUS_COLOR_MAP[record.get('status')];
  return classNames(`${status}`, 'bold-text');
}

const actionDisplayHandler = (record) => {
  return record.get('is_archived') ? t('common.button.view', 'View') : t('common.edit', 'Edit');
}

export const RECORD_FIELDS = {
    EMERGENCY_DRUGS_AND_EQUIPMENT: fromJS([
    {name: ['emergency_drugs_and_equipment','product'], display_name: t('common.product', 'Product'), type: 'string', sortable: true},
    {name: ['emergency_drugs_and_equipment','type'], display_name: t('common.type', 'Type'), type: 'string', sortable: true},
    {
      name: ['emergency_drugs_and_equipment','expiry_date'],
      display_name: t('digitalTools.records.expiryDate', 'Expiry date'),
      type: 'string',
      sortable: true,
      styleHandler: expiryDateStyleHandler,
    },
    {
      name: ['status'],
      display_name: t('common.status', 'Status'),
      type: 'string',
      sortable: true,
      styleHandler: statusStyleHandler,
    },
    {
      display_name: t('common.action', 'Action'),
      type: 'detail',
      displayHandler: actionDisplayHandler,
      route: '/page/tools/records/${type_url}/${id}/',
    },
  ]),

  FIRE_DRILL: fromJS([
    {name: ['fire_drill','completed_date'], display_name: t('common.date', 'Date'), type: 'string', sortable: true},
    {name: ['responsible_staff_name'], display_name: t('digitalTools.records.teamMemberInCharge','Team Member in Charge'), type: 'string', sortable: true},
    {name: ['open_actions'], display_name: t('common.completedActions', 'Completed Actions'), type: 'string', sortable: true},
    {
      display_name: t('common.action', 'Action'),
      type: 'detail',
      displayHandler: actionDisplayHandler,
      route: '/page/tools/records/${type_url}/${id}/',
    },
  ]),

  EVENT: fromJS([
    {name: ['event', 'reference'], display_name: t('digitalTools.records.refNumber', 'Ref Number'), type: 'string', sortable: true},
    {name: ['sub_type'], display_name: t('digitalTools.forms.label.typeofEvent', 'Type of Event'), type: 'string', sortable: true},
    {name: ['event', 'event_date'], display_name: t('digitalTools.forms.label.dateOfEvent', 'Date of Event'), type: 'string', sortable: true},
    {name: ['responsible_staff_name'], display_name: t('digitalTools.records.leadTeamMember', 'Lead Team Member'), type: 'string', sortable: true},
    {name: ['open_actions'], display_name: t('common.completedActions', 'Completed Actions'), type: 'string', sortable: true},
    {name: ['status'], display_name: t('common.status', 'Status'), type: 'string', sortable: true},
    {
      display_name: t('common.action', 'Action'),
      type: 'detail',
      displayHandler: actionDisplayHandler,
      route: '/page/tools/records/${type_url}/${id}/',
    },
  ]),
  COSHH: fromJS([
    {name: ['coshh', 'type'], display_name: t('common.productType', 'Product Type'), type: 'string', sortable: true},
    {name: ['coshh', 'product_name'], display_name: t('common.productName', 'Product Name'), type: 'string', sortable: true},
    {name: ['coshh', 'used_by'], display_name: t('common.usedBy', 'Used by'), type: 'string', sortable: true},
    {name: ['responsible_staff_name'], display_name: t('digitalTools.records.assignedTo', 'Assigned to'), type: 'string', sortable: true},
    {name: ['status'], display_name: t('common.status', 'Status'), type: 'string', sortable: true},
    {
      display_name: t('common.action', 'Action'),
      type: 'detail',
      displayHandler: actionDisplayHandler,
      route: '/page/tools/records/${type_url}/${id}/',
    },
  ]),
}
