import React from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { Map, Set } from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';

import { t } from '^/i18n';
import {
  REMINDER_CHOICES_TEXT,
  getRepeatOptions,
  toOptionValue,
} from '^/models/task';

import { GROUP_TASKS } from '^/consts/collectionKeys';
import { archiveGroupTask } from '^/actions/actionSequences';

import PureComponent from '^/components/common/PureComponent';
import FormError from '^/components/app/forms/FormError';
import LiveButton from '^/components/app/forms/LiveButton';
import DateInput from '^/components/app/forms/DateInput';
import ManyRelatedSelect from '^/components/app/forms/ManyRelatedSelect';
import MultipleFileUpload from '^/components/app/forms/MultipleFileUpload';
import DemoModeMayDisallow from '^/components/app/perms/DemoModeMayDisallow';
import DemoModeDisable from '^/components/app/perms/DemoModeDisable';
import CheckBoxGroupForGroupPractices from '^/components/app/widgets/CheckBoxGroupForGroupPractices';

export const CREATE_GROUP_TASK_FORM = 'CREATE_GROUP_TASK_FORM';

export class CreateEditGroupTasksForm extends PureComponent {
  constructor(props) {
    super(props);
    this.handleArchive = this.handleArchive.bind(this);
    this.handleApplyToLiveCyclesCheckedBox = this.handleApplyToLiveCyclesCheckedBox.bind(
      this
    );
    this.state = { showApplyToLiveCycles: false };
  }

  handleArchive(event) {
    event.preventDefault();
    this.props.archiveGroupTask(GROUP_TASKS, this.props.groupTaskId, {
      is_archived: true,
    });
  }

  handleApplyToLiveCyclesCheckedBox() {
    this.setState(
      { showApplyToLiveCycles: !this.state.showApplyToLiveCycles },
      () => {
        if (!this.state.showApplyToLiveCycles) {
          this.props.fields.practices_to_apply.onChange([]);
          this.props.fields.deadline.onChange(null);
          this.props.fields.reminder.onChange(null);
        }
      }
    );
  }

  render() {
    const {
      fields,
      handleSubmit,
      response,
      values,
      templateDocuments,
      uploadFileResponse,
      archiveResponse,
      responsibilities,
      isManage,
      pollingError,
      groupPractices,
    } = this.props;

    const {
      title,
      description,
      deadline,
      repeatOption,
      related_templates,
      related_files,
      responsibility,
      apply_to_live_cycles,
      apply_existing_to_existing_cycles,
      is_locked_at_practice_level,
      reminder,
      related_practices,
      practices_to_apply,
    } = fields;

    const relatedTemplateSet =
      values && values.related_templates
        ? Set(values.related_templates)
        : Set();

    const ALL_PRACTICE_OPTIONS =
      groupPractices &&
      groupPractices
        .map(practice => {
          return {
            label: practice.get('name'),
            value: practice.get('id'),
            disableOption: isManage
              ? !practice.get('can_apply_group_task')
              : false,
          };
        })
        .toJS();

    return (
      <form onSubmit={handleSubmit}>
        <FormError response={response} error={pollingError} />
        {isManage && (
          <div className="form-group">
            <div className="col-1-3">
              <label htmlFor="apply_existing_to_existing_cycles">
                {t('groups.label.applyTaskToExistingCycles', 'Apply this task to existing practice cycles')}
              </label>
            </div>
            <div className="col-2-3">
              <input
                id="apply_existing_to_existing_cycles"
                type="checkbox"
                {...apply_existing_to_existing_cycles}
              />
            </div>
            <div className="col-2-3">
              <FormError
                error={
                  apply_existing_to_existing_cycles &&
                  apply_existing_to_existing_cycles.touched &&
                  apply_existing_to_existing_cycles.error
                }
                formKey="apply_existing_to_existing_cycles"
              />
              <FormError response={response} formKey="repeat" />
              <FormError response={response} formKey="repeat_interval" />
            </div>
          </div>
        )}
        {apply_existing_to_existing_cycles.checked && (
          <div className="form-group">
            <CheckBoxGroupForGroupPractices
              field={related_practices}
              response={response}
              options={ALL_PRACTICE_OPTIONS}
              selectAllID={'related_practices'}
            />
          </div>
        )}
        {apply_existing_to_existing_cycles.checked && (
          <div className="form-group">
            <div className="col-1-3">
              <label htmlFor="deadline">{t('groups.label.due', 'Due')} *</label>
            </div>
            <div className="col-2-3">
              <DateInput id="deadline" {...deadline} />
              <FormError
                error={deadline && deadline.touched && deadline.error}
                formKey="deadline"
              />
            </div>
          </div>
        )}
        {apply_existing_to_existing_cycles.checked && (
          <div className="form-group">
            <div className="col-1-3">
              <label htmlFor="reminder">{t('groups.label.reminder', 'Reminder')}</label>
            </div>
            <div className="col-2-3">
              <select {...reminder} id="reminder">
                {Map(REMINDER_CHOICES_TEXT).map((choiceText, choice) => (
                  <option key={choice} value={choice}>
                    {choiceText}
                  </option>
                ))}
              </select>
              <FormError response={response} formKey="reminder" />
            </div>
          </div>
        )}
        <div className="form-group">
          <div className="col-1-3">
            <label htmlFor="title">{t('groups.label.title', 'Title')}{isManage && ' *'} </label>
          </div>
          <div className="col-2-3">
            <input
              type="text"
              placeholder={t('groups.label.title', 'Title')}
              className="form-control"
              id="title"
              {...title}
            />
            <FormError response={response} formKey="title" />
            <FormError error={title && title.touched && title.error} />
          </div>
        </div>

        <div className="form-group">
          <div className="col-1-3">
            <label htmlFor="description">{t('groups.label.description', 'Description')}{isManage && ' *'} </label>
          </div>
          <div className="col-2-3">
            <textarea
              id="description"
              value={description.value || ''}
              {...description}
            />
            <FormError response={response} formKey="description" />
          </div>
        </div>

        <div className="form-group">
          <div className="col-1-3">
            <label htmlFor="responsibility">{t('groups.label.assignedResponsibility', 'Assigned Responsibility')}</label>
          </div>
          <div className="col-2-3">
            <select
              defaultValue={0}
              id="responsibility"
              className="form-control-margin"
              {...responsibility}
            >
              <option value={0}>{t('groups.label.chooseResponsibility', 'Choose a responsibility')}...</option>
              {responsibilities.map(responsibilityOption => (
                <option
                  key={responsibilityOption.get('id')}
                  value={responsibilityOption.get('id')}
                >
                  {responsibilityOption.get('name')}
                </option>
              ))}
            </select>
            <FormError response={response} formKey="responsibility" />
            <FormError
              error={
                responsibility && responsibility.touched && responsibility.error
              }
            />
          </div>
        </div>

        <div className="form-group">
          <div className="col-1-3">
            <label htmlFor="assignee">{t('groups.label.attachDocuments', 'Attach Documents')}</label>
          </div>
          <div className="col-2-3">
            <div className="row">
              <div className="col-1">
                <p>{t('groups.uploadYourOwnDocument', 'Upload your own document')}</p>
                <DemoModeDisable message={t('groups.cannotAttachFiles', 'cannot attach files')}>
                  <MultipleFileUpload
                    handleSubmit={handleSubmit}
                    field={related_files}
                    uploadFile={this.props.uploadFile}
                    uploadFileResponse={uploadFileResponse}
                    formName={CREATE_GROUP_TASK_FORM}
                  />
                </DemoModeDisable>
                <hr className="thin" />
              </div>
              <div className="col-1">
                <p>{t('groups.selectFromiComply', 'Or select one from iComply')}</p>
                <ManyRelatedSelect
                  selectedItems={templateDocuments.filter(each =>
                    relatedTemplateSet.contains(each.get('id'))
                  )}
                  items={templateDocuments}
                  onAdd={id =>
                    related_templates.onChange(
                      relatedTemplateSet.add(id).toArray()
                    )
                  }
                  onRemove={id =>
                    related_templates.onChange(
                      relatedTemplateSet.delete(id).toArray()
                    )
                  }
                  controlName="related-templates"
                  displayKey={['name']}
                />
                <FormError response={response} formKey="related_templates" />
              </div>
            </div>
          </div>
        </div>

        <div className="form-group">
          <div className="col-1-3">
            <label htmlFor="repeats">{t('groups.label.recurrence', 'Recurrence')}</label>
          </div>
          <div className="col-2-3">
            <select {...repeatOption} id="repeats">
              {getRepeatOptions(Map({ deadline: deadline.value })).map(
                option => (
                  <option key={option} value={toOptionValue(option)}>
                    {option.get('text')}
                  </option>
                )
              )}
            </select>
            <FormError response={response} formKey="repeat" />
            <FormError response={response} formKey="repeat_interval" />
          </div>
        </div>

        {!isManage && (
          <div className="form-group">
            <div className="col-1-3">
              <label htmlFor="apply_to_live_cycles">{t('groups.label.applyToLiveCycles', 'Apply to live cycles')}</label>
            </div>
            <div className="col-2-3">
              <input
                id="apply_to_live_cycles"
                type="checkbox"
                {...apply_to_live_cycles}
                checked={this.state.showApplyToLiveCycles}
                onChange={this.handleApplyToLiveCyclesCheckedBox}
              />
            </div>
            <div className="col-2-3">
              <FormError
                error={
                  apply_to_live_cycles &&
                  apply_to_live_cycles.touched &&
                  apply_to_live_cycles.error
                }
                formKey="apply_existing_to_existing_cycles"
              />
              <FormError response={response} formKey="repeat" />
              <FormError response={response} formKey="repeat_interval" />
            </div>
          </div>
        )}

        {this.state.showApplyToLiveCycles && (
          <div className="form-group">
            <CheckBoxGroupForGroupPractices
              field={practices_to_apply}
              response={response}
              options={ALL_PRACTICE_OPTIONS}
              selectAllID={'practices_to_apply'}
            />
          </div>
        )}

        {this.state.showApplyToLiveCycles && (
          <div className="form-group">
            <div className="col-1-3">
              <label htmlFor="deadline">{t('groups.label.due', 'Due')} *</label>
            </div>
            <div className="col-2-3">
              <DateInput id="deadline" {...deadline} />
              <FormError
                error={deadline && deadline.touched && deadline.error}
                formKey="deadline"
              />
            </div>
          </div>
        )}

        {this.state.showApplyToLiveCycles && (
          <div className="form-group">
            <div className="col-1-3">
              <label htmlFor="reminder">{t('groups.label.reminder', 'Reminder')}</label>
            </div>
            <div className="col-2-3">
              <select {...reminder} id="reminder">
                {Map(REMINDER_CHOICES_TEXT).map((choiceText, choice) => (
                  <option key={choice} value={choice}>
                    {choiceText}
                  </option>
                ))}
              </select>
              <FormError response={response} formKey="reminder" />
            </div>
          </div>
        )}
        <div className="form-group">
          <div className="col-1-3">
            <label htmlFor="is_locked_at_practice_level">
              {t('groups.lockTaskAtPracticeLevel', 'Lock Task at Practice Level?')}
            </label>
          </div>
          <div className="col-2-3">
            <input
              id="is_locked_at_practice_level"
              type="checkbox"
              {...is_locked_at_practice_level}
            />
          </div>
          <div className="col-2-3">
            <FormError
              response={response}
              formKey="is_locked_at_practice_level"
            />
          </div>
        </div>

        {isManage && (
          <div className="mt-1 mb-1 text-center alert-warning">
            {t('groups.tasksUpdateWarningMsg', 'If you update this Group Task it will update all future Practice Tasks!')}
          </div>
        )}

        <div className="form-group">
          <div className="col-1">
            <DemoModeMayDisallow
              message="can create a maximum of five tasks"
              response={response}
            >
              <LiveButton
                pendingMessage={isManage ? `${t('common.editing', 'Editing')}...` : `${t('common.creating', 'Creating')}...`}
C              response={response}
                className="btn-default pull-left"
              >
                {isManage ? t('common.button.save', 'Save') : t('common.button.create', 'Create')}
              </LiveButton>
              {isManage && (
                <LiveButton
                  pendingMessage={`${t('common.archiving', 'Archiving')}...`}
                  response={archiveResponse}
                  className="btn-warning pull-right"
                  onClick={this.handleArchive}
                >
                  {t('common.button.archive', 'Archive')}
                </LiveButton>
              )}
            </DemoModeMayDisallow>
          </div>
        </div>
      </form>
    );
  }
}

export function validate(values) {
  const {
    title,
    responsibility,
    apply_to_live_cycles,
    apply_existing_to_existing_cycles,
    deadline,
    related_practices,
    practices_to_apply,
  } = values;
  const errors = {};

  if (!title) {
    errors.title = t('groups.titleRequired', 'Title is required.');
  }
  if (!responsibility) {
    errors.responsibility = t('groups.responsibilityRequired', 'Assign a responsibility.');
  }

  if (
    (apply_to_live_cycles || apply_existing_to_existing_cycles) &&
    !deadline
  ) {
    errors.deadline = t('groups.deadlineRequired', 'You must provide a deadline.');
  }

  if (
    apply_to_live_cycles &&
    (!practices_to_apply || !practices_to_apply.length)
  ) {
    errors.apply_to_live_cycles =
      t('groups.practiceRequiredForLiveCycles', 'You must select a practice if you tick Apply to Live Cycles');
  }

  if (
    apply_existing_to_existing_cycles &&
    (!related_practices || !related_practices.length)
  ) {
    errors.apply_existing_to_existing_cycles =
      t('groups.practiceRequredForExistingCycles', 'You must select a practice if you tick Apply to this task to existing practice cycles');
  }

  return errors;
}

CreateEditGroupTasksForm.propTypes = {
  templateDocuments: ImmutablePropTypes.iterable.isRequired,
  responsibilities: ImmutablePropTypes.iterable.isRequired,
  isManage: React.PropTypes.bool,
};

export const FormifiedCreateEditGroupTasksForm = reduxForm({
  form: CREATE_GROUP_TASK_FORM,
  fields: [
    'title',
    'description',
    'deadline',
    'repeatOption',
    'related_templates',
    'related_files',
    'responsibility',
    'apply_to_live_cycles',
    'apply_existing_to_existing_cycles',
    'reminder',
    'is_locked_at_practice_level',
    'practices_to_apply',
    'related_practices',
  ],
  validate,
})(CreateEditGroupTasksForm);

export default connect(null, {
  archiveGroupTask,
})(FormifiedCreateEditGroupTasksForm);
