import React from 'react';
import { Map } from 'immutable';
import { connect } from 'react-redux';

import { t } from '^/i18n';
import BatteryBar from '^/components/app/groups/insights/visualisations/BatteryBar';
import Table from '^/components/app/groups/insights/visualisations/Table';
import TableFooter from '^/components/app/groups/insights/visualisations/TableFooter';
import { changePracticeAndNavToDashboard } from '^/actions/actionSequences';
import withLoadInsightDataOnMount from '^/components/app/groups/insights/hoc/withLoadInsightDataOnMount';
import {
  INSIGHTS,
  INSIGHTS_API_URLS,
  INSIGHTS_CSV_API_URLS,
  GROUP_DOCUMENTS_ADOPTED_THRESHOLDS
} from '^/components/app/groups/insights/constants';
import { getSubGroupFilterFromPath } from '^/components/app/groups/insights/utils';

const INSIGHT = INSIGHTS.GROUP_DOCUMENTS_ADOPTED_BY_PRACTICE;

const COLUMNS = (onClick) => [
  {
    key: 'practice_name',
    header: t('groups.practice', 'Practice'),
    className: 'text-align-left',
    cell: ({ row }) =>
      <a onClick={() => onClick(row.get('practice_id'))}>
        {row.get('practice_name')}
      </a>
  },
  {
    key: 'adoption_rate',
    header: t('groups.insights.adoptedDocuments', 'Adopted Documents'),
    className: 'text-align-right',
    cell: ({ row }) =>
      <div className="insights-documents-table-adopted-documents-wrapper">
        <span className="insights-documents-table-adopted-documents">
          {`${row.get('practice_adoption_count')} / ${row.get('group_adoption_count')}`}
        </span>
        <BatteryBar
          rate={row.get('adoption_rate')}
          height={8}
          borderRadius={20}
          thresholdBad={GROUP_DOCUMENTS_ADOPTED_THRESHOLDS.BAD}
          thresholdOk={GROUP_DOCUMENTS_ADOPTED_THRESHOLDS.OK}
        />
      </div>
  },
];

export const GroupDocumentsTable = (props) => (
  <div className="row">
    <div className="col-1">
      <Table
        insight={INSIGHT}
        columns={COLUMNS(props.changePracticeAndNavToDashboard)}
        defaultSortByKey="adoption_rate"
        defaultSortIsAscending
      />
      <TableFooter
        insight={INSIGHT}
        csvUrl={INSIGHTS_CSV_API_URLS.GROUP_DOCUMENTS_ADOPTED_BY_PRACTICE_REPORT}
      />
    </div>
  </div>
);

const ConnectedGroupDocumentsTable = connect(
  undefined,
  { changePracticeAndNavToDashboard }
)(GroupDocumentsTable);

export default withLoadInsightDataOnMount({
  insight: INSIGHT,
  url: INSIGHTS_API_URLS.GROUP_DOCUMENTS_ADOPTED_BY_PRACTICE,
  getFilters: () => (
    Map({
      practice_sub_group_id: getSubGroupFilterFromPath(),
    })
  )
})(ConnectedGroupDocumentsTable);
