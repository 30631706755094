import React from 'react';
import moment from 'moment';
import { reduxForm } from 'redux-form';

import { t } from '^/i18n';
import DateInput from '^/components/app/forms/DateInput';
import FormError from '^/components/app/forms/FormError';
import LiveButton from '^/components/app/forms/LiveButton';

const FORM_NAME = 'SetupCycleDateForm';

const SetupCycleDateForm = ({
  fields: { start_date }, handleSubmit, response
}) => {
  return (
    <form onSubmit={handleSubmit}>

      <FormError response={response} />

      <div className="form-group">
        <div className="col-2-3">
          <label htmlFor="start_date" className="label-left padded">
            {t('users.profile.newCycleStartDate', 'Set the start date for your new cycle')}.
          </label>
        </div>
        <div className="col-1-3">
          <DateInput id="start_date" {...start_date} />
          <FormError response={response} formKey={'start_date'} />
        </div>
      </div>

      <div className="form-group">
        <div className="col-1">
          <LiveButton
            pendingMessage={`${t('common.saving', 'Saving')}...`}
            response={response}
            className="btn-default pull-right"
          >
            {t('users.profile.goToCycleSetup', 'Go to cycle setup')}
          </LiveButton>
        </div>
      </div>

    </form>
  );
};

export default reduxForm({
  form: FORM_NAME,
  fields: ['start_date'],
  initialValues: {
    'start_date': moment().add(1, 'months').startOf('month'),
  },
})(SetupCycleDateForm);
