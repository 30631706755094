import React from 'react';
import { connect } from 'react-redux';

import { isPending } from '^/consts/responseStates';
import { generateCSVReport } from '^/actions/actionSequences';
import LiveButton from '^/components/app/forms/LiveButton';
import PureComponent from '^/components/common/PureComponent';
import { t } from '^/i18n';

export class CSVExporterButton extends PureComponent {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    this.props.generate(this.props.url, this.props.filters);
  }

  render() {
    const {response, children, className} = this.props;

    return (
      <LiveButton
        className={className}
        onClick={this.onClick}
        disabled={isPending(response)}
        response={response}
      >
        {children || t('common.downloadCSVUpperCase', 'DOWNLOAD CSV')}
      </LiveButton>
    );
  }
}

export function mapStateToProps(state) {
  return {
    response: state.responses.get('getCSVReportURL')
  };
}

CSVExporterButton.propTypes = {
  url: React.PropTypes.string.isRequired,
};

export default connect(mapStateToProps, {generate: generateCSVReport}) (CSVExporterButton);
