import React from 'react';
import { connect } from 'react-redux';
import { Map, List } from 'immutable';
import { t } from '^/i18n';

import { getActivePracticeUsers, isLoggedOnUserAdminOfCurrentPractice } from '^/stateHelpers';
import { saveCycleResponsibilitiesAndRedirectTo } from '^/actions/actionSequences';
import { RESPONSIBILITIES, CYCLE_RESPONSIBILITIES } from '^/consts/collectionKeys';
import { getAllCollection } from '^/actions/collections';

import PureComponent from '^/components/common/PureComponent';
import LiveButton from '^/components/app/forms/LiveButton';
import Loading from '^/components/app/content/Loading';
import CycleResponsibilitiesForm, { OVERRIDE_TEXT } from '^/components/app/account-set-up/CycleResponsibilitiesForm';


// Match the structure returned by /practices/ID/setup/team-members/
function getTeamMembers(practiceMembers) {
  return practiceMembers
    .map(member => member.getIn(['user', 'staffdetail']))
    .filter(staffdetail => staffdetail)
    .map(staffdetail => Map({
      full_name: staffdetail.get('full_name'),
      staffdetail: staffdetail.get('id'),
    }));
}

export class CycleResponsibilitiesPage extends PureComponent {

  getCycleId() {
    return this.props.practice.getIn(['current_cycle', 'id']);
  }

  componentWillMount() {
    this.props.getAllCollection(RESPONSIBILITIES);
    this.props.getAllCollection(CYCLE_RESPONSIBILITIES);
  }

  transformAndSubmitData(data) {
    const dataToSubmit = Map(this.props.responsibilities.map((each) => {
      if (data[each.get('id')]) {
        return [
          each.get('id'),
          {staff_id: data[each.get('id')], override_text: data[each.get('id') + OVERRIDE_TEXT]}
        ];
      }
    })).toJS();
    this.props.saveCycleResponsibilitiesAndRedirectTo(dataToSubmit, '/page/profile/my-practices');
  }

  render () {
    const {
      user,
      response,
      responsibilities,
      cycleResponsibilities,
      practice,
      practiceMembers,
      isAdmin,
    } = this.props;

    if (!user || !responsibilities || !practice || !cycleResponsibilities) {
      return <Loading />;
    }

    const self = user.getIn(['staffdetail', 'id']);

    const fields = responsibilities.map(each => each.get('id'));
    const overrideTextFields = responsibilities.map(each => each.get('id') + OVERRIDE_TEXT);

    const initialValues = Map(fields.map(field => {
      const responsibility = cycleResponsibilities.find(each => each.get('responsibility') === field);
      return [field, responsibility ? responsibility.get('staff') : self];
    }));

    const overrideInitialValues = Map(overrideTextFields.map(field => {
      const responsibility = cycleResponsibilities.find(each => each.get('responsibility') + OVERRIDE_TEXT === field);
      return [field, responsibility ? responsibility.get('override_text') : ""];
    }));

    return isAdmin && (
      <div className="mb-2">
        <h5>{practice.get('name')}:</h5>

        <h1>{t('common.responsibilitiesCapitalCase', 'Responsibilities')}</h1>

        <hr className="dark" />

        <p>
          {t('practices.chooseReponsibleTeamMembersMsg', `Please choose team members who will be responsible for compliance reminders and administrative activities.`)}
        </p>

        <hr className="thin" />

        <CycleResponsibilitiesForm
          response={response}
          teamMembers={getTeamMembers(practiceMembers)}
          responsibilities={responsibilities}
          fields={fields.concat(overrideTextFields).toJS()}
          initialValues={initialValues.merge(overrideInitialValues).toJS()}
          onSubmit={data => this.transformAndSubmitData(data)}
        >
          <LiveButton className="btn-default" pendingMessage={`${t('common.saving', 'Saving')}...`} response={response}>
            {t('common.button.save', 'Save')}
          </LiveButton>
        </CycleResponsibilitiesForm>
      </div>
    );
  }
}

export const mapStateToProps = (state) => ({
  user: state.userProfile,
  practice: state.currentPractice,
  practiceMembers: getActivePracticeUsers(state),
  response: state.responses.get('saveCycleResponsibilities'),
  responsibilities: state.collections.getIn([RESPONSIBILITIES, 'items']),
  cycleResponsibilities: state.collections.getIn([CYCLE_RESPONSIBILITIES, 'items'], List()),
  isAdmin: isLoggedOnUserAdminOfCurrentPractice(state),
});

export default connect(mapStateToProps,
  {
    saveCycleResponsibilitiesAndRedirectTo,
    getAllCollection,
  }
)(CycleResponsibilitiesPage);
