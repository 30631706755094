import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { fromJS, Set } from 'immutable';
import classNames from 'classnames';

import { t } from '^/i18n';
import PureComponent from '^/components/common/PureComponent';
import { isPending } from '^/consts/responseStates';
import { collapseAllSections, toggleExpandSection } from '^/actions/actions';
import { isComplete, isOverdue, isNotStarted } from '^/models/activities';
import { getRepeatText, REPEAT_CHOICES, forSelf, getRepeatGroupId } from '^/models/task';
import Icon from '^/components/app/content/Icon';
import MaybeCollapsible from '^/components/app/widgets/MaybeCollapsible';
import Loading from '^/components/app/content/Loading';
import LinkAndCloseMenu from '^/components/app/content/LinkAndCloseMenu';
import { LIST_NAV_ID } from '^/components/app/widgets/CollapsibleLeftNav';

const ICON_NAMES = fromJS({overdue: 'exclamation', complete: 'check'});

const TaskItem = ({task, section, activeId, numberOfRepeats, toggleExpandRepeats, isExpanded, isRepeat}) =>
  <li className={classNames({'sidenav-active': task.get('id') === activeId, 'ml-1': isRepeat})}>
    <LinkAndCloseMenu
      className={`step-link step-${section}`}
      to={`/page/tasks/${task.get('id')}/`} collapseId={LIST_NAV_ID}
    >
      <Icon type="check-circle-o" className={classNames('task-icon task-icon-indicator', section)} />
      {task.get('title')}
      <span className={'task-date ' + section}>{moment(task.get('deadline')).format('Do MMM')}</span>
      {task.get('repeat') !== REPEAT_CHOICES.NONE && (
        <span title={`repeating task: ${getRepeatText(task)}`}>
          <Icon type="repeat" className="repeat-icon small-icon gray-icon" title={t('tasks.repeatingTask', 'repeating task')} />
        </span>
      )}
      {!isRepeat && (numberOfRepeats > 0) && (
        <span> <span onClick={toggleExpandRepeats} className="inline-link x-small-text">
          {isExpanded ? 'hide' : 'show'} {numberOfRepeats} {t('tasks.repeats', 'repeats')}
        </span></span>
      )}
      {ICON_NAMES.get(section) && (
        <Icon type={ICON_NAMES.get(section)} className={'step-icon step-icon-right ' + section} />
      )}
    </LinkAndCloseMenu>
  </li>;

const SECTION_TITLES = fromJS({overdue: t('tasks.outstanding', 'Outstanding'), complete: t('tasks.completed', 'Completed')});

const TasksSection = ({
  tasks,
  section,
  activeId,
  collapsible,
  onLoadMore,
  isLoading,
  expandedRepeats,
  toggleExpandRepeats
}) => {
  if (!(tasks && tasks.size) && !collapsible) {
    return <noscript />;
  }

  // Group by repeat; in each group, put the parent first if there is one
  const groupedTasks = tasks.groupBy(getRepeatGroupId)
    .map(taskGroup => taskGroup.sortBy(task => task.get('repeat_clone_of') ? 1 : 0));

  const isExpanded = id => expandedRepeats && expandedRepeats.contains(id);

  return (
    <div className="clearfix">
      <MaybeCollapsible
        collapsible={collapsible}
        id={'my-tasks-' + section}
        initiallyCollapsed
        collapseButton={
          <h5 className={classNames('mt-1', section)}>
            {SECTION_TITLES.get(section, t('tasks.upcoming', 'Upcoming'))}:
          </h5>
        }
      >
        <ul className="list-no-indent icon-right icon-left">
          {groupedTasks
            .map(taskGroup =>
              taskGroup.map((task, idx) => {
                const isRepeat = idx > 0;
                const numberOfRepeats = taskGroup.count() - 1;
                const repeatGroupId = getRepeatGroupId(task);
                const isGroupExpanded = isExpanded(repeatGroupId);

                if (isRepeat && !isGroupExpanded) {
                  return <noscript />;
                }

                return (
                  <TaskItem
                    key={task.get('id')}
                    task={task}
                    section={section}
                    activeId={activeId}
                    isRepeat={isRepeat}
                    numberOfRepeats={numberOfRepeats}
                    isExpanded={isGroupExpanded}
                    toggleExpandRepeats={() => toggleExpandRepeats(repeatGroupId)}
                  />
                );
              })
            )
            .valueSeq().flatten(1)
          }
          {isLoading ? (
            <Loading />
          ) : (
            onLoadMore && (
              <a className="underlined small-text bold-text" onClick={onLoadMore}>
                {t('common.button.loadMore', 'Load more')}…
              </a>
            )
          )}
        </ul>
      </MaybeCollapsible>
    </div>
  );
};


export class EndUserTasksList extends PureComponent {
  componentWillMount() {
    this.props.collapseAllRepeats();
  }

  render() {
    const {
      myTasks,
      activeId,
      onLoadMoreCompleted,
      loadCompletedResponse,
      toggleExpandRepeats,
      expandedRepeats
    } = this.props;

    const sortedTasks = myTasks.sortBy(each => each.get('deadline'));

    const overdueTasks = sortedTasks.filter(forSelf(isOverdue));
    const isNotStartedTasks = sortedTasks.filter(forSelf(isNotStarted));
    const completedTasks = sortedTasks.filter(forSelf(isComplete)).reverse();

    return (
      <div>
        <TasksSection
          tasks={overdueTasks}
          section="overdue"
          activeId={activeId}
          expandedRepeats={expandedRepeats}
          toggleExpandRepeats={toggleExpandRepeats}
        />
        <TasksSection tasks={isNotStartedTasks} section="notstarted" activeId={activeId} />
        <TasksSection
          tasks={completedTasks}
          section="complete"
          activeId={activeId} collapsible
          onLoadMore={onLoadMoreCompleted}
          isLoading={isPending(loadCompletedResponse)}
          expandedRepeats={expandedRepeats}
          toggleExpandRepeats={toggleExpandRepeats}
        />
      </div>
    );
  }
}

const MY_TASKS = 'MY-TASKS';

export function mapStateToProps(state) {
  return {
    expandedRepeats: state.ui.getIn(['expandedSections', MY_TASKS], Set())
  };
}

function mapDispatchToProps(dispatch) {
  return {
    toggleExpandRepeats: id => dispatch(toggleExpandSection(MY_TASKS, id)),
    collapseAllRepeats: () => dispatch(collapseAllSections(MY_TASKS)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EndUserTasksList);
