import React from 'react';
import { reduxForm } from 'redux-form';

import { t } from '^/i18n';
import PureComponent from '^/components/common/PureComponent';
import LiveButton from '^/components/app/forms/LiveButton';

export class NameDocumentCopyForm extends PureComponent {
  render() {
    const field = this.props.fields.name;
    return (
      <form onSubmit={this.props.handleSubmit}>
        <div className="form-group">
          <div className="col-1-3">
            <label htmlFor="name" className="label-left padded">{t('templateDocument.documentName', 'Document Name')}:</label>
            <span className="help-inline">(optional)</span>
          </div>
          <div className="col-2-3">
            <input
              {...field}
              id="name"
              type="text"
              placeholder={t('templateDocument.documentName', 'Document Name')}
              className="form-control"
            />
          </div>
        </div>
        <div className="align-right">
          <LiveButton className="btn-primary" response={this.props.response}>
            {t('common.button.save', 'Save')}
          </LiveButton>
        </div>
      </form>
    );
  }
}

export default reduxForm({
  form: 'nameDocumentCopyForm',
  fields: ['name'],
})(NameDocumentCopyForm);
