import React from 'react';
import { connect } from 'react-redux';
import { fromJS, Map } from 'immutable';

import { transformInitialValues, ENGLAND } from '^/models/practice';
import { setupPracticeDetailsAndReloadUser, setPendingUploadAndResetError } from '^/actions/actionSequences';

import Loading from '^/components/app/content/Loading';
import PracticeDetailsSetupForm, { getPracticeDetailsSetupFormFields } from '^/components/app/account-set-up/PracticeDetailsSetupForm';
import AccountSetupHeader from '^/components/app/account-set-up/AccountSetupHeader';
import AddPracticePaymentPage from '^/components/app/practices/AddPracticePaymentPage';
import { t } from '^/i18n';

const DEFAULT_VALUES = Map({
  country: ENGLAND,
});

export const getPracticeDetailsData = (data, practice) => {
  const newData = fromJS(data)
    .filter(item => typeof item !== 'undefined' && item !== null)
    .set('practice_id', practice.get('id'))
    .toJS();

  // If the logo hasn't changed, remove it from the submitted data. It'll be a URL, which isn't an image.
  if (newData['logo'] === practice.get('logo')) {
    delete newData['logo']
  }

  return newData;
}

const getInitialValues = (practice) =>
  DEFAULT_VALUES.mergeDeep(fromJS(transformInitialValues(practice.get('compliance_details'))))
    .mergeDeep(
      fromJS(transformInitialValues(practice))
    )
    .toJS();


const RESPONSE_NAME = 'setupPracticeDetails';

const PracticeDetailsSetup = ({user, setup, response, setFileUpload, resetError, practice}) =>
  !practice ? (
    <AddPracticePaymentPage />
  ) : (
    <div>
      <AccountSetupHeader user={user}>
        { t('accountSetup.practiceDetails', 'Setup - practice details')}
      </AccountSetupHeader>

      <p className="small-text">
        { t('accountSetup.enterPracticeDetails', 'Enter your practice details') }:
      </p>
      <hr />

      <PracticeDetailsSetupForm
        initialValues={getInitialValues(practice)}
        fields={getPracticeDetailsSetupFormFields(practice)}
        user={user}
        practice={practice}
        response={response}
        resetError={resetError}
        onSubmit={data => {setup(getPracticeDetailsData(data, practice));}}
        setPendingUploadInForm={(form, field, file) => setFileUpload(form, field, file, RESPONSE_NAME)}
      />
    </div>
  );

export const PracticeDetailsSetupPage = (props) =>
  props.user ? <PracticeDetailsSetup {...props} /> : <Loading />;

export const mapStateToProps = (state) => ({
  user: state.userProfile,
  practice: state.currentPractice,
  response: state.responses.get(RESPONSE_NAME),
});

export default connect(
  mapStateToProps,
  { setup: setupPracticeDetailsAndReloadUser, setFileUpload: setPendingUploadAndResetError }
) (PracticeDetailsSetupPage);
