import React from 'react';
import { reduxForm } from 'redux-form';

import { t } from '^/i18n';
import Icon from '^/components/app/content/Icon';
import FormError from '^/components/app/forms/FormError';
import LiveButtonSet from '^/components/app/forms/LiveButtonSet';
import Notes from '^/components/app/widgets/Notes';
import ActivityDocuments from '^/components/app/activities/documents/ActivityDocuments';
import RelatedTasksNoCollapse from '^/components/app/activities/RelatedTasksNoCollapse';
import ActivityActionsTaken from '^/components/app/activities/generic-activity/ActivityActionsTaken';
import CollapseButton from '^/components/app/content/CollapseButton';
import Collapse from '^/components/app/content/Collapse';


function confirmMarkComplete(onConfirm) {
  if (
    window.confirm(t(
      'activities.completeActivitiesConfirmationMsg',
      'Are you sure you want to mark this activity complete?\nYou won\'t be able to change it afterwards.')
    )
  ) {
    onConfirm();
  }
}

export const ActivityEvidenceNotesForm = ({
  fields: { info, notes, doc_location }, handleSubmit, values, response, isCompleted, onMarkComplete, activity, displayActionsTaken
}) =>
  <form onSubmit={handleSubmit} >
    <div className="mb-1">
      <ActivityDocuments activity={activity} />

      <hr />

      <div className="collapsible-section-activities">
        <CollapseButton collapseId={'RELATED_TASKS_COLLPASE_ID'}>
          <h2>{t('activities.relatedTasks', 'Related Tasks')}:</h2>
        </CollapseButton>
        <Collapse initiallyCollapsed collapseId={'RELATED_TASKS_COLLPASE_ID'}>
        <RelatedTasksNoCollapse
            activity={activity}
            helpText={t(
              'activities.realatedActivitiesHelpText',
              `You can create related activities as Task’s to show that you have carried out follow-up actions to meet compliance requirements. It is important to have evidence of follow-up actions to show that you are well-led.`
            )}
            wide
          />
        </Collapse>
      </div>

      {
        displayActionsTaken && 
        (<div>
          <hr />
          <ActivityActionsTaken activity={activity} />
          <FormError response={response} formKey="actions_taken" />
        </div>)
      }

      <hr />

      <div className="collapsible-section-activities">
        <CollapseButton collapseId={'COMPLIANCE_NOTES_COLLPASE_ID'}>
          <h2>{t('activities.complianceReportNotes', 'Compliance Report notes')}:</h2>
        </CollapseButton>
        <Collapse initiallyCollapsed collapseId={'COMPLIANCE_NOTES_COLLPASE_ID'}>

          <p className="help-block">
            <Icon type="lightbulb-o" className="tip-icon" />
            {t('activities.complianceReportBriefNotes', 'Write brief notes outlining the actions you have taken to show on your compliance report.')}
          </p>

          {!isCompleted ? (
            <div>
              <textarea
                cols="30" rows="5"
                placeholder={t('common.placeholder.enterNotesHere', 'Enter notes here')}
                {...info}
                disabled={isCompleted}
              />
              <FormError response={response} formKey="info" />
            </div>
          ) : (
            <Notes emptyMessage="No notes added" value={info && info.value} />
          )}

          <p className="help-block">
            <Icon type="lightbulb-o" className="tip-icon" />
            {t('activities.hardCopyEvidence', 'If there is hard copy evidence, write where it is stored such as an iComply folder')}:
          </p>
          {!isCompleted ? (
            <input type="text" {...doc_location} />
          ) : (
            <Notes value={doc_location && doc_location.value} />
          )}

          {isCompleted && notes && notes.value && (
            <div>
              <h2>{t('common.label.notes', 'Notes')}:</h2>
              <Notes value={notes && notes.value} />
              <hr />
            </div>
          )}
        </Collapse>
      </div>

      {!isCompleted && (
        <div className="mt-1">
          <LiveButtonSet pendingMessage={`${t('common.saving', 'Saving')}...`} response={response}>
            <button
              type="submit"
              className="btn btn-save indented mr-1"
            >
              <Icon type="folder-open" className="button-icon" /> {t('common.button.save', 'Save')}
            </button>

            <button
              type="button"
              className="btn btn-complete indented"
              onClick={() => confirmMarkComplete(() => onMarkComplete(values))}
            >
             <Icon type="check" className="button-icon" /> {t('common.button.markAsComplete', 'Mark as complete')}
           </button>
          </LiveButtonSet>
        </div>
      )}

    </div>
  </form>
;

export default reduxForm({
  form: 'complianceReviewEvidenceNotes',
  fields: [
    'info', 'notes', 'doc_location',
  ],
}) (ActivityEvidenceNotesForm);
