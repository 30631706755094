import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ConnectHOC } from '@dabapps/connect-hoc';

import { t } from '^/i18n';
import { openDeleteAuditHealthcheckModal } from '^/actions/modals';

interface DispatchProps {
  openDHCModal: typeof openDeleteAuditHealthcheckModal;
}

interface OwnProps {
  id: string;
}

interface Options {
  type: ItemType;
  modalType: string;
}

export enum ItemType {
  AUDIT = 'audit',
  HEALTHCHECK = 'healthcheck',
}

export type DeleteAuditHealthcheckProps = DispatchProps & OwnProps;
const connectHOC = connect as ConnectHOC;

const createDeleteAuditHealthcheck = (options: Options) => {
  class DeleteAuditHealthcheck extends Component<
    DeleteAuditHealthcheckProps,
    {}
  > {
    public render() {
      const { id, openDHCModal } = this.props;

      function openModal() {
        openDHCModal(
          {
            itemId: id,
          },
          options.modalType
        );
      }

      return (
        <div className="ml-1-2">
          <p className="text-empty-state mb-1 mt-1">
            {t(
              'healthcheck.startAndResume',
              'Please note: you can start, and resume'
            )}{' '}
            {this.chooseIndefiniteArticle(options.type)} {options.type}{' '}
            {t('healthcheck.atAnyTime', 'at any time')}
          </p>
          <p className="text-empty-state mb-1 mt-1">
            {t('healthcheck.deleting', 'Deleting')}{' '}
            {this.chooseIndefiniteArticle(options.type)} {options.type}{' '}
            {t(
              'healthcheck.deleteLoggedAssociatedData',
              'deletes all associated data logged so far.'
            )}
          </p>

          <button className="btn btn-danger" onClick={openModal}>
            Delete {options.type}
          </button>
        </div>
      );
    }

    private chooseIndefiniteArticle = (item: ItemType) =>
      item === ItemType.AUDIT
        ? t('healthcheck.an', 'an')
        : t('healthcheck.a', 'a');
  }

  return connectHOC<{}, DispatchProps, OwnProps>(undefined, {
    openDHCModal: openDeleteAuditHealthcheckModal,
  })(DeleteAuditHealthcheck);
};

export default createDeleteAuditHealthcheck;
