import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';

import { t } from '^/i18n';
import { StoreState } from '^/store/types';
import {
  selecthasCreateHealthcheckPermissions,
  selectCurrentUserId,
  selectInProgressHealthcheck,
} from '^/selectors/healthcheck';
import Loading from '^/components/app/content/Loading';
import {
  createHealthcheckAndContinueHealthcheckForm,
  updateToSpecifiedHealthcheckStatusAndContinue,
  getHealthcheckActionsProgressAndRedirect,
} from '^/actions/healthcheck';
import { openCompleteHealthcheckConfirmModal } from '^/actions/modals';
import { formatDate } from '^/utils';
import { selectCurrentPracticeId } from '^/selectors/practice';
import { HealthcheckReview, Healthcheck, HealthcheckStatus } from './types';
import FormError from '^/components/app/forms/FormError';

interface StateProps {
  isLoading: boolean;
  currentUserId: string | undefined;
  currentPracticeId: string;
  hasCreateHealthcheckPermissions: boolean;
  inProgressHealthcheck: Healthcheck | null;
  healthcheckReview: HealthcheckReview | null;
}

interface DispatchProps {
  createHealthcheckAndContinueHealthcheckForm: typeof createHealthcheckAndContinueHealthcheckForm;
  updateToSpecifiedHealthcheckStatusAndContinue: typeof updateToSpecifiedHealthcheckStatusAndContinue;
  getHealthcheckActionsProgressAndRedirect: typeof getHealthcheckActionsProgressAndRedirect;
  openCompleteHealthcheckConfirmModal: typeof openCompleteHealthcheckConfirmModal;
}

export type Props = StateProps & DispatchProps;

interface LinkToAllHealthchecksProps {
  currentPracticeId: string;
}

const LinkToAllHealthchecks: React.SFC<LinkToAllHealthchecksProps> = ({
  currentPracticeId,
}) => {
  return (
    <div className="mt-2">
      <Link to={`/page/practices/${currentPracticeId}/compliance_healthcheck/`}>
        {t('healthcheck.viewAllHealthchecks', 'view all healthchecks')}
      </Link>
    </div>
  );
};

class ReviewNextSteps extends Component<Props, {}> {
  public render() {
    const {
      currentUserId,
      currentPracticeId,
      hasCreateHealthcheckPermissions,
      inProgressHealthcheck,
      healthcheckReview,
      isLoading,
    } = this.props;

    if (isLoading) {
      return <Loading />;
    }

    if (!healthcheckReview) {
      return (
        <FormError
          error={t(
            'healthcheck.noHealthcheckReview',
            'Could not get healthcheck review'
          )}
        />
      );
    }

    const { modified, status, user: healthCheckUser } = healthcheckReview;

    return (
      <div>
        {status === HealthcheckStatus.REVIEW && (
          <div className="review-actions pb-2 pt-2 ph-large">
            {healthCheckUser.id === currentUserId ? (
              <div>
                <p>
                  <span className="bold-text">
                    {t('healthcheck.pleaseNote', 'Please note')}:{' '}
                  </span>
                  {t(
                    'healthcheck.returnToViewActions',
                    'you can return to view actions (complete and remaining) before marking this healthcheck as complete. Healthchecks need to be completed before starting another one.'
                  )}
                </p>
                <div className="pt-2">
                  <button
                    onClick={this.onClickViewActionsFromReview}
                    className="btn btn-secondary"
                  >
                    {'<'} {t('healthcheck.viewActions', 'View actions')}
                  </button>
                  <span className="pl-1 pr-1">or</span>
                  <button
                    className="btn btn-save"
                    onClick={this.onCompleteHealthcheck}
                  >
                    {t(
                      'healthcheck.markHealthcheckCompleted',
                      'Mark healthcheck completed'
                    )}
                  </button>
                </div>
              </div>
            ) : (
              <div>
                <p>
                  <span className="bold-text">
                    {t('healthcheck.pleaseNote', 'Please note')}:{' '}
                  </span>
                  {t(
                    'healthcheck.stillInProgressContactOwner',
                    "This healthcheck is still in progress, so a new one cannot be started. Contact it's owner"
                  )}
                  <a href={`mailto:${healthCheckUser.email}`}>
                    {healthCheckUser.email}
                  </a>{' '}
                  {t(
                    'healthcheck.markItAsCompleted',
                    'to mark it as completed.'
                  )}
                </p>
                <LinkToAllHealthchecks currentPracticeId={currentPracticeId} />
              </div>
            )}
          </div>
        )}
        {status === HealthcheckStatus.COMPLETED && (
          <span>
            <div className="review-actions pb-2 pt-2 ph-large">
              {healthCheckUser.id === currentUserId && (
                <div>
                  <p>
                    <span className="bold-text">
                      {t('healthcheck.pleaseNote', 'Please note')}:{' '}
                    </span>
                    {t(
                      'healthcheck.returnViewActions',
                      'you can still return to view actions (complete and incomplete)'
                    )}
                  </p>
                  <div className="pt-2">
                    <button
                      onClick={this.onClickViewActionsFromCompleted}
                      className="btn btn-secondary"
                    >
                      {'<'} {t('healthcheck.viewActions', 'View actions')}
                    </button>
                  </div>
                </div>
              )}
            </div>
            <div className="review-actions">
              <h3 className="mb-1">
                {t(
                  'healthcheck.healthcheckCompletedOn',
                  'This Healthcheck was completed on'
                )}{' '}
                {formatDate(modified)}.
              </h3>
              {hasCreateHealthcheckPermissions && !inProgressHealthcheck && (
                <button
                  className="btn btn-save"
                  onClick={this.onStartHealthcheck}
                >
                  {t(
                    'healthcheck.button.startNewHealthcheck',
                    'Start a new healthcheck'
                  )}
                </button>
              )}
              <LinkToAllHealthchecks currentPracticeId={currentPracticeId} />
            </div>
          </span>
        )}
      </div>
    );
  }

  private onClickViewActionsFromCompleted = () => {
    if (this.props.healthcheckReview)
      return this.props.getHealthcheckActionsProgressAndRedirect(
        this.props.healthcheckReview.id
      );
  };

  private onClickViewActionsFromReview = () =>
    this.props.updateToSpecifiedHealthcheckStatusAndContinue(
      HealthcheckStatus.REVIEW,
      HealthcheckStatus.ACTIONS
    );

  private onCompleteHealthcheck = () => {
    this.props.openCompleteHealthcheckConfirmModal();
  };

  private onStartHealthcheck = () => {
    this.props.createHealthcheckAndContinueHealthcheckForm();
  };
}

const mapStateToProps = (state: StoreState): StateProps => ({
  isLoading:
    state.thunkRequests.updateHealthcheckStatus.isLoading ||
    state.thunkRequests.createHealthcheck.isLoading,
  currentUserId: selectCurrentUserId(state),
  currentPracticeId: selectCurrentPracticeId(state),
  hasCreateHealthcheckPermissions: selecthasCreateHealthcheckPermissions(state),
  inProgressHealthcheck: selectInProgressHealthcheck(state),
  healthcheckReview: state.healthcheck.review,
});

export { ReviewNextSteps as ReviewNextStepsUnconnected };

export default connect<StateProps, DispatchProps, {}>(mapStateToProps, {
  createHealthcheckAndContinueHealthcheckForm,
  updateToSpecifiedHealthcheckStatusAndContinue,
  getHealthcheckActionsProgressAndRedirect,
  openCompleteHealthcheckConfirmModal,
})(ReviewNextSteps);
