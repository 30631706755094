import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { routeActions } from 'react-router-redux';
import classNames from 'classnames';

import { t } from '^/i18n';
import { isLoggedOnUserAdminOfCurrentPractice } from '^/stateHelpers';
import { selecthasCreateHealthcheckPermissions, selecthasContinueHealthcheckPermissions } from '^/selectors/healthcheck';
import { isPending, hasFailed } from '^/consts/responseStates';
import { switchPractice } from '^/actions/actions';
import { PRACTICE_SECTION, PRACTICE_SECTION_TEXT, getPracticeSectionUrls } from '^/models/practice';

import PureComponent from '^/components/common/PureComponent';
import Loading from '^/components/app/content/Loading';


export const PracticeNavItem = ({ section, currentSection, sectionUrls }) => {
  const isCurrent = section === currentSection;
  return (
    <li className={isCurrent ? 'sidenav-active' : ''}>
      <Link className="sidenav-text" to={sectionUrls.get(section, '')}>
        <span>{PRACTICE_SECTION_TEXT.get(section)}</span>
      </Link>
    </li>
  );
};

export const PracticeNav = ({ className, currentSection, sectionUrls, practiceName, isAdmin, hasCreateHealthcheckPermissions, hasContinueHealthcheckPermissions }) =>
  <div className={classNames(className, 'secondary-left')}>
    <Link className="small-text" to="/page/profile/my-practices">
      <span>&lt; {t('practices.allPractices', 'ALL PRACTICES')}</span>
    </Link>
    <h2 className="mt-1-2">{practiceName}:</h2>
    <ul className="list-no-indent icon-right">
      {PRACTICE_SECTION.map(section =>
        (isAdmin || section !== PRACTICE_SECTION.get('RESPONSIBILITIES'))
        && (hasCreateHealthcheckPermissions || hasContinueHealthcheckPermissions || section !== PRACTICE_SECTION.get('COMPLIANCE_HEALTHCHECK'))
        && (
          <PracticeNavItem
            key={section}
            section={section}
            currentSection={currentSection}
            sectionUrls={sectionUrls}
          />
        )
      )}
    </ul>
  </div>;


export class PracticePage extends PureComponent {

  componentWillMount() {
    this.props.switchPractice(this.props.practiceId);
  }

  componentWillReceiveProps(nextProps) {
    const { redirect, currentPractice, currentSection } = this.props;

    const currentPracticeId = currentPractice.get('id');
    const nextCurrentPracticeId = nextProps.currentPractice.get('id');

    if (currentPracticeId !== nextCurrentPracticeId) {
      const newUrl = getPracticeSectionUrls(nextCurrentPracticeId).get(currentSection);

      redirect(newUrl);
    }
  }

  render() {
    const { children, currentSection, currentPractice, sectionUrls, response, isAdmin, hasCreateHealthcheckPermissions, hasContinueHealthcheckPermissions } = this.props;

    if (hasFailed(response)) {
      return <p className="text-error">{t('practices.practiceLoadFailureMsg', 'Failed to load practice')}</p>;
    }

    if (isPending(response) || !currentPractice) {
      return <Loading />;
    }

    return <div className="mt-2">
      <PracticeNav
        practiceName={currentPractice.get('name')}
        className="col-1-3"
        currentSection={currentSection}
        sectionUrls={sectionUrls}
        isAdmin={isAdmin}
        hasCreateHealthcheckPermissions={hasCreateHealthcheckPermissions}
        hasContinueHealthcheckPermissions={hasContinueHealthcheckPermissions}
      />
      <div className="col-2-3 bordered-left">
        <div className="ml-1">{children}</div>
      </div>
    </div>;
  }
}

export const mapStateToProps = (state, props) => {
  const practiceId = props.params.uuid;
  const sectionUrls = getPracticeSectionUrls(practiceId);
  const path = state.routing.location.pathname || '';

  return {
    response: state.responses.get('switchPractice'),
    currentSection: sectionUrls.keyOf(path),
    currentPractice: state.currentPractice,
    sectionUrls,
    practiceId,
    isAdmin: isLoggedOnUserAdminOfCurrentPractice(state),
    hasCreateHealthcheckPermissions: selecthasCreateHealthcheckPermissions(state),
    hasContinueHealthcheckPermissions: selecthasContinueHealthcheckPermissions(
      state
    ),
  };
};

export default connect(
  mapStateToProps,
  {
    switchPractice,
    redirect: routeActions.push
  }
)(PracticePage);
