import { Moment } from 'moment';

export enum HealthcheckTypes {
  Actions = 'Actions',
  Audit = 'Audit',
  AuditResults = 'Audit Results',
}

export interface IntroOptions {
  type: HealthcheckTypes;
  header: string;
  buttonText: string;
  intendedFromStatus: HealthcheckStatus;
}

export interface BaseItem {
  id: string;
}

export interface ItemRendererProps<T extends BaseItem> {
  item: T;
  healthcheckId: string;
  categoryId: string;
  areaId: string;
  disabled?: boolean;
}

export enum HealthcheckStatus {
  CREATED = 'CREATED',
  AUDIT_INTRO_1 = 'AUDIT_INTRO_1',
  AUDIT = 'AUDIT',
  AUDIT_RESULTS = 'AUDIT_RESULTS',
  ACTIONS_INTRO_1 = 'ACTIONS_INTRO_1',
  ACTIONS = 'ACTIONS',
  REVIEW = 'REVIEW',
  COMPLETED = 'COMPLETED',
}

export enum HealthcheckQuestionResponse {
  YES = 'YES',
  NO = 'NO',
  NA = 'N/A',
}

export interface HealthcheckUser {
  id: string;
  email: string;
}

export interface Healthcheck {
  id: string;
  status: HealthcheckStatus;
  user: HealthcheckUser;
}

export interface HealthcheckQuestion {
  id: string;
  question_text: string;
  response?: HealthcheckQuestionResponse | null;
}

export interface HealthcheckActionTemplateDocument {
  id: string;
  name: string;
}

export interface HealthcheckAction {
  id: string;
  question_text: string;
  action_text: string;
  action_template_documents: HealthcheckActionTemplateDocument[];
  is_completed: boolean;
  notes: string | null;
}

export interface HealthcheckReview {
  modified: string | null;
  overall_score: number;
  question_yes_count: number;
  question_total_count: number;
  action_completed_count: number;
  action_total_count: number;
  status: HealthcheckStatus;
  user: HealthcheckUser;
  id: string;
}

export interface HealthcheckListSummary {
  overall_score: number;
  action_completed_count: number;
  action_total_count: number;
  id: string;
  modified: Date;
  user: HealthcheckUser;
  status: HealthcheckStatus;
}

export interface HealthcheckArea<T> {
  id: string;
  name: string;
  items: readonly T[];
}

export interface HealthcheckAuditResult {
  id: string;
  name: string;
  score: number;
}

interface CategoriesBase {
  id: string;
  name: string;
}

// Response for displaying list of categories in sidebar
export type HealthcheckCategoriesProgress = readonly CategoryProgress[];
export interface CategoryProgress extends CategoriesBase {
  is_completed: boolean;
}

// Response containing questions/actions
export type HealthcheckCategoriesWithAreas<
  T
> = readonly HealthcheckCategoryWithAreas<T>[];
export interface HealthcheckCategoryWithAreas<T> extends CategoriesBase {
  areas: readonly HealthcheckArea<T>[];
}

// List of categories possibly merged with questions/actions
export type HealthcheckCategoriesMerged<T> = readonly HealthcheckCategoryMerged<
  T
>[];
export type HealthcheckCategoryMerged<T> = CategoryProgress &
  Partial<HealthcheckCategoryWithAreas<T>>;

export type CachedItem<T> = T & { requested: Moment };
export type ItemKeyedById<T> = { [id: string]: T };

export interface HealthcheckState {
  genericHealthcheckError: null | string;
  questionsCategories: HealthcheckCategoriesMerged<HealthcheckQuestion> | null;
  actionsCategories: HealthcheckCategoriesMerged<HealthcheckAction> | null;
  review: HealthcheckReview | null;
  auditResults: HealthcheckAuditResult[] | null;
  currentPracticeHealthchecks: HealthcheckListSummary[];
  healthcheckById: ItemKeyedById<CachedItem<Healthcheck>>;
}
