import React from 'react';
import { reduxForm } from 'redux-form';

import { t } from '^/i18n';
import PureComponent from '^/components/common/PureComponent';
import FormError from '^/components/app/forms/FormError';
import DateInput from '^/components/app/forms/DateInput';
import TimeInput from '^/components/app/forms/TimeInput';
import { isPending, hasSucceeded } from '^/consts/responseStates';
import LiveButton from '^/components/app/forms/LiveButton';

const Field = function (props) {
  return (
    <div>
      <div className="form-group">
        <div className="col-1-3">
          <label htmlFor={props.id} className="label-left padded">{ props.label }</label>
        </div>
        <div className="col-2-3">
          <input
            type="text"
            id={props.id}
            placeholder={props.placeholder}
            className="form-control"
            disabled={isPending(props.response)}
            {...props}
          />
        </div>
      </div>
      <FormError response={props.response} formKeyPath={props.formKeyPath} />
    </div>
  );
};

export class UpdateProfileForm extends PureComponent {
  render() {
    const {
      fields: {
        email,
        alert_time,
        staffdetail: {
          first_name,
          last_name,
          mobile,
          phone,
          birth_date,
          indemnity_provider,
          indemnity_expiration_date,
          has_indemnity_provider
        }
      },
      handleSubmit, response
    } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <Field
          id="first_name"
          response={response}
          label={t('common.forms.firstName', 'First Name')}
          placeholder={t('common.forms.firstName', 'First Name')}
          formKeyPath={['staffdetail', 'first_name']}
          {...first_name}
        />

        <Field
          id="last_name"
          response={response}
          label={t('common.forms.lastName', 'Last name')}
          placeholder={t('common.forms.lastName', 'Last name')}
          formKeyPath={['staffdetail', 'last_name']}
          {...last_name}
        />

        <Field
          id="email"
          response={response}
          label={t('common.forms.email', 'Email')}
          placeholder={t('common.forms.email', 'Email')}
          formKeyPath={['email']}
          {...email}
        />

        <Field
          id="phone"
          response={response}
          label={t('common.forms.phone', 'Phone')}
          placeholder={t('common.forms.phone', 'Phone')}
          formKeyPath={['staffdetail', 'phone']}
          {...phone}
        />

        <Field
          id="mobile"
          response={response}
          label={t('common.forms.mobile', 'Mobile')}
          placeholder={t('common.forms.mobile', 'Mobile')}
          formKeyPath={['staffdetail', 'mobile']}
          {...mobile}
        />

        <div className="form-group">
          <div className="col-1-3">
            <label htmlFor="birth_date" className="label-left padded">{t('common.forms.DOB', 'Date of Birth')}</label>
          </div>
          <div className="col-2-3">
            <DateInput id="birth_date" readonly={isPending(response)} {...birth_date} />
          </div>
        </div>
        <FormError response={response} formKeyPath={['staffdetail', 'birth_date']} />


        <div className="form-group">
          <div className="col-1-3">
            <label htmlFor="alert_time" className="label-left padded">
              {t('users.profile.preferredAlertTime', 'Preferred email alert time')}
            </label>
            <span className="help-inline">{t('common.hours', 'Hours')}</span>
          </div>
          <div className="col-2-3">
            <TimeInput id="alert_time" readonly={isPending(response)} {...alert_time} />
          </div>
        </div>
        <FormError response={response} formKey="alert_time" />

        <div className="form-group">
          <div className="col-1-3 label-left">
            <h5>{t('users.profile.professionalIndemnity', 'Professional Indemnity')}</h5>
          </div>
          <div className="col-2-3" id="has_indemnity_provider">
           <label className="label-inline">
              <input
                {...has_indemnity_provider}
                type="radio"
                value="true"
                checked={has_indemnity_provider.value === 'true'}
              />
              {t('common.label.yes', 'Yes')}
            </label>
            <label className="label-inline">
              <input
                {...has_indemnity_provider}
                type="radio"
                value="false"
                checked={has_indemnity_provider.value !== 'true'}
              />
              {t('common.no', 'No')}
            </label>
          </div>
        </div>

        { has_indemnity_provider.value === 'true' && (
          <div>
            <Field
              id="indemnity_provider"
              response={response}
              label={t('users.profile.indemnityProvider', 'Indemnity Provider')}
              placeholder={t('users.profile.indemnityProvider', 'Indemnity Provider')}
              formKeyPath={['staffdetail', 'indemnity_provider']}
              {...indemnity_provider}
            />

            <div className="form-group">
              <div className="col-1-3">
                <label htmlFor="indemnity_expiration_date" className="label-left padded">
                  {t('users.profile.expiryDate', 'Expiry Date')}
                </label>
              </div>
              <div className="col-2-3">
                <DateInput
                  id="indemnity_expiration_date"
                  readonly={isPending(response)}
                  {...indemnity_expiration_date}
                />
              </div>
            </div>
            <FormError response={response} formKeyPath={['staffdetail', 'indemnity_expiration_date']} />
          </div>
        )}

        <hr className="thin" />

        <div className="col-1-3">
          <LiveButton
            className="btn btn-primary pull-right"
            response={response}
            pendingMessage={`${t('common.updating', 'Updating')}...`}
          >
            {t('common.button.submit', 'Submit')}
          </LiveButton>
        </div>
        { hasSucceeded(response) && (<p className="alert mt-1 alert-success">{t('common.forms.successfullyUpdated', 'Successfully updated')}!</p>) }
      </form>
    );
  }
}

export default reduxForm({
  form: 'userProfile',
  fields: [
    'staffdetail.first_name',
    'staffdetail.last_name',
    'staffdetail.mobile',
    'staffdetail.phone',
    'staffdetail.birth_date',
    'staffdetail.indemnity_provider',
    'staffdetail.has_indemnity_provider',
    'staffdetail.indemnity_expiration_date',
    'alert_time',
    'email',
  ],
})(UpdateProfileForm);
