import React from 'react';
import { connect } from 'react-redux';

import { switchLocaleAndReload } from '^/actions/actionSequences';

function onSwitchLocale(newLocale, connectedSwitchLocaleAndReload) {
  const currentLocale = window.USER_PREFERRED_LANGUAGE;
  if (newLocale !== currentLocale) {
    connectedSwitchLocaleAndReload(newLocale);
  }
}

export const LocaleSwitcher = ({ connectedSwitchLocaleAndReload }) => {
  const currentLocale = window.USER_PREFERRED_LANGUAGE;

  return (
    <select
      className="nav-switcher"
      key="nav-switcher"
      defaultValue={currentLocale}
      onChange={event =>
        onSwitchLocale(event.target.value, connectedSwitchLocaleAndReload)
      }
    >
      <option key="en" value="en">
        English
      </option>
      <option key="nl" value="nl">
        Dutch
      </option>
    </select>
  );
};

export default connect(null, {
  connectedSwitchLocaleAndReload: switchLocaleAndReload,
})(LocaleSwitcher);
