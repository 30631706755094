import { t } from '^/i18n';
import createSummaryTable from './hoc/create-summary-table';
import HealthcheckActionCell from '^/components/app/healthcheck/action-cell';
import { HEALTHCHECK_SUMMARY_TABLE_COMMON_COLUMNS } from '^/consts/healthcheck-audit';
import { HealthcheckListSummary } from './types';

export default createSummaryTable<HealthcheckListSummary>({
  columns: [
    ...HEALTHCHECK_SUMMARY_TABLE_COMMON_COLUMNS,
    {
      name: t('common.actions', 'Actions'),
      renderer: HealthcheckActionCell,
    },
  ],
});
