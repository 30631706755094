import React from 'react';
import { Map } from 'immutable';

import { makeStaticPath } from '^/utils';

import FormError from '^/components/app/forms/FormError';
import DateInput from '^/components/app/forms/DateInput';
import TimeInput from '^/components/app/forms/TimeInput';
import Icon from '^/components/app/content/Icon';
import RelatedTasks from '^/components/app/digital-tools/records/RelatedTasks';
import ChoosePracticeMembers from '^/components/app/digital-tools/records/digital-records/forms/ChoosePracticeMembers';
import DemoModeDisable from '^/components/app/perms/DemoModeDisable';
import MultipleFileUpload from '^/components/app/digital-tools/records/digital-records/forms/MultipleFileUpload';
import { DISABLED_STYLE } from '^/consts/dropzone';
import { t } from '^/i18n';

export const EVENT_TYPE_OPTIONS = [
  {
    "key": "PROBLEM",
    "value": t('digitalTools.records.problem', 'Problem'),
  },
  {
    "key": "SAFETY_INCIDENT",
    "value": t('digitalTools.records.safetyIncident', 'Safety Incident'),
  },
  {
    "key": "SAFETY_ALERT",
    "value": t('digitalTools.records.safetyAlert', 'Safety Alert'),
  },
  {
    "key": "NEVER_EVENT",
    "value": t('digitalTools.records.neverEvent', 'Never Event'),
  },
  {
    "key": "HAZARD_OBSERVATION",
    "value": t('digitalTools.records.hazardObservation', 'Hazard Observation'),
  },
  {
    "key": "NEGATIVE_REVIEW",
    "value": t('digitalTools.records.negativeReview', 'Negative Review'),
  },
  {
    "key": "SIGNIFICANT",
    "value": t('digitalTools.records.significant', 'Significant'),
  },
  {
    "key": "OTHER",
    "value": t('activities.activityTagText.Other', 'Other'),
  },
  {
    "key": "COMPLIMENT",
    "value": t('digitalTools.records.compliment', 'Compliment'),
  },
  {
    "key": "COMPLAINT",
    "value": t('digitalTools.records.complaint', 'Complaint'),
  },
];

export const EVENT_SEVERITY_OPTIONS = [
  {
    "key": "LOW",
    "value": t('digitalTools.records.low', 'Low')
  },
  {
    "key": "MEDIUM",
    "value": t('digitalTools.records.medium', 'Medium')
  },
  {
    "key": "HIGH",
    "value": t('digitalTools.records.high', 'High')
  },
];

export const EVENT_STATUS_CHOICES = [
  {
    "key": "OPEN",
    "value": t('common.button.open', 'Open'),
  },
  {
    "key": "IN_PROGRESS",
    "value": t('common.label.inProgress.capitalCase', 'In Progress')
  },
  {
    "key": "CLOSED",
    "value": t('common.label.closed', 'Closed')
  },
];

export const COSHH_SAFETY_PICTOGRAM_CHOICES = [
  {
    image: <img
            src={makeStaticPath('images/icons/ozone-layer-hazard.jpg')}
            alt="ozone layer hazard"
            height="100px"
          />,
    label: t('digitalTools.records.hazardousToTheOzoneLayer', 'Hazardous to the Ozone Layer'),
    value: "HEALTH_HAZARD",
  },
  {
    image: <img
            src={makeStaticPath("images/icons/environment-hazard.jpg")}
            alt="environment hazard"
            height="100px"
          />,
    label: t('digitalTools.records.hazardousToTheEnvironment', 'Hazardous to the Environment'),
    value: "ENVIRONMENTAL_HAZARD"
  },
  {
    image: <img
            src={makeStaticPath("images/icons/oxidising-agent-hazard.jpg")}
            alt="oxidising agent hazard"
            height="100px"
          />,
    label: t('digitalTools.records.oxidisingAgent', 'Oxidising Agent'),
    value: "OXIDISING_AGENT"
  },
  {
    image: <img
            src={makeStaticPath("images/icons/serious-health-hazard.jpg")}
            alt="serious health hazard"
            height="100px"
          />,
    label: t('digitalTools.records.seriousHealthHazard', 'Serious Health Hazard'),
    value: "SERIOUS_HEALTH_HAZARD"
  },
  {
    image: <img
            src={makeStaticPath("images/icons/acute-toxicity.jpg")}
            alt="acute toxicity"
            height="100px"
          />,
    label: t('digitalTools.records.acuteToxicity', 'Acute Toxicity'),
    value: "ACUTE_TOXICITY"
  },
  {
    image: <img
            src={makeStaticPath("images/icons/corrosive.jpg")}
            alt="corrosive"
            height="100px"
          />,
    label: t('digitalTools.records.corrosive', 'Corrosive'),
    value: "CORROSIVE"
  },
  {
    image: <img
            src={makeStaticPath("images/icons/explosive.jpg")}
            alt="explosive"
            height="100px"
          />,
    label: t('digitalTools.records.explosive', 'Explosive'),
    value: "EXPLOSIVE"
  },
  {
    image: <img
            src={makeStaticPath("images/icons/flammable.jpg")}
            alt="flammable"
            height="100px"
          />,
    label: t('digitalTools.records.flammable', 'Flammable'),
    value: "FLAMMABLE"
  },
  {
    image: <img
            src={makeStaticPath("images/icons/gas-under-pressure.jpg")}
            alt="gas under pressure"
            height="100px"
          />,
    label: t('digitalTools.records.gasUnderPressure', 'Gas Under Pressure'),
    value: "GAS_UNDER_PRESSURE"
  },
];

export const getPracticeMembersOptions = (practiceMembers) => practiceMembers.map((member) => {
  return Map({
    name: member.getIn(['user', 'staffdetail', 'full_name']),
    id: member.getIn(['user', 'staffdetail', 'id']),
  });
});

export const renderOptionsField = (
  field,
  label,
  options,
  optionsLabel,
  response,
  isArchived,
  isMandatory=false
) => {
  return (
    <div className="form-group">
      <div className="col-1-3">
        <label htmlFor={field.name}>
          {isMandatory ? label + "*" : label}
        </label>
      </div>
      <div className="col-2-3">
        {isArchived ?
          <p>
            {options === EVENT_STATUS_CHOICES ?
              t('common.archived', 'Archived') :
              options.map(option => option.key === field.value && option.value)
            }
          </p> :
          <select
            id={field.name}
            className="form-control-margin"
            {...field}
          >
            <option value={0}>{optionsLabel}</option>
            {options.map(option =>
              <option key={option.key} value={option.key}>
                {option.value}
              </option>
            )}
          </select>
        }
        <FormError response={response} formKey={field.name} />
        <FormError
          error={field && field.touched && field.error}
        />
      </div>
    </div>
  );
}

export const renderTypeField = (type, response, isArchived) => {
  const EMERGENCY_DRUGS_AND_EQUIPMENT_TYPE_OPTIONS = [
    {
      "key": "EQUIPMENT",
      "value": t('digitalTools.records.equipment', 'Equipment'),
    },
    {
      "key": "DRUG",
      "value": t('digitalTools.records.drug', 'Drug'),
    },
  ];

  return (
    <div className="form-group">
      <div className="col-1-3">
        <label htmlFor="type">{ t('common.type', 'Type') }*</label>
      </div>
      <div className="col-2-3">
        {isArchived ?
          <p>{type.value}</p> :
          <select
            id="type"
            className="form-control-margin"
            {...type}
          >
            <option value={0}>{t('common.chooseType', 'Choose a type')}...</option>
            {EMERGENCY_DRUGS_AND_EQUIPMENT_TYPE_OPTIONS.map(typeOption =>
              <option key={typeOption.key} value={typeOption.value}>
                {typeOption.value}
              </option>
            )}
          </select>
        }
        <FormError response={response} formKey="type" />
        <FormError
          error={type && type.touched && type.error}
        />
      </div>
    </div>
  );
}

export const renderTextField = (
  field,
  label,
  response,
  isArchived,
  extraInfo = null
) => {
  return (
    <div className="form-group">
      <div className="col-1-3">
        <label htmlFor="field">{label}</label>
      </div>
      <div className="col-2-3 extra-info-to-side">
        {isArchived ? (
          <p>{field.value}</p>
        ) : (
          <input
            type="text"
            placeholder={label}
            className="form-control"
            id={field.name}
            {...field}
          />
        )}
        {extraInfo ? (
          <div className="extra-info-containter pull-right">
            <Icon type="info-circle" className="tip-icon" />
            <span className="extra-info-text">{extraInfo}</span>
          </div>
        ) : null}
        <FormError response={response} formKey="field" />
        <FormError error={field && field.touched && field.error} />
      </div>
    </div>
  );
};

export const renderDateField = (
  field,
  label,
  response,
  isArchived,
  isMandatory=false
) => {
  return (
    <div className="form-group">
      <div className="col-1-3">
        <label className="bold-text">
          {isMandatory ? label + "*" : label}
        </label>
      </div>
      <div className="col-2-3">
        {isArchived ?
          <p>{field.value}</p> :
          <DateInput id={field.name} value={field.value} {...field} />
        }
        <FormError response={response} formKey={field.name} />
        <FormError error={field && field.touched && field.error} />
      </div>
    </div>
  );
}

export const renderExpiryDateField = (expiry_date, response, isArchived, disabled) => { 
  return (
    <div className="form-group">
      <div className="col-1-3">
        <label className="bold-text">{ t('users.profile.expiryDate') }</label>
      </div>
      <div className="col-2-3">
        {isArchived ?
          <p>{expiry_date.value}</p> :
          <div>
          <DateInput id="expiry_date" value={expiry_date.value} {...expiry_date} disabled={disabled} />
          </div>
        }
        <FormError response={response} formKey={"expiry_date"} />
        <FormError error={expiry_date && expiry_date.touched && expiry_date.error} />
      </div>
    </div>
  );
}


export const renderExpiryDateFieldWithNull = (expiry_date, label, response, isArchived, disabled, expiry_date_null, value, onChange) => { 
  return (
    <div className="form-group">
      <div className="col-1-3">
        <label className="bold-text">{label}</label>
      </div>
      <div className="col-2-3 date-null">
        {isArchived ?
          <p>{expiry_date.value}</p> :
          <div>
          <DateInput id="null_expiry_date" value={expiry_date.value} {...expiry_date} disabled={disabled} />
          </div>
        }
        <label className=""> 
        { t('common.notApplicable', 'N/A') }
        <input
        {...expiry_date_null}
          type="checkbox"
          id="no_expiry"
          value={value}
          onChange={onChange}
        />
      </label>
        <FormError response={response} formKey={"expiry_date"} />
        <FormError error={expiry_date && expiry_date.touched && expiry_date.error} />
      </div>
    </div>
  );
}

export const renderNotesField = (notes, response, isArchived) => {
  return (
    <div>
      <div className="form-group">
        <div className="col-1-3">
          <label className="notes-heading" htmlFor="notes">{ t('common.label.notes', 'Notes') }</label>
        </div>
      </div>
      <div className="form-group">
        <div className="ml-1 mb-2">
          <textarea
            id="notes"
            readOnly={isArchived}
            value={notes.value || ''}
            rows="5"
            cols="200"
            {...notes}
          />
          <FormError response={response} formKey="notes" />
          <p className="italic-text">
            {
              isArchived ?
              t('common.label.cannotEditArchivedNote', 'You cannot edit an archived note') :
              t('common.label.updateFieldsAfterSaving', 'You will be able to update all fields after saving.')
            }
          </p>
        </div>
      </div>
    </div>
  )
}

export const renderAssignToLabel = (person_or_role, isArchived) => {
  return (
    <div className="form-group">
      <div className="col-1-3">
        <label className="mr-1" htmlFor="person_or_role">
          {
            isArchived ?
            `${t('digitalTools.records.assignedTo', 'Assigned to')}:` :
            `${t('tasks.assignTo', 'Assign to')}:`
          }
        </label>
      </div>
      <div className="col-2-3">
        {isArchived ?
          <p>{person_or_role.value}</p> :
          <div>
            <label className="label-inline">
            <input
              {...person_or_role}
              type="radio"
              value="person"
              checked={person_or_role.value === 'person'}
            />
            { t('digitalTools.records.person', 'Person') }
            </label>
            <label className="label-inline">
              <input
                {...person_or_role}
                type="radio"
                value="role"
                checked={person_or_role.value === 'role'}
              />
              { t('digitalTools.records.role', 'Role') }
            </label>
          </div>
        }
      </div>
    </div>
  )
}

export const renderPersonOrRoleDropDown = (
  assign_to,
  person_or_role,
  practiceMembers,
  responsibilities,
  response,
  isArchived
) => {
  return (
    <div className="form-group">
      <div className="col-1-3">
        <label className="mr-1" htmlFor="assign_to">
          {
            isArchived ?
            t('digitalTools.records.personOrRole', 'Person/Role') :
            t('digitalTools.records.selectRoleOrPerson', 'Select a role/person:')
          }
        </label>
      </div>
      <div className="col-2-3">
        <select
          defaultValue={null}
          id="type"
          className="form-control-margin"
          disabled={isArchived}
          {...assign_to}
        >
          <option value={null}>{t('common.chooseType', 'Choose a type')}...</option>
          {
            person_or_role.value === 'person' ?
            practiceMembers.map(practiceMember =>
              <option key={practiceMember.id} value={practiceMember.id}>
                {practiceMember.name}
              </option>
            ) :
            responsibilities.map(responsibilityOption =>
              <option
                key={responsibilityOption.get('id')}
                value={responsibilityOption.get('id')}
              >
                {responsibilityOption.get('name')}
              </option>)
          }
          </select>
        <FormError response={response} formKey="assign_to" />
        <FormError error={assign_to && assign_to.touched && assign_to.error} />
      </div>
    </div>
  )
}


export const practiceMemberField = (
  assign_to,
  practiceMembers,
  response,
  isArchived
) => {
  return (
    <div className="form-group">
      <div className="col-1-3">
        <label className="mr-1" htmlFor="assign_to">
          { t('digitalTools.records.teamMemberInCharge', 'Team Member in charge*') }
        </label>
      </div>
      <div className="col-2-3">
        <select
          defaultValue={null}
          id="type"
          className="form-control-margin"
          disabled={isArchived}
          {...assign_to}
        >
          <option value={null}>{ t('digitalTools.option.chooseAPracticeMember.capitalCase', 'Choose a Practice Member') }</option>
          {
            practiceMembers.map(practiceMember =>
              <option key={practiceMember.id} value={practiceMember.id}>
                {practiceMember.name}
              </option>)
          }
          </select>
        <FormError response={response} formKey="assign_to" />
        <FormError error={assign_to && assign_to.touched && assign_to.error} />
      </div>
    </div>
  )
}

export const renderPracticeMembersField = (
  field,
  label,
  practiceMemberOptions,
  response,
  isArchived,
  isMandatory=false
) => {
  return (
    <div className="form-group">
      <div className="col-1-3">
        <label className="mr-1" htmlFor={field.name}>
          {isMandatory ? label + '*' : label}
        </label>
      </div>
      <div className="col-2-3">
        <select
          id={field.name}
          className="form-control-margin"
          disabled={isArchived}
          {...field}
        >
          <option value={0}>{t('digitalTools.option.chooseAPracticeMember.capitalCase', 'Choose practice member')}...</option>
          {
            practiceMemberOptions.map(practiceMember =>
              <option key={practiceMember.get("id")} value={practiceMember.get("id")}>
                {practiceMember.get("name")}
              </option>)
          }
          </select>
        <FormError response={response} formKey={field.name} />
        <FormError error={field && field.touched && field.error} />
      </div>
    </div>
  )
}

export const renderEquipmentTrainingProvidedField = (
  equipment_training_provided,
  response,
  isArchived
) => {
  return (
    <div className="form-group">
      <div className="col-1-3">
        <label className="mr-1" htmlFor="equipment_training_provided">
          { t('digitalTools.label.wasFireFightingTrainingEquipmentProvided', 'Was fire fighting training equipment provided') }
        </label>
      </div>
      <div className="col-2-3">
        <select
          defaultValue={false}
          id="type"
          className="form-control-margin"
          disabled={isArchived}
          {...equipment_training_provided}
        >
        {
          [{name: t('common.no', 'No'), value: false}, {name: t('common.yes', 'Yes'), value: true}].map(trainingProvidedValue =>
            <option key={trainingProvidedValue.name} value={trainingProvidedValue.value}>
              {trainingProvidedValue.name}
            </option>)
        }
          </select>
        <FormError response={response} formKey="equipment_training_provided" />
        <FormError error={equipment_training_provided && equipment_training_provided.touched && equipment_training_provided.error} />
      </div>
    </div>
  )
}

export const renderCompleteDateField = (completed_dated, response, isArchived) => {
  return (
    <div className="form-group">
      <div className="col-1-3">
        <label className="bold-text">{t('common.date', 'Date')}*</label>
      </div>
      <div className="col-2-3">
        {isArchived ?
          <p>{completed_dated.value}</p> :
          <DateInput id="completed_date" value={completed_dated.value} {...completed_dated} />
        }
        <FormError response={response} formKey={"expiry_date"} />
        <FormError error={completed_dated && completed_dated.touched && completed_dated.error} />
      </div>
    </div>
  );
}

export const renderCompleteTimeField = (completed_time, response, isArchived) => {
  return (
    <div className="form-group">
      <div className="col-1-3">
        <label className="bold-text">{t('common.time', 'Time')}*</label>
      </div>
      <div className="col-2-3">
        {isArchived ?
          <p>{completed_time.value}</p> :
          <TimeInput
            id="alert_time"
            className={"timeinput-field-narrow"}
            timeConstraints={{minutes: { step: 1 }}}
            {...completed_time}
          />
        }
        <FormError response={response} formKey={"expiry_date"} />
        <FormError error={completed_time && completed_time.touched && completed_time.error} />
      </div>
    </div>
  );
}

export const renderEvacuationDurationField = (
  evacuation_duration_mins,
  evacuation_duration_secs
) => {
  return (
    <div className="form-group">
      <div className="col-1-3">
        <label className="bold-text">{t('digitalTools.label.evacuationDuration', 'Evacuation Duration')}*</label>
      </div>
      <div className="col-2-3 evac-duration-field">
        <input
          className="number-time"
          type="number"
          max="60"
          min="0"
          {...evacuation_duration_mins}
        />
        <label className="bold-text">{t('common.labels.mins', 'Mins')}</label>
        <input
          className="number-time"
          type="number"
          max="60"
          min="0"
          {...evacuation_duration_secs}
        />
        <label className="bold-text">{t('common.label.secs', 'Secs')}</label>
      </div>
    </div>
  );
};


export const renderRelatedTasksField = (staff_tasks, isArchived) => {
  return (
    <div className="form-group">
      <div className="col-1-3">
        <label className="bold-text">{t('digitalTools.label.addFollowUpActions', 'Add follow-up actions')}</label>
      </div>
      <div className="col-2-3">
        <RelatedTasks tasks={staff_tasks} isArchived={isArchived} hideRepeat showTitle />
      </div>
    </div>
  );
}

export const renderCheckboxField = (field, label, response, isArchived, isMandatory=false) => {
  return (
    <div className="form-group">
      <div className="col-1-3">
        <label className="bold-text">{isMandatory ? label + "*" : label}</label>
      </div>
      <div className="col-2-3">
        <input
          type="checkbox"
          className="form-control"
          id={field.name}
          disabled={isArchived}
          {...field}
        />
        <FormError response={response} formKey={field.name} />
      </div>
    </div>
  );
}

export const renderTextAreaField = (field, label, response, isArchived) => {
  return (
    <div>
      <div className="form-group">
        <div className="col-2-3">
          <label htmlFor={field.name}>{label}</label>
        </div>
      </div>
      <div className="form-group">
        <div className="ml-1 mb-2">
          <textarea
            defaultValue={""}
            id={field.name}
            readOnly={isArchived}
            value={field.value || ''}
            rows="5"
            cols="200"
            {...field}
          />
          <FormError response={response} formKey={field.name} />
        </div>
      </div>
    </div>
  );
}

export const renderChoosePracticeMembersField = (
  field,
  label,
  optionLabel,
  chosenMemberSet,
  practiceMemberOptions,
  isArchived,
  isMandatory=false
) => {
  return (
    <div className="form-group">
      <div className="col-1-3">
        <label htmlFor={field.name}>
          {(isMandatory && !isArchived) ? label + "*" : label}
        </label>
      </div>
      <div className="col-2-3">
        <ChoosePracticeMembers
          chosenMembers={practiceMemberOptions.filter(each =>
            chosenMemberSet.contains(each.get('id'))
          )}
          practiceMembers={practiceMemberOptions}
          onAdd={id => field.onChange(chosenMemberSet.add(id).toArray())}
          onRemove={id =>
            field.onChange(chosenMemberSet.delete(id).toArray())
          }
          optionLabel={optionLabel}
          isArchived={isArchived}
        />
      </div>
    </div>
  );
}

export const renderMultipleFileUploadField = (
  field,
  label,
  handleSubmit,
  uploadFile,
  uploadFileResponse,
  formName,
  isArchived,
  isMandatory=false,
) => {
  return (
    <div className="form-group">
      <div className="col-1-3">
        <label htmlFor={field.name}>
          {isMandatory ? label + "*" : label}
        </label>
      </div>
      <div className="col-2-3">
        <DemoModeDisable message={t('digitalTools.records.cannotAttachFiles', 'cannot attach files')}>
          <MultipleFileUpload
            handleSubmit={handleSubmit}
            field={field}
            uploadFile={uploadFile}
            uploadFileResponse={uploadFileResponse}
            formName={formName}
            isArchived={isArchived}
          />
        </DemoModeDisable>
      </div>
    </div>
  );
}

export const renderDisabledFileUploadField = (label) => {
  return (
    <div className="form-group">
      <div className="col-1-3">
        <label>
          {label}
        </label>
      </div>
      <div className="col-2-3">
        <div style={DISABLED_STYLE} />
        <button disabled type="button" className="btn btn-default">
          Select a file
        </button>
      </div>
    </div>
  );
}
