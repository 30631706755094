import React from 'react';
import { reduxForm } from 'redux-form';

import { t } from '^/i18n';
import PureComponent from '^/components/common/PureComponent';
import SuccessMessage from '^/components/app/content/SuccessMessage';
import FormError from '^/components/app/forms/FormError';
import Icon from '^/components/app/content/Icon';
import LiveButtonSet from '^/components/app/forms/LiveButtonSet';


export class CompletionNotesForm extends PureComponent {

  render() {
    const {
      fields: { completion_notes },
      handleSubmit,
      onMarkComplete,
      response,
      values,
      hideSave,
      onCancel
    } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <textarea cols="30" rows="10" {...completion_notes} />

        <FormError response={response} formKey="completion_notes" />

        <SuccessMessage className="mt-1" response={response}>{t('tasks.savedSuccessfully', 'Saved successfully')}!</SuccessMessage>

        <div className="mt-1">
          <LiveButtonSet pendingMessage={`${t('common.updating', 'Updating')}...`} response={response}>
            {!hideSave && (
              <button type="submit" className="btn btn-save indented mr-1">
                <Icon type="folder-open" className="button-icon" /> {t('common.button.save', 'Save')}
              </button>
            )}

            {onCancel && (
              <button type="button" className="btn btn-default mr-1" onClick={onCancel}>
                {t('common.button.cancel', 'Cancel')}
              </button>
            )}

            <button
              type="button"
              className="btn btn-complete indented"
              onClick={() => onMarkComplete(values)}
            >
             <Icon type="check" className="button-icon" /> {t('common.button.markAsComplete', 'Mark as complete')}
            </button>
          </LiveButtonSet>


        </div>
      </form>
    );
  }
}

export default reduxForm({
  form: 'setCompletionNotes',
  fields: ['completion_notes'],
}) (CompletionNotesForm);
