import React from 'react';
import { connect } from 'react-redux';

import { t } from '^/i18n';
import PureComponent from '^/components/common/PureComponent';
import { copyTemplateVersionWithNameAndRedirectToAndPrint } from '^/actions/actionSequences';
import { collapseUiComponent, resetResponse } from '^/actions/actions';
import NameDocumentCopyForm from './NameDocumentCopyForm';
import { getDocumentLink } from '^/models/documents';


export class NameDocumentCopyModal extends PureComponent {
  componentWillMount() {
    this.props.resetResponse('copyTemplateVersion');
  }

  submit({name}) {
    const { redirectToFunc, doc, content, currentPractice, activityId, documentType } = this.props;

    this.props.copyTemplateVersionWithNameAndRedirectToAndPrint(
      doc,
      content,
      name,
      currentPractice,
      null,
      redirectToFunc || (version => getDocumentLink(version.copied_template_document.id)),
      activityId,
      documentType
    );
  }

  render() {
    return (
      <div>
        <p>{t('templateDocument.enterCopyNameMsg', 'Please enter a name for your copy')}</p>
        <NameDocumentCopyForm
          onSubmit={this.submit.bind(this)}
          initialValues={{name: this.props.defaultName}}
          response={this.props.response}
        />
      </div>
    );
  }
}

export function mapStateToProps(state) {
  return {
    response: state.responses.get('copyTemplateVersion'),
  };
}

export default connect(mapStateToProps, {
  copyTemplateVersionWithNameAndRedirectToAndPrint,
  collapseUiComponent,
  resetResponse
})(NameDocumentCopyModal);
