import { t } from '^/i18n';

import {
  TEMPLATE_CATEGORIES,
  TEMPLATE_ADMIN_STEPS,
  TEMPLATE_FOLDERS,
  TEMPLATE_PRIORITIES,
} from '^/consts/collectionKeys';

export const COMMON_VIEW_BY = {
  CATEGORY: 'CATEGORY',
  FOLDER: 'FOLDER',
};

export const PRACTICE_TEMPLATE_VIEW_BY = {
  ...COMMON_VIEW_BY,
  PRIORITY: 'PRIORITY',
};

export const TEMPLATE_VIEW_BY = {
  ...COMMON_VIEW_BY,
  RELATED_ADMIN_STEP: 'RELATED_ADMIN_STEP',
  NEW_AND_UPDATED_DOCUMENTS: 'NEW_AND_UPDATED_DOCUMENTS',
  DRAFTS: 'DRAFTS',
};

export const TEMPLATE_VIEW_BY_TEXT = {
  [TEMPLATE_VIEW_BY.CATEGORY]: t('consts.category', 'Category'),
  [TEMPLATE_VIEW_BY.RELATED_ADMIN_STEP]: t('consts.relatedAdminStep', 'Related admin step'),
  [TEMPLATE_VIEW_BY.FOLDER]: t('consts.folder', 'Folder'),
  [TEMPLATE_VIEW_BY.NEW_AND_UPDATED_DOCUMENTS]: t('consts.newAndUpdated', 'New and Updated'),
  [TEMPLATE_VIEW_BY.DRAFTS]: t('consts.drafts', 'Drafts'),
  [PRACTICE_TEMPLATE_VIEW_BY.PRIORITY]: t('consts.priority', 'Priority'),
};

export const TEMPLATE_VIEW_BY_COLLECTION = {
  [TEMPLATE_VIEW_BY.CATEGORY]: TEMPLATE_CATEGORIES,
  [TEMPLATE_VIEW_BY.RELATED_ADMIN_STEP]: TEMPLATE_ADMIN_STEPS,
  [TEMPLATE_VIEW_BY.FOLDER]: TEMPLATE_FOLDERS,
  [PRACTICE_TEMPLATE_VIEW_BY.PRIORITY]: TEMPLATE_PRIORITIES,
};

export const TEMPLATE_VIEW_BY_PROPERTIES = {
  [TEMPLATE_VIEW_BY.CATEGORY]: 'template_category',
  [TEMPLATE_VIEW_BY.RELATED_ADMIN_STEP]: 'template_activities',
  [TEMPLATE_VIEW_BY.FOLDER]: 'folder',
  [PRACTICE_TEMPLATE_VIEW_BY.PRIORITY]: 'priority',
};

export const VIEW_BY_NAME = 'TEMPLATE_DOCUMENTS_VIEW_BY';
export const SEARCH_NAME = 'TEMPLATE_DOCUMENTS_SEARCH';
export const TEMPLATE_DOCUMENTS_TABSET = 'practice-group';
