import React from 'react';

import { t } from '^/i18n';
import PureComponent from '^/components/common/PureComponent';


class EditMemberActivitiesModal extends PureComponent {
  render() {
    return (
      <div>
        <h2>{t('common.notYetImplemented', 'Not yet implemented.')}</h2>
      </div>
    );
  }
}

export default EditMemberActivitiesModal;
