import React from 'react';
import { connect } from 'react-redux';
import ImmutablePropTypes from 'react-immutable-proptypes';

import { t } from '^/i18n';
import { isPending, hasSucceeded } from '^/consts/responseStates';
import { generateComplianceReportPdf, resetResponse } from '^/actions/actions';
import PureComponent from '^/components/common/PureComponent';
import Icon from '^/components/app/content/Icon';
import PollDownload from '^/components/app/activities/documents/PollDownload';

const BUTTON_CLASS_NAME = 'btn btn-save indented pull-right mr-1';

export class DownloadReportButton extends PureComponent {
  componentWillMount() {
    this.props.resetResponse('generateComplianceReportPdf');
  }

  startRenderDocument() {
    this.props.generateComplianceReportPdf(this.props.filter.toObject());
  }

  render() {
    const { response } = this.props;

    if (!response || !response.get('state')) {
      return (
        <a className={BUTTON_CLASS_NAME} onClick={() => this.startRenderDocument()}>
          <Icon type="cloud-download" className="button-icon" /> { t('common.print', 'Print') }
        </a>
      );
    }

    if (isPending(response)) {
      return (
        <a className={BUTTON_CLASS_NAME} disabled>
          <Icon type="cog" className="button-icon rotate" /> { t('common.generatingPDF', 'Generating PDF') }...
        </a>
      );
    }

    if (hasSucceeded(response)) {
      return (
        <PollDownload
          className={BUTTON_CLASS_NAME}
          exportId={response.getIn(['payload', 'id'])}
          onRetry={() => this.startRenderDocument()}
        />
      );
    }

    return (
      <span>
        <a className={BUTTON_CLASS_NAME} onClick={() => this.startRenderDocument()}>
          <Icon type="cloud-download" className="button-icon" /> { t('common.print', 'Print') }
        </a>

        <p className="pull-right cr alert-error">
          { t('common.errorGeneratingPDF', 'Unable to access PDF. Please try again later.') }
        </p>
      </span>
    );
  }
}

DownloadReportButton.propTypes = {
  filter: ImmutablePropTypes.map.isRequired,
};

export function mapStateToProps(state) {
  return {
    response: state.responses.get('generateComplianceReportPdf'),
  };
}

export default connect(mapStateToProps, { generateComplianceReportPdf, resetResponse }) (DownloadReportButton);
