import React from 'react';
import { connect } from 'react-redux';

import { t } from '^/i18n';
import { getSetupTeamMembers } from '^/actions/actions';
import { loadItem } from '^/actions/items';
import { CYCLES, CYCLE_GROUP_TASKS } from '^/consts/collectionKeys';
import {
  setupCycleReviewGroupTasksAndRedirectToComplianceReviews,
} from '^/actions/actionSequences';
import { getCollectionFromUrl, getAllCollection } from '^/actions/collections';
import PureComponent from '^/components/common/PureComponent';
import Loading from '^/components/app/content/Loading';
import HelpBlock from '^/components/app/widgets/HelpBlock';
import { transformGroupTaskData } from '^/components/app/account-set-up/utils';
import CycleSetupHeader from '^/components/app/cycle-setup/CycleSetupHeader';
import CycleSetupReviewGroupTasksForm from '^/components/app/cycle-setup/CycleSetupReviewGroupTasksForm';
import { isPending, hasFailed } from '^/consts/responseStates';
import { getActiveCycleSetUpPracticeMembers } from '^/stateHelpers';


export class CycleSetupReviewGroupTasksPage extends PureComponent {

  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.onSubmitNoGroupTasks = this.onSubmitNoGroupTasks.bind(this);
  }

  componentDidMount() {
    const { practice, cycleId } = this.props;
    this.props.loadItem(CYCLES, cycleId);
    this.props.getSetupTeamMembers(practice.get('id'));
    this.props.getAllCollection(CYCLE_GROUP_TASKS);
  }

  onSubmit(data) {
    const { cycleId, setup } = this.props;
    setup(cycleId, transformGroupTaskData(data));
  }

  onSubmitNoGroupTasks() {
    const { cycleId, setup } = this.props;
    setup(cycleId, transformGroupTaskData([]));
  }

  render () {
    const { practice, teamMembers, cycle, groupTasks, setupResponse, user, isLoading, hasFailedToLoad } = this.props;

    if (isLoading) {
      return (<Loading />);
    }

    if (hasFailedToLoad) {
      return (<p className="text-error">{t('common.failedToLoad', 'Failed to load')}</p>);
    }

    if (!teamMembers || !cycle || !groupTasks) {
      return null;
    }


    return (
      <div>
        <CycleSetupHeader practice={practice}>{t('cycleSetup.reviewGroupTasks', 'Review Group Tasks')}</CycleSetupHeader>

        {groupTasks.count() === 0 ? (
          <div>
          <HelpBlock className='mb-2'>{t('cycleSetup.noGroupTasks', 'Your group has no tasks')}.</HelpBlock>
          <button
            className='btn btn-default'
            pendingMessage={(`${t('common.saving', 'Saving')}...`)}
            onClick={this.onSubmitNoGroupTasks}>
              {t('common.next', 'Next')}...
          </button>
          </div>
        ) : (
          <div>
            <HelpBlock className="mb-2">
              {t('cycleSetup.reviewGroupTasksMsg', 'Review the Group Tasks you require. Dates should be set now but these can be changed later in calendar.')}
            </HelpBlock>

            <CycleSetupReviewGroupTasksForm
              response={setupResponse}
              cycle={cycle}
              user={user}
              teamMembers={teamMembers}
              groupTasks={groupTasks}
              onSubmit={this.onSubmit}
            />
          </div>
        )}
      </div>
    );
  }

}


export const mapStateToProps = (state, props) => {
  const loadTeamMemberResponse = state.responses.get('getSetupTeamMembers');
  const loadCycleResponse = state.responses.getIn(['getCollection', CYCLES]);
  const loadGroupTasksResponse = state.responses.getIn(['loadItem', CYCLE_GROUP_TASKS]);
  return {
    user: state.userProfile,
    practice: state.currentPractice,
    cycleId: props.params.uuid,
    cycle: state.items.get(CYCLES),
    teamMembers: getActiveCycleSetUpPracticeMembers(state),
    groupTasks: state.collections.getIn([CYCLE_GROUP_TASKS, 'items']),
    setupResponse: state.responses.get('setupCycleReviewGroupTasks'),
    isLoading: Boolean(
      isPending(loadTeamMemberResponse) ||
      isPending(loadCycleResponse) ||
      isPending(loadGroupTasksResponse)
    ),
    hasFailedToLoad: Boolean(
      hasFailed(loadTeamMemberResponse) ||
      hasFailed(loadCycleResponse) ||
      hasFailed(loadGroupTasksResponse)
    ),
  };
}


export default connect(
  mapStateToProps,
  {
    loadItem,
    setup: setupCycleReviewGroupTasksAndRedirectToComplianceReviews,
    getAllCollection,
    getCollectionFromUrl,
    getSetupTeamMembers,
  }
) (CycleSetupReviewGroupTasksPage);
