import React from 'react';
import { reduxForm } from 'redux-form';

import { t } from '^/i18n';
import PureComponent from '^/components/common/PureComponent';
import FormError from '^/components/app/forms/FormError';
import LiveButton from '^/components/app/forms/LiveButton';

class CreateGroupFolderForm extends PureComponent {
  render() {
    return (
      <form onSubmit={this.props.handleSubmit}>
        <div className="form-group">
          <div className="col-1-3">
            <label htmlFor="name" className="label-left padded">{t('groups.label.folderName', 'Folder name')}:</label>
          </div>
          <div className="col-2-3">
            <input
              {...this.props.fields.name}
              id="name"
              type="text"
              placeholder="Folder name"
              className="form-control"
            />
            <FormError formKey="name" response={this.props.response} />
          </div>
        </div>
        <div className="form-group">
          <div className="col-1-3">
            <label htmlFor="number" className="label-left padded">{t('groups.label.folderNumber', 'Folder number')}:</label>
          </div>
          <div className="col-2-3">
            <input
              {...this.props.fields.number}
              id="number"
              type="text"
              placeholder="Folder number"
              className="form-control"
            />
            <FormError formKey="number" response={this.props.response} />
          </div>
        </div>
        <div className="align-right mt-1 p-1">
          <LiveButton className="btn btn-default ml-1" response={this.props.response}>
            {t('groups.button.addFolder', 'Add folder')}
          </LiveButton>
        </div>
      </form>
    );
  }
}

export { CreateGroupFolderForm as UnconnectedCreateGroupFolderForm };

export default reduxForm({
  form: 'CreateGroupFolderForm',
  fields: ['name', 'number'],
})(CreateGroupFolderForm);
