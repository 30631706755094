import React from 'react';

import { t } from '^/i18n';
import { groupDocumentsInsightsConfig, FILTER_KEYS } from '^/components/app/groups/insights/constants';
import Header from '^/components/app/groups/insights/Header';
import GroupDocumentsTable from '^/components/app/groups/insights/group-documents/GroupDocumentsTable';
import SubGroupFilter from '^/components/app/groups/insights/filters/SubGroupFilter';

import
  GroupDocumentsDetails
from '^/components/app/groups/insights/group-documents/GroupDocumentsDetails';


const GroupDocumentsPage = ({ location: { pathname, query } }) => (
  <div className="col-1">
    <Header
      shouldLinkToDashboard
      subtitle={t('groups.insights.groupDocuments', 'Group documents')}
      query={query}
    />
    <SubGroupFilter
      insightsConfig={groupDocumentsInsightsConfig}
      filterKey={FILTER_KEYS.SUB_GROUP}
      pathname={pathname}
      selectedSubGroup={query.sub_group}
      country={query.country}
    />
    <GroupDocumentsDetails />
    <GroupDocumentsTable />
  </div>
);

export default GroupDocumentsPage;
