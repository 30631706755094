import React from 'react';
import { reduxForm } from 'redux-form';

import { t } from '^/i18n';
import LiveButton from '^/components/app/forms/LiveButton';
import {
  SuggestedTasksSetupTable, validateTasks, getTaskFields
} from '^/components/app/account-set-up/SuggestedTasksSetupForm';
import { getGroupTasksSetupInitialValues } from '^/components/app/account-set-up/utils';

export const FORM_NAME = 'setupCycleReviewGroupTasks';

export const CycleSetupReviewGroupTasksForm = ({
  fields, handleSubmit, response, groupTasks, cycleStartDate, cycleEndDate, teamMembers,
}) =>
  <form onSubmit={handleSubmit}>
    <SuggestedTasksSetupTable
      fields={fields}
      response={response}
      templateOrGroupTasks={groupTasks}
      teamMembers={teamMembers}
      cycleEndDate={cycleEndDate}
      cycleStartDate={cycleStartDate}
    />

    <hr className="thin" />

    <LiveButton pendingMessage={(`${t('common.saving', 'Saving')}...`)} response={response}>{t('common.next', 'Next')}...</LiveButton>
  </form>;

export const mapStateToProps = (_state, { groupTasks, cycle, user, }) => {
  const { start_date, end_date } = cycle.toObject();
  return {
    fields: getTaskFields(groupTasks),
    initialValues: getGroupTasksSetupInitialValues(groupTasks, user),
    validate: (data, props) => validateTasks(data, start_date, props).toJS(),
    cycleStartDate: start_date,
    cycleEndDate: end_date,
  };
};

export default reduxForm({ form: FORM_NAME, }, mapStateToProps)(CycleSetupReviewGroupTasksForm);
