import React from 'react';
import moment from 'moment';

import { t } from '^/i18n';
import { formatDate } from '^/utils';

import { VersionAndDate } from '^/components/app/template-documents/DocumentEditor';

const MinimalDocumentHeader = ({ 
    name,
    versionNumber,
    created,
    categoryName,
    code,
    folderNumber,
    codeModified,
    templateModified,
    practiceName
  }) => {
    const wasReviewed = moment(templateModified).isAfter(moment(codeModified));
    const reviewWord = wasReviewed ? 'reviewed' : 'updated';
    const reviewDate = wasReviewed ? templateModified : codeModified;
  
    return(
      <div className="document-header-info">
        <h2>
          {name} <VersionAndDate version_number={versionNumber} created={created} />
        </h2>
        <div className="light-text small-text grey">
          {t('templateDocuments.adoptedBy', 'Adopted by')} <b>{practiceName}</b> <span className="heading-date">
            {formatDate(created)} (last {reviewWord} {formatDate(reviewDate)})
          </span>
        </div>
        <div>
          <span className="grey">{t('templateDocuments.category', 'Category')}: </span>
          <b className="bold-text">{categoryName}</b>
        </div>
        <div>
          <span className="grey">{('templateDocuments.code', 'Code')}: </span>
          <b className="bold-text">{code}</b>
        </div>
        <div>
          <span className="grey">{('templateDocuments.folder', 'Folder')}: </span>
          <b className="bold-text">{folderNumber}</b>
        </div>
      </div>
    );
  }

	export default MinimalDocumentHeader;
  