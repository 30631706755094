import React from 'react';
import { connect } from 'react-redux';
import ImmutablePropTypes from 'react-immutable-proptypes';

import { t } from '^/i18n';
import { setCollapse, switchPractice } from '^/actions/actions';
import PureComponent from '^/components/common/PureComponent';

export const COLLAPSE_ID = 'PRACTICE_FILTER';


export class PracticeSwitcher extends PureComponent {

  componentWillMount() {
    this.hideDropdown();
  }

  update(evt) {
    this.props.switchPractice(evt.target.value);
    this.hideDropdown();
  }

  showDropdown() {
    this.props.setCollapse(COLLAPSE_ID, false);
  }

  hideDropdown() {
    this.props.setCollapse(COLLAPSE_ID, true);
  }

  render() {
    const { showDropdown, practiceId, practices } = this.props;

    return showDropdown && practices ? (
      <span className="inline-link">
        <select
          value={practiceId}
          onChange={evt => this.update(evt)}
        >
          {practices.map(practice =>
            <option key={practice.get('id')} value={practice.get('id')}>
              {practice.get('name')}
            </option>
          )}
        </select> <a
          onClick={() => this.hideDropdown()}
        >{t('common.button.cancel', 'Cancel')}</a>
      </span>
    ) : (
      <span className="inline-link">
        <a onClick={() => this.showDropdown()}>
          {t('dashboard.button.changePractice', 'Change practice')}
        </a>
      </span>
    );
  }
}

PracticeSwitcher.propTypes = {
  practices: ImmutablePropTypes.list.isRequired,
  practiceId: React.PropTypes.string,
};

export function mapStateToProps(state) {
  return {
    showDropdown: !state.ui.getIn(['collapse', COLLAPSE_ID]),
  };
}

export default connect(mapStateToProps, { switchPractice, setCollapse })(PracticeSwitcher);

