import { t } from '^/i18n';

export const PRIORITIES = {
  CRITICAL: 'CRITICAL',
  HIGH: 'HIGH',
  MEDIUM: 'MEDIUM',
  LOW: 'LOW',
  OPERATIONAL: 'OPERATIONAL',
};

export const PRIORITY_NAMES = {
  [PRIORITIES.CRITICAL]: t('priorities.critical.capitalCase', 'Critical'),
  [PRIORITIES.HIGH]: t('priorities.high.capitalCase', 'High'),
  [PRIORITIES.MEDIUM]: t('priorities.medium.capitalCase', 'Medium'),
  [PRIORITIES.LOW]: t('priorities.low.capitalCase', 'Low'),
  [PRIORITIES.OPERATIONAL]: t('priorities.operational.capitalCase', 'Operational'),
};

export const PRIORITY_CLASSES = {
  [PRIORITIES.CRITICAL]: 'critical',
  [PRIORITIES.HIGH]: 'high',
  [PRIORITIES.MEDIUM]: 'medium',
  [PRIORITIES.LOW]: 'low',
  [PRIORITIES.OPERATIONAL]: 'operational',
};

export const CALENDAR_PRIORITY_FILTERS = [
  PRIORITIES.CRITICAL,
  PRIORITIES.HIGH,
  PRIORITIES.MEDIUM,
  PRIORITIES.LOW,
  PRIORITIES.OPERATIONAL,
];

export const DOCUMENT_PRIORITIES = [
  PRIORITIES.CRITICAL,
  PRIORITIES.HIGH,
  PRIORITIES.MEDIUM,
  PRIORITIES.LOW,
];
