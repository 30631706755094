import React, { Component } from 'react';

import { t } from '^/i18n';
import createIntroPage from './hoc/create-intro-page';
import { HealthcheckTypes, HealthcheckStatus } from './types';

class Content extends Component<{}, {}> {
  public render() {
    return (
      <div className="content">
        <p>
          {t(
            'healthcheck.compliaceUpToDateAuditIntroMessage',
            'It is important to ensure that your practice compliance is up to date. Inspectors want to see that practices who are behind on their compliance are aware of their gaps, are working to close them and are then on top of their legal requirements. The healthcheck will help you identify and close gaps, while giving you evidence you could show an inspector should they visit you during or after this process.'
          )}
        </p>
        <p className="mt-1">
          {t(
            'healthcheck.pressStartButtonMessage',
            'When you press the start button you will be presented with a series of questions under multiple categories. These questions are based on Agilio’s experience of helping practices get compliant and pass inspections.'
          )}
        </p>
        <p className="mt-1">
          {t(
            'healthcheck.answerOptionsForRelevantPracticesMessage',
            'For each question you will be able to answer ‘Yes’, ‘No’ or ‘N/A’. Only choose N/A if you are absolutely sure that this question doesn’t apply to you as most questions are relevant to all practices.'
          )}
        </p>
        <p className="mt-1">
          {t(
            'healthcheck.timeFrameToCompleteMessage',
            'It is important to bear in mind that the audit part of the healthcheck would take an Agilio consultant approximately 8 hours to complete in your practice with the assistance of the person responsible for compliance.'
          )}
        </p>
        <p className="mt-1">
          {t(
            'healthcheck.auditCompletionTimeMessage',
            'This audit should therefore take you approximately 8-16 hours to complete if performed properly.'
          )}
        </p>
        <p className="mt-1">
          {t(
            'healthcheck.keyPointsForPerforming',
            'Key points for performing a quality audit'
          )}
          :
        </p>
        <ul className="mt-1">
          <li>
            {t(
              'healthcheck.takeYourTimeMessage',
              'Take your time – As said above this audit would take a professional 8 hours, with assistance. Take your time and get it right'
            )}
          </li>
          <li>
            {t(
              'healthcheck.checkEverythingMessage',
              'Check everything – Don’t assume that something has been done properly (especially if you did it), go and double check'
            )}
          </li>
          <li>
            {t(
              'healthcheck.dontStayAtYourDesk',
              'Don’t stay at your desk – If you’re sat at your desk doing the audit then you’re not checking everything'
            )}
          </li>
          <li>
            {t(
              'healthcheck.noBenefitOfDoubt',
              'Don’t give yourself the benefit of the doubt – If you’re not sure then it’s better to say you haven’t done something than say you have and then be wrong'
            )}
          </li>
          <li>
            {t(
              'healthcheck.alwaysConsiderCompetence',
              'Always consider competence – Was the person who did a task up to the job and are you knowledgeable enough to check it? If you’re not, then ask for help'
            )}
          </li>
          <li>
            {t(
              'healthcheck.agilioHereToHelp',
              'Agilio are here to help – If you’re not sure what a question means or need some help then give us a call'
            )}
          </li>
        </ul>
      </div>
    );
  }
}

export default createIntroPage({
  type: HealthcheckTypes.Audit,
  header: t(
    'healthcheck.preparingForAnAudit',
    'Preparing for an Audit - what you need:'
  ),
  buttonText: t('healthcheck.startAudit', 'Start Audit'),
  intendedFromStatus: HealthcheckStatus.AUDIT_INTRO_1,
})(Content);
