import React from 'react';
import { connect } from 'react-redux';
import { Map, fromJS } from 'immutable';
import moment from 'moment';

import { t } from '^/i18n';
import { loadItem } from '^/actions/items';
import { CYCLES } from '^/consts/collectionKeys';
import { setupCycleOptionalAuditsAndRedirectToReviewRiskAssessments } from '^/actions/actionSequences';
import { loadTemplateActivities, loadTemplateActivityTypeLastDone } from '^/actions/actions';
import { AUDIT_TEMPLATE_TYPE } from '^/models/activities';
import { isCompulsory } from '^/models/compliancereviews';
import { calculateNextDueDate } from '^/models/urgency';

import PureComponent from '^/components/common/PureComponent';
import Loading from '^/components/app/content/Loading';
import HelpBlock from '^/components/app/widgets/HelpBlock';
import CycleSetupHeader from '^/components/app/cycle-setup/CycleSetupHeader';
import CycleSetupOptionalAuditsForm from '^/components/app/cycle-setup/CycleSetupOptionalAuditsForm';
import { transformAuditData } from '^/components/app/account-set-up/SecurityAuditsSetupForm';
import { DATE_LAST_DONE, NEVER_DONE, DUE_DATE } from '^/consts/account-set-up/fieldNames';

const lastDoneData = (templateId, templateActivityTypeLastDone) =>
  templateActivityTypeLastDone
    .find(each => each.get('id') === templateId, null, Map())
    .get('date_last_done');

function getInitialValueTuple(template, templateActivityTypeLastDone, cycleStartDate, includeOptional) {
  const { id } = template.toObject();
  const lastDone = lastDoneData(id, templateActivityTypeLastDone);
  const neverDone = !lastDone;
  const nextDueDate = calculateNextDueDate(
    lastDone, template, moment(cycleStartDate), 1, neverDone
  );

  return [
    id,
    Object.assign(
      { [DATE_LAST_DONE]: lastDone, [NEVER_DONE]: neverDone },
      (includeOptional || isCompulsory(template)) && { [DUE_DATE]: nextDueDate }
    )
  ];
}

export const initialValues = (templateAudits, templateActivityTypeLastDone, cycleStartDate, includeOptional = false) =>
  Map(
    templateAudits.map(template =>
      getInitialValueTuple(template, templateActivityTypeLastDone, cycleStartDate, includeOptional)
    )
  ).toJS();

const CycleSetupOptionalAudits = ({
  practice, response, setup, cycleId, cycle, templateAudits, templateActivityTypeLastDone
}) =>
  <div>
    <CycleSetupHeader practice={practice}>
      Review Audits
    </CycleSetupHeader>

    <HelpBlock>
      <p className="small-text">
        {t('cycleSetup.selectCompulsoryAuditsMsg', `We've already selected all of the compulsory audits for you, so all you need to do is pick the optional ones you'd like to add, and add the 'date last done' dates.`
        )}
      </p>
      <p className="small-text">
        {t('cycleSetup.populateDueDateMsg', `For compulsory audits, next due dates will populate automatically on entering a last done date. You'll be able to edit these dates via the calendar once the cycle setup is complete.`)}
      </p>
      <p className="small-text">
        {t('cycleSetup.enterOptionalAuditMsg', 'For optional audits, you may enter a date if you wish to schedule any.')}
      </p>
    </HelpBlock>

    <CycleSetupOptionalAuditsForm
      response={response}
      cycle={cycle}
      templateAudits={templateAudits}
      initialValues={initialValues(templateAudits, templateActivityTypeLastDone, cycle.get('start_date'))}
      onSubmit={data => setup(cycleId, transformAuditData(fromJS(data), templateAudits, cycle, null))}
    />
  </div>;

export class CycleSetupOptionalAuditsPage extends PureComponent {

  componentWillMount() {
    this.loadData(this.props);
  }

  componentWillReceiveProps(newProps) {
    if (this.props.practice.get('id') !== newProps.practice.get('id')) {
      this.loadData(newProps);
    }
  }

  loadData(props) {
    const { practice, cycleId } = props;

    this.props.loadItem(CYCLES, cycleId);
    this.props.loadTemplateActivities(AUDIT_TEMPLATE_TYPE, practice.get('id'), cycleId);
    this.props.loadTemplateActivityTypeLastDone(AUDIT_TEMPLATE_TYPE, cycleId);
  }

  render () {
    const { practice, templateAudits, cycle, templateActivityTypeLastDone } = this.props;
    return (practice && templateAudits && cycle && templateActivityTypeLastDone) ?
      <CycleSetupOptionalAudits {...this.props} /> :
      <Loading />;
  }

}

export const mapStateToProps = (state, props) => ({
  practice: state.currentPractice,
  templateActivityTypeLastDone: state.templateActivityTypeLastDone.get(AUDIT_TEMPLATE_TYPE),
  templateAudits: state.templateActivities.get(AUDIT_TEMPLATE_TYPE),
  cycleId: props.params.uuid,
  cycle: state.items.get(CYCLES),
  response: state.responses.get('setupCycleOptionalAudits'),
});

export default connect(
  mapStateToProps,
  {
    loadItem,
    setup: setupCycleOptionalAuditsAndRedirectToReviewRiskAssessments,
    loadTemplateActivities,
    loadTemplateActivityTypeLastDone
  }
) (CycleSetupOptionalAuditsPage);
