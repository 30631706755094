import React from 'react';
import { reduxForm } from 'redux-form';
import { List } from 'immutable';

import { t } from '^/i18n';
import PureComponent from '^/components/common/PureComponent';
import LiveButton from '^/components/app/forms/LiveButton';
import FormError from '^/components/app/forms/FormError';

export class ChangePasswordForm extends PureComponent {

  render() {
    const { fields: {new_password1, new_password2}, handleSubmit, response } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <p>{t('users.forms.enterYourPassword', 'Enter your new password below')}:</p>

        <div className="form-group mt-1">
          <div className="col-1-3">
            <label htmlFor="new_password1" className="label-left padded">
              {t('users.forms.newPassword', 'New Password')}
            </label>
          </div>
          <div className="col-2-3">
            <input type="password" placeholder={t('users.forms.password', 'Password')} id="new_password1" {...new_password1} />
            <FormError error={new_password1 && new_password1.touched && new_password1.error} />
            <FormError response={response} formKey="new_password1" />
          </div>
        </div>
        <div className="form-group">
          <div className="col-1-3">
            <label htmlFor="new_password2" className="label-left padded">
              {t('users.forms.confirmPassword', 'Confirm Password')}
            </label>
          </div>
          <div className="col-2-3">
            <input
              type="password"
              placeholder={t('users.forms.confirmPassword', 'Confirm Password')}
              id="new_password2"
              {...new_password2}
            />
            <FormError error={new_password2 && new_password2.touched && new_password2.error} />
            <FormError response={response} formKey="new_password2" />
          </div>
        </div>

        <LiveButton
          response={this.props.response}
          pendingMessage={t('users.forms.changingPassword', 'Changing password...')}
          className="btn-primary"
          successContent={
            <p>
              {t('users.forms.passwordChanged', 'Password has been sucessfully changed.')}
            </p>
          }
          getAdditionalErrors={(responseData) => responseData.getIn(['errors', 'non_field_errors'], List())}
        >
          {t('users.profile.changePassword', 'Change Password')}
        </LiveButton>
      </form>
    );
  }
}

export function validateData(data) {
  if (data.new_password1 === '' || !data.new_password1) {
    return { new_password1: t('users.forms.passwordNotEmpty', 'Password must not be empty') };
  }
  else if (data.new_password1 !== data.new_password2) {
    return { new_password2: t('users.forms.passwordMatch', 'Passwords must match') };
  }
  return {};
}

export default reduxForm({
  form: 'changePassword',
  fields: ['new_password1', 'new_password2'],
  validate: validateData
})(ChangePasswordForm);
