import React from 'react';
import { connect } from 'react-redux';
import { fromJS } from 'immutable';

import { loadItem } from '^/actions/items';
import { CYCLES } from '^/consts/collectionKeys';
import { loadTemplateActivities } from '^/actions/actions';
import { setupAssessmentsAndReloadUser } from '^/actions/actionSequences';
import { getCurrentPracticeId } from '^/models/user';
import { getCycleToSetupId } from '^/consts/setup';

import PureComponent from '^/components/common/PureComponent';
import AccountSetupHeader from '^/components/app/account-set-up/AccountSetupHeader';
import HelpBlock from '^/components/app/widgets/HelpBlock';
import Loading from '^/components/app/content/Loading';

import RiskAssessmentsSetupForm, { transformAssessmentData }  from '^/components/app/account-set-up/RiskAssessmentsSetupForm';
import { t } from '^/i18n';

export const RISK_ASSESSMENT_TEMPLATE_TYPE = 'activities/setup/assessments';

export class RiskAssessmentsSetupPage extends PureComponent {

  componentWillMount() {
    const { practiceId, cycleId } = this.props;
    this.loadPracticeCycleAndTemplateActivities(practiceId, cycleId);
  }

  componentWillReceiveProps(newProps) {
    if (this.props.practiceId !== newProps.practiceId || this.props.cycleId !== newProps.cycleId) {
      this.loadPracticeCycleAndTemplateActivities(newProps.practiceId, newProps.cycleId);
    }
  }

  loadPracticeCycleAndTemplateActivities(practiceId, cycleId) {
    if (practiceId && cycleId) {
      this.props.loadItem(CYCLES, cycleId);
      this.props.loadTemplateActivities(RISK_ASSESSMENT_TEMPLATE_TYPE, practiceId, cycleId);
    }
  }

  transformAndSubmitData(data) {
    const { cycleId, templateRiskAssessments, cycle } = this.props;

    const assessments = fromJS(data);
    const dataToSubmit = { assessments : transformAssessmentData(assessments, templateRiskAssessments, cycle)};

    this.props.setupAssessmentsAndReloadUser(cycleId, dataToSubmit);
  }

  render() {

    const { user, practice, response, cycle, templateRiskAssessments } = this.props;

    if (!user || !practice || !templateRiskAssessments) {
      return <Loading />;
    }

    return (
      <div>

        <AccountSetupHeader user={user} practice={practice}>
          { t('accountSetup.setupRiskAssessmentsHeaderMsg', 'Setup - Assessments') }
        </AccountSetupHeader>

        <HelpBlock>
          <p className="small-text">
            {t(
              'accountSetup.setupRiskAssessmentsHelpMsg',
              `Input or check the completion and next due date of each of your assessments. Most assessments are done annually.`
            )}
          </p>
        </HelpBlock>


        <RiskAssessmentsSetupForm
          response={response}
          cycleStartDate={cycle.get('start_date')}
          templateRiskAssessments={templateRiskAssessments}
          onSubmit={data => this.transformAndSubmitData(data)}
        />

      </div>
    );
  }

}


export const mapStateToProps = (state) => {
  const practiceId = getCurrentPracticeId(state.userProfile);
  return {
    user: state.userProfile,
    practiceId,
    cycleId: getCycleToSetupId(state.userProfile, practiceId),
    practice: state.currentPractice,
    templateRiskAssessments: state.templateActivities.get(RISK_ASSESSMENT_TEMPLATE_TYPE),
    cycle: state.items.get(CYCLES),
    response: state.responses.get('setupRiskAssessments'),
  };
};

export default connect(mapStateToProps,
  { loadItem, loadTemplateActivities, setupAssessmentsAndReloadUser }
) (RiskAssessmentsSetupPage);
