import React from 'react';
import { connect } from 'react-redux';

import { t } from '^/i18n';
import { getPracticeMembers } from '^/stateHelpers';
import { List } from 'immutable';
import PureComponent from '^/components/common/PureComponent';
import Loading from '^/components/app/content/Loading';


class ManageCollaboratorsControl extends PureComponent {
  constructor (props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange(event) {
    if (event.target.value) {
      this.props.onAdd(event.target.value);
    }
  }

  render () {
    const { onRemove, practiceMembers, currentCollaborators, isLoading, loggedInUser } = this.props;

    const practiceMembersNotCollaborators = practiceMembers
      .filterNot(member => currentCollaborators.find(collaborator => collaborator === member.get('user')))
      .filterNot(member => member.get('user') === loggedInUser.get('id'));

    return isLoading ?
      (<Loading className="small inline" />) :
      (
        <div>
          <div>
            {currentCollaborators.map(collaborator => {
              const user = practiceMembers.find(member => member.get('user') === collaborator);
              return (
                <div className="nowrap" key={collaborator}>
                  {user.get('full_name')} <a
                    className="inline-edit"
                    onClick={() => onRemove(collaborator)}
                  >
                    {t('common.button.remove', 'Remove')}
                  </a>
                </div>
              );
            })}
          </div>

          <select
            defaultValue={0}
            onChange={this.onChange}
            id="assignee" className="form-control-margin"
          >
            <option value={0}>
              {currentCollaborators.isEmpty() ? t('templateDocuments.chooseAn', 'Choose an') : t('templateDocuments.addAnother', 'Add another')} {t('templateDocuments.collaborator', 'collaborator')}...
            </option>
            {practiceMembersNotCollaborators.map(member =>
              <option key={member.get('user')} value={member.get('user')}>
                {member.get('full_name')}
              </option>
            )}
          </select>
        </div>
      );
  }
}

function mapStateToProps(state) {
  return {
    practiceMembers: getPracticeMembers(state) || List(),
    loggedInUser: state.userProfile,
  };
}

export default connect(mapStateToProps)(ManageCollaboratorsControl);
