import moment from 'moment';
import { Map, Range, fromJS } from 'immutable';
import { pluralize, formatBackendDate } from '^/utils';
import { ACTIVITY_STATUSES, isOverdue } from '^/models/activities';
import { isReadOnlyInCurrentPractice } from '^/models/user';

export const REMINDER_CHOICES = {
  NONE: 'NONE',
  ONE_DAY_BEFORE: 'ONE_DAY_BEFORE',
  ONE_WEEK_BEFORE: 'ONE_WEEK_BEFORE',
  TWO_WEEKS_BEFORE: 'TWO_WEEKS_BEFORE',
  ONE_MONTH_BEFORE: 'ONE_MONTH_BEFORE',
  TWO_MONTHS_BEFORE: 'TWO_MONTHS_BEFORE',
};

export const REMINDER_CHOICES_TEXT = {
  [REMINDER_CHOICES.NONE]: 'No early reminder',
  [REMINDER_CHOICES.ONE_DAY_BEFORE]: 'Remind 1 day before',
  [REMINDER_CHOICES.ONE_WEEK_BEFORE]: 'Remind 1 week before',
  [REMINDER_CHOICES.TWO_WEEKS_BEFORE]: 'Remind 2 weeks before',
  [REMINDER_CHOICES.ONE_MONTH_BEFORE]: 'Remind 1 month before',
  [REMINDER_CHOICES.TWO_MONTHS_BEFORE]: 'Remind 2 months before',
};

export const REPEAT_CHOICES = {
  NONE: 'NONE',
  DAILY: 'DAILY',
  WEEKLY: 'WEEKLY',
  BI_WEEKLY: 'BI_WEEKLY',
  EVERY_N_MONTHS: 'EVERY_N_MONTHS',
};

export const REPEAT_CHOICES_TEXT = {
  [REPEAT_CHOICES.NONE]: 'No repeat',
  [REPEAT_CHOICES.DAILY]: 'Repeat daily on business days',
  [REPEAT_CHOICES.WEEKLY]: 'Repeat weekly',
  [REPEAT_CHOICES.BI_WEEKLY]: 'Repeat every two weeks',
  [REPEAT_CHOICES.EVERY_N_MONTHS]: 'Repeat every N months',
};

export function getRepeatOptions(task) {
  const { deadline, repeat, repeat_interval } = task.toObject();
  const deadlineDay = moment(deadline).format('dddd');

  return fromJS([
    {
      repeat: REPEAT_CHOICES.NONE,
      text: 'No repeat',
    },
    {
      repeat: REPEAT_CHOICES.DAILY,
      text: 'Repeat daily on business days'
    },
    {
      repeat: REPEAT_CHOICES.WEEKLY,
      text: deadline ? `every ${deadlineDay}` : 'weekly',
    },
    {
      repeat: REPEAT_CHOICES.BI_WEEKLY,
      text: deadline ? `every other ${deadlineDay}` : 'every two weeks',
    }
  ]).concat(Range(1, 7).map(interval =>
    Map({
      repeat: REPEAT_CHOICES.EVERY_N_MONTHS,
      repeat_interval: interval,
      text: interval === 1 ? 'monthly' : `every ${pluralize(interval, 'month')}`,
    })
  )).push(
    Map({
      repeat: REPEAT_CHOICES.EVERY_N_MONTHS,
      repeat_interval: 12,
      text: 'yearly',
    })
  ).push(
    Map({
      repeat: REPEAT_CHOICES.EVERY_N_MONTHS,
      repeat_interval: 24,
      text: '2 yearly',
    })
  ).push(
    Map({
      repeat: REPEAT_CHOICES.EVERY_N_MONTHS,
      repeat_interval: 36,
      text: '3 yearly',
    })
  ).push(
    Map({
      repeat: REPEAT_CHOICES.EVERY_N_MONTHS,
      repeat_interval: 60,
      text: '5 yearly',
    })
  ).map(each =>
    each.set('isSelected',
      repeat === each.get('repeat') &&
        (repeat !== REPEAT_CHOICES.EVERY_N_MONTHS || repeat_interval === each.get('repeat_interval'))
    )
  );
}

export function getSelectedRepeatOption(task) {
  return getRepeatOptions(task).find(each => each.get('isSelected'));
}

export function getRepeatText(task) {
  const selectedOption = getSelectedRepeatOption(task);

  if (selectedOption.get('repeat') === REPEAT_CHOICES.NONE) {
    return '';
  }
  return ` (repeating ${selectedOption.get('text')})`;
}

export function toOptionValue(option) {
  const { repeat, repeat_interval } = option.toObject();
  if (!repeat) { return undefined; }
  return `${repeat}${repeat_interval ? `-${repeat_interval}` : ''}`;
}

export function fromOptionValue(optionValue) {
  return optionValue.split('-');
}

export function transformRepeatOption(data) {
  const { repeatOption } = data;
  if (!repeatOption) {
    return data;
  }
  const [ repeat, repeat_interval ] = fromOptionValue(repeatOption);

  return fromJS(data)
    .remove('repeatOption')
    .merge(Map({
      repeat,
      repeat_interval: repeat_interval || 0
    }))
    .toJS();
}

export const isAssignedTo = staffId => task =>
  task.get('assignees')
    .find(assignee => assignee.get('id') === staffId);

export const forSelf = predicate => task => predicate(task.get('self_assignee'));

export function assigneeStatus(assignee, task) {
  if (assignee.get('is_completed')) {
    return ACTIVITY_STATUSES.COMPLETED;
  }
  else if (isOverdue(task)) {
    return ACTIVITY_STATUSES.OVERDUE;
  }

    return ACTIVITY_STATUSES.IN_PROGRESS;

}

export const isComplete = task => task.get('status') === ACTIVITY_STATUSES.COMPLETED;

export const hasAnyAssigneeStarted = task =>
  task.get('assignees').some(assignee => assignee.get('is_modified'));

export const getAnyAssigneeStarted = task =>
  task.get('assignees').filter(assignee => assignee.get('is_modified'));

export function transformNewCycleTaskData(data) {
  return fromJS(data)
    .merge({
      'included': true,
      'staff': fromJS(data.assignees || []).first(),
      'repeatOption': toOptionValue(fromJS(data)),
    });
}

export const getRepeatGroupId = task =>
  task.get('repeat_clone_of') || task.get('id');


export const wasIncludedLastCycle = (template) =>
  !!template.get('included_last_cycle');


export function getNextDue (template) {
  const { next_due_date } = template.toObject();
  return next_due_date ? formatBackendDate(next_due_date) : null;
}

export const readOnlyOrAssignedToTaskPermission = task => user => {
  if (!isReadOnlyInCurrentPractice(user)) {
    return true;
  }
  const findAssignedTo = isAssignedTo(user.getIn(['staffdetail', 'id']))(task);
  if (findAssignedTo !== undefined) {
    return !findAssignedTo.isEmpty();
  }
  return false;
};

export const readOnlyOrAssigneeIsUserPermission = assignee => user => {
  if (!isReadOnlyInCurrentPractice(user)) {
    return true;
  }
  return user.getIn(['staffdetail', 'id']) === assignee.get('id');
};
