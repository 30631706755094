import React from 'react';
import { reduxForm } from 'redux-form';
import moment from 'moment';
import ImmutablePropTypes from 'react-immutable-proptypes';

import { t } from '^/i18n';
import PureComponent from '^/components/common/PureComponent';
import DateInput from '^/components/app/forms/DateInput';
import FormError from '^/components/app/forms/FormError';
import LiveButton from '^/components/app/forms/LiveButton';
import CheckBoxGroupWithSelectAll from '^/components/app/widgets/CheckBoxGroupWithSelectAll';

export const DOC_READ_SEND_FORM = 'DOC_READ_SEND_FORM';
const ONE_MONTH_FROM_TODAY = moment().add(1, 'M');

export class DocReadAddTeamMembersForm extends PureComponent {
  render() {
    const {
      fields,
      response,
      handleSubmit,
      onClose,
      allActivePracticeMembers,
    } = this.props;

    const {
      all_team_members,
      due_date,
      message,
    } = fields;

    const ALL_PRACTICE_MEMBERS_OPTIONS = allActivePracticeMembers.map((member) => {
      return {
        label: member.getIn(['user', 'staffdetail', 'full_name']),
        value: member.getIn(['user', 'id']),
      }
    }).toJS()

    return (
      <form onSubmit={handleSubmit}>
        <FormError response={response} />
        <div className="form-group">
          <div className="col-1 mb-1">
            <label className="bold-text">{t('templateDocument.selectTeamMembers', 'Select team members')}:</label>
          </div>
          <CheckBoxGroupWithSelectAll
            field={all_team_members}
            response={response}
            options={ALL_PRACTICE_MEMBERS_OPTIONS}
            selectAllID={"all_team_members"}
          />
        </div>
        <div className="form-group mb-1">
          <div className="col-1">
            <label className="bold-text">{t('common.forms.dueDate', 'Due Date')}:</label>
          </div>
          <div className="col-1">
            <DateInput id="due_date" value={ONE_MONTH_FROM_TODAY} {...due_date} />
            <FormError response={response} formKey={"due_date"} />
            <FormError error={due_date && due_date.touched && due_date.error} />
          </div>
        </div>
        <div className="form-group">
          <div className="col-1">
            <label className="bold-text">{t('common.forms.message', 'Message (optional)')}:</label>
            <textarea id="message" value={message.value || ''} {...message} />
          </div>
        </div>
        <div className="form-group align-right mt-1">
          <div className="col-1">
            <a className="btn btn-default pull-left" onClick={onClose}>
              {t('common.button.cancel', 'Cancel')}
            </a>
            <LiveButton
              pendingMessage={`${t('common.adding', 'Adding')}...`}
              response={response}
              className="btn-warning pull-right"
            >
              {t('common.button.send', 'Send')}
            </LiveButton>
          </div>
        </div>
      </form>
    );
  }
}

export function validate(values) {
  const { all_team_members, due_date } = values;
  const errors = {};

  if (!all_team_members) {
    errors.all_team_members = t('templateDocument.mustSelectTeamMemberMsg', 'You must select a team member.');
  }
  if (!due_date) {
    errors.due_date = t('templateDocument.mustAssignDueDateMsg', 'You must assgin a due date.');
  }

  return errors;
}

DocReadAddTeamMembersForm.propTypes = {
  allActivePracticeMembers: ImmutablePropTypes.list,
  onClose: React.PropTypes.func,
};

export default reduxForm({
  form: DOC_READ_SEND_FORM,
  fields: [
    "all_team_members",
    "due_date",
    "message",
  ],
  validate
})(DocReadAddTeamMembersForm);
