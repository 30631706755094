import React from 'react';
import { connect } from 'react-redux';

import { t } from '^/i18n';
import { closeModal } from '^/actions/modals';
import {
  togglePrivacyUploadedFileReloadActivityAndCloseModal,
  togglePrivacyUploadedFileReloadTaskAssigneeFilesAndCloseModal
} from '^/actions/actionSequences';

import LiveButton from '^/components/app/forms/LiveButton';

export const TogglePrivacyUploadedActivityTaskFileModal = ({
  response, activityId, taskAssigneeId, taskId, togglePrivacyActivity, togglePrivacyTaskAssignee, uploadedFile, cancel
}) =>
  <div>
    <p className="mb-2">
      {t('activities.makeConfirmationMsg', 'Are you sure you want to make')} <b>{uploadedFile.get('name')}</b> {t('activities.fromThis', 'from this')} {
        activityId ? t('common.activity', 'activity') : t('common.task', 'task')
      } {
        uploadedFile.get('is_secure_document') ? t('activities.viewableBy', 'viewable by') : t('activities.privateFrom', 'private from')
      } {t('activities.otherMembersOfPracticeQuestion', 'other members of this practice?')}
    </p>

    <button onClick={cancel} className="btn btn-default mr-1">{t('common.button.cancel', 'Cancel')}</button>

    <LiveButton
      response={response}
      onClick={() =>
        activityId ?
          togglePrivacyActivity(activityId, uploadedFile.get('id'), !uploadedFile.get('is_secure_document')) :
          togglePrivacyTaskAssignee(
            taskAssigneeId,
            taskId,
            uploadedFile.get('id'),
            !uploadedFile.get('is_secure_document')
          )
        }
      className="btn btn-warning"
    >
      { uploadedFile.get('is_secure_document') ? t('activities.makeViewable', 'Make Viewable') : t('activities.makePrivate', 'Make Private') }
    </LiveButton>
  </div>;

export const mapStateToProps = (state) => ({
  response: state.responses.get('togglePrivacyUploadedFile'),
});

TogglePrivacyUploadedActivityTaskFileModal.propTypes = {
  activityId: React.PropTypes.string,
  taskAssigneeId: React.PropTypes.string,
  taskId: React.PropTypes.string,
  uploadedFile: React.PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  {
    togglePrivacyActivity: togglePrivacyUploadedFileReloadActivityAndCloseModal,
    togglePrivacyTaskAssignee: togglePrivacyUploadedFileReloadTaskAssigneeFilesAndCloseModal,
    cancel: closeModal,
  }
) (TogglePrivacyUploadedActivityTaskFileModal);
