import { fromJS } from 'immutable';

import { t } from '^/i18n';

export const RESPONSIBILITIES = 'RESPONSIBILITIES';
export const ACTIVITIES = 'ACTIVITIES';
export const TASKS = 'TASKS';
export const PRIVATE_DOCUMENTS = 'PRIVATE_DOCUMENTS';
const CONFIRM = 'CONFIRM';

export const REASSIGN_STEPS = 'REASSIGN_STEPS';
export const REASSIGN_MEMBER = 'REASSIGN_MEMBER';

export const USER_REASSIGN_STEPS = fromJS([
  RESPONSIBILITIES,
  ACTIVITIES,
  TASKS,
  PRIVATE_DOCUMENTS,
  CONFIRM,
]);

export const STEP_TEXT = fromJS({
  [USER_REASSIGN_STEPS.get(0)]: t('consts.reassignResponsibilities', 'Reassign Responsibilities'),
  [USER_REASSIGN_STEPS.get(1)]: t('consts.reassignActivities', 'Reassign Activities'),
  [USER_REASSIGN_STEPS.get(2)]: t('consts.reassignTasks', 'Reassign Tasks'),
  [USER_REASSIGN_STEPS.get(3)]: t('consts.reassignPrivateDocuments', 'Reassign Private Documents'),
  [USER_REASSIGN_STEPS.get(4)]: t('consts.confirmReassignment', 'Confirm Reassignment'),
});
