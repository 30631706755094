import React from 'react';
import { connect } from 'react-redux';

import { t } from '^/i18n';
import ScrollableTableContainer from '^/components/common/content/ScrollableTableContainer';
import { sortInsightBy } from '^/actions/insights';
import withInsightTableData from '^/components/app/groups/insights/hoc/withInsightTableData';
import SortIcon from '^/components/app/groups/insights/visualisations/SortIcon';
import PureComponent from '^/components/common/PureComponent';

export class Table extends PureComponent {
  componentDidMount() {
    const { insight, defaultSortByKey, defaultSortIsAscending } = this.props;
    this.props.sortInsightBy(insight, defaultSortByKey, defaultSortIsAscending);
  }

  render() {
    const {
      columns,
      data: rows,
      insight,
      tableSortByKey,
      tableSortIsAscending,
      isLoading,
      hasFailedToLoad
    } = this.props;
    return (
      <ScrollableTableContainer>
        <table className="insights-table">
          <thead>
            <tr>
              {columns.map(({ key, header, className: columnClassName }) => (
                <th key={key} className={columnClassName}>
                  <p>
                    {header}
                    <SortIcon
                      insight={insight}
                      itemKey={key}
                      isSorted={key === tableSortByKey}
                      isAscending={tableSortIsAscending}
                      onClick={this.props.sortInsightBy}
                    />
                  </p>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {rows.size ? (
              rows.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {columns.map(
                    ({ key, cell: Cell, className: columnClassName }) => (
                      <td key={key} className={columnClassName}>
                        {<Cell row={row} />}
                      </td>
                    )
                  )}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={columns.length}>
                  {isLoading && 'Loading'}
                  {!isLoading &&
                    (hasFailedToLoad ? t('groups.failedToLoad', 'Failed to load') : t('groups.noDataAvailable', 'No data available.'))}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </ScrollableTableContainer>
    );
  }
}

Table.propTypes = {
  columns: React.PropTypes.array.isRequired,
  insight: React.PropTypes.string.isRequired,
  defaultSortByKey: React.PropTypes.string.isRequired,
  defaultSortIsAscending: React.PropTypes.bool.isRequired,
  tableSortByKey: React.PropTypes.string,
  tableSortIsAscending: React.PropTypes.bool,
  isLoading: React.PropTypes.bool,
  hasFailedToLoad: React.PropTypes.bool
};

Table.defaultProps = {
  isLoading: false,
  hasFailedToLoad: false
};

const ConnectedTable = connect(undefined, { sortInsightBy })(Table);

export default withInsightTableData(ConnectedTable);
