import React from 'react';
import { Link } from 'react-router';
import ImmutablePropTypes from 'react-immutable-proptypes';

import { t } from '^/i18n';
import { isAdminOfPractice } from '^/models/staffPractice';
import { MEMBERSHIP_TYPE_DISPLAY, BASE_MEMBERSHIP_TYPE_DISPLAY, BASE_MEMBERSHIP_TYPE } from '^/models/practice';

import CycleDetails from '^/components/app/users/profile/my-practices/CycleDetails';
import { getPlaceholderImage } from '^/utils';


export const Logo = ({ logo }) =>
  <div>
    <img src={logo || getPlaceholderImage()} alt="logo" className="centered" />
    <hr className="thin" />
  </div>;

export const TeamMembers = ({ practice, teamMembersCount, isPracticeAdmin }) =>
  <div>
    {isPracticeAdmin &&
      <Link to={`/page/practices/${practice.get('id')}/team/`}>
        <button className="btn btn-default pull-right">{t('common.button.view', 'view')}</button>
      </Link>
    }
    <h5>{t('common.teamMembersCapitalCase', 'Team members')}</h5>
    <p>{teamMembersCount} {t('common.teamMembersLowerCase', 'team members')}</p>
    <hr className="thin" />
  </div>;


export const TeamResponsibilities = ({ practice }) =>
  <div>
    <Link to={`/page/practices/${practice.get('id')}/responsibilities/`}>
      <button className="btn btn-default pull-right">{t('common.button.view', 'View')}</button>
    </Link>
    <h5 className="mb-2">{t('users.profile.teamResponsibilities', 'Team responsibilities')}</h5>
    <hr className="thin" />
  </div>;


export const Membership = ({memberships}) =>
  <div>
    <h5>{t('common.memberships', 'Memberships')}</h5>
    {(memberships && memberships.size) ? (
      memberships.map((membership, idx) =>
        <div key={idx}>
          <p>
            {membership.get('base_membership') === BASE_MEMBERSHIP_TYPE.QPS ? (
              MEMBERSHIP_TYPE_DISPLAY[membership.get('membership')]
            ) : (
              BASE_MEMBERSHIP_TYPE_DISPLAY[membership.get('base_membership')]
            )}
          </p>
        </div>
      )
    ) : (
      <p className="hint">
        {t('users.profile.noMemberships', 'No memberships')}.
      </p>
    )}
  </div>;


export const PracticeDetail = props => {

  const { practice, isPracticeAdmin } = props;

  const { logo, name, id, members } = practice.toObject();

  return (
    <li>
      <div className="card">
        <Link to={`/page/practices/${id}/`}>
          <button className="btn btn-default pull-right">{t('common.details', 'details')}</button>
        </Link>
        <h4>{name}</h4>

        <div className="card-content mt-1">
          <Logo logo={logo} />
          <CycleDetails practice={practice} />
          <TeamMembers
            practice={practice}
            isPracticeAdmin={isPracticeAdmin}
            teamMembersCount={members ? members.size : 0}
          />

          {isPracticeAdmin &&
            <TeamResponsibilities practice={practice} />
          }

          <Membership memberships={practice.get('memberships')} />
        </div>
      </div>
    </li>
  );
};


export const PracticesDetailGrid = ({ practices, user }) =>
  <ul className="card-container">
    {practices.map((practice) =>
      <PracticeDetail
        key={practice.get('id')}
        isPracticeAdmin={isAdminOfPractice(practice, user) && !practice.get('is_demo_calendar_only', true)}
        practice={practice}
      />
    )}
  </ul>;

PracticesDetailGrid.propTypes = {
  practices: ImmutablePropTypes.list.isRequired,
  user: ImmutablePropTypes.map.isRequired
};

export default PracticesDetailGrid;
