import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ConnectHOC } from '@dabapps/connect-hoc';

import {
  AuditAction,
  Task,
  ItemRendererProps,
} from '^/components/app/digital-tools/audit/types';
import { openCreateTaskModal } from '^/actions/modals';
import { StoreState } from '^/store/types';
import { selectNonImmutableUserProfile } from '^/selectors/healthcheck';
import { CurrentUserProfile, UserTypeInPractice } from '^/types/accounts';
import NotesEditor from './question-notes';
import { updateAuditQuestion } from '^/actions/audit';
import { t } from '^/i18n';

type OwnProps = ItemRendererProps<AuditAction>;

type DispatchProps = {
  openCreateTaskModal: typeof openCreateTaskModal;
  updateAuditQuestion: typeof updateAuditQuestion;
};

type StateProps = {
  tasks: readonly Task[];
  userProfile: CurrentUserProfile | null;
};

type Props = OwnProps & DispatchProps & StateProps;

class ActionRow extends Component<Props, {}> {
  public render() {
    const { question, tasks, userProfile } = this.props;
    return (
      <div className="audit-action-row">
        <div className="row pt-1-2">
          <div className="bold-text subheading col-2-12">
            {t('common.question', 'Question')}:
          </div>
          <div className="col-7-12">{question.question_text}</div>
          <div className="col-3-12 center">
            {question.is_actioned ? (
              <span className="right button-container">
                <button
                  className="btn btn-primary"
                  onClick={this.onUpdateActioned}
                  disabled={
                    userProfile?.user_type_in_current_practice ===
                    UserTypeInPractice.READ_ONLY
                  }
                >
                  {t('digitalTools.audit.actioned', 'Actioned')}
                </button>
              </span>
            ) : (
              <span className="right button-container">
                <button
                  className="btn btn-warning-large"
                  onClick={this.onUpdateActioned}
                  disabled={
                    userProfile?.user_type_in_current_practice ===
                    UserTypeInPractice.READ_ONLY
                  }
                >
                  {t('digitalTools.audit.markAsActioned', 'Mark As Actioned')}
                </button>
              </span>
            )}
          </div>
        </div>
        <div className="row pt-1-2">
          <div className="bold-text subheading col-2-12">
            {t('common.feedback', 'Feedback')}:
          </div>
          <div className="col-10-12 pre-wrap">
            {question.is_correct
              ? question.correct_answer_feedback
              : question.incorrect_answer_feedback}
          </div>
        </div>
        <div className="row pt-1-2 pb-1-2">
          <div className="bold-text subheading col-2-12">
            {t('common.action', 'Action')}:
          </div>
          <div className="col-10-12">
            {tasks && tasks.length > 0 && (
              <div>
                {tasks.map((task, idx) => (
                  <div key={idx} className="mb-1-2 underlined">
                    <a target="_blank" href={`/page/tasks/manage/${task.id}/`}>
                      <span>{task.title}</span>
                      {' - '}
                      <span>{task.deadline}</span>
                      {' - '}
                      {task.assignees.map((assignee, id) => (
                        <span key={id} className="mr-1-2">
                          {assignee.full_name}
                        </span>
                      ))}
                    </a>
                  </div>
                ))}
              </div>
            )}
            {userProfile &&
              userProfile.user_type_in_current_practice !==
                UserTypeInPractice.READ_ONLY && (
                <div>
                  <button className="btn-minimal" onClick={this.onCreateTask}>
                    {t('calendar.createTask', 'Create Task')}
                  </button>
                </div>
              )}
          </div>
        </div>
        <NotesEditor
          onClick={this.onSubmitNotes}
          notes={question.note}
          disabled={
            userProfile?.user_type_in_current_practice ===
            UserTypeInPractice.READ_ONLY
          }
        />
        <hr className="thin" />
      </div>
    );
  }

  private onCreateTask = () => {
    this.props.openCreateTaskModal({
      practiceDigitalAuditActionId: this.props.question.id,
      hideRepeat: true,
    });
  };

  private onSubmitNotes = (note: string) => {
    const { auditId, categoryId, question } = this.props;

    this.props.updateAuditQuestion(auditId, categoryId, question.id, { note });
  };

  private onUpdateActioned = () => {
    const { auditId, categoryId, question } = this.props;
    const is_actioned = !question.is_actioned;

    this.props.updateAuditQuestion(auditId, categoryId, question.id, {
      is_actioned,
    });
  };
}

const mapStateToProps = (state: StoreState, props: OwnProps): StateProps => ({
  userProfile: selectNonImmutableUserProfile(state),
  tasks: state.audit.tasksByAuditQuestionId[props.question.id],
});

export default (connect as ConnectHOC)<
  StateProps,
  DispatchProps,
  OwnProps,
  StoreState
>(mapStateToProps, {
  openCreateTaskModal,
  updateAuditQuestion,
})(ActionRow);
