import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';

import { t } from '^/i18n';
import { REMINDER_CHOICES_TEXT, getRepeatText } from '^/models/task';
import { formatDate } from '^/utils';

import PureComponent from '^/components/common/PureComponent';
import Icon from '^/components/app/content/Icon';
import Loading from '^/components/app/content/Loading';


export class TitleAndDetailsViewBar extends PureComponent {
  render() {
    const { task, isLoading } = this.props;
    const { deadline, title, assignees, reminder } = task.toObject();

    return (
      <div>
        <h1>
          {title}
        </h1>

        {isLoading && <Loading />}

        <div className="metadata">
          <Icon type="user" className="inline-icon" /> {
            assignees.size ? (
              assignees.map(assignee => assignee.get('full_name')).join(', ')
            ) : (
              <i>{t('tasks.noAssignees', 'No assignees')}</i>
            )
          }
          <span className="inline-edit" />

          <Icon type="calendar" className="inline-icon" /> {formatDate(deadline)}
          <span>{getRepeatText(task)}</span>
          <span className="inline-edit" />

          <Icon type="bell-o" className="inline-icon" /> {
            REMINDER_CHOICES_TEXT[reminder]
          }
        </div>
      </div>
    );
  }
}

TitleAndDetailsViewBar.propTypes = {
  task: ImmutablePropTypes.map.isRequired,
  isLoading: React.PropTypes.bool,
};

export default TitleAndDetailsViewBar;
