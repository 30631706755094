import React from 'react';
import { List } from 'immutable';

import { t } from '^/i18n';

export function getColumns (practices, onClick) {
  const hasAnySubGroups = !practices.filter(practice => Boolean(practice.get('sub_group'))).isEmpty();
  return List([
    {
      key: 'name',
      header: t('groups.practice', 'Practice'),
      value: (practice) => (
        practice.get('is_member_of_practice') ?
        <a onClick={() => onClick(practice.get('id'))}>{practice.get('name')}</a> :
        <span>{practice.get('name')}</span>
      ),
      sortKey: 'name',
    },
    ...(
      hasAnySubGroups ?
      [{
        key: 'sub_group',
        header: t('groups.subGroup', 'Sub Group'),
        value: (practice) => (<span>{practice.get('sub_group') || '--'}</span>),
        sortKey: 'sub_group__name',
      }] :
      []
    ),
    {
      key: 'postcode',
      header: t('groups.postcode', 'Postcode'),
      value: (practice) => (<span>{practice.get('postcode')}</span>),
      sortKey: 'postcode',
    },
  ]);
}
