import { t } from '^/i18n';
import { AuditStatus } from '^/components/app/digital-tools/audit/types';
import { HealthcheckStatus } from '^/components/app/healthcheck/types';

export const HEALTHCHECK_API_URL_BASE = '/api/healthchecks/';
export const HEALTHCHECK_CLIENT_URL_BASE = '/page/healthcheck/';

export const THRESHOLD_BAD = 0.5;
export const THRESHOLD_OK = 0.95;

export const PROGRESS_BAR_STEPS = [
  {
    text: t('consts.audit', 'Audit'),
    minInProgressIndex: 0,
    minCompleteIndex: 5,
  },
  {
    text: t('consts.actions', 'Actions'),
    minInProgressIndex: 5,
    minCompleteIndex: 10,
  },
  {
    text: t('consts.results', 'Results'),
    minInProgressIndex: 10,
    minCompleteIndex: 10,
  },
];

export const STATUS_ORDER = [
  HealthcheckStatus.CREATED,
  HealthcheckStatus.AUDIT_INTRO_1,
  HealthcheckStatus.AUDIT,
  HealthcheckStatus.AUDIT_RESULTS,
  HealthcheckStatus.ACTIONS_INTRO_1,
  HealthcheckStatus.ACTIONS,
  HealthcheckStatus.REVIEW,
  HealthcheckStatus.COMPLETED,
];

export const STATUS_ORDER_COMBINED = [
  HealthcheckStatus.CREATED,
  HealthcheckStatus.AUDIT_INTRO_1,
  AuditStatus.AUDIT_INTRO_1,
  HealthcheckStatus.AUDIT,
  AuditStatus.AUDIT,
  HealthcheckStatus.AUDIT_RESULTS,
  HealthcheckStatus.ACTIONS_INTRO_1,
  AuditStatus.ACTIONS_INTRO_1,
  HealthcheckStatus.ACTIONS,
  AuditStatus.ACTIONS,
  HealthcheckStatus.REVIEW,
  AuditStatus.REVIEW,
  AuditStatus.COMPLETED,
  HealthcheckStatus.COMPLETED,
];
