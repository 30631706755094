import { t } from '^/i18n';

export const CHECKLIST_RECORD_GROUP_NAME_MAPPING = {
  daily_surgery_checklist: t('digitalTools.records.dailySurgeryChecklist', 'Daily Surgery Checklist'),
  fridge_temperature_log: t('digitalTools.records.fridgeTemperatureLog', 'Medical Fridge Temperature Log'),
  ede_checklist: t('digitalTools.records.edeChecklist', 'Emergency Drugs Checklist'),
  decontamination_room_checklist: t('digitalTools.records.decontaminationRoomChecklist', 'Decontamination Room Checklist'),
  legionella_temperature_log: t('digitalTools.records.legionellaTemperatureLog', 'Legionella Temperature Log'),
  autoclave_daily_log: t('digitalTools.records.autoclaveDailyLog', 'Autoclave Daily Log'),
};

// TODO: finish this when I get a response/clarification from the team
export const CHECKLIST_RECORD_DESCRIPTION_TEXT_MAPPING = {
  daily_surgery_checklist: [
    'In order to meet the requirements of HTM 01-05 and ensure that team members perform essential injection control tasks it is important to use checklists in your decontamination and treatment rooms. CQC inspectors regularly review these checklists as part of assessing whether a practice is meeting the Safe KLOE.',
    'It is important to review a few completed checklists from each room from time to time to make sure that they are being completed properly. You may want to create a repeating Task on the calendar to remind you to monitor the checklists.',
  ],
  fridge_temperature_log: [
    "To ensure compliance with National Guidance from the Department of Health & Social Care, ‘Immunisation against infectious disease’ (the 'Green Book'), fridges used to store medicines must be checked daily to ensure they are operating correctly between +2°C and +8°C.",
    'To complete this log, a maximum-minimum thermometer will be required that is independent of mains power.',
  ],
  ede_checklist: [
    "To meet the requirements of the 'Quality Standards: Primary dental care equipment list', as governed by the Resuscitation Council UK, as well as 'Medical Emergencies in a Dental Practice', as governed by NICE (National Institute for Health and Care Excellence), all dental practices must have readily available the below list of emergency drugs and resuscitation equipment as a minimum. This checklist must therefore be completed weekly to ensure supplies are present and in date.",
  ],
  decontamination_room_checklist: [
    'To ensure that team members perform essential infection control tasks it is important to use checklists in your decontamination room. Inspectors will often review these checklists as part of assessing whether a practice has adopted appropriate safety systems and processes.',
  ],
  legionella_temperature_log: [
    "A temperature control regime is the traditional and most effective strategy for controlling legionella and reducing associated risk. Cold water systems should be maintained at a temperature below 20\u2103, hot water should be stored at 60\u2103 and distributed so that it reaches a temperature of 55\u2103 within healthcare settings. Therefore, to meet the requirements of the Approved Code of Practice L8: Legionnaires' disease, and HSG274 Part 2: The control of legionella bacteria in hot and cold-water systems, this log should be completed monthly to ensure these temperature parameters are being achieved.",
  ],
  autoclave_daily_log: [
    'To ensure compliance with Health Technical Memorandum (HTM 01-05), this checklist must be completed daily for all sterilisers. If the steriliser fails to meet any of the test requirements, it should be withdrawn from service and advice sought from the manufacturer and/or maintenance contractor. Any instruments processed in an unsuccessful cycle should not be used.\nAt the end of the working day, sterilisers should be cleaned, dried, and left empty with the door kept open.\nBefore carrying out the daily tests, the user should clean the rubber door seal with a clean, damp, non-linting cloth, check the chamber and shelves for cleanliness and debris, and turn the power source on.',
  ],
};

export const CHECKLIST_RECORD_GROUP_FORM_NAME_MAPPING = {
  daily_surgery_checklist: 'DAILY_SURGERY_CHECKLIST_FORM',
  fridge_temperature_log: 'DAILY_FRIDGE_TEMPERATURE_LOG_RECORD_FORM',
  ede_checklist: 'EDE_CHECKLIST_FORM',
  decontamination_room_checklist: 'DECONTAMINATION_ROOM_CHECKLIST_FORM',
  legionella_temperature_log: 'LEGIONELLA_TEMPERATURE_LOG',
  autoclave_daily_log: 'AUTOCLAVE_DAILY_LOG',
};
