import React from 'react';
import moment from 'moment';
import { Map } from 'immutable';

import { t } from '^/i18n';
import { formatDateTime } from '^/utils';
import DetailsCard from '^/components/app/groups/insights/DetailsCard';
import { INSIGHTS, INSIGHTS_API_URLS } from '^/components/app/groups/insights/constants';
import { getSubGroupFilterFromPath } from '^/components/app/groups/insights/utils';
import withInsight from '^/components/app/groups/insights/hoc/withInsight';

const ActiveUserCountDetails = (props) => {
  return (
    <DetailsCard>
      <h3>Active users</h3>
      <p className="insights-timestamp">{t('groups.insights.updated', 'Updated')}: {formatDateTime(moment())}</p>
      <p className="active-user-count-details">
        {props.data || '--'}
      </p>
      <p className="caption">
        {props.hasFailedToLoad ? t('common.failedToLoad', 'Failed to load') : t('groups.insights.notRetiredOrSuspendedUsers', 'Users who are not retired or suspended')}
      </p>
    </DetailsCard>
  );
};

export { ActiveUserCountDetails };

export default withInsight({
  insight: INSIGHTS.ACTIVE_USER_COUNT,
  url: INSIGHTS_API_URLS.ACTIVE_USER_COUNT,
  getFilters: () => (
    Map({
      practice_sub_group_id: getSubGroupFilterFromPath(),
    })
  )
})(ActiveUserCountDetails);
