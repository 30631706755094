import React from 'react';
import { connect } from 'react-redux';
import ImmutablePropTypes from 'react-immutable-proptypes';

import { t } from '^/i18n';
import { setCollapse } from '^/actions/actions';
import PureComponent from '^/components/common/PureComponent';
import Icon from '^/components/app/content/Icon';
import { IS_DISSMISED_CHOICES } from '^/models/alerts';

const Alert = ({onDismiss, alert}) => {
  const { id, message, is_dismissed, is_staff_alert } = alert.toObject();

  const canBeDismissed = (!is_dismissed || is_dismissed !== IS_DISSMISED_CHOICES.NOT_DISMISSIBLE) && onDismiss;

  return (
    <li key={id}>
      {message}
      {canBeDismissed &&
        <a className="alert-dismiss-link" onClick={() => onDismiss(id, is_staff_alert)}>
          <Icon type="close" className="alert-dismiss" />
        </a>
      }
    </li>
  );
};

const ALERT_LIMIT_COUNT = 2;
const ALERTS_COLLAPSE = 'alerts';

export class Alerts extends PureComponent {
  componentWillMount() {
    this.props.setAlertsCollapsed(true);
  }

  render() {
    const {alertCount, alerts, onDismiss, alertsCollapsed, setAlertsCollapsed, loadMore} = this.props;

    return alertCount ? (
      <div>
        <h4 className="m-toggle">
          <Icon type="bullhorn" className="title" />
          <span className="count-indicator">{alertCount}</span> {t('common.alerts', 'Alerts')}
        </h4>
        <ul className="list-no-indent alert-items">
          {(alertsCollapsed ? alerts.take(ALERT_LIMIT_COUNT) : alerts).map(alert =>
            <Alert key={alert.get('id')} alert={alert} onDismiss={onDismiss} />
          )}
        </ul>

        {alertCount > (alertsCollapsed ? ALERT_LIMIT_COUNT : alerts.size) && (
          <a
            className="inline-edit" onClick={
            alertsCollapsed ? () => setAlertsCollapsed(false) : loadMore
          }
          >
            {t('common.showMore', 'Show more')} {'>>'}
          </a>
        )}
      </div>
    ) : <noscript />;
  }
}

Alerts.propTypes = {
  alerts: ImmutablePropTypes.list.isRequired,
  alertCount: React.PropTypes.number,
  onDismiss: React.PropTypes.func,
  loadMore: React.PropTypes.func,
};

export const mapStateToProps = state => ({
  alertsCollapsed: state.ui.getIn(['collapse', ALERTS_COLLAPSE]),
});
export const mapDispatchToProps = dispatch => ({
  setAlertsCollapsed: alertsCollapsed => dispatch(setCollapse(ALERTS_COLLAPSE, alertsCollapsed)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Alerts);
