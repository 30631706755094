import React from 'react';
import { routeActions } from 'react-router-redux';
import { connect } from 'react-redux';
import { Map } from 'immutable';

import { t } from '^/i18n';
import PureComponent from '^/components/common/PureComponent';
import Loading from '^/components/app/content/Loading';
import { isPending } from '^/consts/responseStates';
import {
  loadItem,
  clearItem,
  discardWorkingDraft,
  saveAsWorkingDraft,
} from '^/actions/items';
import { ACTIVITY_RELATED_TEMPLATE, ACTIVITIES } from '^/consts/collectionKeys';
import {
  collapseUiComponent,
  expandUiComponent,
  setEditorContent,
  resetResponse,
} from '^/actions/actions';
import {
  createTemplateVersionRenderDocumentAndOptionallyRedirect,
  adoptTemplateVersionRenderDocumentAndOptionallyRedirect,
  openExitConfirmationModalAndCheckForLogin,
} from '^/actions/actionSequences';
import { isGroupDocument, formatDate, handleOnBeforeUnload } from '^/utils';
import { isHTMLDocument, getBaseTemplateDocument, templateDocumentKindIsPolicyOrOverview } from '^/models/documents';
import { linkRelatedTemplates, isComplete } from '^/models/activities';
import DocumentEditor from '^/components/app/template-documents/DocumentEditor';
import DemoModeMayDisallow from '^/components/app/perms/DemoModeMayDisallow';
import _ from 'underscore';
import {
  openNameDocumentCopyModal,
  openRestoreAdoptedVersionModal,
} from '^/actions/modals';
import moment from 'moment';
import { isReadOnlyInCurrentPractice } from '^/models/user';
import { withRouter } from '^/withRouter';
import BackButton from '^/components/app/nav/back-button';

const INITIAL_DOC_CONTENT = '';

export class ActivityRelatedTemplateEditPage extends PureComponent {
  constructor(props) {
    super(props);
    this.isEditing = this.isEditingDocument.bind(this);
    this.confirmBeforeTransition = this.confirmBeforeTransition.bind(this);
  }

  componentWillMount() {
    const {
      setContent,
      loadTemplate,
      loadActivity,
      params: { related_template_id, uuid },
      resetSaveResponses,
    } = this.props;
    setContent(INITIAL_DOC_CONTENT);
    loadTemplate(related_template_id);
    loadActivity(uuid);
    resetSaveResponses();
    this.clearTransitionListener = this.props.router.listenBefore(
      this.confirmBeforeTransition
    );
    window.onbeforeunload = handleOnBeforeUnload;
  }

  confirmBeforeTransition(location, callback) {
    callback(false);
    this.confirmIfEditing(() => callback(true));
  }

  confirmIfEditing(onContinue) {
    const {
      relatedTemplateVersion,
      editorContent,
      editingGroupDocument,
      currentPracticeGroupId,
      saveResponse,
    } = this.props;

    if (this.isEditing() && !isPending(saveResponse)) {
      this.props.exitConfirmation(
        relatedTemplateVersion,
        editorContent,
        editingGroupDocument && currentPracticeGroupId,
        () => {
          this.onSave(null, false, false);
          onContinue();
        },
        () => {
          this.props.discardWorkingDraft(relatedTemplateVersion.get('id'));
          onContinue();
        },
        this.isEditing
      );
    } else {
      onContinue();
    }
  }

  componentWillReceiveProps(newProps) {
    const {
      relatedTemplateVersion,
      setContent,
      loadTemplate,
      params: { related_template_id },
      userId,
      editorContent,
    } = this.props;

    if (
      newProps.relatedTemplateVersion &&
      relatedTemplateVersion !== newProps.relatedTemplateVersion
    ) {
      setContent(
        newProps.relatedTemplateVersion.getIn([
          'working_version',
          'user',
          'id',
        ]) === userId
          ? newProps.relatedTemplateVersion.getIn([
              'working_version',
              'content',
            ])
          : newProps.relatedTemplateVersion.get('content')
      );
    }
    if (
      newProps.params &&
      newProps.params.related_template_id !== related_template_id
    ) {
      loadTemplate(newProps.params.related_template_id);
    }
    if (
      editorContent !== INITIAL_DOC_CONTENT &&
      editorContent !== newProps.editorContent &&
      newProps.relatedTemplateVersion &&
      (newProps.relatedTemplateVersion.getIn([
        'working_version',
        'user',
        'id',
      ]) === userId ||
        !newProps.relatedTemplateVersion.get('working_version'))
    ) {
      if (
        newProps.params.related_template_id &&
        !newProps.relatedTemplateVersion.working_version
      ) {
        this.props.saveAsWorkingDraft(
          newProps.params.related_template_id,
          newProps.editorContent
        );
      }
    }
  }

  componentWillUnmount() {
    this.props.resetSaveResponses();
    this.props.clearTemplate();
    this.props.collapseUi();
    this.clearTransitionListener();
    window.onbeforeunload = null;
  }

  onSave(documentType = null, isGenerate, shouldRedirect = true) {
    const {
      editorContent,
      currentPracticeId,
      relatedTemplateVersion,
      readOnly,
      createNewVersion,
      adoptTemplate,
    } = this.props;

    const adoptedOrCopiedDocument =
      relatedTemplateVersion.get('adopted_template_document') ||
      relatedTemplateVersion.get('copied_template_document');

    if (shouldRedirect) {
      this.clearTransitionListener();
    }

    if (adoptedOrCopiedDocument) {
      createNewVersion(
        adoptedOrCopiedDocument.get('id'),
        relatedTemplateVersion.get('id'),
        editorContent,
        documentType,
        isGenerate,
        shouldRedirect
      );
    } else if (!readOnly) {
      const doc = relatedTemplateVersion.get('template_document')
        ? relatedTemplateVersion.get('template_document')
        : relatedTemplateVersion.get('group_template_document');
      adoptTemplate(
        doc.get('id'),
        editorContent,
        currentPracticeId,
        documentType,
        isGenerate,
        shouldRedirect
      );
    }
  }

  onSaveCopy(documentType = null) {
    const {
      editorContent,
      currentPracticeId,
      relatedTemplateVersion,
      createNewCopy,
      readOnly,
      activityComplete,
    } = this.props;

    if (!readOnly) {
      let doc, name;

      if (relatedTemplateVersion.get('group_template_document')) {
        doc = relatedTemplateVersion.get('group_template_document');
        name = doc.getIn(['template_document', 'name']);
      } else {
        doc =
          relatedTemplateVersion.get('template_document') ||
          relatedTemplateVersion.getIn([
            'copied_template_document',
            'template_document',
          ]);
        name = doc.get('name');
      }

      createNewCopy(
        doc.get('id'),
        `${name} - ${t('activities.copiedDate', 'copied date')} ${formatDate(moment())}`,
        editorContent,
        currentPracticeId,
        documentType,
        activityComplete
      );
    }
  }

  discard() {
    const { relatedTemplateVersion } = this.props;

    if (relatedTemplateVersion) {
      this.clearTransitionListener();
      this.props.discardWorkingDraftAndClose(relatedTemplateVersion.get('id'));
    }
  }

  cancel() {
    const { relatedTemplateVersion } = this.props;

    if (relatedTemplateVersion.get('working_version')) {
      this.clearTransitionListener();
      this.props.discardWorkingDraftAndClose(relatedTemplateVersion.get('id'));
    }
    this.props.onCancel();
  }

  isEditingDocument() {
    const {
      isEditing,
      relatedTemplateVersion,
      userId,
      activityComplete,
      isReadOnlyUser,
    } = this.props;

    return Boolean(
      relatedTemplateVersion &&
      (
        (
          templateDocumentKindIsPolicyOrOverview(
            getBaseTemplateDocument(relatedTemplateVersion)
          )
          && !activityComplete
          && !isReadOnlyUser
        )
        || relatedTemplateVersion.getIn(['working_version', 'user', 'id']) === userId
        || isEditing
      )
    );
  }

  render() {
    const {
      relatedTemplateVersion,
      setContent,
      isLoading,
      response,
      editorContent,
    } = this.props;

    return (
      <div className="wrapper">
        <DemoModeMayDisallow
          message={t('activities.cannotEditDocuments', 'cannot edit documents')}
          response={response}
          goBack
        >
          <BackButton />

          {isLoading || !relatedTemplateVersion ? (
            <Loading />
          ) : (
            <DocumentEditor
              {...this.props}
              showingCopies
              toggleShowingCopies={_.noop}
              doc={relatedTemplateVersion}
              isEditing={this.isEditingDocument()}
              hideToggleButton
              setEditorContent={setContent}
              onSaveWorking={() =>
                this.props.saveAsWorkingDraft(
                  relatedTemplateVersion.get('id'),
                  editorContent
                )
              }
              discard={() => this.discard()}
              startEditing={() =>
                this.props.startEditing(relatedTemplateVersion)
              }
              onCancel={() => this.cancel()}
              onSave={documentType => this.onSave(documentType, true, true)}
              onSaveAndClose={documentType =>
                this.onSave(documentType, false, true)
              }
              onSaveCopy={documentType => this.onSaveCopy(documentType)}
              restoreAdoptedVersion={() =>
                this.props.restoreAdoptedVersion(
                  relatedTemplateVersion.getIn([
                    'adopted_template_document',
                    'id',
                  ])
                )
              }
            />
          )}
        </DemoModeMayDisallow>
      </div>
    );
  }
}

const name = props =>
  `TEMPLATE_DOCUMENT_VERSION.content.${props.params && props.params.uuid}`;

export function mapStateToProps(state, props) {
  const relatedTemplateVersion = state.items.get(ACTIVITY_RELATED_TEMPLATE);
  const activity = state.items.get(ACTIVITIES);
  const readOnly = !isHTMLDocument(relatedTemplateVersion);
  const response = state.responses.getIn([
    'loadItem',
    ACTIVITY_RELATED_TEMPLATE,
  ]);
  const activityResponse = state.responses.getIn(['loadItem', ACTIVITIES]);

  const { userProfile, currentPractice } = state;

  const isGroupAdmin = isGroupDocument(currentPractice, userProfile);
  const userId = userProfile.get('id');
  const isAdmin = userProfile.get('is_admin_of_current_practice');

  return {
    activityComplete: activity && isComplete(activity),
    relatedTemplateVersion,
    autosaveAsWorkingDraftResponse: state.responses.get('saveAsWorkingDraft'),
    isLoading: isPending(response) || isPending(activityResponse),
    isEditing: state.ui.get('expandedUiComponents').contains(name(props)),
    isGroupAdmin,
    isAdmin,
    response,
    saveResponse: Map().merge(
      state.responses.get('createTemplateVersion'),
      state.responses.get('adoptTemplateVersion'),
      state.responses.get('copyTemplateVersion')
    ),
    editorContent: state.ui.get('editorContent'),
    currentPracticeId: state.currentPractice && state.currentPractice.get('id'),
    readOnly,
    userId,
    isReadOnlyUser: isReadOnlyInCurrentPractice(userProfile),
    currentPathname: state.routing.location.pathname,
  };
}

export function mapDispatchToProps(dispatch, props) {
  const {
    params: { uuid },
  } = props;
  const activitesPage = `/page/activities/${uuid}/`;

  return {
    loadTemplate: id => dispatch(loadItem(ACTIVITY_RELATED_TEMPLATE, id)),
    loadActivity: id => dispatch(loadItem(ACTIVITIES, id)),
    clearTemplate: () => dispatch(clearItem(ACTIVITY_RELATED_TEMPLATE)),
    collapseUi: () => dispatch(collapseUiComponent(name(props))),
    startEditing: doc => {
      dispatch(expandUiComponent(name(props)));

      if (
        !doc.get('working_version') &&
        doc.get('id') === props.params.related_template_id
      ) {
        dispatch(saveAsWorkingDraft(doc.get('id'), doc.get('content')));
      }
    },
    createNewVersion: (
      documentId,
      templateVersionId,
      content,
      documentType,
      isGenerate,
      shouldRedirect
    ) => {
      dispatch(
        createTemplateVersionRenderDocumentAndOptionallyRedirect(
          documentId,
          content,
          version =>
            isGenerate ? linkRelatedTemplates(uuid, version.id) : activitesPage,
          {activity: uuid},
          documentType,
          shouldRedirect
        )
      );
      if (shouldRedirect) {
        dispatch(collapseUiComponent(name(props)));
        dispatch(routeActions.push(activitesPage));
        dispatch(discardWorkingDraft(templateVersionId));
      }
      dispatch(setEditorContent(content));
    },
    restoreAdoptedVersion: documentId => {
      dispatch(
        openRestoreAdoptedVersionModal(documentId, uuid, version =>
          linkRelatedTemplates(uuid, version.id)
        )
      );
      dispatch(discardWorkingDraft(documentId));
      dispatch(collapseUiComponent(name(props)));
    },
    adoptTemplate: (
      documentId,
      content,
      currentPracticeId,
      documentType,
      isGenerate,
      shouldRedirect
    ) => {
      dispatch(
        adoptTemplateVersionRenderDocumentAndOptionallyRedirect(
          documentId,
          content,
          currentPracticeId,
          null,
          version =>
            isGenerate ? linkRelatedTemplates(uuid, version.id) : activitesPage,
          uuid,
          documentType,
          shouldRedirect
        )
      );
      if (shouldRedirect) {
        dispatch(discardWorkingDraft(documentId));
        dispatch(collapseUiComponent(name(props)));
        dispatch(routeActions.push(activitesPage));
      }
      dispatch(setEditorContent(content));
    },
    createNewCopy: (
      docId,
      defaultNewName,
      content,
      currentPracticeId,
      documentType,
      completed
    ) => {
      dispatch(
        openNameDocumentCopyModal(
          docId,
          content,
          currentPracticeId,
          defaultNewName,
          version => linkRelatedTemplates(uuid, version.id),
          !completed && uuid,
          documentType
        )
      );
      dispatch(discardWorkingDraft(docId));
      dispatch(collapseUiComponent(name(props)));
      dispatch(setEditorContent(content));
    },
    discardWorkingDraft: id => {
      dispatch(discardWorkingDraft(id));
      dispatch(collapseUiComponent(name(props)));
    },
    discardWorkingDraftAndClose: id => {
      dispatch(discardWorkingDraft(id));
      dispatch(collapseUiComponent(name(props)));
      dispatch(routeActions.push(activitesPage));
    },
    saveAsWorkingDraft: _.throttle(
      (docId, content) => dispatch(saveAsWorkingDraft(docId, content, true)),
      10000,
      true
    ),
    onCancel: () => {
      dispatch(routeActions.push(activitesPage));
      dispatch(collapseUiComponent(name(props)));
    },
    setContent: content => dispatch(setEditorContent(content)),
    resetSaveResponses: () => {
      dispatch(resetResponse('createTemplateVersion'));
      dispatch(resetResponse('adoptTemplateVersion'));
      dispatch(resetResponse('copyTemplateVersion'));
      dispatch(resetResponse('renderDocument'));
    },
    exitConfirmation: (document, content, groupId, onSave, discard) => {
      dispatch(
        openExitConfirmationModalAndCheckForLogin(
          document,
          content,
          groupId,
          uuid,
          onSave,
          discard
        )
      );
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ActivityRelatedTemplateEditPage));
