import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from '^/withRouter';

import { handleOnBeforeUnload } from '^/utils';

import Icon from '^/components/app/content/Icon';
import LiveButton from '^/components/app/forms/LiveButton';
import PureComponent from '^/components/common/PureComponent';
import HelpBlock from '^/components/app/widgets/HelpBlock';
import Loading from '^/components/app/content/Loading';
import { Map, OrderedMap } from 'immutable';

import {
  getPredefinedChecklistFields,
  createUpdateDigitalRecordChecklist,
  clearCustomFields,
  removeCustomField,
} from '^/actions/actions';
import {
  openCreateCustomFieldModal,
  openCreateChecklistRecordGroupModal,
  openExitCreateRecordWithCustomFieldsConfirmationModal,
} from '^/actions/modals';
import CreateChecklistRecordGroupMetadataForm from './forms/CreateChecklistRecordGroupMetadataForm';
import { CHECKLIST_RECORD_GROUP_NAME_MAPPING } from '^/components/app/digital-tools/records/constants';
import { renderDisabledFileUploadField } from '^/components/app/digital-tools/records/digital-records/forms/FormFields';
import { isPending, hasFailed } from '^/consts/responseStates';
import { t } from '^/i18n';

const CREATE_CHECKLIST_RECORD_GROUP_METADATA_FORM =
  'CREATE_CHECKLIST_RECORD_GROUP_METADATA_FORM';

export class CreateChecklistRecordGroup extends PureComponent {
  constructor(props) {
    super(props);
    this.confirmBeforeTransition = this.confirmBeforeTransition.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { recordTypeUrl } = this.props;
    if (recordTypeUrl !== prevProps.recordTypeUrl) {
      this.props.getPredefinedChecklistFields(recordTypeUrl);
    }
  }

  componentDidMount() {
    const { recordTypeUrl } = this.props;
    this.props.getPredefinedChecklistFields(recordTypeUrl);
  }

  componentWillMount() {
    this.clearTransitionListener = this.props.router.listenBefore(
      this.confirmBeforeTransition
    );
    window.onbeforeunload = handleOnBeforeUnload;
  }

  componentWillUnmount() {
    this.clearTransitionListener();

    window.onbeforeunload = null;
  }

  confirmBeforeTransition(location, callback) {
    if (location.action === 'REPLACE') {
      callback(true);
      return;
    }

    if (this.props.customChecklistFields.size > 0) {
      this.props.openExitCreateRecordWithCustomFieldsConfirmationModal(
        () => callback(true),
        () => this.props.clearCustomFields()
      );
    } else {
      callback(true);
    }
  }

  render() {
    const {
      predefinedChecklistFieldsResponse,
      predefinedChecklistFields,
      customChecklistFields,
      recordTypeUrl,
    } = this.props;

    if (
      isPending(predefinedChecklistFieldsResponse) ||
      !predefinedChecklistFields
    ) {
      return <Loading />;
    }

    if (hasFailed(predefinedChecklistFieldsResponse)) {
      return (
        <p>{t('digitalTools.records.checklist.oopsCouldNotRetrievePredefinedFields', 'Oops! Could not retrieve predefined fields for this record group!')}</p>
      );
    }

    const checklistName = predefinedChecklistFields.name;
    const predefinedChecklistFieldsData =
      predefinedChecklistFields.predefined_fields;

    const recordGroupMetadataFieldData =
      predefinedChecklistFields.record_group_meta_fields;

    const recordGroupMetadataFieldKeys = recordGroupMetadataFieldData.map(
      field => field.slug
    );

    // Determine if a checklist is dynamic or non-dynamic from the dynamic attribute
    // of it's first predefined field.
    const isDynamicChecklist = predefinedChecklistFieldsData[0].dynamic;

    // Map headings found in the fields list and determine their start and end positions.
    // We use these positions to determine the order of new custom fields in relation to
    // headings the user wants them to appear under.
    let headingsMap = OrderedMap({});

    for (let i = 0; i < predefinedChecklistFieldsData.length; i++) {
      const slug = predefinedChecklistFieldsData[i].slug;
      const heading = predefinedChecklistFieldsData[i].heading;
      const order = predefinedChecklistFieldsData[i].order;

      if (heading !== null && slug !== `${recordTypeUrl}_notes`) {
        if (headingsMap.has(heading)) {
          headingsMap = headingsMap.setIn([heading, 'lastIndex'], order);
        } else {
          headingsMap = headingsMap.set(
            heading,
            Map({
              heading: heading,
              firstIndex: order,
              lastIndex: order,
            })
          );
        }
      }
    }

    // Add heading for Miscellaneous items
    headingsMap = headingsMap.set(
      'Misc',
      Map({
        heading: t('digitalToolls.records.checklist.misc', 'Misc'),
        firstIndex: predefinedChecklistFieldsData.length + 1,
        lastIndex: predefinedChecklistFieldsData.length + 1,
      })
    );

    const predefinedChecklistHeadings = headingsMap.toList().toJS();

    const showFieldHeading = index => {
      if (index === 0 && predefinedChecklistFieldsData[0].heading !== null) {
        return (
          <h3 className="mb-1">
            {predefinedChecklistFieldsData[index].heading}
          </h3>
        );
      }

      if (
        index > 0 &&
        predefinedChecklistFieldsData[index].heading !==
          predefinedChecklistFieldsData[index - 1].heading
      ) {
        return (
          <h3 className="mb-1">
            {predefinedChecklistFieldsData[index].heading}
          </h3>
        );
      }
    };

    const renderPredefinedCheckItemField = (
      options,
      field,
      isCustomField = false
    ) => {
      return (
        <div className="form-group">
          <div className={isCustomField ? 'col-1-3' : 'col-2-3'}>
            <label className="mr-1" htmlFor={field.name}>
              {field.name}
            </label>
          </div>
          <div className={'col-1-3'}>
            {options.map((choice, idx) => {
              return (
                <label className="label-inline" key={idx}>
                  <input type="radio" value={choice} disabled />
                  {choice}
                </label>
              );
            })}
          </div>
          {isCustomField && (
            <div className="col-1-3">
              <a onClick={() => this.props.removeCustomField(field)}>
                <Icon type="close" className="small-icon" />
              </a>
            </div>
          )}
        </div>
      );
    };

    const renderPredefinedTextItemField = (field, isCustomField = false) => {
      return (
        <div className="form-group">
          <div className={isCustomField ? 'col-1-3' : 'col-2-3'}>
            <label className="mr-1" htmlFor={field.name}>
              {field.name}
            </label>
          </div>
          <div className="col-1-3">
            <label className="livetext-field">
              <input
                id={field.name}
                type="text"
                className="form-control"
                disabled
              />
            </label>
          </div>
          {isCustomField && (
            <div className="col-1-3 mt-1">
              <a onClick={() => this.props.removeCustomField(field)}>
                <Icon type="close" className="small-icon" />
              </a>
            </div>
          )}
        </div>
      );
    };

    const renderPredefinedTextAreaField = field => {
      return (
        <div className="form-group">
          <div className="col-1">
            <label className="livetext-field">
              <textarea id={field.name} className="form-control" disabled />
            </label>
          </div>
        </div>
      );
    };

    return (
      <div>
        <h1 className="mb-1">
          {CHECKLIST_RECORD_GROUP_NAME_MAPPING[recordTypeUrl]}
        </h1>
        <HelpBlock className="small-text mb-1">
          <h5 className="m0 mb-1-2 mt-1-4 extra-bold-text">
            { t('digitalTools.records.checklist.createnewDigitalChecklist', 'Create a new Digital Checklist.') }
          </h5>
          {!isDynamicChecklist && (
            <p>
            {t(
              'digitalTools.records.checklist.addCustomFieldDescription',
              `Below are the default list items that will be included within each Checklist of this type, that you create. If you would like to add additional fields of your own, you may do so using the ‘Add Custom Field’ button at the bottom of the page. You can add as many as you like. But these items are final, so make sure you include everything before you create the Checklist.`
            )}
          </p>
          )}
          <LiveButton
            onClick={() =>
              this.props.openCreateChecklistRecordGroupModal(
                checklistName,
                recordTypeUrl,
                recordGroupMetadataFieldKeys
              )
            }
            className={`mb-1 btn-default ${
              !isDynamicChecklist ? 'pull-right' : 'pull-left'
            }`}
          >
            {t('digitalTools.records.checklist.createChecklist', 'Create Checklist')}
          </LiveButton>
        </HelpBlock>
        {recordGroupMetadataFieldData.length > 0 && (
          <div>
            <h2 className="mb-1">{ t('digitalTools.records.checklist.recordGroupInformation', 'Record group information') }</h2>
            <div className="checklist-border mb-1 mt-1" />
            <CreateChecklistRecordGroupMetadataForm
              form={CREATE_CHECKLIST_RECORD_GROUP_METADATA_FORM}
              fields={recordGroupMetadataFieldKeys}
              fieldsData={recordGroupMetadataFieldData}
            />
          </div>
        )}
        {!isDynamicChecklist && (
          <div>
            <h2 className="mb-1">{ t('digitalTools.records.checklist.predefinedFields', 'Predefined Fields') }</h2>
            <div className="checklist-border mb-1 mt-1" />
            {predefinedChecklistFieldsData.map((field, i) => {
              switch (field.type) {
                case 'YES_NO_NA':
                  return (
                    <div>
                      {showFieldHeading(i)}
                      {renderPredefinedCheckItemField(
                        [
                          t('common.label.yes.upperCase', 'YES'),
                          t('common.label.no.upperCase', 'NO'),
                          t('common.label.na.upperCase', 'NA'),
                        ],
                        field
                      )}
                      {field.help_text && (
                        <p className="pb-2 col-1 checklist-help-text">
                          {field.help_text}
                        </p>
                      )}
                    </div>
                  );
                case 'YES_NO':
                  return (
                    <div>
                      {showFieldHeading(i)}
                      {renderPredefinedCheckItemField([
                        t('common.label.yes.upperCase', 'YES'),
                        t('common.label.no.upperCase', 'NO'),
                      ], field)}
                      {field.help_text && (
                        <p className="pb-2 col-1 checklist-help-text">
                          {field.help_text}
                        </p>
                      )}
                    </div>
                  );
                case 'FREE_TEXT':
                  return (
                    <div>
                      {showFieldHeading(i)}
                      {renderPredefinedTextItemField(field)}
                      {field.help_text && (
                        <p className="pb-2 col-1 checklist-help-text">
                          {field.help_text}
                        </p>
                      )}
                    </div>
                  );
                case 'FREE_TEXT_AREA':
                  return (
                    <div>
                      {showFieldHeading(i)}
                      {renderPredefinedTextAreaField(field)}
                      {field.help_text && (
                        <p className="pb-2 col-1 checklist-help-text">
                          {field.help_text}
                        </p>
                      )}
                    </div>
                  );
                case 'PASS_FAIL_NA':
                  return (
                    <div>
                      {showFieldHeading(i)}
                      {renderPredefinedCheckItemField(
                        [
                          t('common.label.pass.upperCase', 'PASS'),
                          t('common.label.fail.upperCase', 'FAIL'),
                          t('common.label.na.upperCase', 'NA'),
                        ],
                        field
                      )}
                      {field.help_text && (
                        <p className="pb-2 col-1 checklist-help-text">
                          {field.help_text}
                        </p>
                      )}
                    </div>
                  );
                default:
                  return (
                    <div>
                      {showFieldHeading(i)}
                      {renderPredefinedTextItemField(field)}
                      {field.help_text && (
                        <p className="pb-2 col-1 checklist-help-text">
                          {field.help_text}
                        </p>
                      )}
                    </div>
                  );
              }
            })}

            <h3 className="mb-1">
              Documents
            </h3>
            {renderDisabledFileUploadField("Add Supporting Documents:")}

            <div className="checklist-border mb-1 mt-1" />
            <h2 className="mb-1">Custom Fields</h2>
            {customChecklistFields.toJS().map(field => {
              switch (field.type) {
                case 'YES_NO_NA':
                  return (
                    <div>
                      <label className="italic-text light-grey">
                        {field.heading}
                      </label>
                      {renderPredefinedCheckItemField(
                        [
                          t('common.label.yes.upperCase', 'YES'),
                          t('common.label.no.upperCase', 'NO'),
                          t('common.notApplicable', 'N/A'),
                        ],
                        field,
                        true
                      )}
                    </div>
                  );
                case 'YES_NO':
                  return (
                    <div>
                      <label className="italic-text light-grey">
                        {field.heading}
                      </label>
                      {renderPredefinedCheckItemField(
                        [
                          t('common.label.yes.upperCase', 'YES'),
                          t('common.label.no.upperCase', 'NO'),
                        ],
                        field,
                        true
                      )}
                    </div>
                  );
                case 'FREE_TEXT':
                  return (
                    <div>
                      <label className="italic-text light-grey">
                        {field.heading}
                      </label>
                      {renderPredefinedTextItemField(field, true)}
                    </div>
                  );
                default:
                  return (
                    <div>
                      <label className="italic-text light-grey">
                        {field.heading}
                      </label>
                      {renderPredefinedTextItemField(field)}
                    </div>
                  );
              }
            })}
            <LiveButton
              onClick={() =>
                this.props.openCreateCustomFieldModal(
                  predefinedChecklistHeadings,
                  customChecklistFields
                )
              }
              className="mb-1 btn-default pull-left"
            >
              {t('digitalTools.records.checklist.addCustomField', 'Add Custom Field')}
            </LiveButton>
          </div>
        )}
      </div>
    );
  }
}

export function mapStateToProps(state, props) {
  return {
    predefinedChecklistFields: state.predefinedChecklistFields,
    customChecklistFields: state.customChecklistFields,
    predefinedChecklistFieldsResponse: state.responses.get(
      'getPredefinedChecklistFields'
    ),
    digitalRecordChecklist: state.digitalRecordChecklist,
    digitalRecordChecklistResponse: state.responses.get(
      'getDigitalRecordChecklist'
    ),
    recordTypeUrl: props.params?.recordTypeUrl,
  };
}

export default connect(mapStateToProps, {
  clearCustomFields,
  removeCustomField,
  openCreateCustomFieldModal,
  getPredefinedChecklistFields,
  createUpdateDigitalRecordChecklist,
  openCreateChecklistRecordGroupModal,
  openExitCreateRecordWithCustomFieldsConfirmationModal,
})(withRouter(CreateChecklistRecordGroup));
