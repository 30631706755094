import React from 'react';
import moment from 'moment';
import classNames from 'classnames';

import Icon from '^/components/app/content/Icon';
import { t } from '^/i18n';

export function renderTextItem(field, label, onBlurFn, showSavedIcon = true) {
  return (
    <div className="form-group">
      <div className="col-2-3">
        <label className="mr-1" htmlFor={field.name}>
          {label}
        </label>
      </div>
      <div className="col-1-3">
        <label className="livetext-field">
          <input
            id={field.name}
            type="text"
            onBlurCapture={onBlurFn}
            className="form-control"
            {...field}
          />
          {showSavedIcon &&
            ((!field.touched === true && field.initialValue === true) ||
              field.pristine === true) && (
              <Icon type="floppy-disk" width="24" height="auto" />
            )}
        </label>
      </div>
    </div>
  );
}

export function renderTextAreaItem(
  field,
  label,
  onBlurFn,
  showSavedIcon = true
) {
  return (
    <div className="form-group">
      <div className="col-1">
        <label className="livetext-field">
          <textarea
            id={field.name}
            onBlurCapture={onBlurFn}
            className="form-control"
            {...field}
          />
          {showSavedIcon &&
            ((!field.touched === true && field.initialValue === true) ||
              field.pristine === true) && (
              <Icon type="floppy-disk" width="24" height="auto" />
            )}
        </label>
      </div>
    </div>
  );
}

export function renderCheckItem(
  field,
  label,
  onChangeFn,
  choices = [
    t('common.label.yes.upperCase', 'YES'),
    t('common.label.no.upperCase', 'NO'),
    t('common.label.na.upperCase', 'NA'),
  ]
) {
  return (
    <div className="form-group">
      <div className="col-2-3">
        <label className="mr-1" htmlFor={field.name}>
          {label}
        </label>
      </div>
      <div className="col-1-3">
        {choices.map((choice, i) => {
          return (
            <label className="label-inline" key={i}>
              <input
                {...field}
                type="radio"
                value={choice}
                checked={field.value === choice}
                onChange={onChangeFn}
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                onBlur={() => {}}
              />
              {choice}
            </label>
          );
        })}
      </div>
    </div>
  );
}

export function applyColumnItemStyle(data) {
  if (
    moment(data, 'YYYY-MM-DD', true).isValid() &&
    moment(data, 'YYYY-MM-DD').isBefore()
  ) {
    return 'drug-expired';
  }
}

export function renderCheckItemWithSnapshotData(
  field,
  onChangeFn,
  snapshotData,
  choices = [
    t('common.label.yes.upperCase', 'YES'),
    t('common.label.no.upperCase', 'NO'),
    t('common.label.na.upperCase', 'NA'),
  ]
) {
  return (
    <tr>
      {snapshotData.map((data, i) => {
        const formattedData = moment(data, 'YYYY-MM-DD', true).isValid()
          ? moment(data).format('DD-MM-YYYY')
          : data;
        return (
          <td className={classNames(applyColumnItemStyle(data))} key={i}>
            {formattedData}
          </td>
        );
      })}
      {choices.map((choice, i) => {
        return (
          <th key={i}>
            <label>
              {choice}
              <input
                {...field}
                type="radio"
                value={choice}
                checked={field.value === choice}
                onChange={onChangeFn}
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                onBlur={() => {}}
              />
            </label>
          </th>
        );
      })}
    </tr>
  );
}

export function renderTextItemWithSnapshotData(field, onBlurFn, snapshotData) {
  return (
    <tr>
      {snapshotData.map((data, i) => (
        <th key={i}>{data}</th>
      ))}
      <th>
        <label className="livetext-field">
          <input
            id={field.name}
            type="text"
            onBlurCapture={onBlurFn}
            className="form-control"
            {...field}
          />
          {((!field.touched === true && field.initialValue === true) ||
            field.pristine === true) && (
            <Icon type="floppy-disk" width="24" height="auto" />
          )}
        </label>
      </th>
    </tr>
  );
}
