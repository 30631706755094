import React from 'react';

import { t } from '^/i18n';
import PureComponent from '^/components/common/PureComponent';
import NoAuth from '^/components/app/users/login/NoAuth';
import LoggedOutLogo from '^/components/app/users/LoggedOutLogo';
import { connect } from 'react-redux';
import { logout } from '^/actions/actions';
import LiveButton from '^/components/app/forms/LiveButton';
import { openConfirmUpgradeDemoModal } from '^/actions/modals';


export class LockedOutDemo extends PureComponent {
  render() {
    return (
      <div>
        <LoggedOutLogo />
        <NoAuth>
          <div className="text-center pl-1 pr-1 pb-2">
            <h2 className="mt-2 mb-1-2">{t('users.accountExpired', 'Account Expired')}</h2>
            <p>{t('users.thankYouMsg', 'Thank you for your interest in iComply')}.</p>
            <p className="bold-text">{t('users.demoExpiredMsg', 'Your demo period has expired')}.</p>
            <p className="mb-1">{t('users.practiceSignupMsg', 'Please click the button below to sign up your practice for its first cycle')}.</p>

            <LiveButton className="btn-default btn-primary mr-1" onClick={this.props.openConfirmUpgradeDemoModal}>
              {t('common.button.register', 'Register')}
            </LiveButton>
          <LiveButton className="btn-default" onClick={this.props.logout}>
            {t('common.button.logout', 'Logout')}
          </LiveButton>
          </div>
        </NoAuth>
      </div>
    );
  }
}

export default connect(null, { logout, openConfirmUpgradeDemoModal })(LockedOutDemo);
