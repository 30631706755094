import { ConnectHOC } from '@dabapps/connect-hoc';
import React, { Component } from 'react';
import { RouteComponentProps, Link } from 'react-router';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import { t } from '^/i18n';
import {
  BaseItem,
  HealthcheckCategoryMerged,
  HealthcheckCategoriesMerged,
  Healthcheck,
  CachedItem,
  HealthcheckStatus,
} from '^/components/app/healthcheck/types';
import CategoryArea from '^/components/app/healthcheck/category-area';
import DeleteHealthcheck from '^/components/app/healthcheck/delete';
import ProgressBar from '^/components/app/healthcheck/progress-bar';
import { StoreState } from '^/store/types';
import Loading from '^/components/app/content/Loading';
import NonFieldErrorRenderer from '^/components/common/NonFieldErrorRenderer';
import {
  selectNonImmutableUserProfile,
  selectCategoryIndexById,
  selectCurrentPracticeHealthCheck,
  selectPrevCategoryId,
  selectNextCategoryId,
  selectHealthcheckById,
} from '^/selectors/healthcheck';
import {
  clearThunkRequestErrors,
  updateHealthcheckStatusIfMatchAndContinue,
  getHealthcheckById,
} from '^/actions/healthcheck';
import ProgressListComponent from '^/components/app/healthcheck/progress-list';
import { HEALTHCHECK_CLIENT_URL_BASE } from '^/consts/healthcheck';
import { cachedItemIsStale } from '^/components/app/healthcheck/utils';
import { CategoryOptionsHealthcheck } from './types';

const connectHOC = connect as ConnectHOC;

interface StateProps<T extends BaseItem> {
  practiceName: string | undefined;
  isLoading: boolean;
  errors: unknown;
  categoryIndex: number | undefined;
  category: HealthcheckCategoryMerged<T> | undefined;
  categories: HealthcheckCategoriesMerged<T> | null;
  healthcheck: Healthcheck | undefined;
  nextCategoryId: string | null;
  prevCategoryId: string | null;
  displayedHealthcheck: CachedItem<Healthcheck> | null;
}

interface DispatchProps {
  fetchCategoryItems: (healthcheckId: string, categoryId: string) => void;
  navigateToCategory: (healthcheckId: string, categoryId: string) => void;
  updateHealthcheckStatusIfMatchAndContinue: typeof updateHealthcheckStatusIfMatchAndContinue;
  clearThunkRequestErrors: typeof clearThunkRequestErrors;
  getHealthcheckById: typeof getHealthcheckById;
}

type RouteProps = RouteComponentProps<
  { healthcheckId: string; categoryId: string },
  {}
>;

type Props<T extends BaseItem> = StateProps<T> & DispatchProps & RouteProps;

const createCategoryPage = <T extends BaseItem>(
  options: CategoryOptionsHealthcheck<T>
) => {
  const pushToCategory = (healthcheckId: string, categoryId: string) =>
    push(
      `${HEALTHCHECK_CLIENT_URL_BASE}${healthcheckId}${options.urlPart}${categoryId}`
    );

  class HealthcheckCategoryPage extends Component<Props<T>, {}> {
    public componentDidMount() {
      const {
        displayedHealthcheck,
        params: { healthcheckId, categoryId },
      } = this.props;

      if (categoryId) {
        this.props.fetchCategoryItems(healthcheckId, categoryId);
      }

      if (!displayedHealthcheck || cachedItemIsStale(displayedHealthcheck)) {
        this.props.getHealthcheckById(healthcheckId);
      }
    }

    public componentDidUpdate(prevProps: Props<T>) {
      const {
        displayedHealthcheck,
        params: { healthcheckId, categoryId },
      } = this.props;

      if (prevProps.params.categoryId !== categoryId) {
        options.requestKeys.map(requestKey =>
          this.props.clearThunkRequestErrors(requestKey)
        );
        this.props.fetchCategoryItems(healthcheckId, categoryId);
      }

      if (!displayedHealthcheck || cachedItemIsStale(displayedHealthcheck)) {
        this.props.getHealthcheckById(healthcheckId);
      }
    }

    public render() {
      const {
        isLoading,
        categoryIndex,
        category,
        categories,
        practiceName,
        params,
        prevCategoryId,
        displayedHealthcheck,
      } = this.props;

      if (isLoading) {
        return (
          <div className="healthcheck">
            <Loading />
          </div>
        );
      }

      if (!displayedHealthcheck) {
        return (
          <div className="healthcheck">
            <NonFieldErrorRenderer errors="Failed to get active healthcheck" />
          </div>
        );
      }

      return (
        <div className="healthcheck">
          <div className="main">
            <h1>
              <span className="bold">
                {t(
                  'healthcheck.complianceHealthcheck',
                  'Compliance Healthcheck'
                )}{' '}
                - {options.type}
              </span>
              <span className="ml-1">{practiceName}</span>
            </h1>
            <hr className="thin" />
            <NonFieldErrorRenderer errors={this.props.errors} />
            <div>
              <p className="content">
                {category ? options.content : options.noContent}
              </p>
              {category && (
                <h2>
                  {t('healthcheck.category', 'Category')}{' '}
                  {(categoryIndex || 0) + 1}/{categories?.length ?? 0}:{' '}
                  {category.name}
                </h2>
              )}
              {category &&
                category.areas?.map(area => (
                  <CategoryArea
                    key={area.id}
                    area={area}
                    category={category}
                    itemRenderer={options.itemRenderer}
                    healthcheckId={params.healthcheckId}
                    areaHeaders={options.areaHeaders}
                    disabled={
                      displayedHealthcheck?.status !== HealthcheckStatus.ACTIONS
                    }
                  />
                ))}
              <div className="button-group">
                {category && (
                  <button
                    className="btn btn-secondary"
                    onClick={this.onPrevClick}
                    disabled={!prevCategoryId}
                  >
                    {'<'} {t('common.previous', 'Previous')}
                  </button>
                )}
                {this.renderButton()}
              </div>
            </div>
          </div>

          <div className="aside">
            <ProgressBar item={displayedHealthcheck} />
            <ProgressListComponent
              type={options.type}
              selectedCategoryId={params.categoryId}
              id={params.healthcheckId}
              categories={categories}
              urlPart={options.urlPart}
            />
            {displayedHealthcheck?.status !== HealthcheckStatus.COMPLETED && (
              <DeleteHealthcheck id="1" />
            )}
          </div>
        </div>
      );
    }

    private renderButton = () => {
      const {
        displayedHealthcheck,
        nextCategoryId,
        params: { healthcheckId },
      } = this.props;
      if (
        displayedHealthcheck &&
        ![HealthcheckStatus.ACTIONS, HealthcheckStatus.AUDIT].includes(
          displayedHealthcheck.status
        )
      ) {
        if (nextCategoryId) {
          return (
            <button className="btn btn-save" onClick={this.onNextClick}>
              {t('common.next', 'Next')} >
            </button>
          );
        }

        return (
          <button className="btn btn-save">
            <Link to={`${HEALTHCHECK_CLIENT_URL_BASE}${healthcheckId}/review/`}>
              {t('healthcheck.backToReview', 'Back to review')}
            </Link>
          </button>
        );
      }

      return (
        <button className="btn btn-save" onClick={this.onNextClick}>
          {nextCategoryId
            ? `${t('common.next', 'Next')} >`
            : `${t('healthcheck.complete', 'Complete')} ${options.type} stage`}
        </button>
      );
    };

    private onNextClick = () => {
      const { params, nextCategoryId } = this.props;
      if (nextCategoryId) {
        this.props.navigateToCategory(params.healthcheckId, nextCategoryId);
      } else {
        this.props.updateHealthcheckStatusIfMatchAndContinue(
          options.intendedFromStatus
        );
      }
    };

    private onPrevClick = () => {
      const { params, prevCategoryId } = this.props;
      if (prevCategoryId) {
        this.props.navigateToCategory(params.healthcheckId, prevCategoryId);
      }
    };
  }

  const mapStateToProps = (
    state: StoreState,
    props: RouteProps
  ): StateProps<T> => {
    const categories = options.getCategories(state);
    const categoryIndex = selectCategoryIndexById(
      categories,
      props.params.categoryId
    );

    return {
      practiceName: selectNonImmutableUserProfile(state)?.staffdetail
        .current_practice.name,
      isLoading: options.getIsLoading(state),
      errors: options.getErrors(state),
      categoryIndex,
      category:
        typeof categoryIndex !== 'undefined' && categoryIndex >= 0
          ? categories?.[categoryIndex]
          : undefined,
      categories,
      healthcheck: selectCurrentPracticeHealthCheck(state),
      nextCategoryId: selectNextCategoryId(categories, props.params.categoryId),
      prevCategoryId: selectPrevCategoryId(categories, props.params.categoryId),
      displayedHealthcheck: selectHealthcheckById(
        state,
        props.params.healthcheckId
      ),
    };
  };

  return connectHOC<StateProps<T>, DispatchProps, RouteProps, StoreState>(
    mapStateToProps,
    {
      fetchCategoryItems: options.fetchCategoryAction,
      navigateToCategory: pushToCategory,
      updateHealthcheckStatusIfMatchAndContinue: updateHealthcheckStatusIfMatchAndContinue,
      clearThunkRequestErrors,
      getHealthcheckById,
    }
  )(HealthcheckCategoryPage);
};

export default createCategoryPage;
