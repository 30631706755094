import React from 'react';
import PureComponent from '^/components/common/PureComponent';
import moment from 'moment';
import { t } from '^/i18n';

import LocaleSwitcher from '^/components/app/nav/LocaleSwitcher';

export default class Footer extends PureComponent {
  render() {
    return (
      <div className="footer">
        <div className="wrapper">
          <div className="row display-flex">
            <div className="mr-1">
              &copy; {moment().year()} |{' '}
              {t(
                'common.footer',
                `iComply is a trading name registered trademark of CODEplan Limited, part of the Agilio Software Group, registered at Unit 18, Jessops Riverside, 800 Brightside Lane, Sheffield, England, S9 2RX | Registered in England and Wales | Company Number: 03927086 | VAT 865046024`
              )}
            </div>
            <LocaleSwitcher />
          </div>
        </div>
      </div>
    );
  }
}
