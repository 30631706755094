import React from 'react';
import { connect } from 'react-redux';
import { List } from 'immutable';

import { t } from '^/i18n';
import { getCollection, getAllCollection } from '^/actions/collections';
import { TASKS, TASKS_COMPLETE, MY_TASKS_MANAGE } from '^/consts/collectionKeys';
import { deleteTaskAndClearSelection } from '^/actions/actionSequences';
import { loadItem } from '^/actions/items';
import { isAssignedTo } from '^/models/task';
import { isNotReadOnlyInCurrentPractice } from '^/models/user';
import { isPending } from '^/consts/responseStates';
import { openCreateTaskModal } from '^/actions/modals';
import { getActivePracticeMembers } from '^/stateHelpers';
import PermissionsComponent from '^/components/app/perms/PermissionsComponent';
import PureComponent from '^/components/common/PureComponent';
import Loading from '^/components/app/content/Loading';
import ManageTasksList from '^/components/app/tasks/manage/ManageTasksList';
import ViewManageTask from '^/components/app/tasks/manage/ViewManageTask';
import CollapsibleLeftNav from '^/components/app/widgets/CollapsibleLeftNav';
import PracticeMembersFilter from '^/components/app/dashboard/PracticeMembersFilter';


export class ManageTasksPage extends PureComponent {

  componentWillMount() {
    const { currentPracticeId, activeId } = this.props;
    if (currentPracticeId) {
      this.loadManageTasks(currentPracticeId);
    }
    this.loadActiveTask(activeId);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.currentPracticeId !== this.props.currentPracticeId) {
      this.loadTasks(nextProps.currentPracticeId);
    }

    if (nextProps.activeId !== this.props.activeId) {
      this.loadActiveTask(nextProps.activeId);
    }
  }

  loadTasks(currentPracticeId) {
    if (currentPracticeId) {
      this.props.getAllCollection(TASKS, { filters: { practice: currentPracticeId } });
      this._loadCompletedTasks(currentPracticeId);
    }
  }

  _loadCompletedTasks(currentPracticeId, opts) {
    this.props.getCollection(
      TASKS_COMPLETE,
      Object.assign({ filters: { practice: currentPracticeId } }, opts)
    );
  }

  loadCompletedTasks(opts) {
    this._loadCompletedTasks(this.props.currentPracticeId, opts);
  }

  loadMoreCompletedTasks() {
    this.loadCompletedTasks({page: this.props.completedTasksPage + 1, shouldAppend: true});
  }


  loadActiveTask(activeTaskId) {
    if (activeTaskId) {
      this.props.loadItem(TASKS, activeTaskId);
    }
  }

  loadManageTasks(currentPracticeId) {
    this.props.getAllCollection(MY_TASKS_MANAGE, {filters: {practice: currentPracticeId}});
  }

  renderNoActiveTask() {
    const { response, tasks, activeId, activeTaskResponse } = this.props;
    const message = tasks.isEmpty() ? t('tasks.noTasksFound', 'No tasks found.') : t('tasks.chooseTaskFromList', 'Please choose a task from the list on the left.');

    return (
      <div className="empty-screen">
        {(isPending(response) || isPending(activeTaskResponse)) ? (
          <Loading />
        ) : (
          <div>
            <h5 className="text-empty-state">
              {activeId ? t('tasks.noTaskAccessMsg', 'You do not have access to this task.') : message}
            </h5>
            <PermissionsComponent hasPermission={isNotReadOnlyInCurrentPractice} >
              <button onClick={this.props.openCreateTaskModal} type="button" className="btn btn-default">
                {t('tasks.button.createTask', 'Create a task')}
              </button>
            </PermissionsComponent>
          </div>
        )}
      </div>
    );
  }

  getTasks() {
    const { tasks, currentTeamMember } = this.props;
    const currentTeamMemberId = currentTeamMember && currentTeamMember.getIn(['staffdetail', 'id']);

    return currentTeamMemberId
      ? tasks.filter(isAssignedTo(currentTeamMemberId))
      : tasks;
  }

  renderNoTasks() {
    const { response } = this.props;

    return isPending(response) ? (
      <Loading />
    ) : (
      <div>
        <hr className="thin" />
        <p className="help-block">{t('tasks.noTasks', 'No tasks.')}</p>
      </div>
    );
  }

  render() {
    const {
      response, activeId, loadCompletedResponse, moreCompletedTasksAvailable,
      activeTask, practiceMembers, children, user, deleteTaskResponse, currentPractice
    } = this.props;

    const tasks = this.getTasks();

    return (
      <div className="row">
        <div className="col-1-3 secondary-left">
          <CollapsibleLeftNav>
            {children}
            <hr className="thin" />
            <PracticeMembersFilter />

            {tasks.isEmpty() ? (
              this.renderNoTasks()
            ) : (
              <div>
                <ManageTasksList
                  tasks={tasks}
                  activeId={activeId}
                  onLoadCompleted={() => this.loadCompletedTasks()}
                  onLoadMoreCompleted={moreCompletedTasksAvailable && (() => this.loadMoreCompletedTasks())}
                  loadCompletedResponse={loadCompletedResponse}
                />
                {isPending(response) && <Loading />}
              </div>
            )}
          </CollapsibleLeftNav>
        </div>
        <div className="col-2-3 bordered-left-d">
          {activeTask ? (
            <ViewManageTask
              user={user}
              task={activeTask}
              practiceMembers={practiceMembers}
              delete={() => this.props.deleteTask(activeTask.get('id'))}
              deleteTaskResponse={deleteTaskResponse}
              currentPractice={currentPractice}
            />
          ) : (
            this.renderNoActiveTask()
          )}
        </div>
      </div>
    );
  }
}

export function mapStateToProps(state, props) {
  const incompleteTasks = state.collections.getIn([TASKS, 'items']) ||
    state.collections.getIn([MY_TASKS_MANAGE, 'items']) || List();
  const completedTasks = state.collections.get(TASKS_COMPLETE);
  const completedTasksPage = completedTasks && completedTasks.get('page');
  const moreCompletedTasksAvailable = completedTasks && completedTasks.get('hasMore');
  const tasks = incompleteTasks.concat(completedTasks && completedTasks.get('items') || List());

  const activeId = props.params.uuid;
  const activeTask = state.items.get(TASKS);
  const currentPracticeId = state.currentPractice && state.currentPractice.get('id');
  const practiceMembers = getActivePracticeMembers(state);

  return {
    activeId,
    activeTask,
    tasks,
    currentPracticeId,
    practiceMembers,
    moreCompletedTasksAvailable,
    completedTasksPage,
    user: state.userProfile,
    loadCompletedResponse: state.responses.getIn(['getCollection', TASKS_COMPLETE]),
    activeTaskResponse: state.responses.getIn(['loadItem', TASKS]),
    deleteTaskResponse : state.responses.getIn(['deleteItem', TASKS]),
    response: state.responses.getIn(['getCollection', TASKS]) ||
      state.responses.getIn(['getCollection', MY_TASKS_MANAGE]),
    currentTeamMember: state.currentTeamMember,
    currentPractice: state.currentPractice,
  };
}

export default connect(
  mapStateToProps,
  { getCollection, getAllCollection, openCreateTaskModal, deleteTask: deleteTaskAndClearSelection, loadItem, }
) (ManageTasksPage);
