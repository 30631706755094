import React, { Component } from 'react';
import { connect } from 'react-redux';

import AuditSummaryTable from '^/components/app/digital-tools/audit/summary-table';
import {
  AuditListSummary,
  AuditType,
} from '^/components/app/digital-tools/audit/types';
import Loading from '^/components/app/content/Loading';
import { getAuditsByType, createAuditAndContinue } from '^/actions/audit';
import { StoreState } from '^/store/types';
import { t } from '^/i18n';

interface OwnProps {
  type: string;
}

interface DispatchProps {
  getAuditsByType: typeof getAuditsByType;
  createAuditAndContinue: typeof createAuditAndContinue;
}

interface StateProps {
  audits: AuditListSummary[] | undefined;
  auditType: AuditType | undefined;
  isLoading: boolean;
}

export type AuditListProps = OwnProps & DispatchProps & StateProps;

class AuditList extends Component<AuditListProps, {}> {
  componentDidMount() {
    const { type } = this.props;
    this.props.getAuditsByType(type);
  }

  componentDidUpdate(prevProps: OwnProps) {
    const { type } = this.props;

    if (prevProps.type !== type) {
      this.props.getAuditsByType(type);
    }
  }

  render() {
    const { audits, auditType, isLoading } = this.props;

    if (isLoading) {
      return <Loading />;
    }

    return (
      <div className="ml-1">
        <h3>{auditType ? auditType.name : ''}</h3>
        {audits && audits.length ? (
          <div>
            <p className="mb-3">
              {t(
                'digitalTools.audit.continueAudit',
                'You can only continue an audit if you were the user who started it, or you are a Practice Admin or Manager.'
              )}
              <br />
              {t(
                'digitalTools.audit.ifThisIsNotYou',
                'If this is not you, please contact the user who started the audit or contact the iComply team.'
              )}
            </p>
            <AuditSummaryTable summaryList={audits} />
          </div>
        ) : (
          <div>
            {t(
              'digitalTools.audit.noAuditsStarted',
              'No Digital Audits started yet.'
            )}
          </div>
        )}
        {auditType && auditType.allow_new_creation && (
          <button
            className="btn btn-save mt-1 mr-1 mb-3 pull-right"
            onClick={this.onClickCreateAudit}
          >
            {t('digitalTools.audit.startAudit', 'Start Audit')}
          </button>
        )}
      </div>
    );
  }
  private onClickCreateAudit = () => {
    if (this.props.auditType) {
      this.props.createAuditAndContinue(this.props.auditType.id);
    }
  };
}

const mapStateToProps = (state: StoreState, props: OwnProps): StateProps => {
  const filteredAuditTypes = state.audit.auditTypes.filter(
    type => type.id === props.type
  );

  return {
    audits: state.audit.auditsByType[props.type],
    auditType: filteredAuditTypes ? filteredAuditTypes[0] : undefined,
    isLoading: state.thunkRequests.getAuditsByType.isLoading,
  };
};

export default connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, {
  getAuditsByType,
  createAuditAndContinue,
})(AuditList);
