import React from 'react';
import { connect } from 'react-redux';

import { t } from '^/i18n';
import CSVExporterButton from '^/components/common/CSVExporterButton';
import PureComponent from '^/components/common/PureComponent';
import { selectInsightFilters } from '^/selectors/insights';


export class InsightsCSVExportButton extends PureComponent {
  render() {
    const { url, filters } = this.props;
    return (
      <CSVExporterButton
        url={url}
        filters={filters}
        className="btn btn-default"
      >
        {t('groups.insights.downloadCsv', 'DOWNLOAD CSV')}
      </CSVExporterButton>
    );
  }
}

export function mapStateToProps(state, props) {
  return {
    filters: selectInsightFilters(props.insight)(state)
  };
}

export default connect(mapStateToProps) (InsightsCSVExportButton);
