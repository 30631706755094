import { t } from '^/i18n';

export const TABS_PATH = {
  DASHBOARD: 'dashboard',
  CALENDAR: 'calendar',
  TASKS: 'tasks',
  DOCUMENTS: 'documents',
  REPORTS: 'reports',
  DIGITAL_TOOLS: 'tools',
  PRACTICE: 'practice',
  HELP: 'help',
};


export const TABS = [
  {
    path: TABS_PATH.DASHBOARD,
    label: t('common.dashboard', 'dashboard'),
    counter: 'overDueActivityCount',
  },
  {
    path: TABS_PATH.CALENDAR,
    label: t('common.calendar', 'calendar'),
    counter: 'alertsCount',
  },
  {
    path: TABS_PATH.TASKS,
    label: t('common.tasks', 'tasks'),
    applicationOnly: true,
  },
  {
    path: TABS_PATH.DOCUMENTS,
    label: t('common.templateDocuments', 'template documents'),
  },
  {
    path: TABS_PATH.REPORTS,
    label: t('common.reports', 'reports'),
    applicationOnly: true,
    adminOnly: true,
  },
  {
    path: TABS_PATH.DIGITAL_TOOLS,
    label: t('common.digital', 'digital tools'),
  },
  {
    path: TABS_PATH.PRACTICE,
    label: t('common.practices', 'practices'),
  },
  {
    path: TABS_PATH.HELP,
    label: t('common.help', 'help'),
    iconType: 'comments',
    iconClassName: 'white-icon title ml-1',
  },
];
