import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import { getAuditAuditResults } from '^/actions/audit';
import createIntroPage from './hoc/create-intro-page';
import { StoreState } from '^/store/types';
import { AuditAuditResult, AuditStatus, AuditWorkflowTypes } from './types';
import AuditResultsTable from '^/components/app/healthcheck/audit-results-table';
import AuditScore from '^/components/app/healthcheck/audit-score';
import { t } from '^/i18n';

interface StateProps {
  auditAuditResults: AuditAuditResult[] | null;
  auditAuditResultsIsLoading: boolean;
  rawScore?: number;
}

interface DispatchProps {
  getAuditAuditResults: typeof getAuditAuditResults;
}

type OwnProps = RouteComponentProps<{ auditId: string }, {}>;

export type Props = StateProps & DispatchProps & OwnProps;

export class Content extends Component<Props, {}> {
  public componentDidMount() {
    const {
      params: { auditId },
    } = this.props;
    this.props.getAuditAuditResults(auditId);
  }

  public render() {
    const {
      auditAuditResults,
      auditAuditResultsIsLoading,
      rawScore,
    } = this.props;

    return (
      <div className="content">
        <div>
          {t(
            'common.hereAreTheResultsOfYourAudit',
            'Here are the results of your audit as a total and broken down by each section:'
          )}
        </div>
        <div className="review-row pb-2 pt-2">
          <AuditResultsTable
            isLoading={auditAuditResultsIsLoading}
            results={auditAuditResults}
          />
          <AuditScore
            isLoading={auditAuditResultsIsLoading}
            review={null}
            rawScore={rawScore}
          />
        </div>
        <div>
          {t(
            'common.wellDoneOnCompletingTheAudit',
            "Well done on completing the audit. Don't worry if you don't do as well as expected, auditing is all about learning and improving."
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: StoreState): StateProps => {
  return {
    auditAuditResults: state.audit.auditResults?.categories || null,
    rawScore: state.audit.auditResults?.overall_score,
    auditAuditResultsIsLoading:
      state.thunkRequests.getAuditAuditResults.isLoading,
  };
};

const ConnectedContent = connect<StateProps, DispatchProps, OwnProps>(
  mapStateToProps,
  {
    getAuditAuditResults,
  }
)(Content);

export default createIntroPage<Props>({
  type: AuditWorkflowTypes.AuditResults,
  intendedFromStatus: AuditStatus.AUDIT_RESULTS,
  buttonText: t('common.next', 'Next'),
})(ConnectedContent as any); // eslint-disable-line @typescript-eslint/no-explicit-any
