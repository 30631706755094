import React, { PropTypes } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';

import { t } from '^/i18n';
import { mergeFieldChange, SEDATION_TYPES } from '^/models/practice';
import { filterObject } from '^/utils';

import FormError from '^/components/app/forms/FormError';
import classNames from 'classnames';

const IS_OFFERING_SEDATION = 'is_offering_sedation';
const SEDATION_TYPE = 'sedation_type';

export const FIELD_DESCRIPTIONS = {
  has_nhs_treatment_only: t('practices.hasNHSTreatment', 'Do you provide NHS treatment only?'),
  has_mixed_nhs_private_treatment: t('practices.hasMixedNHSPrivateTreatment', 'Do you provide mixed NHS/private treatment?'),
  has_private_treatment_only: t('practices.hasPrivateTreatmentOnly', 'Do you provide private treatment only?'),
  has_washer_disinfector: t('practices.hasWasherDisinfector', 'Do you have a washer disinfector?'),
  has_ultrasonic_bath: t('practices.hasUltrasonicBath', 'Do you have an ultrasonic bath?'),
  has_hygienist_prescribed_local_anaesthetics: t('practices.hasHygienistPrescribedLocalAnasthetics', `Does your hygienist/therapist prescribe local anaesthetics? (i.e. do you use Patient Group Directions)`),
  has_radon: t('practices.hasRadon', `Do you have radon or have you not assessed for it? (tick no if you have assessed for it and found that you don't have any)`),
  has_asbestos: t('practices.hasAsbestos', `Do you have asbestos or have you not assessed for it? (tick no if you have assessed for it and found that you don't have any)`),
  [IS_OFFERING_SEDATION]: t('practices.isOfferingSedation', 'Do you perform sedation? (if yes what type?)'),
  has_practice_dispensed_drugs: t('practices.hasPracticeDispensedDrugs', 'Do you dispense drugs from the practice?'),
  has_lasers: t('practices.hasLasers', 'Do you use lasers?'),
  has_digital_xray_equipment: t('practices.hasDigitalXrayEquipment', 'Do you use digital x-ray equipment?'),
  has_film_xray_equipment: t('practices.hasFilmXrayEquipment', 'Do you use film x-ray equipment?'),
  has_handheld_xray_equipment: t('practices.hasHandheldXrayEquipment', 'Do you use hand-held x-ray equipment?'),
  has_staff_incentive_scheme: t('practices.hasStaffIncentiveScheme', 'Do you run a staff incentive scheme?'),
  has_gas_boiler: t('practices.hasGasBoiler', 'Do you have a gas boiler?'),
  is_offering_surgical_procedures: t('practices.isOfferingSurgicalProcedures', 'Do you offer Surgical Procedures inc contraceptive implants?'),
  has_non_medical_prescribers: t('practices.hasNonMedicalPrescribers', 'Do you have non-medical prescribers?'),
  has_allied_health_professionals: t('practices.hasAlliedHealthProfessionals', 'Are any of your staff Allied Health Professionals?'),
  has_lift: t('practices.hasLift', 'Do you have a lift?'),
  has_aed: t('practices.hasAed', 'Do you have an AED?'),
  has_air_conditioning: t('practices.hasAirConditioning', 'Does the practice have air conditioning?'),
  has_domiciliary_care: t('practices.hasDomiciliaryCare', 'Do you offer domiciliary care?'),
  has_cctv: t('practices.hasCctv', 'Do you have CCTV?'),
  has_hygienists_work_without_nurse: t('practices.hasHygienistsWorkWithoutNurse', 'Do your hygienists/therapists work without a nurse?'),
  has_call_log: t('practices.hasCallLog', 'Does your practice record calls?'),
  has_dac_autoclaves: t('practices.hasDacAutoclaves', 'Do you have any DAC autoclaves?'),
  has_infection_control_best_practice: t('practices.hasInfectionControlBestPractice', 'Is your infection control currently at W/HTM 0105 \'Best Practice\'?'),
  has_practice_organisation_or_partnership: t('practices.hasPracticeOrganisationOrPartnership', 'Is your practice part of an organisation or partnership?'),
  has_patient_xray_referrals: t('practices.hasPatientXrayReferrals', 'Do you refer patients for X-rays (no X-ray equipment onsite)?'),
  has_practice_amalgam: t('practices.hasPracticeAmalgam', 'Does your practice use amalgam (mercury)?'),
  // Netherlands fields
  has_pharmacy: t('Has a pharmacy?'),
  is_npa: t('Is NPA?'),
  is_iso9001: t('Is ISO 9001?'),
  is_nen_en15224: t('NEN-EN15224?'),
};

const fieldName = (field) => field.name.indexOf('.') !== -1 ? field.name.split('.')[1] : field.name;

const getFieldChange = (field, valuesChanged) => valuesChanged ?
    event => mergeFieldChange(valuesChanged, field, fieldName(field), event) :
    field.onChange;

const isPending = (name, pendingFields, nestedFormPath) =>
  pendingFields && pendingFields.hasIn(nestedFormPath.concat(name));


export const SedationTypeSelect = ({ field, valuesChanged, pendingFields, nestedFormPath, response }) =>
  <div className="form-group">
    <div className="col-2-3 label-left">
      <label htmlFor={SEDATION_TYPE}>{t('practices.sedationType', 'Sedation type')}:</label>
    </div>
    <div className="col-1-3">
      <select
        id={SEDATION_TYPE}
        disabled={isPending(fieldName(field), pendingFields, nestedFormPath)}
        value={field.value}
        onChange={getFieldChange(field, valuesChanged)}
        className="form-select"
      >
        {SEDATION_TYPES.map((display, value) =>
          <option value={value} key={value}>{display}</option>)}
      </select>
    </div>

    <FormError response={response} formKeyPath={nestedFormPath.concat([SEDATION_TYPE])} />
  </div>;

export const BooleanRadioField = ({
  field,
  description,
  valuesChanged,
  pendingFields,
  sedationTypeField,
  response,
  nestedFormPath
}) =>
  <div>
    <div className="form-group">

      <div className="col-2-3 label-left">
        <label>{description}</label>
      </div>
      <div className="col-1-3">
        <label className="label-inline">
          <input
            type="radio"
            onChange={getFieldChange(field, valuesChanged)}
            disabled={isPending(fieldName(field), pendingFields, nestedFormPath)}
            value
            name={fieldName(field)}
            checked={field.value === 'true'}
          />
          <span className={classNames(field.value === 'true' && 'bold-text')}> {t('common.label.yes', 'Yes')}</span>
        </label>
        <label className="label-inline">
          <input
            type="radio"
            onChange={getFieldChange(field, valuesChanged)}
            disabled={isPending(fieldName(field), pendingFields, nestedFormPath)}
            value={false}
            name={fieldName(field)}
            checked={field.value !== 'true'}
          />
          <span className={classNames(field.value !== 'true' && 'bold-text')}> {t('common.no', 'No')}</span>
        </label>

      </div>
      <FormError response={response} formKeyPath={nestedFormPath.concat([fieldName(field)])} />
    </div>

    {fieldName(field) === IS_OFFERING_SEDATION && field.value === 'true' &&
      <SedationTypeSelect
        pendingFields={pendingFields}
        valuesChanged={valuesChanged}
        field={sedationTypeField}
        response={response}
        nestedFormPath={nestedFormPath}
      />
    }

  </div>;

export function getComplianceDetailsFields(practice) {
  return practice.get('compliance_details_fields').toArray();
}

export function filterComplianceDetailsFields(allFields, practice) {
  const expectedFields = getComplianceDetailsFields(practice);
  return filterObject(allFields, (_, field) => expectedFields.includes(field));
}

export const PracticeComplianceDetailsForm = ({
  fields, practice, valuesChanged, pendingFields, response, nestedFormPath
}) => {
  const orderedFieldNames = getComplianceDetailsFields(practice).filter(field => field !== SEDATION_TYPE);
  return (<div>
    {orderedFieldNames.map((name) =>
      <BooleanRadioField
        key={name}
        valuesChanged={valuesChanged}
        field={fields[name]}
        description={FIELD_DESCRIPTIONS[name]}
        pendingFields={pendingFields}
        nestedFormPath={nestedFormPath || []}
        response={response}
        sedationTypeField={fields[SEDATION_TYPE]}
      />
    )}
  </div>);

}

PracticeComplianceDetailsForm.propTypes = {
  fields: PropTypes.object.isRequired,
  valuesChanged: PropTypes.func,
  nestedFormPath: PropTypes.array,
  pendingFields: ImmutablePropTypes.map,
  response: ImmutablePropTypes.map,
};

export default PracticeComplianceDetailsForm;
