import React from 'react';
import classNames from 'classnames';

import { t } from '^/i18n';
import NonFieldErrorRenderer from '^/components/common/NonFieldErrorRenderer';
import { getRateColourClassNames, asPercent } from '^/utils';
import { THRESHOLD_BAD, THRESHOLD_OK } from '^/consts/healthcheck';
import Loading from '^/components/app/content/Loading';
import { HealthcheckReview } from './types';
import { AuditReview } from '^/components/app/digital-tools/audit/types';

interface AuditScoreProps {
  isLoading: boolean;
  review: HealthcheckReview | AuditReview | null;
  rawScore?: number;
}

const AuditScore = ({ isLoading, review, rawScore }: AuditScoreProps) => {
  if (isLoading) {
    return <Loading />;
  }

  if (!review && !rawScore) {
    return (
      <NonFieldErrorRenderer
        errors={[
          t('healthcheck.couldNotGetAuditScore', 'Could not get audit score.'),
        ]}
      />
    );
  }

  return (
    <div>
      <h2>Audit Score</h2>
      <p
        className={classNames(
          'review-score',
          'bold',
          getRateColourClassNames(
            rawScore || review?.overall_score,
            THRESHOLD_BAD,
            THRESHOLD_OK
          )
        )}
      >
        {asPercent(rawScore || review?.overall_score)}%
      </p>
    </div>
  );
};

export default AuditScore;
