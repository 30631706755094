import React from 'react';

import { t } from '^/i18n';
import Icon from '^/components/app/content/Icon';
import ChooseAssignees from './ChooseAssignees';
import TaskError from './TaskError';

export const AssigneePopup = ({onAdd, onRemove, practiceMembers, assignees, onClose, response, }) =>
  <div className="popup">
    <a className="close" onClick={onClose}>
      <Icon type="close" className="inline-icon" />
    </a>

    <h5>{t('tasks.changeAssignees', 'Change assignee(s)')}</h5>

    <ChooseAssignees
      assignees={assignees}
      practiceMembers={practiceMembers}
      onAdd={onAdd}
      onRemove={onRemove}
    />

    <TaskError response={response} />
  </div>;

export default AssigneePopup;
