import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';

import { isComplete } from '^/models/activities';
import { openCreateTaskModal } from '^/actions/modals';
import Icon from '^/components/app/content/Icon';
import PermissionsComponent from '^/components/app/perms/PermissionsComponent';
import { isNotReadOnlyInCurrentPractice } from '^/models/user';
import TasksList from '^/components/app/activities/TasksList';

import { createDigitalRecordTaskAndCloseModal } from '^/actions/actionSequences';
import { t } from '^/i18n';

function CreateRecordsTask(
  data,
  user,
) {
  const transformedTaskData = Object.assign(
    {},
    data,
    {
      created_by: user.getIn(['staffdetail', 'id']),
      assignees: data.assignees,
    },
  );
  return createDigitalRecordTaskAndCloseModal(transformedTaskData);
}

export const RelatedTasks = props => {
  const { tasks, wide, helpText, isArchived} = props;
  const completeTasks = tasks.filter(isComplete)
  const incompleteTasks = tasks.filterNot(isComplete);

  const button =
    <PermissionsComponent hasPermission={isNotReadOnlyInCurrentPractice} >
      {!isArchived && (
        <button
          className="btn btn-default indented"
          onClick={() => props.openCreateTaskModal({createTask: props.CreateRecordsTask, hideRepeat: true})}
          type="button"
        >
          <Icon type="check-square-o" className="button-icon" /> { t('activities.createRelatedTask', 'Create Related Task') }
        </button>
      )}
    </PermissionsComponent>
  ;

  const list =
    <div>
      <h4 className="m-toggle mt-1">
        { `${t('activities.relatedTasks', 'Related Tasks')}:`}
        {helpText && (
          <p className="help-block">
            <Icon type="lightbulb-o" className="tip-icon" />
            {helpText}
          </p>
        )}
      </h4>

      {!tasks.isEmpty() ? (
        <div>
          <TasksList tasks={incompleteTasks} />
          {!completeTasks.isEmpty() && (
            <div>
              <h4 className="m-toggle">{ `${t('activities.completedTasks', 'Completed Tasks')}:` }</h4>
              <TasksList tasks={completeTasks} />
            </div>
          )}
        </div>
      ) : (
        <h5 className="text-empty-state">
          { t('activities.noRelatedTasks', 'No related tasks') }
        </h5>
      )}
    </div>
  ;

  return (
    <div>
      {wide ? (
        <div>{list}{button}</div>
      ) : (
        <div>{button}{list}</div>
      )}
    </div>
  );
};

RelatedTasks.propTypes = {
  tasks: ImmutablePropTypes.list.isRequired,
};


export function mapDispatchToProps(dispatch) {
  return {
    CreateRecordsTask: (data, user) => dispatch(CreateRecordsTask(data, user)),
    openCreateTaskModal: (createTask) => dispatch(openCreateTaskModal(createTask))
  };
}

export default connect(null, mapDispatchToProps) (RelatedTasks);
