import React, { Component } from 'react';
import { connect } from 'react-redux';

import { t } from '^/i18n';
import { completeHealthcheckAndCloseModal } from '^/actions/healthcheck';
import { HealthcheckReview } from '^/components/app/healthcheck/types';
import NonFieldErrorRenderer from '^/components/common/NonFieldErrorRenderer';
import { StoreState } from '^/store/types';
import { makeStaticPath } from '^/utils';
import { extractErrorMessage } from '^/utils/common';

interface StateProps {
  healthcheckReview: HealthcheckReview | null;
  isLoading: boolean;
  error: string | null;
}

interface DispatchProps {
  completeHealthcheckAndCloseModal: typeof completeHealthcheckAndCloseModal;
}

export type Props = StateProps & DispatchProps;

class CompleteHealthcheckConfirmModal extends Component<Props, {}> {
  public render() {
    if (!this.props.healthcheckReview) {
      return null;
    }

    const {
      action_completed_count,
      action_total_count,
    } = this.props.healthcheckReview;

    return (
      <div className="modal-body large-text">
        <div className="p-1 clearfix">
          <div className="text-center">
            <img
              src={makeStaticPath('images/brand/logo-color.svg')}
              className="brand-logo"
            />
          </div>
          <p className="mt-2">
            {t(
              'healthcheck.completeHealthcheckConfirmationMessage',
              'Are you sure you want to complete the healthcheck?'
            )}
          </p>
          <p className="mt-1">
            {`${
              action_total_count
                ? `${t(
                    'healthcheck.youHaveCompleted',
                    'You have completed'
                  )} ${action_completed_count}/${action_total_count} ${t(
                    'healthcheck.actions',
                    'actions'
                  )}.`
                : t('healthcheck.noActionsToComplete', 'No actions to complete')
            }`}
          </p>
          <p className="mt-1">
            {t(
              'healthcheck.markAsCompletedNoreturnToCompleteActions',
              'Once you mark as completed you will not be able to return to complete the actions.'
            )}
          </p>
          <p className="mt-1">
            {t(
              'healthcheck.clickComplete',
              'Click COMPLETE to complete the healthcheck or exit to continue the healthcheck.'
            )}
          </p>
          <button
            className="btn btn-save pull-right mt-2"
            onClick={this.onCompleteHealthcheck}
            disabled={this.props.isLoading}
          >
            {t('healthcheck.button.complete', 'Complete')}
          </button>
          <div className="mt-2">
            <NonFieldErrorRenderer errors={this.props.error} />
          </div>
        </div>
      </div>
    );
  }

  private onCompleteHealthcheck = () => {
    this.props.completeHealthcheckAndCloseModal();
  };
}

export { CompleteHealthcheckConfirmModal as UnconnectedCompleteHealthcheckConfirmModal };

const mapStateToProps = (state: StoreState): StateProps => {
  return {
    healthcheckReview: state.healthcheck.review,
    isLoading: state.thunkRequests.updateHealthcheckStatus.isLoading,
    error: extractErrorMessage(
      state.thunkRequests.updateHealthcheckStatus.errors
    ),
  };
};

export default connect<StateProps, DispatchProps, {}>(mapStateToProps, {
  completeHealthcheckAndCloseModal,
})(CompleteHealthcheckConfirmModal);
