import React from 'react';

import { t } from '^/i18n';
import NoAuth from '^/components/app/users/login/NoAuth';
import LoggedOutLogo from '^/components/app/users/LoggedOutLogo';

const InvalidEmailVerification = () => (
  <div>
    <LoggedOutLogo />
    <NoAuth>
      <div className="form-padded">
        <p>
          {t(
            'users.invalidEmailVerificationMsg',
            `This email verification link is no longer valid. Please contact Code, who will verify your email to allow access to iComply.`
          )}
        </p>
      </div>
    </NoAuth>
  </div>
);

export default InvalidEmailVerification;
