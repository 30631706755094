import React from 'react';
import {connect} from 'react-redux';
import { reduxForm } from 'redux-form';

import { t } from '^/i18n';
import { GROUP_DETAILS } from '^/consts/collectionKeys';
import Img from '^/components/app/content/Img';
import FormError from '^/components/app/forms/FormError';
import LiveButton from '^/components/app/forms/LiveButton';
import PictureUpload from '^/components/app/forms/PictureUpload';
import PureComponent from '^/components/common/PureComponent';
import { PRACTICE_GROUP_DETAILS } from '^/models/practice';
import TextField from '^/components/app/widgets/TextField';
const EDIT_GROUP_DETAILS_FORM = 'EDIT_GROUP_DETAILS_FORM';


export class EditGroupDetailsForm extends PureComponent {
  render() {
    const {
      fields: {
        name,
        logo,
        suppress_all_code_document_alerts_to_practices,
        override_code_document_alert_suppression_to_group,
      },
      fields,
      handleSubmit,
      response,
      setPendingUploadInForm,
    } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <FormError response={response} />
        <TextField field={name} display="Group Name" response={response} required />
        {PRACTICE_GROUP_DETAILS.map((display, fieldName) =>
          <TextField key={fieldName} field={fields[fieldName]} display={display} response={response} />
        )}

        <div className="form-group">
          <div className="col-1-3">
            <label htmlFor={suppress_all_code_document_alerts_to_practices.name}>
              {t('groups.label.suppressDocumentsAlertAtPracticeLevel', 'Suppress ALL iComply document alerts at Practice level')}
            </label>
          </div>
          <div className="col-2-3">
            <div className="display-flex flex-align-items-flex-start">
              <input
                id={suppress_all_code_document_alerts_to_practices.name}
                type="checkbox"
                {...suppress_all_code_document_alerts_to_practices}
                />
              <span className="help-inline">
                {t(
                  'groups.notifyGroupUpdatesOnDashboardMsg',
                  'When selected, Practices in the Group will only be notified of Group document updates on their dashboard.'
                )}
              </span>
            </div>
            <FormError response={response} formKey={suppress_all_code_document_alerts_to_practices.name} />
          </div>
        </div>
        <div className="form-group">
          <div className="col-1-3">
            <label htmlFor={override_code_document_alert_suppression_to_group.name}>
              {t('groups.label.overrideSuppressedAlerts', 'Override suppressed alerts at a Group Admin level')}
            </label>
          </div>
          <div className="col-2-3">
            <div className="display-flex flex-align-items-flex-start">
              <input
                id={override_code_document_alert_suppression_to_group.name}
                type="checkbox"
                {...override_code_document_alert_suppression_to_group}
              />
              <span className="help-inline">
                {t('groups.documentVisibleToAdminsWhenSelectedMsg', 'When selected, Group Admins will see all iComply document updates, including the suppressed alerts with minor changes.')}
              </span>
            </div>
            <FormError response={response} formKey={override_code_document_alert_suppression_to_group.name} />
          </div>
        </div>

        <div className="form-group">
          <div className="col-1-3">
            <label htmlFor="logo">{t('groups.label.logo', 'Logo')}</label>
          </div>
          <div className="col-2-3">
            {logo.defaultValue && (
              <PictureUpload
                id="logo" src={logo.value && (logo.value.preview || logo.value)}
                display={src => src && <Img cover className="image-fit" src={src} />}
                setPendingUpload={(file) => setPendingUploadInForm(EDIT_GROUP_DETAILS_FORM, 'logo', file)}
                onSubmit={handleSubmit}
              />
            )}
            <FormError response={response} formKey="logo" />
          </div>
        </div>
        <div className="modal-footer text-right">
          <LiveButton pendingMessage={`${t('groups.savingGroupDetails', 'Saving Group Details')}...`} response={response}>
            {t('common.button.save', 'Save')}
          </LiveButton>
        </div>
      </form>
    );
  }
}

const FORM_FIELDS =
  PRACTICE_GROUP_DETAILS.keySeq()
    .toArray()
    .concat([
      'name',
      'logo',
      'suppress_all_code_document_alerts_to_practices',
      'override_code_document_alert_suppression_to_group',
    ]);

const FormifiedEditGroupDetailsForm = reduxForm({
  form: EDIT_GROUP_DETAILS_FORM,
  fields: FORM_FIELDS
})(EditGroupDetailsForm);

function mapStateToProps(state) {
  return {
    response: state.responses.getIn(['updateItem', GROUP_DETAILS])
  };
}

export default connect(mapStateToProps)(FormifiedEditGroupDetailsForm);
