import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';

import { t } from '^/i18n';
import PureComponent from '^/components/common/PureComponent';
import Loading from '^/components/app/content/Loading';
import { getPracticeDocumentVersions } from '^/actions/actions';
import { getAppSectionPathPrefix } from '^/utils';


export class PracticeAdoptionModal extends PureComponent {

  componentWillMount() {
    const { template } = this.props;
    this.props.getPracticeDocumentVersions(template.get('id'));
  }

  renderVersion(documentVersion, currentPathname) {
    const {
      id,
      version_number,
      template_document,
      group_template_document,
      adopted_template_document,
    } = documentVersion.toObject();

    const linkProps = {
      to: `${getAppSectionPathPrefix(currentPathname)}version/${id}/`,
      className: 'underlined inline-link',
      target: '_blank',
    };

    if (template_document) {
      return (
        <Link {...linkProps} >
          {t('templateDocument.icomplyDocument', 'iComply document')} v{version_number}
        </Link>
      );
    }

    const { template_document_version } = (adopted_template_document || group_template_document).toObject();

    return (
      <span className="small-text">
        <Link {...linkProps} >
          {adopted_template_document ? 'Practice document' : `Group document v${version_number}`}
        </Link> {t('templateDocument.adoptedFrom', 'adopted from')} {
          this.renderVersion(template_document_version, currentPathname)
        }
      </span>
    );
  }

  render() {
    const { practiceDocumentVersions, template, currentPathname } = this.props;

    if (!practiceDocumentVersions || practiceDocumentVersions.get('template') !== template.get('id')) {
      return <Loading />;
    }

    return (
      <div>
        <h4>{template.get('name')}</h4>
        <dl>
        {practiceDocumentVersions.get('practices').map((each, idx) =>
          <div key={idx}>
            <dt>{each.getIn(['practice', 'name'])}</dt>
            <dd>{this.renderVersion(each.get('template_document_version'), currentPathname)}</dd>
          </div>
        )}
        </dl>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    practiceDocumentVersions: state.practiceDocumentVersions,
    currentPathname: state.routing.location.pathname,
  };
}

export default connect(
  mapStateToProps,
  { getPracticeDocumentVersions }
) (PracticeAdoptionModal);
