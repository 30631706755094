import React from 'react';
import { reduxForm } from 'redux-form';
import { Link } from 'react-router';

import { t } from '^/i18n';
import Icon from '^/components/app/content/Icon';
import { getDocumentLink } from '^/models/documents';
import LiveButton from '^/components/app/forms/LiveButton';

export const REASSIGN_PRIVATE_DOCUMENTS_COPIES_LIST_FORM = 'REASSIGN_PRIVATE_DOCUMENTS_COPIES_LIST_FORM';

export const PrivateDocumentField = ({fields, privateDocument, currentPracticeMembers}) => {
  const field = fields[privateDocument.get('id')];
  return (
    <tr>
      <td>
        <label htmlFor={privateDocument.get('id')}>
          <Link
            className="list-link indented-link"
            target="_blank"
            to={getDocumentLink(privateDocument.get('id')) + 'copies/'}
          >
            <Icon type="file-text-o" className="template-icon" /> {privateDocument.get('name')}
           </Link>
        </label>
      </td>
      <td>
        <select id={field} {...field} >
          {currentPracticeMembers.map(each =>
            <option key={each.getIn(['user', 'staffdetail', 'id'])} value={each.getIn(['user', 'staffdetail', 'id'])}>
              {each.getIn(['user', 'staffdetail', 'full_name'])}
            </option>
          )}
        </select>
      </td>
    </tr>
  );
};

export const ReassignPrivateDocumentsCopiesListForm = ({
  fields,
  templates,
  currentPracticeMembers,
  response,
  handleSubmit,
  onCancel
}) =>
  <form onSubmit={handleSubmit}>
    <div className="mt-1 small-text">
      {templates.size > 0 ? (
        <div>
          <table>
            <thead>
              <tr>
                <th>
                  <strong>{t('templateDocument.privateDocuments', 'Private Documents')} ({templates.size})</strong>
                </th>
                <th>{t('common.newAssignee', 'New Assignee')}</th>
              </tr>
            </thead>
            <tbody>
              {templates.map(each =>
                <PrivateDocumentField
                  key={each.get('id')}
                  currentPracticeMembers={currentPracticeMembers}
                  privateDocument={each}
                  fields={fields}
                />
              )}
            </tbody>
          </table>
          <hr className="thin" />
          <div className="row">
            <div className="col-1">
              <LiveButton
                className="btn-primary pull-right"
                response={response}
              >
                {t('common.button.reassign', 'Reassign')}
              </LiveButton>
              <a className="btn btn-default pull-right mr-1-2" onClick={onCancel}>
                {t('common.button.cancel', 'Cancel')}
              </a>
            </div>
          </div>
        </div>
      ) : (
        <p>{t('templateDocument.noPrivateDocumentsToReassign', 'No private documents to reassign')}.</p>)
      }
    </div>
  </form>;

export default reduxForm({
  form: REASSIGN_PRIVATE_DOCUMENTS_COPIES_LIST_FORM,
})(ReassignPrivateDocumentsCopiesListForm);
