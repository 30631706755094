import React from 'react';
import moment from 'moment';
import { reduxForm } from 'redux-form';
import { Map, Set } from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';

import { t } from '^/i18n';
import {
  REMINDER_CHOICES_TEXT,
  getRepeatOptions,
  toOptionValue,
} from '^/models/task';

import PureComponent from '^/components/common/PureComponent';
import DateInput from '^/components/app/forms/DateInput';
import FormError from '^/components/app/forms/FormError';
import LiveButton from '^/components/app/forms/LiveButton';
import ManyRelatedSelect from '^/components/app/forms/ManyRelatedSelect';
import MultipleFileUpload from '^/components/app/forms/MultipleFileUpload';
import ChooseAssignees from './ChooseAssignees';
import ShowIfAdmin from '^/components/app/perms/ShowIfAdmin';
import DemoModeMayDisallow from '^/components/app/perms/DemoModeMayDisallow';
import DemoModeDisable from '^/components/app/perms/DemoModeDisable';

export const CREATE_TASK_FORM = 'CREATE_TASK_FORM';

export class CreateTaskForm extends PureComponent {
  render() {
    const {
      fields,
      handleSubmit,
      response,
      practiceMembers,
      isRelated,
      values,
      singleAssignee,
      templateDocuments,
      uploadFileResponse,
      relatedActivityFiles,
      hideRepeat
    } = this.props;

    const {
      assignees,
      title,
      entry,
      deadline,
      reminder,
      repeatOption,
      related_templates,
      related_files,
      related_activity_files,
    } = fields;

    const assigneeSet =
      values && values.assignees ? Set(values.assignees) : Set();
    const relatedTemplateSet =
      values && values.related_templates
        ? Set(values.related_templates)
        : Set();
    const relatedActivityFileSet =
      values && values.related_activity_files
        ? Set(values.related_activity_files)
        : Set();

    return (
      <form onSubmit={handleSubmit}>
        <FormError response={response} />

        <div className="form-group">
          <div className="col-1-3">
            <label htmlFor="title">{t('common.title', 'Title')}*</label>
          </div>
          <div className="col-2-3">
            <input
              type="text"
              placeholder={t('common.title', 'Title')}
              className="form-control"
              id="title"
              {...title}
            />
            <FormError response={response} formKey="title" />
            <FormError error={title && title.touched && title.error} />
          </div>
        </div>

        <div className="form-group">
          <div className="col-1-3">
            <label htmlFor="description">{t('tasks.description', 'Description')}</label>
          </div>
          <div className="col-2-3">
            <textarea id="description" value={entry.value || ''} {...entry} />
            <FormError response={response} formKey="entry" />
          </div>
        </div>

        <ShowIfAdmin>
          <div className="form-group">
            <div className="col-1-3">
              <label htmlFor="assignee">{t('tasks.assignTo', 'Assign to')}*</label>
            </div>
            <div className="col-2-3">
              <ChooseAssignees
                assignees={practiceMembers.filter(each =>
                  assigneeSet.contains(each.get('id'))
                )}
                practiceMembers={practiceMembers}
                onAdd={id => assignees.onChange(assigneeSet.add(id).toArray())}
                singleAssignee={singleAssignee}
                onRemove={id =>
                  assignees.onChange(assigneeSet.delete(id).toArray())
                }
              />
              <FormError response={response} formKey="assignees" />
              <FormError
                error={assignees && assignees.touched && assignees.error}
              />
            </div>
          </div>
        </ShowIfAdmin>

        <ShowIfAdmin>
          <div className="form-group">
            <div className="col-1-3">
              <label htmlFor="assignee">{t('tasks.attachDocuments', 'Attach Documents')}</label>
            </div>
            <div className="col-2-3">
              <div className="row">
                <div className="col-1">
                  <p>{t('tasks.uploadYourOwnDocument', 'Upload your own document')}</p>
                  <DemoModeDisable message="cannot attach files">
                    <MultipleFileUpload
                      handleSubmit={handleSubmit}
                      field={related_files}
                      uploadFile={this.props.uploadFile}
                      uploadFileResponse={uploadFileResponse}
                      formName={CREATE_TASK_FORM}
                    />
                  </DemoModeDisable>
                  <hr className="thin" />
                </div>
                <div className="col-1">
                  <p>{t('tasks.selectFromiComply', 'Or select one from iComply')}</p>
                  <ManyRelatedSelect
                    selectedItems={templateDocuments.filter(each =>
                      relatedTemplateSet.contains(each.get('id'))
                    )}
                    items={templateDocuments}
                    onAdd={id =>
                      related_templates.onChange(
                        relatedTemplateSet.add(id).toArray()
                      )
                    }
                    onRemove={id =>
                      related_templates.onChange(
                        relatedTemplateSet.delete(id).toArray()
                      )
                    }
                    controlName="related-templates"
                    displayKey={['name']}
                  />
                  <FormError response={response} formKey="related_templates" />
                </div>
                {isRelated && relatedActivityFiles && (
                  <div className="col-1">
                    <p>{t('tasks.selectRelatedActivityFileUpload', 'Or select a file uploaded to the related activity')}</p>
                    <ManyRelatedSelect
                      selectedItems={relatedActivityFiles.filter(each =>
                        relatedActivityFileSet.contains(each.get('id'))
                      )}
                      items={relatedActivityFiles}
                      onAdd={id =>
                        related_activity_files.onChange(
                          relatedActivityFileSet.add(id).toArray()
                        )
                      }
                      onRemove={id =>
                        related_activity_files.onChange(
                          relatedActivityFileSet.delete(id).toArray()
                        )
                      }
                      controlName="related-templates"
                      displayKey={['name']}
                    />
                    <FormError
                      response={response}
                      formKey="related_activity_files"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </ShowIfAdmin>

        <div className="form-group">
          <div className="col-1-3">
            <label htmlFor="deadline">Due*</label>
          </div>
          <div className="col-2-3">
            <DateInput id="deadline" {...deadline} />
            <FormError response={response} formKey="deadline" />
            <FormError
              error={deadline && deadline.touched && deadline.error}
            />
          </div>
        </div>

        <div className="form-group">
          <div className="col-1-3">
            <label htmlFor="reminder">{t('tasks.reminder', 'Reminder')}</label>
          </div>
          <div className="col-2-3">
            <select {...reminder} id="reminder">
              {Map(REMINDER_CHOICES_TEXT).map((choiceText, choice) => (
                <option key={choice} value={choice}>
                  {choiceText}
                </option>
              ))}
            </select>
            <FormError response={response} formKey="reminder" />
          </div>
        </div>

        {!isRelated && !hideRepeat && (
          <div className="form-group">
            <div className="col-1-3">
              <label htmlFor="repeats">{t('tasks.repeats', 'Repeats')}</label>
            </div>
            <div className="col-2-3">
              <select {...repeatOption} id="repeats">
                {getRepeatOptions(Map({ deadline: deadline.value })).map(
                  option => (
                    <option key={option} value={toOptionValue(option)}>
                      {option.get('text')}
                    </option>
                  )
                )}
              </select>
              <FormError response={response} formKey="repeat" />
              <FormError response={response} formKey="repeat_interval" />
            </div>
          </div>
        )}

        <div className="form-group">
          <div className="col-1">
            <DemoModeMayDisallow
              message={t('tasks.createMaximumTasks', 'can create a maximum of five tasks')}
              response={response}
            >
              <LiveButton
                pendingMessage={`${t('common.creating', 'Creating')}...`}
                response={response}
                className="btn-default"
              >
                {t('common.button.create', 'Create')}
              </LiveButton>
            </DemoModeMayDisallow>
          </div>
        </div>
      </form>
    );
  }
}

export function validate(values) {
  const { title, assignees, deadline } = values;
  const errors = {};

  const hasValidDeadline = deadline && moment(deadline).isValid();
  if (!hasValidDeadline) {
    errors.deadline = t('tasks.dueDateRequired', 'Due date is required.');
  }
  if (!title) {
    errors.title = t('tasks.titleRequired', 'Title is required.');
  }
  if (assignees && assignees.length === 0) {
    errors.assignees = t('tasks.assigneeRequired', 'At least one assignee is required.');
  }

  return errors;
}

CreateTaskForm.propTypes = {
  practiceMembers: ImmutablePropTypes.iterable.isRequired,
  templateDocuments: ImmutablePropTypes.iterable.isRequired,
  isRelated: React.PropTypes.bool,
};

export default reduxForm({
  form: CREATE_TASK_FORM,
  fields: [
    'assignees',
    'title',
    'entry',
    'deadline',
    'reminder',
    'repeatOption',
    'related_templates',
    'related_files',
    'related_activity_files',
  ],
  validate,
})(CreateTaskForm);
